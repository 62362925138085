import React from 'react';
import Card from '../../../Card';
import { Button } from '../../../inputs';
import preformTrainingStyles from './styles.module.scss';
import history from '../../../../history';
import QuizScoreBox from '../../QuizScoreBox/quizScoreBox';

export const QuizStatusCard = ({
  trainingId,
  training,
  canTakeQuiz,
  fromAdminister,
  wide,
  name
}) => (
  <Card
    title={training?.quiz.title}
    showHeader
    name={name ?? 2}
    className={preformTrainingStyles.quizCard}
    wide={wide}
  >
    <span className={preformTrainingStyles.quizAnswers}>
      {`${training?.quiz.questions.length} Question(s) / ${training?.quiz
        ?.numberOfRetriesAllowed ?? 1} Attempt(s)`}
    </span>
    <Button
      text={canTakeQuiz ? 'Take Quiz' : 'View Quiz'}
      onClick={() => {
        if (fromAdminister)
          localStorage.setItem('trainingTemplate', JSON.stringify(training));
        history.push({
          pathname: '/app/training/quiz',
          state: {
            passedQuiz: canTakeQuiz
              ? {
                  ...training.quiz,
                  questions: training.quiz.questions.map(q => ({
                    ...q,
                    assigneeSelectedAnswers: []
                  }))
                }
              : training.quiz,
            isPreview: !canTakeQuiz,
            hideCheckmark: fromAdminister,
            showAnswers: !fromAdminister && !canTakeQuiz,
            trainingId: trainingId ?? training._id
          }
        });
      }}
      color="blue"
      className={preformTrainingStyles.button}
    />
    {training.quizScores?.map((score, i) => (
      <QuizScoreBox
        quizScore={score}
        attemptNumber={i + 1}
        didPass={
          !training.quiz?.percentToPass || score >= training.quiz.percentToPass
        }
        attemptsAllowed={training.quiz?.numberOfRetriesAllowed ?? 1}
      />
    ))}
  </Card>
);
