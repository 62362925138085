import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessLevel } from '../../../selectors/users';
import {
  REPORT_NOTIFICATION_OPTIONS,
  FROI_NOTIFICATION_OPTIONS,
  RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
  SAFETY_WALK_NOTIFICATION_OPTIONS,
  TRAINING_NOTIFICATION_OPTIONS,
  DOCUMENT_NOTIFICATION_OPTIONS,
  TASK_NOTIFICATION_OPTIONS,
  REPORT_NOTIFICATION_TYPES,
  BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS,
  CLAIMS_NOTIFICATIONS,
  QUALITY_AUDIT_NOTIFICATION_OPTIONS,
  SAFETY_SUGGESTION_NOTIFICATION_OPTIONS,
  LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS
} from '../../../constants/constants';
import {
  getIsPerrp,
  getActiveCompany,
  getUserCompaniesSelector
} from '../../../selectors/company';
import BlockText from '../../../components/inputs/BlockText';
import Card from '../../../components/Card';
import Dropdown from '../../../components/inputs/Dropdown';
import Switch from '../../../components/inputs/Switch';
import { TwoColumn } from '../../../components/inputs/index';
import { usePrevious } from '../../../utils/hooks';
import styles from './notifications.module.scss';
import { addMessage } from '../../../actions/messages';

export default function Notifications({
  isEditing,
  emailNotificationsActive,
  notifications,
  setEmailNotificationsActive,
  setNotifications,
  defaultNotifications,
  testID
}) {
  const dispatch = useDispatch();
  let accessLevel = useSelector(getAccessLevel);
  const activeCompany = useSelector(getActiveCompany);
  const isPerrp = useSelector(getIsPerrp);
  const userCompanies = useSelector(getUserCompaniesSelector);
  const [projectDropdown, setProjectDropdown] = useState([]);

  let groupDropdown = [];
  let reportTypes = REPORT_NOTIFICATION_TYPES;
  if (!activeCompany.showAircraft) {
    reportTypes = REPORT_NOTIFICATION_TYPES.filter(r => !r.showAircraft);
  }
  reportTypes = reportTypes.map(t => ({
    label: t.label,
    fieldValue: t.value,
    options: REPORT_NOTIFICATION_OPTIONS
  }));
  if (userCompanies.length > 0) {
    userCompanies.forEach(company =>
      company.groups
        ?.filter(g => !g.isHidden)
        .forEach(group =>
          groupDropdown.push({
            value: group._id,
            label: group.name
          })
        )
    );
  }
  const notificationDropdowns = [
    {
      fieldValue: 'froi',
      label: 'Employee Injury',
      options: FROI_NOTIFICATION_OPTIONS.filter(
        option => option.minAccessLevel <= accessLevel
      ).map(option => {
        if (option.label === 'Marked OSHA Recordable' && isPerrp) {
          return {
            label: 'Marked PERRP Recordable',
            value: option.value
          };
        }

        return option;
      })
    },
    ...reportTypes,
    {
      fieldValue: 'behaviorObservation',
      label: 'Behavior Observations',
      options: BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetyAudit',
      label: 'Safety Audits',
      options: RISK_ASSESSMENT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'qualityAudit',
      label: 'Quality Audits',
      options: QUALITY_AUDIT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'lockoutTagout',
      label: 'Lockout/Tagout Procedures',
      options: LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetyWalk',
      label: activeCompany.isDash ? 'DASH Report' : 'Safety Walks',
      options: SAFETY_WALK_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'training',
      label: 'Training',
      options: TRAINING_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'document',
      label: 'Documents',
      options: DOCUMENT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'task',
      label: 'Tasks',
      options: TASK_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetySuggestions',
      label: 'Safety Suggestions',
      options: SAFETY_SUGGESTION_NOTIFICATION_OPTIONS,
      hide: accessLevel < 900
    },
    {
      fieldValue: 'claims',
      label: 'Claims',
      options: CLAIMS_NOTIFICATIONS,
      hide: !activeCompany.showClaims
    }
  ];

  const prevNotifcationsGroups = usePrevious(notifications.groups);

  useEffect(() => {
    let dropdown = getProjectDropdown();
    setProjectDropdown(dropdown);

    if (prevNotifcationsGroups?.length <= notifications?.groups?.length) {
      let differentGroup = notifications?.groups?.filter(
        g => !prevNotifcationsGroups?.includes(g)
      )?.[0];

      if (differentGroup) {
        setNotifications('selectAll', dropdown, 'projects');
      }
    } else {
      let differentGroup = prevNotifcationsGroups?.filter(
        g => !notifications?.groups?.includes(g)
      )?.[0];
      if (differentGroup) {
        let dropdownToClear = getGroupProjectIds(differentGroup);
        setNotifications('clearAll', dropdownToClear, 'projects');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.groups, prevNotifcationsGroups]);

  const handleUpdateProjects = values => {
    let onlyValues = values.map(v => v.value);
    let projectGroupIds = [...new Set(projectDropdown.map(v => v.groupId))];

    let newValues = onlyValues;
    for (let i = 0; i < projectGroupIds.length; i++) {
      let containsProjectStill = clearedGroupProjects(
        projectGroupIds[i],
        onlyValues
      );

      if (!containsProjectStill) {
        let newProjects = notifications.projects;
        for (let x = 0; x < notifications?.groups?.length; x++) {
          const g = notifications.groups[x];
          const projects = getGroupProjectIds(g);
          if (projects.length && !notifications.projects.includes(projects[0]))
            newProjects.push(projects[0]);
        }
        setNotifications(newProjects, projectDropdown, 'projects');
        dispatch(
          addMessage({
            message: 'All Groups need at least one Area selected',
            info: true
          })
        );
        return;
      }
    }
    setNotifications(newValues, projectDropdown, 'projects');
  };

  const clearedGroupProjects = (groupId, updatedAnswer) => {
    let groupProjectIds = getGroupProjectIds(groupId);
    return updatedAnswer.some(a => groupProjectIds.includes(a));
  };

  const getAnswers = (options, label) => {
    let answers = options?.filter(o =>
      label === 'groups' || label === 'projects'
        ? notifications[label]?.includes(o.value)
        : notifications?.[label]?.[o.value]
    );
    return answers;
  };
  const getProjectDropdown = () => {
    let chosenGroupIds = getAnswers(groupDropdown, 'groups').map(g => g.value);

    let chosenGroups = userCompanies
      .map(company =>
        company.groups
          ?.filter(g => !g.isHidden)
          ?.filter(group => chosenGroupIds.includes(group._id))
      )
      .reduce((elem1, elem2) => elem1.concat(elem2));

    let groupProjects = chosenGroups
      .map(group =>
        group?.projects.length > 0
          ? {
              groupName: group.name,
              projects: group.projects?.filter(p => !p.isHidden),
              groupId: group._id
            }
          : []
      )
      ?.reduce((elem1, elem2) => elem1.concat(elem2), []);

    let dropdown = groupProjects
      ?.map(project =>
        project.projects?.map(p => ({
          value: p._id,
          label: `${project.groupName} - ${p.name}`,
          groupId: project.groupId
        }))
      )
      .reduce((elem1, elem2) => elem1.concat(elem2), []);

    return dropdown;
  };

  const getGroupProjectIds = groupId => {
    let group = userCompanies
      .map(c => c.groups.find(g => g._id === groupId))
      .filter(g => g)?.[0];

    let groupProjects = group?.projects?.map(p => p._id) ?? [];

    return groupProjects;
  };

  return (
    <Card title="Notification Settings" showHeader>
      <Switch
        testID="emailNotifications"
        currentValue={emailNotificationsActive}
        onChange={value => setEmailNotificationsActive(value)}
        label="Email Notifications"
        name="userEmailSwitch"
        disabled={!isEditing}
      />
      <BlockText
        blockOfText="You will always receive notifications for Tasks, Safety/Quality Audits, and Trainings assigned to you."
        className={styles.blockText}
      />
      <BlockText
        blockOfText="When selecting a group all areas for that group will be added to the area dropdown - if a group contains area(s) at least one area must be chosen"
        className={styles.blockText}
      />
      {(emailNotificationsActive && accessLevel !== 100 && (
        <TwoColumn>
          <Dropdown
            multi
            fieldLabel="Group/Establishment"
            placeholder="Notify me when..."
            options={groupDropdown}
            disabled={!isEditing}
            currentValue={getAnswers(groupDropdown, 'groups')}
            onChange={values =>
              setNotifications(values, groupDropdown, 'groups')
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            hideClear={true}
            fieldLabel="Area"
            placeholder="Notify me when..."
            options={projectDropdown}
            disabled={!isEditing}
            currentValue={getAnswers(projectDropdown, 'projects')}
            onChange={handleUpdateProjects}
            searchable
            selectButtons
            bareValues={false}
          />
          {notificationDropdowns.map(dropdown => (
            <>
              {dropdown.hide ? (
                <></>
              ) : (
                <Dropdown
                  multi
                  fieldLabel={dropdown.label}
                  placeholder="Notify me when..."
                  options={dropdown.options}
                  disabled={!isEditing}
                  currentValue={getAnswers(
                    dropdown.options,
                    dropdown.fieldValue
                  )}
                  onChange={values =>
                    setNotifications(
                      values,
                      dropdown.options,
                      dropdown.fieldValue
                    )
                  }
                  defaultAnswers={defaultNotifications?.[
                    dropdown.fieldValue
                  ]?.map(d => d)}
                  searchable
                  selectButtons
                />
              )}
            </>
          ))}
        </TwoColumn>
      )) ||
        undefined}
    </Card>
  );
}
