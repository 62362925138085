import { createSelector } from 'reselect';

const oshaLogSelector = state => state.oshaLogs;

export const getActiveOshaLogSelector = createSelector(
  oshaLogSelector,
  oshaLog => oshaLog && oshaLog.activeOshaLog
);

export const getOsha300LogSelector = createSelector(
  oshaLogSelector,
  oshaLog => oshaLog && oshaLog.osha300
);

export const getOsha300ALogSelector = createSelector(
  oshaLogSelector,
  oshaLog => oshaLog && oshaLog.osha300A
);

export const getOshaLogInfoSelector = createSelector(
  oshaLogSelector,
  oshaLog => oshaLog && oshaLog.oshaInfo
);
