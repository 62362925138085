import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import LeaderboardTable from '../../../components/LeaderboardTable';
import Modal from '../../../components/Modal';
import history from '../../../history';
import {
  fetchDashboardById,
  createLeaderboard,
  deleteLeaderboardById,
  downloadLeaderboard
} from '../../../api/v4';
import {
  LEADERBOARD_SUB_CATEGORIES_TOOLTIPS,
  LEADERBOARD_SUB_CATEGORIES
} from '../../../constants/constants';

import styles from '../leaderboardStyles.module.scss';

export default function ViewLeaderboardTables() {
  const [leaderboards, setLeaderboards] = useState([]);
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState('thisYear');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [rightButtons, setRightButtons] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [leaderboardToDelete, setLeaderboardToDelete] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (
      mode === 'absolute' &&
      startDate &&
      endDate &&
      moment(startDate).isSameOrBefore(endDate)
    ) {
      fetchDashboardById(id, { dateRange: mode, startDate, endDate }).then(
        response => {
          setReadOnly(response.readOnly);
          setTitle(response.name);
          setLeaderboards(response.leaderboards);
          if (!response.readOnly)
            setRightButtons([
              {
                text: 'Create Leaderboard',
                visible: true,
                color: 'blue',
                onClick: () => history.push(`/app/leaderboards/create/${id}`)
              }
            ]);
        }
      );
    } else if (mode !== 'absolute') {
      fetchDashboardById(id, { dateRange: mode }).then(response => {
        setReadOnly(response.readOnly);
        setTitle(response.name);
        setLeaderboards(response.leaderboards);
        if (!response.readOnly)
          setRightButtons([
            {
              text: 'Create Leaderboard',
              visible: true,
              color: 'blue',
              onClick: () => history.push(`/app/leaderboards/create/${id}`)
            }
          ]);
      });
    }
  }, [id, mode, startDate, endDate]);

  const header = (
    <Header
      section={title}
      clickBack={() => history.goBack()}
      rightButtons={rightButtons}
    />
  );

  const cloneLeaderboard = leaderboardId => {
    createLeaderboard({
      id: leaderboardId,
      clone: true,
      dashboardId: id
    }).then(() =>
      fetchDashboardById(id, { dateRange: mode, startDate, endDate }).then(
        response => {
          setTitle(response.name);
          setLeaderboards(response.leaderboards);
        }
      )
    );
  };

  const deleteLeaderboard = () => {
    deleteLeaderboardById({
      id: leaderboardToDelete._id,
      dashboardId: id
    }).then(() => {
      setLeaderboardToDelete(null);
      setOpenDeleteModal(false);
      fetchDashboardById(id, { dateRange: mode, startDate, endDate }).then(
        response => {
          setTitle(response.name);
          setLeaderboards(response.leaderboards);
        }
      );
    });
  };

  const exportLeaderboardToCsv = async leaderboard => {
    const src = await downloadLeaderboard({
      results: leaderboard.results,
      title: leaderboard.title,
      displayContent: leaderboard?.displayContent,
      columnName: LEADERBOARD_SUB_CATEGORIES.find(
        s => s.value === leaderboard.subCategory
      )?.columnName
    });
    window.location = src;
  };

  return (
    <HeaderAndFooter Header={header}>
      <AnalyticsPeriodPicker
        period={{ mode, startDate, endDate }}
        onChange={value => {
          setMode(value.mode);
          setStartDate(value.startDate);
          setEndDate(value.endDate);
        }}
        readOnly
      />
      <div className={styles.leaderboardGrid}>
        {leaderboards?.map(l => (
          <LeaderboardTable
            title={l.title}
            results={l.results}
            tooltip={LEADERBOARD_SUB_CATEGORIES_TOOLTIPS.find(
              t => t.label === l.subCategory
            )}
            subCategory={l.subCategory}
            id={l._id}
            groupIds={l.groupIds}
            readOnly={readOnly}
            columnName={
              LEADERBOARD_SUB_CATEGORIES.find(s => s.value === l.subCategory)
                ?.columnName
            }
            cloneLeaderboard={id => cloneLeaderboard(id)}
            deleteLeaderboard={id => {
              setLeaderboardToDelete(l);
              setOpenDeleteModal(true);
            }}
            downloadLeaderboard={() => exportLeaderboardToCsv(l)}
            showPercentage={
              LEADERBOARD_SUB_CATEGORIES.find(s => s.value === l.subCategory)
                ?.showPercentage
            }
            jobTitles={l?.additionalFilters?.jobTitles}
          />
        ))}
      </div>
      <Modal
        title="Confirm Delete"
        titleClassName="redHeader"
        isOpen={openDeleteModal}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={() => {
          setOpenDeleteModal(false);
        }}
        submitActions={deleteLeaderboard}
      >
        <h2>Are you sure you want to delete {leaderboardToDelete?.title}?</h2>
      </Modal>
    </HeaderAndFooter>
  );
}
