import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomReportQuestionsRequest,
  setActiveCustomReportQuestions
} from '../../actions/reports';
import {
  REPORT_COMPONENTS_CUSTOMIZABLE,
  REACTIVE_REPORT_TYPES
} from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';
import Card from '../../components/Card';
import Header from '../../components/Header';
import ReportSelector from '../../components/inputs/ReportSelector';

export default function CustomReportQuestionsList() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');
  const [showReportComponents, setShowComponents] = useState(false);

  const company = useSelector(getActiveCompany);

  const handleSelectReportComponent = choice => {
    dispatch(
      setActiveCustomReportQuestions({
        reportTemplateNumber: selected,
        reportComponentName: choice.label
      })
    );
    dispatch(fetchCustomReportQuestionsRequest());
  };

  const customizableReports = company?.showAircraft
    ? REACTIVE_REPORT_TYPES.filter(r => !r.label !== 'Spill')
    : REACTIVE_REPORT_TYPES.filter(r => !r.isAircraft);

  return (
    <div className="customReportQuestionsList">
      <Header title="Custom Report Questions" />
      {!showReportComponents ? (
        <Card title="Type of Report" showHeader wide>
          <ReportSelector
            filteredReportTypes={customizableReports}
            handleChange={x => {
              setSelected(x.value);
              setShowComponents(true);
            }}
          />
        </Card>
      ) : (
        <Card
          title="Type of Report Component"
          showHeader
          wide
          rightButton={{
            text: 'Back To Report Selection',
            color: 'blue',
            onClick: () => setShowComponents(false)
          }}
        >
          <ReportSelector
            filteredReportTypes={REPORT_COMPONENTS_CUSTOMIZABLE.filter(
              component => component.reports.includes(parseInt(selected))
            )}
            handleChange={handleSelectReportComponent}
          />
        </Card>
      )}
    </div>
  );
}
