export const parseTime = time => {
  if (!time) return [null, null];
  let r = time.match(/^\s*([012]?[0-9]):([0-5][0-9])(?:\s*([ap]m))?\s*$/i);
  if (!r) return [null, null];
  let [, hours, minutes, isPM] = r;
  hours = parseInt(hours, 10) % 24;
  minutes = parseInt(minutes, 10) % 60;
  isPM = isPM.toUpperCase();
  if (isPM === 'PM' && hours !== 12) {
    hours = (hours + 12) % 24;
  } else if (isPM === 'AM' && hours === 12) {
    hours = 0;
  }
  return [hours, minutes];
};

export const renderTime = (hours, minutes, addAMPM = false) => {
  if (addAMPM === undefined) addAMPM = null;
  if ((!hours && hours !== 0) || (!minutes && minutes !== 0)) {
    return '';
  }

  let isPM = hours >= 12;
  if (hours === 0) hours = 12;
  if (hours > 12 && addAMPM !== null) hours -= 12;

  let ret = `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
  if (addAMPM) {
    ret += isPM ? ' PM' : ' AM';
  }

  return ret;
};
