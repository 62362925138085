import React from 'react';
import moment from 'moment';

import { printSafetyWalk } from '../../../api/v4';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';
import Card from '../../Card';
import { TwoColumn } from '../../inputs';

import styles from './summaryCard.module.scss';

const printSW = async safetyWalk => {
  const url = await printSafetyWalk(safetyWalk._id);
  window.open(url, '_blank');
};

const hazards = summary => {
  return [
    {
      text: `${summary.hazardsFound} Hazard(s) Found`
    },
    {
      number: summary.immediatelyFixed,
      text: ' Immediately Fixed',
      className: summary.immediatelyFixed ? styles.green : styles.gray
    },
    {
      number: summary.correctiveActionsCreated,
      text: ' Corrective Actions Assigned',
      className: summary.correctiveActionsCreated ? styles.blue : styles.gray
    },
    {
      number: summary.correctiveActionsCompleted,
      text: ' Corrective Actions Completed',
      className: summary.correctiveActionsCompleted ? styles.green : styles.gray
    },
    {
      number: summary.correctiveActionsPastDue,
      text: ' Corrective Actions Past Due',
      className: summary.correctiveActionsPastDue ? styles.red : styles.gray
    }
  ];
};

const bravos = summary => {
  return [
    {
      text: `${summary.bravosFound} Bravos Found`
    },
    {
      number: summary.bravosGiven?.length,
      text: ' Users Mentioned',
      className: summary.bravosGiven?.length ? styles.blue : styles.gray
    },
    {},
    {},
    {}
  ];
};

const SummaryCard = ({ safetyWalk, loggedInUser, name, isDash }) => {
  return (
    <Card name={name} title="Summary" showHeader wide>
      <div className={styles.card}>
        <UserBadge
          size="large"
          showImage
          showName
          byline="Created By"
          userId={safetyWalk.createdBy ?? safetyWalk?.auditLog?.[0].user}
          className={styles.created}
        />
        <div className={styles.date}>
          <div className={styles.topHeader}>
            Created at{' '}
            {safetyWalk &&
              moment(Date.parse(safetyWalk.createdAt)).format('hh:mm A')}
          </div>
          <div className={styles.bottomHeader}>
            {safetyWalk &&
              moment(Date.parse(safetyWalk.createdAt)).format('LL')}
          </div>
        </div>
      </div>
      <TwoColumn>
        <ul className={styles.list}>
          {safetyWalk.summary &&
            hazards(safetyWalk.summary).map((hazard, index) => (
              <li key={index}>
                <div className={hazard.className}>
                  <span> {hazard.number} </span> {hazard.text}
                </div>
              </li>
            ))}
        </ul>
        <ul className={styles.list}>
          {safetyWalk.summary &&
            bravos(safetyWalk.summary).map((bravos, index) => (
              <li key={index}>
                <div className={bravos.className}>
                  <span> {bravos.number} </span>
                  {bravos.text ?? '\u00A0'}
                </div>
              </li>
            ))}
        </ul>
      </TwoColumn>
      <div className={styles.footer}>
        <Button
          color="blue"
          className={styles.button}
          text={isDash ? 'Download DASH Report' : 'Download Safety Walk'}
          onClick={() => printSW(safetyWalk, loggedInUser)}
        />
      </div>
    </Card>
  );
};

export default SummaryCard;
