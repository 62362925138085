import React, { Component, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import socketIOClient from 'socket.io-client';

import { getLoggedInUser } from '../selectors/users';
import config from '../config/config';

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

export function useSocket(socketId, socketComponent) {
  const loggedInUser = useSelector(getLoggedInUser);
  const [socket, setSocket] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [lockedUser, setLockedUser] = useState(null);
  const [id] = useState(socketId);
  const [component] = useState(socketComponent);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const s = socketIOClient(config.apiUrl, {
      transports: ['websocket'],
      auth: { token }
    });

    s.on('lockInfo', lock => {
      if (
        (component && lock.component !== component) ||
        lock.id !== id ||
        lock.userId === loggedInUser._id
      ) {
        return;
      } else {
        setDirty(lock.dirty ? true : dirty);
        setLockedUser(lock.userId || null);
      }
    });

    setSocket(s);

    if (socketId && socketComponent)
      s.emit('subscribe', { id: socketId, component: socketComponent });
    return () => socket?.close?.();
    // eslint-disable-next-line
  }, []);

  const socketEmit = (
    emitValue,
    data = {
      id,
      component
    }
  ) => {
    if (socket && lockedUser !== loggedInUser._id) {
      socket.emit(emitValue, data);
    }
  };

  const socketLock = () => {
    socketEmit('lock');
  };

  const socketDirty = () => {
    socketEmit('dirty');
  };

  return socketComponent || socketId
    ? {
        socket,
        socketLock,
        socketDirty,
        lockInfo: {
          dirty,
          lockedUser,
          myUser: loggedInUser._id
        },
        locked: dirty || (lockedUser && lockedUser !== loggedInUser._id)
      }
    : socket;
}

const withSocket = WrappedComponent => {
  const withSocket = class extends Component {
    state = {
      socket: null,
      dirty: false,
      lockedUser: null,
      id: null,
      component: null
    };

    componentWillUnmount() {
      this.state.socket?.close && this.state.socket.close();
    }

    socketSubscribe = ({ id, component = undefined }) => {
      const token = sessionStorage.getItem('token');
      const socket = socketIOClient(config.apiUrl, {
        transports: ['websocket'],
        auth: { token }
      });
      socket.on('lockInfo', lock => {
        this.setState((state, props) => {
          if (
            (state.component && lock.component !== state.component) ||
            lock.id !== state.id ||
            lock.userId === props.loggedInUser._id
          ) {
            return;
          }
          return {
            dirty: lock.dirty ? true : state.dirty,
            lockedUser: lock.userId || null
          };
        });
      });
      this.setState({ id, component, socket });
      socket.emit('subscribe', { id, component });
    };

    socketEmit = (
      emitValue,
      data = {
        id: this.state.id,
        component: this.state.component
      }
    ) => {
      if (
        this.state.socket &&
        this.state.lockedUser !== this.props.loggedInUser._id
      ) {
        this.state.socket.emit(emitValue, data);
      }
    };

    socketLock = () => {
      this.socketEmit('lock');
    };

    socketDirty = () => {
      this.socketEmit('dirty');
    };

    render() {
      const { dirty, lockedUser } = this.state;
      return (
        <WrappedComponent
          socketLock={this.socketLock}
          socketDirty={this.socketDirty}
          socketEmit={this.socketEmit}
          socketSubscribe={this.socketSubscribe}
          lockInfo={{
            dirty,
            lockedUser,
            myUser: this.props.loggedInUser._id
          }}
          locked={
            dirty || (lockedUser && lockedUser !== this.props.loggedInUser._id)
          }
          {...this.props}
        />
      );
    }
  };

  return connect(mapStateToProps)(withSocket);
};

export { withSocket };
