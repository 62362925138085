import React from 'react';
import { EmployeeDropdown } from '../inputs';
import Loading from '../Loading';
import Modal from '.';
import UserBadge from '../UserBadge';

import styles from './styles.module.scss';

const ReassignUserModal = ({
  isOpen,
  onRequestClose,
  submitActions,
  title,
  previousUser,
  reassignUser,
  disableSubmit,
  message,
  employeeFieldLabel,
  onChange,
  employees,
  permissions,
  groups,
  submitButtonText,
  limit,
  titleClassName,
  placeholder
}) => {
  return (
    <Modal
      title={title}
      titleClassName={titleClassName ?? 'blueHeader'}
      isOpen={isOpen}
      submitButtonColor="blue"
      submitButtonText={submitButtonText ?? 'Save Changes'}
      onRequestClose={onRequestClose}
      submitActions={submitActions}
      disableSubmit={disableSubmit}
    >
      {message}
      <div className={styles.reassignUser}>
        {previousUser ? (
          <>
            <UserBadge showImage hoverName size="large" userId={previousUser} />
            <img
              src={require('../../assets/images/reassignArrow.png')}
              className={styles.reassignArrow}
              alt="assign arrow"
            />
          </>
        ) : null}
        <UserBadge showImage hoverName size="large" userId={reassignUser} />
      </div>
      {employees === null ? (
        <Loading />
      ) : (
        <EmployeeDropdown
          employees={employees}
          fieldLabel={employeeFieldLabel}
          currentValue={reassignUser}
          onChange={onChange}
          placeholder={placeholder ?? `No ${employeeFieldLabel}`}
          permissions={permissions}
          group={groups}
          limit={limit}
        />
      )}
    </Modal>
  );
};

export default ReassignUserModal;
