import React, { useState, useEffect } from 'react';
import history from '../../history';
import { fetchBehaviorObservationTemplates, archiveMany } from '../../api/v4';
import { Dropdown } from '../../components/inputs';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';
import TypeSelection from '../../components/TypeSelection';

export default function BehaviorObservationTemplates() {
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  const [listType, setListType] = useState('Active');

  useEffect(() => {
    fetchBehaviorObservationTemplates().then(response => {
      setList(response);
      setTemplateDropdown(
        response
          .filter(template => !template.isArchived)
          .map(r => {
            return { value: r._id, label: r.title };
          })
      );
    });
  }, []);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'behaviorObservationTemplate',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchBehaviorObservationTemplates().then(response => {
        setList(response);
        setTemplateDropdown(
          response
            .filter(template => !template.isArchived)
            .map(r => {
              return { value: r._id, label: r.title };
            })
        );
      })
    );
  };

  const header = (
    <Header
      title="Behavior Observation Templates"
      pageActionOptions={[
        {
          label: 'Add Behavior Observation Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => history.push('/app/behaviorObservation/template')
        },
        {
          label: 'Duplicate Behavior Observation Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: list.length
        }
      ]}
    />
  );
  const columns = [
    {
      label: 'Title',
      key: 'title'
    },
    {
      label: 'Created At',
      key: 'createdAt',
      datatype: 'date'
    },
    {
      label: 'Last Updated',
      key: 'updatedAt',
      datatype: 'date'
    }
  ];

  const listTypeFilter =
    listType === 'Archived'
      ? list?.filter(template => template.isArchived)
      : list?.filter(template => !template.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={setListType}
        selected={listType}
      />
      <List
        settings={columns}
        data={listTypeFilter}
        dataIsHash
        saveKey="behaviorObservationTemplate"
        rowClick={row =>
          history.push(`/app/behaviorObservation/template/${row._id}`)
        }
        getRowId={r => r._id}
        actions={[
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() =>
          history.push(
            `/app/behaviorObservation/template/${currentTemplate}/true`
          )
        }
        testID="Duplicate Template"
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Behavior Observation Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a behavior observation"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
