import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import TrainingDetails from '../../../components/training/Cards/Details';
import { useDispatch } from 'react-redux';

import history from '../../../history';
import { addMessage } from '../../../actions/messages';
import DeleteTrainingTemplateModal from '../../../components/Modal/deleteTrainingTemplateModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

import {
  addCustomTrainingTemplate,
  deleteCustomTrainingTemplate,
  fetchCustomTrainingTemplates,
  getTrainingTemplateById,
  updateCustomTrainingTemplate
} from '../../../api/v4';
import styles from './styles.module.scss';

export default function CustomTrainingTemplate() {
  const { id, duplicate } = useParams();
  const dispatch = useDispatch();

  const [template, setTemplate] = useState({
    title: '',
    category: '',
    instructions: '',
    needsApproval: false,
    doesExpire: false,
    expiresAfterNumber: '',
    expiresAfterOption: 'days',
    attachments: [],
    approver: '',
    youtubeLinks: [],
    quizMasterId: '',
    minMaterialTime: null,
    titleList: []
  });
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnsavedModal, setOpenUnsavedModal] = useState(false);
  const [originalTitle, setOriginalTitle] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [titleList, setTitleList] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const handleAnswer = answer => {
    setUnsavedChanges(true);
    setTemplate({ ...template, ...answer });
  };
  useEffect(() => {
    const savedTemplate = localStorage.getItem('trainingTemplate');
    localStorage.removeItem('trainingTemplate');
    if (savedTemplate) {
      let parsedTemplate = JSON.parse(savedTemplate);
      setTemplate(parsedTemplate);
      id ? setEditing(true) : setCreating(true);
      setUnsavedChanges(parsedTemplate?.hasUnsavedChanges);
    } else if (id)
      getTrainingTemplateById(id).then(r => {
        if (duplicate) {
          delete r._id;
          setTemplate({
            ...r,
            title: `${r.title} - Copy`,
            expiresAfterOption: r?.expiresAfterOption ?? 'days'
          });
          setCreating(true);
        } else {
          setTemplate(r);
        }
        if (!duplicate) setOriginalTitle(r.title);
        fetchCustomTrainingTemplates().then(response =>
          setTitleList(
            response.map(r => r.title).filter(t => duplicate || t !== r.title)
          )
        );
      });
    else {
      setCreating(true);
      fetchCustomTrainingTemplates().then(response => setTitleList(response));
    }
  }, [id, duplicate]);

  const handleSubmit = () => {
    if (template._id) {
      updateCustomTrainingTemplate({
        ...template
      }).then(r => {
        dispatch(
          addMessage({ error: false, message: 'Template Successfully Updated' })
        );
        handleBack();
      });
    } else {
      addCustomTrainingTemplate({
        ...template,
        duplicated: duplicate
      }).then(r => {
        dispatch(
          addMessage({ error: false, message: 'Template Successfully Created' })
        );
        handleBack();
      });
    }
  };
  const handleBack = () => {
    history.push('/app/training/templates');
  };
  const handleArchive = () => {
    updateCustomTrainingTemplate({
      ...template,
      isArchived: !template.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/training/templates');
      else {
        setTemplate(response);
        setUnsavedChanges(false);
      }
    });
  };

  const invalidTitle =
    template.title?.toLowerCase()?.trim() &&
    titleList
      .filter(t => t !== originalTitle)
      ?.find(t => t === template.title.toLowerCase().trim());

  const header = (
    <Header
      title="Training Template"
      needsSaved={hasUnsavedChanges}
      section={
        creating
          ? 'Create Template'
          : editing
          ? 'Edit Template'
          : 'View Template'
      }
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedModal(true) : handleBack()
      }
      pageActionOptions={
        !creating
          ? [
              {
                label:
                  editing || template?.isArchived
                    ? 'Delete Template'
                    : 'Edit Template',
                onClick: () =>
                  editing || template?.isArchived
                    ? setOpenDeleteModal(true)
                    : setEditing(true),
                color:
                  editing || template?.isArchived
                    ? 'redOutline'
                    : 'blueOutline',
                visible: true
              },
              {
                label: `${template?.isArchived ? 'Un-' : ''}Archive Template`,
                onClick: () => handleArchive(),
                color: 'blueOutline',
                visible: true
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit || invalidTitle ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setOpenUnsavedModal(true)
          : creating
          ? handleBack()
          : setEditing(false)
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(!canSubmit || invalidTitle)}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={editing || creating}
        className={styles.trainingTemplate}
      >
        <TrainingDetails
          training={template}
          onAnswer={handleAnswer}
          titleWarning={invalidTitle}
          editing={editing || creating}
          creating={creating}
          setCanSubmit={setCanSubmit}
          fromTemplate
          hasUnsavedChanges={hasUnsavedChanges}
          missingRequired={missingRequired}
        />
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedModal}
        savingWhat="Training Template"
        onRequestClose={() => setOpenUnsavedModal(false)}
        submitActions={handleBack}
      />
      <DeleteTrainingTemplateModal
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteCustomTrainingTemplate(template).then(handleBack)
        }
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Template"
        isEditing={editing}
      />
    </>
  );
}
