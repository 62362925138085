import React, { useState, useEffect } from 'react';
import history from '../../../history';
import { getCourseTemplates, archiveMany } from '../../../api/v4';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import { Dropdown } from '../../../components/inputs';
import TypeSelection from '../../../components/TypeSelection';

export default function CourseTemplateList() {
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [listType, setListType] = useState('Active');

  useEffect(() => {
    getCourseTemplates().then(setTemplates);
  }, []);

  const templateDropdown = templates
    ?.filter(template => !template.isArchived)
    .map(t => {
      return { value: t._id, label: t.title };
    });

  const header = (
    <Header
      title="Training Course Templates"
      pageActionOptions={[
        {
          label: 'Create Course Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            localStorage.removeItem('courseTemplate');
            history.push('/app/courseTemplates/view/');
          }
        },
        {
          label: 'Duplicate Course Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );

  const handleViewEdit = (template, duplicate = '') => {
    localStorage.removeItem('courseTemplate');
    localStorage.removeItem('trainingTemplate');
    history.push(
      `/app/courseTemplates/view/${template._id ?? template}/${duplicate}`
    );
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'Course Template',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() => getCourseTemplates().then(setTemplates));
  };

  const columns = [
    { key: 'title' },
    {
      key: 'numTrainings',
      label: 'Number of Trainings',
      accessor: r => r.trainings?.length
    },
    { key: 'sequential', label: 'Releases Sequentially', datatype: 'boolean' },
    {
      key: 'updatedAt',
      label: 'Last Updated',
      datatype: 'date'
    }
  ];

  const listTypeFilter =
    listType === 'Archived'
      ? templates?.filter(template => template.isArchived)
      : templates?.filter(template => !template.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={setListType}
        selected={listType}
      />
      <List
        saveKey="courseTemplateList"
        rowClick={handleViewEdit}
        dataIsHash
        data={listTypeFilter}
        settings={columns}
        getRowId={r => r._id}
        actions={[
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => {
          setOpenModal(false);
          setCurrentTemplate();
        }}
        submitActions={() => handleViewEdit(currentTemplate, true)}
        testID="Course Template"
        disableSubmit={!currentTemplate}
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Course Template"
          currentValue={currentTemplate}
          isRequired
          onChange={setCurrentTemplate}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
