import moment from 'moment';
import {
  ACTION_COMPONENTS,
  CLOSURE_COMPONENTS,
  INVESTIGATION_COMPONENTS
} from '../constants/constants';
import config from '../config/config';
import { notFutureDate } from './formValidation';
import { oshaProgress } from './oshaDbMapper';

const isPastDue = dueDate => {
  return moment().diff(dueDate, 'days') > 0;
};

export const getPercentages = (
  reportComponents,
  incidentStage,
  reportTemplateIds
) => {
  let completed = 0;
  let started = 0;
  let pastDue = 0;
  let total = 0;

  if (!reportComponents) return {};

  if (incidentStage === 0) {
    completed = 1;

    INVESTIGATION_COMPONENTS.forEach(component => {
      const report = reportComponents.filter(report => report[0] === component);
      if (report.length > 0 && report[0][4].isComplete) {
        completed++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        isPastDue(Date.parse(report[0][3].dueDate))
      ) {
        pastDue++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        report[0][5].isStarted
      ) {
        started++;
      }

      if (
        reportTemplateIds.indexOf('11') > -1 ||
        reportTemplateIds.indexOf('12') > -1
      ) {
        total = INVESTIGATION_COMPONENTS.length - 2;
      } else {
        total = INVESTIGATION_COMPONENTS.length - 1;
      }
    });
  } else if (incidentStage === 1) {
    ACTION_COMPONENTS.forEach(component => {
      const report = reportComponents.filter(report => report[0] === component);
      if (report.length > 0 && report[0][4].isComplete) {
        completed++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        isPastDue(Date.parse(report[0][3].dueDate))
      ) {
        pastDue++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        report[0][5].isStarted
      ) {
        started++;
      }
    });

    total = ACTION_COMPONENTS.length - 3;
  } else {
    CLOSURE_COMPONENTS.forEach(component => {
      const report = reportComponents.filter(report => report[0] === component);
      if (report.length > 0 && report[0][4].isComplete) {
        completed++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        isPastDue(Date.parse(report[0][3].dueDate))
      ) {
        pastDue++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        report[0][5].isStarted
      ) {
        started++;
      }
    });

    if (reportTemplateIds.indexOf('2') > -1) {
      total = CLOSURE_COMPONENTS.length;
    } else {
      total = CLOSURE_COMPONENTS.length - 2;
    }
  }

  return { completed, started, pastDue, total };
};

export const isSectionComplete = section => {
  const fields = section[1];

  if (section[3] && section[3].notApplicable) {
    return true;
  }

  const complete = fields.map(field => {
    if (
      field.required &&
      typeof field.answer !== 'number' &&
      JSON.stringify(field.answer) !== '{}' &&
      !field.answer &&
      !field.legacy
    ) {
      return 'not answered';
    }

    return '';
  });

  return complete.findIndex(answer => answer === 'not answered') === -1;
};

export const isInitialReportSectionComplete = (section, answers) => {
  return section.fields.every(field => {
    const answer = answers[field._id];
    if (field?.label === 'When did the incident occur?') {
      if (notFutureDate(answer)) return false;
    }

    return (
      field.hide ||
      !field.required ||
      field.legacy ||
      JSON.stringify(field.answer) === '{}' ||
      !(answer?.length === 0 || answer === null || answer === undefined)
    );
  });
};

export const canMarkAsComplete = subSections => {
  if (!subSections) return false;
  let totalSections = subSections?.length;
  let sectionComplete = 0;

  for (const section of subSections) {
    if (isSectionComplete(section)) sectionComplete++;
  }
  return totalSections === sectionComplete;
};

export const canEditTask = (activeIncident, loggedInUser, task) => {
  if (loggedInUser._id === activeIncident.incidentOwner) {
    return true;
  } else if (task.reportComponentName) {
    const reportComponents = activeIncident.reportComponents;

    const assignedTo = reportComponents.filter(
      report => report[0] === task.reportComponentName
    )[0][2].assignedTo;

    return loggedInUser._id === assignedTo;
  } else {
    const assignedTo = task.assignedTo[0];

    return loggedInUser._id === assignedTo;
  }
};

export const getIncidentStagesList = incidents => {
  const incidentContent = incidents?.map(incident => {
    const investigationPercentages = getPercentages(
      incident.reportComponents,
      0,
      incident.reportTemplateIds
    );
    const actionPercentages = getPercentages(
      incident.reportComponents,
      1,
      incident.reportTemplateIds
    );

    if (incident.reportStage && incident.reportStage.label === 'Closed') {
      return {
        ...incident,
        stage: 'Done'
      };
    } else if (
      investigationPercentages.completed !== investigationPercentages.total
    ) {
      return {
        ...incident,
        stage: 'Investigation'
      };
    } else if (actionPercentages.completed !== actionPercentages.total) {
      return {
        ...incident,
        stage: 'Action/Learning'
      };
    } else {
      return {
        ...incident,
        stage: 'Closure'
      };
    }
  });

  const investigationIncidents = incidentContent?.filter(
    incident => incident.stage === 'Investigation'
  );

  const actionIncidents = incidentContent?.filter(
    incident => incident.stage === 'Action/Learning'
  );

  const closureIncidents = incidentContent?.filter(
    incident => incident.stage === 'Closure'
  );

  const doneIncidents = incidentContent?.filter(
    incident => incident.stage === 'Done'
  );

  return [
    investigationIncidents,
    actionIncidents,
    closureIncidents,
    doneIncidents
  ];
};

export const calcPercent = (x, y) => {
  if (y === 0) return 0;
  return Math.round((x / y) * 100);
};

export const getPercentComplete = (reportComponent, oshaForms, isCcLegacy) => {
  if (
    (!reportComponent[1].subSections &&
      reportComponent[0] !== 'OSHA Compliance') ||
    reportComponent[0] === 'Witness Statements' ||
    (reportComponent[0] === 'Return to Work' && !isCcLegacy)
  )
    return 'no percentage';

  const reportQuestions = reportComponent[1].subSections;
  let totalRequired = 0;
  let totalRequiredAnswered = 0;

  if (reportComponent[0] === 'OSHA Compliance' && oshaForms) {
    if (!Array.isArray(oshaForms)) oshaForms = [oshaForms];
    if (oshaForms?.every(o => !o._id)) return 0;
    const result = oshaForms?.reduce((acc, oshaForm) => {
      return acc + oshaProgress(oshaForm.formAnswers);
    }, 0);
    return calcPercent(result, oshaForms.length);
  } else if (reportQuestions) {
    reportQuestions.forEach(section =>
      section[1].forEach(field => {
        if (field.required && !field.legacy) {
          totalRequired++;
          if (
            field.answer &&
            !(Array.isArray(field.answer) && field.answer.length === 0)
          ) {
            totalRequiredAnswered++;
          }
        }
      })
    );
  }

  return calcPercent(totalRequiredAnswered, totalRequired);
};

export const getAvailableUsersForPermissions = (
  users,
  activeIncident,
  confidentialUsers
) => {
  let allUsers = [...users];
  const addedIds = activeIncident?.permissions?.map(user => user.userId);
  if (Array.isArray(confidentialUsers))
    allUsers = allUsers.filter(u => confidentialUsers.includes(u._id));

  return allUsers.filter(
    user =>
      addedIds.indexOf(user._id) === -1 &&
      user.accessLevel >= 400 &&
      !user.isDeactivated
  );
};

export const hasAccess = (loggedInUser, stage, activeIncident) => {
  const access = hasAccessStage(loggedInUser, stage, activeIncident);
  return (
    access === 'Edit' || loggedInUser?.accessLevel === 900 || access === true
  );
};

export const hasAccessStage = (loggedInUser, stage, activeIncident) => {
  if (activeIncident && activeIncident.incidentOwner === loggedInUser._id)
    return true;

  const userPerms = activeIncident?.permissions?.filter(
    perm => perm.userId === loggedInUser._id
  );
  if (!userPerms || userPerms.length === 0) return false;

  return userPerms[0][stage];
};

export const canAddReportType = (loggedInUser, permissions, templateIds) => {
  if (templateIds?.includes('19')) return false;
  if (loggedInUser?.accessLevel === 900) return true;
  if (loggedInUser?.accessLevel < 500) return false;
  let userPermissions = permissions.find(p => p.userId === loggedInUser?._id);
  return (
    userPermissions?.action === 'Edit' &&
    userPermissions?.closure === 'Edit' &&
    userPermissions?.investigation === 'Edit'
  );
};

export const shouldDisableQuestion = (incident, question) => {
  const questionsToDisable = [
    'Were any injuries sustained?',
    'Was any property damaged?',
    'Was an employee injured?',
    'Was a non-employee injured?'
  ];
  return (
    incident.reportTemplateIds?.some(r => r === '17' || r === '18') &&
    questionsToDisable?.includes(question.label)
  );
};

export const isConfidentialReport = incident =>
  incident?.questionAnswerGroups?.find(
    q => q.questionId === config.staticIds.confidentialReport
  )?.questionAnswer?.label === 'Yes';

export const userHasNoConfidentialAccess = (incident, user) => {
  const isConfidential = isConfidentialReport(incident);

  const userPermissions = incident?.permissions?.find(
    p => p.userId === user._id
  );

  return isConfidential && !userPermissions;
};
