import { all, call, put, select } from 'redux-saga/effects';
import {
  addDocumentFolderResponse,
  addDocumentsToFolderResponse,
  deleteDocumentFolderResponse,
  fetchDocumentFoldersResponse,
  setActiveDocumentFolderResponse,
  updateDocumentFolderResponse
} from '../actions/documents';
import { addMessage } from '../actions/messages';
import { getActiveCompany } from '../selectors/company';

export function* fetchDocumentsByFolder(apiV4, { payload }) {
  try {
    let response = [];

    response = yield call(
      apiV4.fetchDocumentsByType,
      payload.documentType,
      payload._id
    );

    delete payload.page;
    delete payload.limit;
    const attachments = yield all(
      response.map(document =>
        call(apiV4.fetchAttachmentByOwnerId, document._id, 'documents')
      )
    );
    response.map((res, index) => (res.attachments = attachments[index]));

    yield put(setActiveDocumentFolderResponse(payload));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchDocumentFolders(apiV4, { payload }) {
  try {
    const response = yield call(
      apiV4.fetchDocumentFoldersByType,
      payload.documentType
    );

    yield put(fetchDocumentFoldersResponse(response));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addDocumentFolder(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    const response = yield call(apiV4.createDocumentFolder, payload);
    yield put(addDocumentFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
    const newSet = yield call(
      apiV4.fetchDocumentFoldersByType,
      payload.documentType
    );
    yield put(fetchDocumentFoldersResponse(newSet));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateDocumentFolder(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.updateDocumentFolder, payload);

    yield put(updateDocumentFolderResponse(response));
    yield put(setActiveDocumentFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteDocumentFolder(api, apiV4, { payload }) {
  try {
    const documents = payload.documents;
    delete payload.documents;

    if (payload.deleteAll) {
      delete payload.deleteAll;

      if (payload.documentType === 'SafetyTraining') {
        yield all(
          documents.map(document => call(apiV4.deleteTraining, document))
        );
      } else {
        let documentIds = documents?.map(d => d._id);
        yield call(apiV4.deleteManyDocuments, {
          documentIds
        });
      }

      yield call(apiV4.deleteDocumentFolder, payload?._id);
    } else {
      delete payload.deleteAll;

      if (payload.documentType === 'SafetyTraining') {
        yield all(
          documents.map(document =>
            call(apiV4.updateTraining, { ...document, documentFolderId: '' })
          )
        );
      } else {
        let documentIds = documents?.map(d => d._id);
        yield call(apiV4.updateManyDocuments, {
          documentIds,
          documentFolderId: ''
        });
      }

      yield call(apiV4.deleteDocumentFolder, payload?._id);
    }

    yield put(deleteDocumentFolderResponse(payload));
    yield put(setActiveDocumentFolderResponse({}));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addDocumentsToFolder(apiV4, { payload }) {
  try {
    if (payload.type === 'documents') {
      yield call(apiV4.updateManyDocuments, {
        documentIds: payload.documents,
        documentFolderId: payload.documentFolderId
      });

      yield put(addMessage({ error: false, message: 'Succesfully Updated' }));
    } else {
      yield all(
        payload.documents.map(document =>
          payload.documentType === 'SafetyTraining'
            ? call(apiV4.updateTraining, {
                _id: document._id,
                documentFolderId: payload.documentFolderId,
                fromAddFolder: true
              })
            : payload.documentType === 'Repeating'
            ? call(apiV4.updateTrainingRepeatable, {
                _id: document._id,
                type: 'trainings',
                documentFolderId: payload.documentFolderId,
                fromAddFolder: true
              })
            : call(apiV4.updateTrainingScanOnDemand, {
                _id: document._id,
                type: 'trainings',
                documentFolderId: payload.documentFolderId,
                fromAddFolder: true
              })
        )
      );
    }
    yield put(addDocumentsToFolderResponse());
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
