import React, { useState } from 'react';
import {
  ACTION_COMPONENTS_PRINT,
  CLOSURE_COMPONENTS_PRINT,
  DOWNLOAD_REPORTS_OPTIONS,
  INVESTIGATION_COMPONENTS_PRINT
} from '../../constants/constants';
import Modal from '.';
import { Checkbox, Dropdown } from '../inputs';
import CheckboxGroup from '../inputs/CheckboxGroup';

import styles from './styles.module.scss';
import reportTemplateMapper from '../../utils/reportTemplateMapper';

const DownloadReportsModal = ({
  isOpen,
  onRequestClose,
  submitActions,
  disableSubmit,
  activeIncident,
  user
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [
    downloadInvestigationReports,
    setDownloadInvestigationReports
  ] = useState([]);
  const [downloadActionReports, setDownloadActionReports] = useState([]);
  const [downloadClosureReports, setDownloadClosureReports] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState([]);

  const resetState = () => {
    setSelectAll(false);
    setDownloadInvestigationReports([]);
    setDownloadClosureReports([]);
    setDownloadActionReports([]);
    setReportTypes([]);
    setAdditionalInfo([]);
  };

  const hasAccessToStage = stage => {
    let userPermissions = activeIncident?.permissions?.find(
      p => p.userId === user?._id
    );

    return (
      user?.accessLevel === 900 ||
      ['Edit', 'View']?.includes(userPermissions?.[stage])
    );
  };

  const handleSelectAll = v => {
    setSelectAll(v);
    if (v) {
      setDownloadInvestigationReports(investigationOptions?.map(o => o.value));
      setDownloadActionReports(actionOptions?.map(o => o.value));
      setDownloadClosureReports(closureOptions?.map(o => o.value));
      setReportTypes(
        availableReportTypes?.length > 1
          ? availableReportTypes?.map(o => o.value)
          : []
      );
      setAdditionalInfo(DOWNLOAD_REPORTS_OPTIONS?.map(o => o.value));
    } else {
      setDownloadInvestigationReports([]);
      setDownloadActionReports([]);
      setDownloadClosureReports([]);
      setReportTypes([]);
      setAdditionalInfo([]);
    }
  };

  const investigationOptions = hasAccessToStage('investigation')
    ? INVESTIGATION_COMPONENTS_PRINT.filter(
        option =>
          activeIncident.reportComponents
            .map(component => component[0])
            .includes(option.label) || option.label === 'Initial Report'
      )
    : [];

  const actionOptions = hasAccessToStage('action')
    ? ACTION_COMPONENTS_PRINT.filter(option =>
        activeIncident.reportComponents
          .map(component => component[0])
          .includes(option.label)
      )
    : [];

  let closureOptions = hasAccessToStage('closure')
    ? CLOSURE_COMPONENTS_PRINT.filter(option =>
        activeIncident.reportComponents
          .map(component => component[0])
          .includes(option.label)
      )
    : [];

  closureOptions =
    user.accessLevel === 400
      ? closureOptions?.filter(o => o.label === 'Return to Work')
      : closureOptions;

  const availableReportTypes = activeIncident?.reportTemplateIds?.map(rt => ({
    value: rt,
    label: reportTemplateMapper(rt)?.report
  }));

  return (
    <Modal
      title="Download Reports"
      titleClassName={'blueHeader'}
      isOpen={isOpen}
      submitButtonColor="blue"
      submitButtonText="Download"
      onRequestClose={() => {
        resetState();
        onRequestClose();
      }}
      submitActions={() => {
        resetState();
        submitActions({
          options: [
            ...downloadInvestigationReports,
            ...downloadActionReports,
            ...downloadClosureReports,
            ...additionalInfo
          ],
          reportTypes
        });
      }}
      disableSubmit={disableSubmit}
    >
      <Checkbox
        fieldLabel="Select All"
        onChange={v => handleSelectAll(v)}
        currentValue={selectAll}
      />
      <Dropdown
        fieldLabel="Investigation"
        searchable
        selectButtons
        options={investigationOptions}
        currentValue={downloadInvestigationReports}
        multi
        onChange={v => {
          setDownloadInvestigationReports(v);
          setSelectAll(false);
        }}
      />
      {actionOptions?.length ? (
        <Dropdown
          fieldLabel="Action"
          searchable
          selectButtons
          options={actionOptions}
          currentValue={downloadActionReports}
          multi
          onChange={v => {
            setDownloadActionReports(v);
            setSelectAll(false);
          }}
        />
      ) : null}
      {closureOptions?.length ? (
        <Dropdown
          fieldLabel="Closure"
          searchable
          selectButtons
          options={closureOptions}
          currentValue={downloadClosureReports}
          multi
          onChange={v => {
            setDownloadClosureReports(v);
            setSelectAll(false);
          }}
        />
      ) : null}
      {availableReportTypes?.length > 1 ? (
        <Dropdown
          fieldLabel="Report Types"
          searchable
          selectButtons
          options={availableReportTypes}
          currentValue={reportTypes}
          multi
          onChange={v => {
            setReportTypes(v);
            setSelectAll(false);
          }}
        />
      ) : null}
      <CheckboxGroup
        options={DOWNLOAD_REPORTS_OPTIONS}
        currentValue={additionalInfo}
        name="investigation"
        onChange={v => {
          setAdditionalInfo(v);
          setSelectAll(false);
        }}
        labelStyles={styles.checkboxText}
        smallerMargin
      />
    </Modal>
  );
};

export default DownloadReportsModal;
