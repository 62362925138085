import React, { Component } from 'react';
import { FieldRenderer } from '../../inputs';
import { DatePicker } from '../../inputs/DateTimePicker';
import FileDownloader from '../../FileDownloader';
import FileUploader from '../../FileUploader';
import HierarchySelector from '../../HierarchySelector';

import './index.css';

const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default class CustomDocumentGenerator extends Component {
  handleAnswer = (field, answer) => {
    field.answer = answer;

    const fields = this.props.fields;
    fields[field._id] = field;
    this.props.answerQuestion(fields);
  };

  render() {
    const {
      fields,
      disabled,
      activeCustomDoc,
      handleExpires,
      addAttachment,
      deleteAttachment,
      activeCompanyId,
      loading,
      hasAccess,
      attachments,
      setDocument,
      missingRequired
    } = this.props;

    return (
      <div className="customDocumentGenerator">
        <h2 className="customDocumentGenerator-header">
          {activeCustomDoc?.label}
        </h2>
        {activeCustomDoc?.shouldHaveExpiredDate || activeCustomDoc?.expires ? (
          <DatePicker
            fieldLabel="Expires"
            onChange={handleExpires}
            currentValue={activeCustomDoc?.expires}
            disabled={disabled}
            name="expirationDate"
          />
        ) : null}
        <HierarchySelector
          multi
          groupDisabled={disabled}
          projectDisabled={disabled}
          groupId={activeCustomDoc.groupIds}
          projectId={activeCustomDoc.projectIds}
          onGroupChange={values => setDocument({ groupIds: values })}
          onProjectChange={values => setDocument({ projectIds: values })}
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
          touched={missingRequired && !activeCustomDoc.groupIds.length}
        />
        {fields &&
          fields
            .filter(f => !f?.legacy)
            .map((field, index) => (
              <FieldRenderer
                key={index}
                fieldLabel={field.label}
                isRequired={field.required}
                name={field.label}
                currentValue={findAnswer(fields, field._id)}
                onChange={v => this.handleAnswer(field, v)}
                disabled={disabled}
                options={field.options}
                type={field.type}
                bareValues={false}
                alphabetize
                ownerType="customDocument"
                missingRequired={missingRequired}
              />
            ))}
        <div>
          <h4>Attachments</h4>
          {attachments?.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteAttachment}
              disabled={!hasAccess || disabled}
              ownerType="documents"
            />
          ))}
        </div>
        {!disabled && (
          <FileUploader
            activeCompanyId={activeCompanyId}
            addAttachment={addAttachment}
            loading={loading}
            ownerId={activeCustomDoc?._id ?? 'pending'}
            ownerType="documents"
          />
        )}
      </div>
    );
  }
}
