import React from 'react';
import Card from '../../components/Card';
import { Checkbox, Dropdown, Textbox } from '../../components/inputs';

export default function RootCauseCard({
  disabled,
  index,
  rc,
  updateAnswer,
  removeRc,
  missingRequired
}) {
  const CATEGORY_DD = [
    { value: 1, label: 'Difficulty of Task or Process' },
    { value: 2, label: 'Equipment Issue' },
    { value: 3, label: 'Human Factor' },
    { value: 4, label: 'Lack of Supervision and/or Training' },
    { value: 5, label: 'Unsafe Personal Act(s)' },
    { value: 6, label: 'Working Conditions' },
    { value: 7, label: 'Other' }
  ];

  return (
    <Card
      name={`rcCard${index}`}
      title={`Root Cause ${++index}`}
      wide
      showHeader
      key={index}
      rightButton={
        disabled ? null : (
          <img
            src={require('../../assets/images/remove.png')}
            alt="delete question"
            onClick={removeRc}
            data-cy="removeButton"
            style={{ margin: 0, cursor: 'pointer' }}
          />
        )
      }
    >
      <Dropdown
        searchable
        fieldLabel="Category"
        options={CATEGORY_DD}
        currentValue={rc.category}
        onChange={v => updateAnswer({ category: v })}
        isRequired
        disabled={disabled}
        bareValues={false}
        touched={missingRequired && !rc.category}
      />
      <Textbox
        fieldLabel="Title"
        currentValue={rc.title}
        onChange={v => updateAnswer({ title: v })}
        isRequired
        disabled={disabled}
        touched={missingRequired && !rc.title}
      />
      <Textbox
        fieldLabel="Description"
        currentValue={rc.description}
        onChange={v => updateAnswer({ description: v })}
        type="textarea"
        isRequired
        disabled={disabled}
        touched={missingRequired && !rc.description}
      />
      <Checkbox
        fieldLabel="Create a Corrective Action"
        onChange={v => updateAnswer({ createCa: v })}
        currentValue={rc.createCa}
        disabled={disabled}
      />
    </Card>
  );
}
