import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import UserBadge from '../../components/UserBadge';
import {
  Input,
  InputRow,
  Dropdown,
  CalendarDropdown
} from '../../components/inputs';

import styles from './shares.module.scss';
const bStyles = classnames.bind(styles);

export default function DashboardShares({ shares, title, expired, onChange }) {
  const filteredShares = expired
    ? shares?.filter(p => p.endDate && moment().isSameOrAfter(p.endDate))
    : shares?.filter(p => !p.endDate || moment().isBefore(p.endDate));
  return (
    <Input fieldLabel={title} className={styles.share}>
      {filteredShares?.map((s, i) => (
        <div key={i} className={bStyles({ expired })}>
          <span
            onClick={() => {
              shares = shares.filter(su => su.userId !== s.userId);
              onChange(shares);
            }}
            data-cy="X"
          >
            &times;
          </span>
          <UserBadge userId={s.userId} showName showImage />
          {expired && (
            <div className={styles.expireMessage}>Permission Expired</div>
          )}
          <InputRow className={styles.shareOptions}>
            <Dropdown
              options={[
                { label: 'View Only', value: true },
                { label: 'View/Edit', value: false }
              ]}
              currentValue={s.readOnly}
              onChange={readOnly => {
                const sharesIndex = shares.findIndex(
                  su => s.userId === su.userId
                );
                shares[sharesIndex].readOnly = readOnly;
                onChange(shares);
              }}
              testID="User Permissions"
            />
            <CalendarDropdown
              currentValue={s.endDate ? moment(s.endDate) : null}
              onChange={endDate => {
                const sharesIndex = shares.findIndex(
                  su => s.userId === su.userId
                );
                shares[sharesIndex].endDate = endDate;
                onChange(shares);
              }}
              placeholder="Indefinitely"
              startTop={expired}
              testID="Calendar Dropdown"
            />
          </InputRow>
        </div>
      ))}
    </Input>
  );
}
