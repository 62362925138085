export const oshaText = {
  happenToWorker: 'Did any of these happen to worker?',
  dateTime: 'When did the incident occur?',
  description: 'Describe where they incident occurred.',
  employeeName: `Injured or ill worker's name.`,
  jobTitle: 'Job Title',
  injuryIllness: 'Classiify Injury or Illness',
  happenToThisWorker: 'Did any of these happen to this worker?',
  timeofDayWorkStarted: 'Time of day worker stated.',
  workingDoing:
    'What was worker doing and using (i.e. tools and materials) just beofre the incident occurred?',
  howInjuryOccurred: 'How did the injury / incident occur?',
  incidentInjuryIllness: 'Affecting',
  bodyParts: 'From',
  physicianName: 'Name of physician or healthcare professional',
  facilityName: 'Where was treatment given?',
  treatedInEmergency: 'Treated in emergency room?',
  hospitalizedInpatient: 'Hospitalized overnight as inpatient',
  daysAwayFromWork: 'Days away from work.',
  daysJobRestriction: 'Days on job restriction or transfer.',
  dateOfDeath: 'Date of Death'
};
