import React from 'react';
import classnames from 'classnames/bind';

import styles from './badge.module.scss';

let Badge = ({ color, text, className, bold, square }) => {
  let classes = classnames.bind(styles)({
    badge: true,
    [color]: true,
    className: true,
    square
  });

  return <div className={classes}>{text}</div>;
};

export default Badge;
