import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

export default function TypeSelection({
  selected,
  selectedArray,
  onClick,
  className
}) {
  return (
    <div className={bStyles(styles.typeSelection, className)}>
      {selectedArray.map((item, index) =>
        item !== null ? (
          <div
            data-cy={item}
            className={item === selected ? styles.toggleSelected : ''}
            onClick={() => onClick(item)}
            key={index}
          >
            {item}
          </div>
        ) : null
      )}
    </div>
  );
}
