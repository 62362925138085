import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { fetchSafetyAuditTemplates, archiveMany } from '../../api/v4';
import history from '../../history';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import Modal from '../../components/Modal';
import { Dropdown } from '../../components/inputs';
import TypeSelection from '../../components/TypeSelection';

export default function SafetyAuditTemplates() {
  const location = useLocation().pathname;
  const auditType = location.split('/').pop();
  const auditName =
    auditType === 'loto'
      ? 'Lockout/Tagout Procedure'
      : `${auditType.charAt(0).toUpperCase() + auditType.slice(1)} Audit`;
  const [templates, setTemplates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  const [listType, setListType] = useState('Active');

  useEffect(() => {
    fetchSafetyAuditTemplates(auditType).then(setTemplates);
  }, [auditType, listType]);

  useEffect(() => {
    fetchSafetyAuditTemplates(auditType).then(response =>
      setTemplateDropdown(
        response
          .filter(template => !template.isArchived)
          .map(r => {
            return { value: r._id, label: r.title };
          })
      )
    );
  }, [auditType]);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'auditTemplate',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() => {
      setTemplates([]);
      fetchSafetyAuditTemplates(auditType).then(response => {
        setTemplates(response);
        setTemplateDropdown(
          response
            .filter(template => !template.isArchived)
            .map(r => {
              return { value: r._id, label: r.title };
            })
        );
      });
    });
  };

  const header = (
    <Header
      title={`${auditName} Templates`}
      pageActionOptions={[
        {
          label: `Add ${auditName} Template`,
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            history.push(`/app/auditTemplates/${auditType}/template/`)
        },
        {
          label: `Duplicate ${auditName} Template`,
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );

  const columns = [
    { key: 'title' },
    { key: 'description' },
    {
      key: 'updatedAt',
      accessor: r =>
        r?.auditLog?.length
          ? moment(r.auditLog[r.auditLog.length - 1].when)
          : '',
      datatype: 'date'
    }
  ];

  const listTypeFilter =
    listType === 'Archived'
      ? templates?.filter(template => template.isArchived)
      : templates?.filter(template => !template.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={setListType}
        selected={listType}
      />
      <List
        saveKey={`${listType === 'Archived' ? 'archived' : ''}safetyTemplates`}
        rowClick={row =>
          history.push(`/app/auditTemplates/${auditType}/template/${row._id}`)
        }
        dataIsHash
        data={listTypeFilter}
        settings={columns}
        getRowId={r => r._id}
        actions={[
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() =>
          history.push(
            `/app/auditTemplates/${auditType}/template/${currentTemplate}/true`
          )
        }
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel={`${auditName} Template`}
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder={`Select a ${auditType} audit`}
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
