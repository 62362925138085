import {
  ADD_DOCUMENT_FOLDER_REQUEST,
  ADD_DOCUMENT_FOLDER_RESPONSE,
  ADD_DOCUMENTS_TO_FOLDER_REQUEST,
  ADD_DOCUMENTS_TO_FOLDER_RESPONSE,
  CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  DELETE_DOCUMENT_FOLDER_REQUEST,
  DELETE_DOCUMENT_FOLDER_RESPONSE,
  FETCH_DOCUMENT_FOLDERS_REQUEST,
  FETCH_DOCUMENT_FOLDERS_RESPONSE,
  SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
  SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE,
  UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  UPDATE_DOCUMENT_FOLDER_REQUEST,
  UPDATE_DOCUMENT_FOLDER_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  sds: [],
  loading: false,
  activeSds: {},
  activeQualification: {},
  lossRun: [],
  qualifications: [],
  qualificationTypes: [],
  documentFolders: [],
  activeDocumentFolder: {},
  activeMyDocument: {},
  myDocsList: [],
  requireAuthDocs: [],
  customDocuments: [],
  activeCustomDocument: {},
  customDocumentTemplates: [],
  activeCustomDocumentTemplate: {}
};

const documents = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOCUMENT_FOLDERS_REQUEST:
    case ADD_DOCUMENT_FOLDER_REQUEST:
    case UPDATE_DOCUMENT_FOLDER_REQUEST:
    case DELETE_DOCUMENT_FOLDER_REQUEST:
    case SET_ACTIVE_DOCUMENT_FOLDER_REQUEST:
    case ADD_DOCUMENTS_TO_FOLDER_REQUEST:
    case CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_DOCUMENT_FOLDERS_RESPONSE:
      return {
        ...state,
        loading: false,
        documentFolders: payload
      };

    case ADD_DOCUMENT_FOLDER_RESPONSE:
      return {
        ...state,
        loading: false,
        documentFolders: [...state.documentFolders, payload]
      };

    case UPDATE_DOCUMENT_FOLDER_RESPONSE: {
      let folderList = state.documentFolders;

      const index = folderList.findIndex(folder => folder._id === payload._id);

      folderList[index] = payload;

      return {
        ...state,
        loading: false,
        documentFolders: folderList
      };
    }

    case DELETE_DOCUMENT_FOLDER_RESPONSE: {
      const folderList = state.documentFolders;
      const index = folderList.findIndex(folder => folder._id === payload._id);

      return {
        ...state,
        loading: false,
        documentFolders: [
          ...folderList.slice(0, index),
          ...folderList.slice(index + 1)
        ]
      };
    }

    case SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE:
      return {
        ...state,
        loading: false,
        activeDocumentFolder: payload
      };

    case ADD_DOCUMENTS_TO_FOLDER_RESPONSE:
      return { ...state, loading: false };

    default:
      return {
        ...state
      };
  }
};

export default documents;
