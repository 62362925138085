import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Pagination from '../../components/Pagination';

import './index.css';
const perPage = 10;
export default class Osha300List extends Component {
  state = {
    page: 0,
    pageCases: []
  };

  componentDidMount = () => {
    const pageCases = this.props?.cases?.slice(
      this.state.page * perPage,
      (this.state.page + 1) * perPage
    );
    this.setState({ pageCases });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const pageCases = this.props?.cases?.slice(
      this.state.page * perPage,
      (this.state.page + 1) * perPage
    );

    if (prevProps.cases !== this.props.cases) {
      this.setState({
        pageCases,
        page: 0
      });
    }

    if (prevState.page !== this.state.page) {
      this.setState({ pageCases });
    }
  };

  handleRowClick = values => {
    if (!values.isRedLined) {
      this.props.setSelected(2);
      this.props.setSelectedRow(values);
    }
  };

  render() {
    const { setActiveIncident, isPerrp } = this.props;
    const { page, pageCases } = this.state;

    return (
      <div className="osha300List" id="osha300List">
        <table className="osha300List-table">
          <thead>
            <tr>
              <th
                colSpan="3"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--firstTopHeader"
              >
                Identify The Person
              </th>
              <th
                colSpan="3"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--secondTopHeader"
              >
                Describe The Case
              </th>
              <th
                colSpan="1"
                rowSpan="2"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--thirdTopHeader"
              >
                Classify The Case
              </th>
              <th
                colSpan="2"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--fourthTopHeader"
              >
                Days Affected
              </th>
              <th
                colSpan="1"
                rowSpan="2"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--fifthTopHeader"
              >
                Injury or Illness
              </th>
            </tr>
            <tr>
              <th scope="col" className="paddingBottom">
                Case #
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Worker Name
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Job Title
              </th>
              <th scope="col" className="paddingBottom">
                Date
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Location
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Description
              </th>
              <th scope="col" className="paddingBottom">
                Away
              </th>
              <th scope="col" className="paddingBottom">
                At Work
              </th>
            </tr>
          </thead>
          <tbody>
            {pageCases &&
              Array.isArray(pageCases) &&
              pageCases.map((row, index) => (
                <tr
                  key={index}
                  className={row.isIncomplete ? 'isIncomplete' : 'isComplete'}
                  onClick={() => this.handleRowClick(row)}
                >
                  <td
                    className={
                      row.isRedLined ? 'paddingTop redLined' : 'paddingTop'
                    }
                  >
                    {row.isIncomplete && (
                      <div
                        className="editicons"
                        onClick={() => setActiveIncident(row.incidentId)}
                      >
                        <ReactTooltip
                          className="oshaListErrorTheme"
                          id="divEstError"
                        >
                          <p>{`Missing ${
                            isPerrp ? 'PERRP' : 'OSHA'
                          } compliant information.`}</p>
                          <p>Click to Go Edit Case </p>
                        </ReactTooltip>
                        <img
                          style={{ width: '32px', height: '32px' }}
                          src={require('../../assets/images/incompleteCase.png')}
                          alt="caseIncomplete"
                          data-tip="oshaListErrorTheme"
                          data-for="divEstError"
                        />
                      </div>
                    )}
                    {row.caseNumber}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-secondWidth redLined'
                        : 'osha300List-secondWidth'
                    }
                  >
                    <div>
                      <ReactTooltip
                        className="tooltip"
                        id={`tooltipName${index}`}
                      >
                        <p>
                          {!row.workerData.privacyConcern
                            ? row.workerData.name
                            : 'Privacy Concern'}
                        </p>
                      </ReactTooltip>
                    </div>
                    <span data-tip data-for={`tooltipName${index}`}>
                      {!row.workerData.privacyConcern
                        ? row.workerData.name
                        : 'Privacy Concern'}
                    </span>
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-thirdWidth redLined'
                        : 'osha300List-thirdWidth'
                    }
                  >
                    <div>
                      <ReactTooltip
                        className="tooltip"
                        id={`tooltipJobTitle${index}`}
                      >
                        <p>
                          {!row.workerData.privacyConcern
                            ? row.workerData.jobTitle
                            : '-----------------'}
                        </p>
                      </ReactTooltip>
                    </div>

                    <span data-tip data-for={`tooltipJobTitle${index}`}>
                      {!row.workerData.privacyConcern
                        ? row.workerData.jobTitle
                        : '-----------------'}
                    </span>
                  </td>
                  <td
                    className={
                      row.isRedLined ? 'paddingTop redLined' : 'paddingTop'
                    }
                  >
                    {row.claimData.dateOfClaim !== ''
                      ? moment(row.claimData.dateOfClaim).format('MM/DD/YYYY')
                      : row.claimData.dateOfClaim}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-thirdWidth redLined'
                        : 'osha300List-thirdWidth'
                    }
                    id={`location${index}`}
                    data-tip="customTheme"
                    data-for={`tooltipLocation${index}`}
                  >
                    <div>
                      <ReactTooltip
                        className="tooltip"
                        id={`tooltipLocation${index}`}
                      >
                        <p>{row.claimData.locationOfClaim}</p>
                      </ReactTooltip>
                    </div>

                    <span data-tip data-for={`tooltipLocation${index}`}>
                      {row.claimData.locationOfClaim}
                    </span>
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-sixthWidth redLined'
                        : 'osha300List-sixthWidth'
                    }
                  >
                    <div>
                      <ReactTooltip
                        className="tooltip"
                        id={`tooltipDesc${index}`}
                      >
                        <p> {row.claimData.descriptionOfClaim}</p>
                      </ReactTooltip>
                    </div>

                    <span data-tip data-for={`tooltipDesc${index}`}>
                      {row.claimData.descriptionOfClaim}
                    </span>
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-seventhWidth redLined'
                        : 'osha300List-seventhWidth'
                    }
                  >
                    {row.claimData.caseClassification}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-eighthWidth redLined'
                        : 'osha300List-eighthWidth'
                    }
                  >
                    {row.claimData.daysAwayFromWork}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-eighthWidth redLined'
                        : 'osha300List-eighthWidth'
                    }
                  >
                    {row.claimData.daysOnJobRestrictionOrTransfer}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-tenthWidth redLined'
                        : 'osha300List-tenthhWidth'
                    }
                  >
                    {row.claimData.typeOfIllnessOrInjury}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          page={page}
          count={this.props?.cases?.length}
          perPage={perPage}
          setPage={page => this.setState({ page })}
        />
      </div>
    );
  }
}
