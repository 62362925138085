import { call, put, select } from 'redux-saga/effects';

import {
  loadUserResponse,
  loadAllUsersResponse,
  loadAllCompanyUsersResponse
} from '../actions/user';
import { getLoadedUsers, getAllUsersLoaded } from '../selectors/users';

export function* loadUser(apiV4, { payload: userId }) {
  try {
    let users = yield select(getLoadedUsers);
    let loading = users?.[userId]?.loading;
    let error = users?.[userId]?.error;

    if ((loading === false && !error) || loading === true) {
      return;
    }

    yield put(
      loadUserResponse({
        _id: userId,
        loading: true
      })
    );

    let response = yield call(apiV4.fetchUser, userId);

    yield put(
      loadUserResponse({
        loading: false,
        ...response
      })
    );
  } catch (e) {
    yield put(
      loadUserResponse({
        _id: userId,
        loading: false,
        error: true
      })
    );
    if (e.status !== 404) console.log(e);
  }
}

export function* loadAllUsers(apiV4) {
  try {
    const allLoaded = yield select(getAllUsersLoaded);

    if (allLoaded) return;

    let response = yield call(apiV4.fetchAllUsers);

    yield put(loadAllUsersResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* loadAllCompanyUsers(apiV4) {
  try {
    let response = yield call(apiV4.fetchCompanyUsers);

    yield put(loadAllCompanyUsersResponse(response));
  } catch (e) {
    console.log(e);
  }
}
