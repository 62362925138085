import React, { useCallback, useEffect, useState } from 'react';
import Card from '../../../../Card';
import HierarchySelector from '../../../../HierarchySelector';
import { DatePicker } from '../../../../inputs/DateTimePicker';
import moment from 'moment';
import {
  isFutureDate,
  notFutureDate
} from '../../../../../utils/formValidation';
import {
  Button,
  Dropdown,
  EmployeeDropdown,
  TwoColumn
} from '../../../../inputs';
import RadioButtons from '../../../../inputs/RadioButtons';
import { fetchCompanyUsers, getJobList } from '../../../../../api/v4';

import styles from './scheduleCard.module.scss';

export default function ScheduleInPersonCard(props) {
  const {
    training,
    onAnswer,
    setCanSubmit,
    company,
    disabled,
    hideWarnings = false,
    editingAssignees,
    location,
    accessLevel,
    missingRequired
  } = props;
  const [jobList, setJobList] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (training.assignBy === 1) {
      getJobList().then(r => {
        setJobList(r?.map(job => ({ value: job, label: job })));
      });
    }
  }, [training.assignBy]);

  const handleGroupChange = values => {
    const validProjectIds = company.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);

    const newProjectIds = training.projectIds?.filter(p =>
      validProjectIds.includes(p)
    );
    const validUsers = training.assignedTo?.filter(user =>
      employees.find(
        u =>
          u._id.toString() === user && u.groupIds.some(g => values.includes(g))
      )
    );
    onAnswer({
      groupIds: values,
      projectIds: newProjectIds,
      assignedTo: values?.length ? validUsers : training.assignedTo
    });
  };

  const assignOnAfterDueDate = moment(training.assignOn).isAfter(
    training.dueDate
  );
  const futureDateWarning = isFutureDate(
    moment(training.dueDate)
      ?.hour(23)
      ?.minute(59)
  );

  const completionDateWarning =
    training?.completionDate && notFutureDate(training.completionDate, true);

  const setSubmit = useCallback(() => {
    if (!setCanSubmit) return;
    if (accessLevel < 900 && !training.groupIds?.length) setCanSubmit(false);
    else if (training.schedule === 3) setCanSubmit(true);
    else if (
      (training.quizMasterId || training.collectSignatures) &&
      training.schedule === 1 &&
      !training.releaseType
    )
      setCanSubmit(false);
    else if (
      training.schedule === 2 &&
      (training.completionDate || training.releaseType) &&
      (training.assignedTo?.length || training.assignByJobTitles?.length) &&
      !completionDateWarning
    )
      setCanSubmit(true);
    else if (
      !training.assignOn ||
      !training.dueDate ||
      assignOnAfterDueDate ||
      futureDateWarning ||
      moment(training.assignOn).isBefore(moment(), 'day')
    )
      setCanSubmit(false);
    else if (
      !training.admins?.length ||
      !training.admins.every(a => a.adminId && a.groupIds?.length)
    )
      setCanSubmit(false);
    else setCanSubmit(true);
  }, [
    setCanSubmit,
    training,
    assignOnAfterDueDate,
    futureDateWarning,
    completionDateWarning,
    accessLevel
  ]);

  useEffect(() => {
    setSubmit();
  }, [training, setSubmit]);
  useEffect(() => {
    fetchCompanyUsers().then(setEmployees);
  }, []);

  const canAssignToDeactivated =
    training.trainingType === 2 &&
    (training.schedule === 2 || !training.quizMasterId) &&
    !training.collectSignatures;

  return (
    <Card showHeader title="Schedule" name="schedule">
      {training.schedule === 2 || training.schedule === 3 ? (
        <HierarchySelector
          multi
          groupRequired={accessLevel < 900}
          groupId={training.groupIds}
          projectId={training.projectIds}
          onGroupChange={handleGroupChange}
          onProjectChange={v => onAnswer({ projectIds: v })}
          groupDisabled={disabled}
          projectDisabled={!(training.groupIds || location) || disabled}
        />
      ) : null}
      {training.schedule === 1 ? (
        <>
          <DatePicker
            fieldLabel="Assign On"
            isRequired
            onChange={v =>
              onAnswer({
                assignOn: v,
                dueDate: moment(v).isAfter(training.dueDate)
                  ? null
                  : training.dueDate
              })
            }
            currentValue={training.assignOn}
            touched={missingRequired && !training.assignOn}
            name="assignOn"
            disabled={disabled}
            minDate={moment()}
          />
          <DatePicker
            fieldLabel="Due By"
            isRequired
            onChange={v => onAnswer({ dueDate: v })}
            currentValue={training.dueDate}
            touched={
              !hideWarnings &&
              missingRequired &&
              training.assignOn &&
              !training.dueDate
            }
            name="dueBy"
            disabled={(disabled || !training.assignOn) && !editingAssignees}
            minDate={moment(training.assignOn).add(1, 'days')}
          />
        </>
      ) : null}
      {training.schedule === 1 &&
      training.trainingType === 2 &&
      (training.quizMasterId || training.collectSignatures) ? (
        <RadioButtons
          options={[
            { value: 1, label: 'On To Do List' },
            { value: 2, label: 'As a QR Code' }
          ]}
          currentValue={training.releaseType}
          onChange={v => onAnswer({ releaseType: v })}
          fieldLabel={`${
            training.quizMasterId && training.collectSignatures
              ? 'Quiz Release and Signature Collection'
              : training.collectSignatures
              ? 'Signature Collection'
              : 'Quiz Release'
          }`}
          needSeparateLines
          isRequired
          disabled={disabled}
          touched={missingRequired && !training.releaseType}
        />
      ) : training.schedule === 2 ? (
        <DatePicker
          fieldLabel="Completed On"
          isRequired
          onChange={v => onAnswer({ completionDate: v })}
          currentValue={training.completionDate}
          touched={missingRequired && !training.completionDate}
          name="completionDate"
          disabled={disabled}
          maxDate={moment()}
        />
      ) : null}
      {training.collectSignatures &&
      training.trainingType === 2 &&
      (training.isAdministered || training.isCompleted) ? (
        <EmployeeDropdown
          fieldLabel="Attendees"
          permissions={[100, 400, 500, 900]}
          onChange={v =>
            onAnswer({
              assignedTo: v
            })
          }
          disabled
          multi
          group={training.groupIds?.length ? training.groupIds : null}
          currentValue={training.assignedTo}
          isRequired
          touched={missingRequired && !training.assignedTo?.length}
        />
      ) : null}
      {training.schedule === 1 ? (
        <>
          <div className={styles.adminContainer}>
            <span className={styles.adminInfo}>
              One training will be created for each group/establishment selected
            </span>
          </div>
          {training.admins?.map((admin, index) => {
            return (
              <TwoColumn className={styles.specialGrid}>
                <TwoColumn>
                  <EmployeeDropdown
                    permissions={[400, 500, 900]}
                    fieldLabel="Administrator"
                    currentValue={admin.adminId}
                    exclude={[
                      ...training.admins
                        .filter(a => a.adminId !== admin.adminId)
                        .map(a => a.adminId)
                    ]}
                    onChange={adminId => {
                      let newAdmin = { ...training.admins[index], adminId };
                      training.admins[index] = { ...newAdmin };
                      onAnswer({ admins: training.admins });
                    }}
                    searchable
                    name="dropdownEmployees"
                    isRequired
                    disabled={disabled}
                    touched={missingRequired && !admin.adminId}
                  />
                  <Dropdown
                    multi
                    selectButtons
                    searchable
                    options={company.groups
                      .filter(g => {
                        const adminGroups = employees.find(
                          e => e._id === admin.adminId
                        )?.groupIds;
                        return adminGroups?.includes(g._id);
                      })
                      ?.map(g => ({
                        value: g._id,
                        label: g.name
                      }))}
                    fieldLabel="Group or Establishment"
                    currentValue={admin.groupIds}
                    onChange={groupIds => {
                      let newGroup = { ...training.admins[index], groupIds };
                      training.admins[index] = newGroup;
                      onAnswer({ admins: training.admins });
                    }}
                    isRequired
                    disabled={disabled || !admin.adminId}
                    touched={
                      missingRequired &&
                      (!disabled || admin.adminId) &&
                      !admin.groupIds
                    }
                  />
                </TwoColumn>
                {training.admins?.length > 1 && (
                  <img
                    type="button"
                    src={require('../../../../../assets/images/remove.png')}
                    alt="delete admin"
                    onClick={() => {
                      training.admins.splice(index, 1);
                      onAnswer({ admins: training.admins });
                    }}
                    className={styles.removeImage}
                    data-cy="removeButton"
                  />
                )}
              </TwoColumn>
            );
          })}
          <Button
            text="Add Administrator"
            color="blue"
            onClick={() =>
              onAnswer({ admins: (training.admins ?? []).concat({}) })
            }
            inputClassName={styles.button}
            disabled={disabled}
          />
        </>
      ) : training.schedule === 2 ? (
        <>
          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={training.assignBy}
            onChange={v =>
              onAnswer({ assignBy: v, assignedTo: [], assignByJobTitles: [] })
            }
            fieldLabel="Assign By"
            needSeparateLines
            isRequired
            disabled={disabled && !editingAssignees}
            touched={missingRequired && !training.assignBy}
          />
          {training.assignBy === 1 ? (
            <Dropdown
              fieldLabel={disabled ? 'Job Titles' : 'Assignees'}
              options={jobList}
              searchable
              selectButtons
              onChange={v => {
                onAnswer({
                  assignedTo: employees.filter(e =>
                    v.includes(e?.jobTitle?.toLowerCase())
                  ),
                  assignByJobTitles: v
                });
              }}
              isRequired
              multi
              currentValue={training.assignByJobTitles}
              disabled={disabled && !editingAssignees}
              touched={missingRequired && !training.assignByJobTitles?.length}
            />
          ) : null}
          {training.assignBy === 2 || disabled ? (
            <EmployeeDropdown
              fieldLabel="Assignees"
              permissions={canAssignToDeactivated ? null : [100, 400, 500, 900]}
              onChange={v =>
                onAnswer({
                  assignedTo: v
                })
              }
              searchable
              selectButtons
              filterChoices={
                disabled
                  ? null
                  : [
                      {
                        label: 'Inactive Employees',
                        value: 'inactive'
                      }
                    ]
              }
              employees={canAssignToDeactivated ? employees : null}
              disabled={(!training.assignBy || disabled) && !editingAssignees}
              multi
              group={training.groupIds?.length ? training.groupIds : null}
              currentValue={training.assignedTo}
              isRequired
              touched={missingRequired && !training.assignedTo?.length}
            />
          ) : null}
        </>
      ) : null}
    </Card>
  );
}
