import { createSelector } from 'reselect';

const taskSelector = state => state.tasks;

export const getUserTasksSelector = createSelector(
  taskSelector,
  tasks => tasks && tasks.list
);

export const getActiveTaskSelector = createSelector(
  taskSelector,
  activeTask => activeTask && activeTask.activeTask
);

export const getRemindedAssignments = createSelector(
  taskSelector,
  assignment => assignment.reminders
);
