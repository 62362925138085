import { createSelector } from 'reselect';

const personnelSelector = state => state.personnel;

export const getActiveEmployeeSelector = createSelector(
  personnelSelector,
  activeEmployee => activeEmployee && activeEmployee.activeEmployee
);

export const getPersonnelLoadingSelector = createSelector(
  personnelSelector,
  personnel => personnel && personnel.loading
);
