import React from 'react';
import moment from 'moment';
import Signature from './Signature';
import CheckboxGroup from './CheckboxGroup';
import { DatePicker, TimePicker } from './DateTimePicker';
import GoogleMap from './GoogleMap';
import RadioButtons from './RadioButtons';
import Money from './Money';
import { Textbox, Dropdown, EmployeeDropdown } from './';
import Notebox from '../Notebox';
import QRSignQuestion from '../QRSignQuestion';
import { BoldTextHeader } from './BoldTextHeader';
import CustomTable from './CustomTable';
import { parseTime, renderTime } from '../../utils/dateHelpers';

export const optionsMapper = options =>
  options.map((option, index) => ({ value: index, label: option }));

export const FieldRenderer = ({ options, type, alphabetize, ...props }) => {
  let invalidDateTime = false;
  let errorMessage;
  if (props.fieldLabel === 'When did the incident occur?') {
    invalidDateTime = moment().isBefore(props.currentValue);
    if (invalidDateTime) errorMessage = 'Must be a past date and time';
  }
  if (props.hide) return <></>;

  return type === 'radiobuttons' ? (
    <RadioButtons
      options={optionsMapper(options)}
      {...props}
      bareValues={false}
      touched={props.missingRequired && !props.currentValue && props.isRequired}
    />
  ) : type === 'textarea' ? (
    <Textbox
      type="textarea"
      {...props}
      touched={props.missingRequired && !props.currentValue && props.isRequired}
    />
  ) : type === 'dateTimePicker' ? (
    <DatePicker
      pickTime
      {...props}
      touched={
        (props.missingRequired && !props.currentValue && props.isRequired) ||
        invalidDateTime
      }
      errorMessage={errorMessage}
    />
  ) : type === 'datePicker' ? (
    <DatePicker
      error={props.missingRequired && !props.currentValue && props.isRequired}
      touched={props.missingRequired && !props.currentValue && props.isRequired}
      {...props}
    />
  ) : type === 'timePicker' ? (
    <TimePicker
      {...props}
      hours={parseTime(props.currentValue)[0]}
      minutes={parseTime(props.currentValue)[1]}
      onChange={(hours, minutes) => {
        props.onChange(renderTime(hours, minutes, true));
      }}
      touched={props.missingRequired && !props.currentValue && props.isRequired}
      onlyTime
    />
  ) : type === 'dropdown' || type === 'dropUp' ? (
    <Dropdown
      options={optionsMapper(options)}
      searchable
      {...props}
      alphabetize={alphabetize}
      touched={props.missingRequired && !props.currentValue && props.isRequired}
    />
  ) : type === 'multiselectDown' ||
    type === 'multiselectUp' ||
    type === 'multiselect' ? (
    <Dropdown
      multi
      selectButtons
      options={optionsMapper(options)}
      searchable
      alphabetize={alphabetize}
      {...props}
      currentValue={props?.currentValue?.length > 0 ? props.currentValue : []}
      touched={
        props.missingRequired && !props.currentValue.length && props.isRequired
      }
    />
  ) : type === 'multiselectPersonnel' ? (
    <EmployeeDropdown
      searchable
      multi
      group={props.groupId}
      selectButtons
      {...props}
      currentValue={props.currentValue || []}
      bareValues={true}
      employees={props?.employees}
      alphabetize
      permissions={
        props.fieldLabel === 'Additional Witnesses of the Incident:'
          ? [100, 400, 500, 900]
          : null
      }
      touched={props.missingRequired && !props.currentValue && props.isRequired}
    />
  ) : type === 'supervisor' ? (
    <EmployeeDropdown
      searchable
      {...props}
      group={props.groupId}
      permissions={[400, 500, 900]}
      currentValue={props.currentValue || []}
      bareValues={true}
      employees={props?.employees}
      alphabetize
      multi
      touched={props.missingRequired && !props.currentValue && props.isRequired}
    />
  ) : type === 'checkbox' ? (
    <CheckboxGroup
      options={optionsMapper(options)}
      onChange={props.onChange}
      bareValues={false}
      alphabetize
      testID={props.fieldLabel}
      touched={
        props.missingRequired &&
        (!props.currentValue || !props.currentValue.length) &&
        props.isRequired
      }
      {...props}
    />
  ) : type === 'textbox' ? (
    <Textbox
      touched={props.missingRequired && !props.currentValue && props.isRequired}
      {...props}
    />
  ) : type === 'qrSign' ? (
    <QRSignQuestion {...props} />
  ) : type === 'money' ? (
    <Money {...props} />
  ) : type === 'googleAddress' ? (
    <GoogleMap
      touched={props.missingRequired && !props.currentValue && props.isRequired}
      {...props}
    />
  ) : type === 'signature' ? (
    <Signature
      questionId={props.id}
      {...props}
      touched={props.missingRequired && !props.currentValue && props.isRequired}
    />
  ) : type === 'table' ? (
    <CustomTable
      questionId={props.id}
      options={options}
      {...props}
      touched={
        props.missingRequired &&
        (!props.currentValue || props.currentValue[0]?.[0]?.trim() === '') &&
        props.isRequired
      }
    />
  ) : type === 'notebox' ? (
    <Notebox note={props.fieldLabel} />
  ) : type === 'blockOfText' ? (
    <div className="customDocumentGenerator-blockText">{props.fieldLabel}</div>
  ) : type === 'boldTextHeader' ? (
    <BoldTextHeader label={props.fieldLabel} {...props} />
  ) : (
    <></>
  );
};

export default FieldRenderer;
