import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '../inputs/Button';
import { InputRow } from '../inputs';
import classnames from 'classnames/bind';
import { getCards } from '../../api/v4';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import svgMapper from '../../utils/svgMapper';
import Loading from '../Loading';
import ToDoCard from './ToDoCard';
import IncidentCard from './IncidentCard';
import ItemGraphCard from './ItemGraphCard';
import { CustomHomepageCard } from './CustomHomepageCard';

import styles from './headerCards.module.scss';
const bStyles = classnames.bind(styles);

export const HeaderCards = ({ location, className, locationId, projectId }) => {
  const [selected, setSelected] = useState(undefined);
  const [cards, setCards] = useState(undefined);
  const [customCardData, setCustomCardData] = useState();
  const [carouselIndexes, setCarouselIndexes] = useState([0, 3]);
  const card = cards && selected !== undefined ? cards[selected] : undefined;

  useEffect(() => {
    setCards(undefined);
    getCards().then(data => {
      setCards(data);
      setSelected(0);
    });
  }, [location, locationId, projectId]);

  if (cards === undefined) {
    return <Loading />;
  }

  return (
    <div className={classnames(styles.container, className)}>
      {card?.type === 'todo' ? (
        <ToDoCard card={card} key={card?.name} right />
      ) : card?.type === 'savedDrafts' ? (
        <ToDoCard card={card} key={card?.name} />
      ) : card?.type === 'assignments' ? (
        <ToDoCard card={card} key={card?.name} reminders right />
      ) : card?.type === 'incidents' ? (
        <IncidentCard card={card} key={card?.name} />
      ) : card?.type === 'reportType' ? (
        <ItemGraphCard card={card} key={card?.name} />
      ) : card?.type === 'custom' ? (
        <CustomHomepageCard
          settings={customCardData.card}
          period={customCardData.period}
        />
      ) : (
        <></>
      )}
      <InputRow className={styles.buttons} center>
        {cards.length < 4 ? (
          cards.map((card, index) => (
            <Button
              className={bStyles({
                button: true,
                selected: selected === index
              })}
              key={index}
              onClick={() => {
                setSelected(index);
                if (card.type === 'custom') {
                  setCustomCardData(card.customCardData);
                }
              }}
              text={card.name}
              color="white"
              isFromHeader
            />
          ))
        ) : (
          <div className={styles.carousel}>
            <div
              className={bStyles('leftChevron', {
                disabled: carouselIndexes[0] === 0
              })}
              onClick={() =>
                carouselIndexes[0] !== 0
                  ? setCarouselIndexes([
                      carouselIndexes[0] - 1,
                      carouselIndexes[1] - 1
                    ])
                  : null
              }
            >
              {svgMapper('chevron')}
            </div>
            {cards
              .slice(carouselIndexes[0], carouselIndexes[1])
              .map((card, index) => (
                <Button
                  className={bStyles({
                    button: true,
                    selected: card?.name === cards[selected]?.name
                  })}
                  key={index}
                  onClick={() => {
                    setSelected(index + carouselIndexes[0]);
                    if (card.type === 'custom') {
                      setCustomCardData(card.customCardData);
                    }
                  }}
                  text={card.name}
                  color="white"
                  isFromHeader
                />
              ))}
            <div
              className={bStyles('rightChevron', {
                disabled: carouselIndexes[1] === cards.length
              })}
              onClick={() =>
                carouselIndexes[1] !== cards.length
                  ? setCarouselIndexes([
                      carouselIndexes[0] + 1,
                      carouselIndexes[1] + 1
                    ])
                  : null
              }
            >
              {svgMapper('chevron')}
            </div>
          </div>
        )}
      </InputRow>
    </div>
  );
};
const mapStateToProps = state => ({
  locationId: getActiveLocationId(state)?._id,
  projectId: getActiveProject(state)?._id
});

export default connect(mapStateToProps)(HeaderCards);
