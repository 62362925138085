import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import { fetchClaims } from '../../api/v4';
import { setActiveIncidentRequest } from '../../actions/incidents';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import { getAccessLevel } from '../../selectors/users';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';

export default function Claims() {
  const dispatch = useDispatch();
  const loadIncident = incidentId =>
    dispatch(
      setActiveIncidentRequest({
        leavePage: false,
        _id: incidentId
      })
    );
  const activeGroup = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const accessLevel = useSelector(getAccessLevel);
  const [claims, setClaims] = useState();
  useEffect(() => {
    fetchClaims().then(setClaims);
  }, []);

  const header = (
    <Header
      title="Claims"
      rightButtons={{
        color: 'blue',
        text: 'Permissions',
        onClick: () => history.push('/app/permissions/Claims'),
        visible: accessLevel === 900
      }}
    />
  );

  const columns = [
    { key: 'createdBy', datatype: 'users' },
    { key: 'employeeId', datatype: 'users', label: 'Employee' },
    {
      key: 'type',
      accessor: r => {
        return r.type === 'au' ? 'Auto' : 'WC';
      },
      enum: ['WC', 'Auto']
    },
    {
      key: 'status',
      accessor: r => {
        if (r.isSubmitted) {
          return 'Claim Submitted';
        } else {
          return 'In Progress';
        }
      },
      enum: ['In Progress', 'Claim Submitted']
    },
    { key: 'incidentDate', datatype: 'date' },
    { key: 'claimNumber' },
    { key: 'insuredReportNumber', label: 'iReportSource Number' },
    { key: 'lastUpdatedAt', datatype: 'date' }
  ];

  if (!activeProject) {
    columns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    columns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  return (
    <HeaderAndFooter Header={header}>
      <List
        saveKey="claims"
        data={claims}
        dataIsHash
        settings={columns}
        rowClick={row => {
          loadIncident(row.incidentId);
          history.push(
            row.type === 'au'
              ? `/app/claims/auto/${row._id}`
              : row.type === 'gl'
              ? `/app/claims/damage/${row._id}`
              : `/app/claims/wc/${row._id}`
          );
        }}
      />
    </HeaderAndFooter>
  );
}
