import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Button from '../inputs/Button';

import styles from './fileCropper.module.scss';

export default class FileCropper extends Component {
  onDrop = files => {
    if (!files || !files?.length) return;

    let data = new FormData();
    files.forEach(file => {
      data.append('attachments', file, file.name);
    });

    this.props.addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'companyLogo'
    });
  };

  render() {
    return (
      <Dropzone
        onDrop={this.onDrop}
        className={styles.dropzone}
        accept={['image/png', 'image/jpeg', 'image/jpg']}
      >
        <Button text="Upload Logo" color="blue" />
        <div>PNG or JPG</div>
      </Dropzone>
    );
  }
}
