import { oshaText } from '../constants/oshaComplianceQuestionText';
import moment from 'moment';

export const oshaDbMapper = oshaQuestions => {
  let newOshaQuestionAnswers = [];

  Object.keys(oshaQuestions).map(key =>
    newOshaQuestionAnswers.push({
      key: key,
      value: oshaQuestions[key],
      questionText: oshaText[key]
    })
  );

  return newOshaQuestionAnswers;
};

export const oshaQuestionMapper = dbQuestions => {
  let oshaQuestions = {};
  dbQuestions.forEach(question => {
    if (question) {
      const key = question.key;
      const value = question.value;
      oshaQuestions[key] = value;
    }
  });

  return oshaQuestions;
};

export const numberOfDays = (daysAway, checkboxSelected) => {
  let days = 0;
  // filter out any objects where endDate = null since that is ongoing
  if (!checkboxSelected) {
    const daysAwayOngoing = daysAway?.filter(d => d.endDate === null);
    daysAway = daysAway?.filter(d => d.endDate !== null);
    if (daysAway?.length > 0) {
      for (let i = 0; i < daysAway.length; i++) {
        let endDate = moment(daysAway[i].endDate);
        let startDate = moment(daysAway[i].startDate);
        days = endDate.diff(startDate, 'days') + days + 1;
      }
    }
    // we need to know todays date to check to see if we need to start counting ongoing
    const today = moment().startOf('day');
    if (daysAwayOngoing?.length > 0) {
      for (let i = 0; i < daysAwayOngoing.length; i++) {
        let startDate = moment(daysAwayOngoing[i].startDate).startOf('day');
        let range = today.diff(startDate, 'days') + 1;
        days = range > 0 ? days + range : days;
      }
    }
  }
  return days;
};

export const containsOngoing = (
  daysAway = [],
  jobRest = [],
  daysAwayCheckbox,
  jobRestCheckbox
) => {
  daysAway = daysAwayCheckbox ? [] : daysAway;
  jobRest = jobRestCheckbox ? [] : jobRest;
  const together = daysAway.concat(jobRest);
  let ongoing = together.filter(d => d.endDate === null);
  return ongoing.length > 0;
};

export const oshaProgress = oshaForm => {
  let numQuestions = 0;
  let answeredQuestions = 0;

  if (!oshaForm) return 0;

  const checkField = field => {
    if (Array.isArray(oshaForm?.[field])) {
      return oshaForm?.[field]?.length > 0;
    }
    return oshaForm?.[field];
  };

  const track = fields => {
    fields.forEach(field => {
      let answered = checkField(field);
      if (Array.isArray(field)) {
        answered = field.every(checkField);
      }
      numQuestions++;
      if (answered) answeredQuestions++;
    });
  };

  track([
    'dateTime',
    'description',
    'employeeName',
    'jobTitle',
    'injuryIllness'
  ]);

  if (oshaForm?.happenToThisWorker?.length > 0) {
    track([
      'timeOfDayWorkStarted',
      'workerDoing',
      'howInjuryOccurred',
      'incidentInjuryIllness',
      'bodyParts',
      'physicianName'
    ]);

    if (oshaForm.facilityTreatedWorksite) {
      track(['facilityTreatedWorksite']);
    } else {
      track([
        [
          'facilityName',
          'facilityAddress',
          'facilityCity',
          'facilityState',
          'facilityZip',
          'treatedInEmergency',
          'hospitalizedInpatient'
        ]
      ]);
    }

    const deathHappened = oshaForm.happenToThisWorker.some(
      option => option.value === 0
    );
    const daysAwayHappened = oshaForm.happenToThisWorker.some(
      option => option.value === 1
    );

    if (deathHappened) {
      track(['dateOfDeath']);
    }

    if (daysAwayHappened) {
      numQuestions += 2;
      if (oshaForm?.daysAwayFromWork >= 0 || oshaForm?.daysAwayFromWorkCheckbox)
        answeredQuestions++;
      if (
        oshaForm?.daysJobRestriction >= 0 ||
        oshaForm?.daysJobRestrictionCheckbox
      )
        answeredQuestions++;
    }

    const isOngoing = oshaForm.isLegacy
      ? oshaForm.ongoing
      : containsOngoing(
          oshaForm?.daysAwayFromWorkArray,
          oshaForm?.daysJobRestrictionArray,
          oshaForm?.daysAwayFromWorkCheckbox,
          oshaForm?.daysJobRestrictionCheckbox
        );

    if (isOngoing) numQuestions++;
  }

  return answeredQuestions / numQuestions;
};

export const canSubmit = oshaForm => {
  return (
    oshaForm?.isNotApplicable ||
    oshaForm?.redline ||
    oshaProgress(oshaForm) === 1
  );
};

export const oshaHasAnswers = oshaForm => {
  const isNotApplicable = oshaForm?.isNotApplicable;
  const hasAnswers = oshaForm && Object.keys(oshaForm).length > 0;

  return hasAnswers && !isNotApplicable;
};
