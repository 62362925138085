import React, { useMemo } from 'react';
import moment from 'moment';

import AuthorizeRejectButtons from '../../inputs/AuthorizeRejectButtons';
import List from '../../List';

export const AssigneeTable = ({
  attachments,
  needsApproval,
  updateAnswer,
  name,
  dueDate,
  isApproving,
  trainings = [],
  disabled
}) => {
  const data = useMemo(() => {
    const data = [];
    trainings.forEach(t => {
      let attachments = t?.attachments?.map(a => ({
        name: a.original_filename,
        timeSpent: a?.timeSpent
      }));

      let youtubeLinks = t?.youtubeLinks?.map(y => ({
        name: y.title,
        timeSpent: y?.timeSpent
      }));

      data.push({
        assignee: t.assignedTo,
        approveReject: t.approveReject,
        attachments: [...(attachments ?? []), ...(youtubeLinks ?? [])],
        completionDate: t.completionDate
      });
    });

    return data;
  }, [trainings]);
  const current = moment();
  const afterDueDate = moment(dueDate).isBefore(current);

  const columns = useMemo(() => {
    const columns = [{ key: 'assignee', label: 'Employee', datatype: 'user' }];

    let seen = {};

    const Cell = ({ value }) => (value ? `${value} mins` : 'Not Opened');
    attachments.forEach(a => {
      let name = a?.original_filename ?? a.title;
      let lcName = name.toLowerCase();
      if (seen[lcName]) return;
      columns.push({
        key: name.toLowerCase(),
        label: name,
        accessor: r =>
          r.attachments.find(a => a.name.toLowerCase() === lcName)?.timeSpent,
        Cell
      });
      seen[lcName] = true;
    });

    columns.push({
      key: 'completionDate',
      datatype: 'date'
    });

    if (needsApproval) {
      columns.push({
        key: 'approveReject',
        label: 'Authorize',
        noClick: true,
        Cell: ({ value, row }) => (
          <AuthorizeRejectButtons
            updateAnswer={approveReject =>
              updateAnswer({
                approveReject,
                assignee: [row.original.assignee]
              })
            }
            answer={value}
            disabled={!isApproving || disabled}
            afterApproving={(afterDueDate && !isApproving) || disabled}
          />
        )
      });
    }

    return columns;
  }, [
    updateAnswer,
    needsApproval,
    afterDueDate,
    isApproving,
    attachments,
    disabled
  ]);

  const handleApproveRejectTrainings = (trainingIds, approveReject) => {
    updateAnswer({
      approveReject,
      assignee: trainingIds
    });
  };

  const actions = [];
  // We may use this later so removing for now
  // if (
  //   afterDueDate &&
  //   !fromApproveTraining &&
  //   accessLevel > 400 &&
  //   activeTraining.repeatingOn.length === 0 &&
  //   activeTraining.masterTemplateId === ''
  // ) {
  //   actions.push({
  //     label: 'Reassign',
  //     onClick: () => {
  //       setActiveTraining({ ...activeTraining, isReassigned: true });
  //       history.push('/app/training/trainingContainer');
  //     }
  //   });
  // }

  if (isApproving && needsApproval) {
    actions.unshift(
      {
        label: 'Approve',
        onClick: row => handleApproveRejectTrainings(row, 'approve')
      },
      {
        label: 'Reject',
        onClick: row => handleApproveRejectTrainings(row, 'reject')
      }
    );

    // We may use this later so removing for now
    // if (afterDueDate) {
    //   actions.push(
    //     {
    //       label: 'Reject & Reassign',
    //       onClick: row => {
    //         handleApproveRejectTrainings(row, 'reject');
    //         updateModal(true);
    //       }
    //     },
    //     {
    //       label: 'Reassign',
    //       onClick: () => {
    //         setActiveTraining({ ...activeTraining, isReassigned: true });
    //         updateModal(true);
    //       }
    //     }
    //   );
    // }
  }

  return (
    <List
      saveKey="assignees"
      data={data}
      dataIsHash
      settings={columns}
      title="Assignee(s) Material List"
      name={name}
      getRowId={r => r.assignee}
      actions={actions}
      wide={false}
    />
  );
};

export default AssigneeTable;
