import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Card from '../../../../Card';
import { Checkbox } from '../../../../inputs';
import { isFutureDate } from '../../../../../utils/formValidation';
import { Dropdown, EmployeeDropdown } from '../../../../inputs';
import { DatePicker } from '../../../../inputs/DateTimePicker';
import HierarchySelector from '../../../../HierarchySelector';
import RadioButtons from '../../../../inputs/RadioButtons';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  RECURRING_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../../../constants/constants';
import { DayOfWeekButtons } from '../../../../inputs/DayOfWeekButtons';
import { fetchCompanyUsers, getJobList } from '../../../../../api/v4';

export default function ScheduleDigitalCard(props) {
  const {
    training,
    groupDropdown,
    projectDropdown,
    onAnswer,
    setCanSubmit,
    disabled,
    company,
    hideWarnings = false,
    editingAssignees,
    fromView,
    location,
    accessLevel,
    missingRequired
  } = props;
  const [users, setUsers] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [originalProjects] = useState(training.projectIds);
  useEffect(() => {
    if (training.assignBy === 1) {
      getJobList().then(r => {
        setJobList(r?.map(job => ({ value: job, label: job })));
      });
    }
    fetchCompanyUsers().then(setUsers);
  }, [training.assignBy]);

  const assignOnAfterDueDate = moment(training.assignOn).isAfter(
    training.dueDate
  );
  const futureDateWarning = isFutureDate(
    moment(training.dueDate)
      ?.hour(23)
      ?.minute(59)
  );

  const setSubmit = useCallback(() => {
    if (!setCanSubmit) return;
    if (accessLevel < 900 && !training.groupIds?.length) setCanSubmit(false);
    else if (training.schedule === 3) setCanSubmit(training.dueBy);
    else if (
      training.schedule === 1 &&
      (!training.assignOn ||
        !training.dueDate ||
        assignOnAfterDueDate ||
        futureDateWarning ||
        moment(training.assignOn).isBefore(moment(), 'day'))
    ) {
      setCanSubmit(false);
    } else if (
      training.schedule === 2 &&
      (!(training.repeatingOn?.length || training.frequency === 'quarterly') ||
        !training.dueBy)
    ) {
      setCanSubmit(false);
    } else if (
      !training.assignBy ||
      !(training.assignedTo?.length || training.assignByJobTitles?.length)
    ) {
      setCanSubmit(false);
    } else setCanSubmit(true);
  }, [
    setCanSubmit,
    training,
    assignOnAfterDueDate,
    futureDateWarning,
    accessLevel
  ]);
  const handleGroupChange = values => {
    const validProjectIds = company.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);

    const newProjectIds = training.projectIds?.filter(p =>
      validProjectIds.includes(p)
    );
    const validUsers = training.assignedTo?.filter(user =>
      users.find(
        u =>
          u._id.toString() === user && u.groupIds.some(g => values.includes(g))
      )
    );
    onAnswer({
      groupIds: values,
      projectIds: newProjectIds,
      assignedTo: values?.length ? validUsers : training.assignedTo
    });
  };

  useEffect(() => {
    setSubmit();
  }, [training, setSubmit]);
  return (
    <Card showHeader title="Schedule" name="schedule">
      {training.schedule === 3 ? (
        <>
          <HierarchySelector
            multi
            groupRequired={accessLevel < 900}
            groupId={training.groupIds}
            projectId={training.projectIds}
            onGroupChange={handleGroupChange}
            onProjectChange={v => onAnswer({ projectIds: v })}
            groupDisabled={disabled}
            projectDisabled={!training.groupIds?.length || disabled}
          />

          <Dropdown
            fieldLabel="Due By"
            isRequired
            shrink
            options={RECURRING_OPTIONS}
            currentValue={training?.dueBy}
            onChange={v => onAnswer({ dueBy: v })}
            disabled={disabled}
            touched={missingRequired && !training?.dueBy}
          />
        </>
      ) : (
        <>
          {training.schedule === 1 ? (
            <>
              <DatePicker
                fieldLabel="Assign On"
                isRequired
                onChange={v =>
                  onAnswer({
                    assignOn: v,
                    dueDate: moment(v).isAfter(training.dueDate)
                      ? null
                      : training.dueDate
                  })
                }
                currentValue={training.assignOn}
                touched={missingRequired && !training.assignOn}
                pickDate
                timeNotDetermined
                name="assignOn"
                disabled={disabled}
                minDate={moment()}
              />
              <DatePicker
                fieldLabel="Due By"
                isRequired
                onChange={v => onAnswer({ dueDate: v })}
                currentValue={training.dueDate}
                touched={
                  !hideWarnings &&
                  futureDateWarning &&
                  missingRequired &&
                  training.assignOn &&
                  !training.dueDate
                }
                name="dueBy"
                disabled={(disabled || !training.assignOn) && !editingAssignees}
                minDate={moment(training.assignOn).add(1, 'days')}
              />
            </>
          ) : training.schedule === 2 ? (
            <>
              <Dropdown
                options={FREQUENCY_OPTIONS}
                fieldLabel="Frequency"
                currentValue={training?.frequency}
                onChange={value =>
                  onAnswer({ frequency: value, repeatingOn: [] })
                }
                isRequired
                disabled={disabled}
                touched={missingRequired && !training?.frequency}
              />
              {training?.frequency === 'weekly' ? (
                <DayOfWeekButtons
                  isRequired
                  fieldLabel="Assign Every:"
                  onChange={values => onAnswer({ repeatingOn: values })}
                  currentValue={training?.repeatingOn}
                  disabled={disabled}
                  touched={missingRequired && !training?.repeatingOn?.length}
                />
              ) : training?.frequency === 'monthly' ? (
                <Dropdown
                  options={MONTHLY_FREQUENCY_OPTIONS}
                  fieldLabel="Assign Every:"
                  currentValue={training?.repeatingOn}
                  onChange={values => onAnswer({ repeatingOn: values })}
                  isRequired
                  disabled={disabled}
                  touched={missingRequired && !training?.repeatingOn?.length}
                  initialTypeArray
                />
              ) : training?.frequency === 'yearly' ? (
                <Dropdown
                  options={YEARLY_FREQUENCY_OPTIONS}
                  fieldLabel="Assign Every:"
                  currentValue={training?.repeatingOn}
                  onChange={values => onAnswer({ repeatingOn: values })}
                  isRequired
                  disabled={disabled}
                  touched={missingRequired && !training?.repeatingOn?.length}
                  initialTypeArray
                />
              ) : null}
              <Dropdown
                fieldLabel="Due By"
                isRequired
                shrink
                options={RECURRING_OPTIONS}
                currentValue={training?.dueBy}
                onChange={v => onAnswer({ dueBy: v })}
                disabled={disabled}
                touched={missingRequired && !training?.dueBy}
              />
            </>
          ) : null}
          <HierarchySelector
            multi
            groupRequired={accessLevel < 900}
            groupId={training.groupIds}
            projectId={training.projectIds}
            filteredGroupDropdown={groupDropdown}
            filteredProjectDropdown={projectDropdown}
            onGroupChange={handleGroupChange}
            onProjectChange={v => onAnswer({ projectIds: v })}
            groupDisabled={disabled || training.equipmentId}
            projectDisabled={
              (!training.groupIds?.length && !location) ||
              disabled ||
              (training.equipmentId && originalProjects.length)
            }
          />

          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={
              training.assignedTo?.length && !training.assignBy
                ? 2
                : training.assignBy
            }
            onChange={v => {
              onAnswer({
                assignBy: v,
                assignedTo: [],
                assignByJobTitles: []
              });
            }}
            fieldLabel="Assign By"
            needSeparateLines
            isRequired
            disabled={disabled}
            touched={missingRequired && !training.assignBy}
          />
          {training.assignBy === 1 ? (
            <Dropdown
              selectButtons
              fieldLabel={disabled ? 'Job Titles' : 'Assignees'}
              options={jobList}
              searchable
              onChange={v => {
                onAnswer({
                  assignByJobTitles: v
                });
              }}
              isRequired
              multi
              currentValue={training.assignByJobTitles}
              disabled={disabled && !editingAssignees}
              touched={missingRequired && !training.assignByJobTitles?.length}
            />
          ) : null}
          {(!fromView || training.schedule === 2) &&
          /* hiding for repeating trainings since it changes based on when the training goes out */
          (training.assignBy === 2 ||
            training.assignedTo?.length ||
            (disabled && training.schedule !== 2)) ? (
            <EmployeeDropdown
              fieldLabel="Assignees"
              permissions={[100, 400, 500, 900]}
              onChange={v =>
                onAnswer({
                  assignedTo: v
                })
              }
              searchable
              selectButtons
              filterChoices={[
                {
                  label: 'Inactive Employees',
                  value: 'inactive'
                }
              ]}
              disabled={(!training.assignBy || disabled) && !editingAssignees}
              multi
              group={training.groupIds?.length ? training.groupIds : null}
              currentValue={training.assignedTo}
              isRequired
              touched={missingRequired && !training.assignedTo?.length}
            />
          ) : null}

          {fromView && training.schedule === 2 ? (
            <Checkbox
              fieldLabel="Pause Training"
              name="pauseTraining"
              onChange={values => onAnswer({ isPaused: values })}
              currentValue={training?.isPaused}
              disabled={disabled}
            />
          ) : null}
        </>
      )}
    </Card>
  );
}
