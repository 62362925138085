import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';

import FileDownloader from '../FileDownloader';
import { getMyUserId } from '../../selectors/users';
import Chevron from '../../svgs/chevron';
import Trash from '../../svgs/trash';
import { isImage } from '../../utils/attachmentSelector';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

function Carousel({
  items,
  title,
  canDelete,
  canDownload = canDelete,
  deleteAction,
  hasStatus = false,
  onlyDeleteOwnImages = false,
  showPostedBy = false,
  deleteFromComplete = false,
  ownerType,
  disabled
}) {
  const [selectedItem, setSelectedItem] = useState(0);

  const loggedInUserId = useSelector(getMyUserId);

  const noAttachmentHeaders =
    items[selectedItem]?.ownerType === 'safetySuggestion';

  const classes = {
    carousel: true,
    oneItem: items?.length === 1,
    statusCarousel: hasStatus
  };

  const deletable =
    canDelete &&
    (items[selectedItem]?.ownerType === undefined ||
      (onlyDeleteOwnImages
        ? loggedInUserId === items[selectedItem].createdByUserId
        : items[selectedItem]?.ownerType === ownerType));

  const addDefaultSrc = ev => {
    ev.target.src = require('../../assets/images/cloud-upload.gif');
  };

  const handleDelete = () => {
    if (selectedItem === items.length - 1) {
      setSelectedItem(selectedItem - 1);
    }
    deleteAction(items[selectedItem]);
  };

  return (
    <>
      {title && <h2 className={styles.carouselHeader}>{title}</h2>}
      <div className={styles.carouselWrapper}>
        {hasStatus && (
          <h3
            className={bStyles(styles.status, {
              correction:
                items[selectedItem]?.photoType?.toLowerCase() === 'corrective',
              defective:
                items[selectedItem]?.photoType === 'defective' ||
                items[selectedItem]?.ownerType === 'hazard' ||
                items[selectedItem]?.ownerType === 'safetyAuditQuestion' ||
                items[selectedItem]?.ownerType === 'qualityAuditQuestion' ||
                items[selectedItem]?.ownerType === 'lotoAuditQuestion' ||
                items[selectedItem]?.ownerType === 'individualTask' ||
                items[selectedItem]?.ownerType === 'behaviorObservation' ||
                items[selectedItem]?.ownerType === 'safetyWalk'
            })}
          >
            {items[selectedItem]?.photoType?.toLowerCase() === 'corrective'
              ? `Corrected`
              : noAttachmentHeaders
              ? null
              : `Defective`}
          </h3>
        )}
        <div className={bStyles(classes)}>
          {items?.length > 1 && (
            <Chevron
              className={bStyles(styles.arrows, styles.left, {
                withStatus: hasStatus && !noAttachmentHeaders
              })}
              onClick={() =>
                setSelectedItem(selectedItem > 0 ? selectedItem - 1 : 0)
              }
            />
          )}
          {items[selectedItem] ? (
            <div className={styles.imageContainer}>
              {isImage(items[selectedItem]) ? (
                <img
                  className={styles.mainImage}
                  src={items[selectedItem].source_url}
                  onError={addDefaultSrc}
                  alt={
                    items[selectedItem].original_fileName ??
                    items[selectedItem].name
                  }
                  onClick={
                    canDownload
                      ? () =>
                          window.open(items[selectedItem].source_url, '_blank')
                      : null
                  }
                />
              ) : (
                <FileDownloader attachment={items[selectedItem]} disabled />
              )}
              {items?.length > 1 && (
                <p className={styles.imageNum}>
                  {selectedItem + 1} / {items?.length}
                </p>
              )}
              {(deletable &&
                !disabled &&
                ![
                  'hazard',
                  'safetyAuditQuestion',
                  'qualityAuditQuestion',
                  'lotoAuditQuestion',
                  'individualTask'
                ].includes(items[selectedItem]?.ownerType)) ||
              deleteFromComplete ? (
                <Trash className={styles.delete} onClick={handleDelete} />
              ) : null}
              {showPostedBy && (
                <div className={styles.attribution}>
                  <p className={styles.alignLeft}>
                    Posted by{' '}
                    {loggedInUserId === items[selectedItem].createdByUserId
                      ? 'You'
                      : items[selectedItem].created_by}
                  </p>
                  <p className={styles.alignRight}>
                    {moment(items[selectedItem].createdAt).format('L LT')}
                  </p>
                </div>
              )}
              <div style={{ clear: 'both' }}></div>
            </div>
          ) : (
            ''
          )}
          {items.length > 1 && (
            <Chevron
              className={bStyles(styles.arrows, styles.right, {
                withStatus: hasStatus && !noAttachmentHeaders
              })}
              onClick={() =>
                setSelectedItem(
                  selectedItem === items?.length - 1
                    ? selectedItem
                    : selectedItem + 1
                )
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Carousel;
