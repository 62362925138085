import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as msal from 'msal';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Modal from '../../../src/components/Modal';
import logoImage from '../TopHeader/logo.png';
import { Button } from '../../components/inputs';
import {
  forgotPasswordRequest,
  signIn,
  submitSecurityQuestions,
  signOut,
  loginFailure
} from '../../actions/auth';
import {
  getLoginFailure,
  getSecurityQuestionsSelector
} from '../../selectors/auth';
import { setActiveCompany } from '../../actions/company';
import LoginForm from '../../forms/LoginForm';
import ResetPasswordForm from '../../forms/ResetPasswordForm';
import { ResetUserPasswordForm } from '../../forms/ResetUserPasswordForm';
import localforage from 'localforage';
import { fetchMicrosoftLoginConfig } from '../../api/v4';
import Chevron from '../../svgs/chevron';
import { addMessage } from '../../actions/messages';

import styles from './login.module.scss';

export let SSOClient = null;

export class Login extends Component {
  state = {
    isLoading: false,
    loginPage: 'login',
    companyName: '',
    mobileModal: isMobile,
    rememberUser: false,
    badUsername: false,
    badPassword: false
  };

  componentDidMount = () => {
    localforage.clear();
    sessionStorage.clear();
    this.props.setActiveCompany({});
    if (localStorage.getItem('username')) {
      this.setState({ rememberUser: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.isLoading === true) {
      this.setState({ isLoading: false });
    }
    if (this.props.loginFailure === 'Your username or password is incorrect') {
      this.setState({ badUsername: true, badPassword: true });
      this.props.setLoginFailure('');
    }
  };

  handleChangeCheckbox = checked => {
    if (!checked) {
      localStorage.removeItem('username');
    }
    this.setState({ rememberUser: checked });
  };

  handleLoginSubmit = values => {
    if (!values.username) {
      if (!values.password) {
        this.setState({ badUsername: true });
        this.setState({ badPassword: true });
        this.props.newMessage({
          error: true,
          message: 'Missing Username and Password'
        });
      } else {
        this.setState({ badUsername: true });
        this.setState({ badPassword: false });
        this.props.newMessage({
          error: true,
          message: 'Missing Username'
        });
      }
    } else if (!values.password) {
      this.setState({ badUsername: false });
      this.setState({ badPassword: true });
      this.props.newMessage({
        error: true,
        message: 'Missing Password'
      });
    } else {
      this.props.signInDispatcher({
        ...values,
        referrer: this.props?.location?.state?.referrer
      });
      this.setState({ isLoading: true });
      if (this.state.rememberUser) {
        localStorage.setItem('username', values.username);
      }
    }
  };

  render() {
    const {
      forgotPassword,
      signInDispatcher,
      securityQuestions,
      submitQuestions,
      logoutUser
    } = this.props;

    const {
      isLoading,
      loginPage,
      mobileModal,
      rememberUser,
      badUsername,
      badPassword
    } = this.state;

    return (
      <>
        {loginPage === 'login' ? (
          <div className={styles.loginWholePage}>
            <div className={styles.loginFlexbox}>
              <div className={styles.login}>
                <img src={require('../../assets/images/Logo.png')} alt="" />
                <LoginForm
                  onSubmit={this.handleLoginSubmit}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  rememberUser={rememberUser}
                  isLoading={isLoading}
                  badUsername={badUsername}
                  badPassword={badPassword}
                  setLoginPage={value => this.setState({ loginPage: value })}
                  onMicrosoftSubmit={async event => {
                    const config = await fetchMicrosoftLoginConfig();
                    if (config) {
                      const msalConfig = {
                        auth: config
                      };
                      SSOClient = new msal.UserAgentApplication(msalConfig);
                      SSOClient.loginPopup()
                        .then(response => {
                          return (
                            signInDispatcher({
                              ...response,
                              referrer: this.props?.location?.state?.referrer,
                              isMicrosoft: true
                            }) && this.setState({ isLoading: true })
                          );
                        })
                        .catch(e => {
                          SSOClient = null;
                        });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.passwordRecoveryWholePage}>
            <Button
              className={styles.passwordRecoveryBackButton}
              color="greenOutline"
              text="Back to Login"
              image={<Chevron className={styles.greenChevron} />}
              shrink
              onClick={() => {
                sessionStorage.clear();
                logoutUser();
                return this.setState({ loginPage: 'login' });
              }}
            />
            <div className={styles.passwordRecovery}>
              {securityQuestions &&
              Array.isArray(securityQuestions) &&
              securityQuestions.length > 1 ? (
                <ResetUserPasswordForm
                  securityQuestions={securityQuestions}
                  submitQuestions={submitQuestions}
                  logoutUser={logoutUser}
                />
              ) : (
                <ResetPasswordForm
                  onSubmit={values => forgotPassword(values)}
                />
              )}
            </div>
          </div>
        )}
        <Modal
          isOpen={mobileModal}
          title="Best Viewed on our Mobile App"
          titleClassName="blueHeader"
          className={styles.modalMobile}
          hideButtons
          onRequestClose={() => this.setState({ mobileModal: false })}
        >
          <img
            src={logoImage}
            alt="iReportSource logo"
            className={styles.loginModalLogo}
          />
          <h4 className={styles.loginMessageHeader}>
            Simplify your safety wherever you go
          </h4>
          {isAndroid && (
            <a href="https://play.google.com/store/apps/details?id=com.ireportsource.ireport&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                className={styles.loginAndroidBadge}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
              />
            </a>
          )}
          {isIOS && (
            <a href="https://itunes.apple.com/app/id1439251127">
              <img
                src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-10-19&kind=iossoftware&bubble=ios_apps"
                alt="Get it from iTunes"
              />
            </a>
          )}
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  securityQuestions: getSecurityQuestionsSelector(state),
  loginFailure: getLoginFailure(state)
});

export const mapDispatchToProps = dispatch => ({
  newMessage: payload => dispatch(addMessage(payload)),
  logoutUser: () => dispatch(signOut()),
  signInDispatcher: payload => dispatch(signIn(payload)),
  forgotPassword: email => dispatch(forgotPasswordRequest(email)),
  submitQuestions: payload => dispatch(submitSecurityQuestions(payload)),
  setLoginFailure: payload => dispatch(loginFailure(payload)),
  setActiveCompany: company => dispatch(setActiveCompany(company))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
