import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131800)">
      <path d="M23.6895 48.4082C23.6895 50.0757 25.753 51.3799 26.2316 52.8819C26.7431 54.4338 25.8686 56.712 26.8094 57.9997C27.7502 59.3038 30.1934 59.1717 31.4976 60.113C32.7853 61.0541 33.4125 63.4312 34.9644 63.943C36.4505 64.4217 38.3492 62.8863 39.9998 62.8863C41.6673 62.8863 43.5493 64.4216 45.0513 63.9426C46.6032 63.4311 47.2139 61.0537 48.5017 60.1126C49.8058 59.1718 52.2491 59.3035 53.2067 57.9993C54.1478 56.7117 53.2561 54.4334 53.768 52.8815C54.2466 51.3794 56.3265 50.0753 56.3265 48.4078C56.3265 46.7568 54.2466 45.4361 53.768 43.934C53.2565 42.3822 54.1478 40.1207 53.2067 38.8166C52.2494 37.5125 49.8062 37.6611 48.5017 36.7034C47.214 35.7622 46.6033 33.4016 45.0513 32.8897C43.5492 32.4114 41.6673 33.9468 39.9998 33.9468C38.3492 33.9468 36.4504 32.4118 34.9647 32.8905C33.4128 33.402 32.7856 35.7629 31.4979 36.7041C30.1938 37.6618 27.7505 37.5132 26.8097 38.8174C25.8685 40.1215 26.7434 42.3833 26.2319 43.9348C25.7529 45.4369 23.6895 46.7575 23.6895 48.4082H23.6895ZM39.9994 41.8545C43.6312 41.8545 46.5699 44.7929 46.5699 48.4086C46.5699 52.0403 43.6316 54.9791 39.9994 54.9791C36.3841 54.9791 33.4454 52.0407 33.4454 48.4086C33.4454 44.7929 36.3841 41.8545 39.9994 41.8545Z" />
      <path d="M51.4578 62.4896C51.0944 62.5888 50.5495 62.7209 50.3845 62.8196C50.3681 62.869 50.0709 63.3312 49.8726 63.6287C49.1133 64.8176 48.0899 66.4189 46.0922 67.079C45.5309 67.2605 44.986 67.3432 44.4248 67.3432C43.9297 67.3432 43.4671 67.2774 43.0215 67.1782L48.6179 80.2855C48.8821 80.9127 49.4928 81.2925 50.1368 81.2925C50.2195 81.2925 50.3183 81.2925 50.401 81.2761C51.1603 81.144 51.7216 80.5332 51.7875 79.7739L52.1838 74.7553L56.0798 77.9414C56.674 78.4204 57.516 78.4368 58.1267 77.9743C58.721 77.5122 58.9358 76.703 58.6383 76.0097L52.7118 62.1091C52.2669 62.2745 51.8373 62.3902 51.4578 62.489L51.4578 62.4896Z" />
      <path d="M21.8893 77.9746C22.5 78.4368 23.3255 78.4203 23.9198 77.9417L27.8322 74.7556L28.2285 79.7743C28.2779 80.5336 28.8557 81.1447 29.5986 81.2764C29.6977 81.2929 29.7804 81.2929 29.8628 81.2929C30.5233 81.2929 31.1175 80.913 31.3817 80.2858L36.9946 67.1785C36.5489 67.2777 36.0867 67.3436 35.5913 67.3436C35.0135 67.3436 34.4686 67.2444 33.9571 67.0794C31.9431 66.4189 30.9031 64.8176 30.1434 63.6455C29.9454 63.3319 29.6318 62.8698 29.4993 62.7377C29.4499 62.7212 28.9051 62.5891 28.5421 62.4899C28.1623 62.3908 27.733 62.2751 27.2873 62.1101L21.3608 76.0106C21.0637 76.7041 21.2785 77.5132 21.8892 77.9753L21.8893 77.9746Z" />
      <path d="M0 0.40101V48.342C0 49.2663 0.74282 49.993 1.65101 49.993H20.6356C20.4706 49.5144 20.3879 48.986 20.3879 48.4082C20.3879 46.295 21.6427 44.7431 22.4682 43.7197C22.6991 43.439 23.0295 43.0263 23.1118 42.8612C23.1283 42.7456 23.0954 42.1843 23.0789 41.8049C23.0127 40.4344 22.9303 38.5526 24.1353 36.8853C25.3571 35.1849 27.1893 34.7063 28.5267 34.3429C28.8897 34.2438 29.4181 34.0952 29.5831 34.0129C29.649 33.9466 29.9461 33.4845 30.1445 33.1709C30.9037 32.0153 31.944 30.414 33.9248 29.7535C34.4697 29.5885 35.0145 29.4893 35.5919 29.4893C36.8796 29.4893 38.0187 29.935 38.9266 30.2815C39.29 30.4136 39.8345 30.6281 40.0328 30.6449C40.1649 30.6284 40.7262 30.4136 41.0892 30.2815C41.997 29.935 43.1361 29.4893 44.4239 29.4893C44.9852 29.4893 45.5301 29.5716 46.0581 29.7535C48.0886 30.4136 49.1121 32.0152 49.8714 33.1874C50.0694 33.4845 50.3665 33.9466 50.499 34.0787C50.5813 34.0952 51.1097 34.2438 51.4731 34.3429C52.8105 34.7063 54.6427 35.1849 55.8645 36.8689C57.1028 38.5693 57.0037 40.4513 56.9209 41.8212C56.9044 42.2011 56.8715 42.7624 56.9209 42.9439C56.9703 43.0262 57.3007 43.439 57.5316 43.7032C58.3572 44.7431 59.6284 46.2786 59.6284 48.4082C59.6284 48.986 59.5292 49.5144 59.3807 49.993H78.349C79.2737 49.993 80 49.2667 80 48.342V0.40101C80 -0.506845 79.2736 -1.25 78.349 -1.25L1.65086 -1.24963C0.743006 -1.24963 0.000233886 -0.506805 0.000233886 0.401001L0 0.40101ZM15.2091 6.43639H64.796C65.7083 6.43639 66.447 7.17471 66.447 8.0874C66.447 8.99974 65.7087 9.73803 64.796 9.73803H15.2091C14.2968 9.73803 13.5581 8.9997 13.5581 8.0874C13.5585 7.17469 14.2968 6.43639 15.2091 6.43639ZM15.2091 18.0234H64.796C65.7083 18.0234 66.447 18.7617 66.447 19.6744C66.447 20.5867 65.7087 21.3254 64.796 21.3254L15.2091 21.325C14.2968 21.325 13.5581 20.5867 13.5581 19.674C13.5585 18.762 14.2968 18.0234 15.2091 18.0234V18.0234Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131800">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
