import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid, submit } from 'redux-form';
import classnames from 'classnames/bind';
import Loading from '../../components/Loading';
import {
  fetchAllUsersForLocation,
  uploadCompanyLogo,
  createUserSecurityQuestions
} from '../../api/v4';
import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  addCompanyLocationRequest,
  addCompanyRequest,
  addProjectRequest,
  setActiveCompany,
  setActiveGroups,
  setActiveLocation,
  setActiveProject,
  setAllLocations,
  setIncidentOwner
} from '../../actions/company';
import { updatePasswordRequest } from '../../actions/user';
import { addMessage } from '../../actions/messages';
import {
  SECURITY_QUESTIONS_OPTIONS_SET_ONE,
  SECURITY_QUESTIONS_OPTIONS_SET_TWO
} from '../../constants/constants';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { isFirstTimeLogin } from '../../selectors/auth';
import {
  getActiveCompany,
  getActiveLocationId,
  getAllLocations,
  getUserCompaniesSelector
} from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import AreaModal from '../../components/Modal/AreaModal';
import CompanyLogo from '../../components/CompanyLogo';
import CompanyCard from '../../components/CompanyCard';
import CompanyForm from '../../forms/CompanyForm';
import GroupModal from '../../components/Modal/groupModal';
import Modal from '../../components/Modal';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import {
  Textbox,
  Dropdown
  // Button,
} from '../../components/inputs';
import ReassignUserModal from '../../components/Modal/reassignUserModal';

import styles from './home.module.scss';
const bStyles = classnames.bind(styles);

export class Home extends Component {
  state = {
    modalOpen: 0,
    modalCompany: {},
    modalGroup: {},
    assignIncidentOwnerModal: false,
    reassignUser: undefined,
    securityQuestionsModalOpen: false,
    securityQuestion1: '',
    securityQuestion2: '',
    securityAnswer1: '',
    securityAnswer2: '',
    users: null,
    hierarchyId: null
  };

  handleOpenModal = (company, group, modalId) => {
    this.setState({
      modalOpen: modalId,
      modalCompany: company,
      modalGroup: group,
      projectName: ''
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: 0,
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      isSeparateProject: null
    });
    this.props.removeAttachment();
  };

  handleSecurityQuestionsCloseModal = () => {
    this.setState({ securityQuestionsModalOpen: false });
  };

  handleGoToDashboard = () => {
    this.handleCloseModal();
    history.push('/app/dashboard');
  };

  handleNewCompany = async values => {
    await this.props.addCompany({ ...values, isOnboarding: true });
    this.handleOpenModal({}, {}, 1);
  };

  handleNewGroup = async values => {
    await this.props.addCompanyLocation(values);
    this.handleGoToDashboard();
  };

  handleUpdateState = stateObject => {
    this.setState(stateObject);
  };

  handleProjectSelection = project => {
    this.props.setActiveCompany(this.state.modalCompany);
    this.props.setActiveLocation(this.state.modalGroup);
    this.props.setActiveProject(project);
    this.props?.location?.state?.fromScreen === 'login'
      ? history.push({
          pathname: '/app/dashboard',
          state: {
            showToasts: true,
            showNotificationModal: !this.props.user.notifications
          }
        })
      : history.push('/app/dashboard');
  };

  componentDidMount() {
    // const msalConfig = {
    //   auth: config.auth
    // };
    // new UserAgentApplication(msalConfig);
    if (this.props.firstTimeLogin && !this.props.user.companies) {
      this.setState({ modalOpen: 4 });
    } else if (this.props.userCompanies?.every(c => !!c.allGroupsHidden)) {
      this.props.addMessage({
        error: true,
        message:
          'All groups you had access to have been removed. Please contact your iReportSource administrator.'
      });
    } else if (
      !this.props.user.email &&
      !this.props.user.phoneNumber &&
      !this.props.user.securityQuestions &&
      !this.props.user.isMicrosoftUser
    ) {
      this.setState({
        securityQuestionsModalOpen: true
      });
    }
  }

  handleCompanyLogoUpload = att => {
    //using a different api call to send data to auth endpoint, not requiring company yet
    uploadCompanyLogo(att.data, {
      ownerId: att.ownerId,
      ownerType: att.ownerType,
      isSignature: att.isSiganture ?? false,
      isPhotoEvidence: att.isPhotoEvidence ?? false,
      photoType: att?.photoType
    }).then(r => {
      this.props.setAddedAttachments([...this.props.addedAttachments, ...r]);
    });
  };

  handleNewCompanySubmit = (values, user) => {
    this.props.addCompany({ ...values });
    this.handleCloseModal();
  };

  handleNewLocationSubmit = (values, user, modalCompany) => {
    this.props.setActiveCompany(modalCompany);
    this.props.addCompanyLocation(values);
    this.handleCloseModal();
  };

  handleNewProjectSubmit = values => {
    this.props.addProject(values);
    this.handleCloseModal();
  };

  openAssigneeModal = (hierarchy, currentValue) => {
    const hierarchyId = hierarchy?.companyId ?? hierarchy?.locationId;
    this.setState(
      {
        users: null,
        assignIncidentOwnerModal: true,
        reassignUser: currentValue,
        hierarchyId
      },
      () =>
        fetchAllUsersForLocation(hierarchyId, {
          ...hierarchy,
          accessLevel: [500, 900]
        }).then(users => this.setState({ users }))
    );
  };

  submitDefaultIncidentOwner = () => {
    this.props.setIncidentOwner({
      locationId: this.state.hierarchyId,
      employeeId: this.state.reassignUser
    });
    this.setState({ assignIncidentOwnerModal: false });
  };

  render() {
    const {
      setActiveCompany,
      setActiveLocation,
      user,
      allLocations,
      addedAttachments,
      removeAttachment,
      companyValid,
      firstTimeLogin,
      setActiveGroups,
      setActiveProject,
      addMessage,
      submitPassword
    } = this.props;

    const {
      modalOpen,
      modalCompany,
      modalGroup,
      assignIncidentOwnerModal,
      reassignUser,
      securityQuestionsModalOpen
    } = this.state;

    const companies = this.props.userCompanies;

    const canSubmitSecurityQuestions =
      this.state.securityQuestion1 &&
      this.state.securityAnswer1?.trim() &&
      this.state.securityQuestion2 &&
      this.state.securityAnswer2?.trim();

    const canSubmitPassword =
      this.state.newPassword?.trim().length &&
      this.state.confirmPassword?.trim().length &&
      this.state.newPassword === this.state.confirmPassword;

    return (
      <div className={styles.home}>
        {/* Hiding this for now */}
        {/* {user.accessLevel === 900 ? (
          <InputRow right>
            <Button
              color="greenOutline"
              onClick={() => this.handleOpenModal({}, {}, 2)}
              text="New Company Division"
            />
          </InputRow>
        ) : (
          <></>
        )} */}
        {!companies ? (
          <Loading />
        ) : (
          companies?.map((company, index) => (
            <div key={index} className={styles.companies}>
              <CompanyLogo
                company={company}
                setActiveCompany={setActiveCompany}
                setActiveLocation={setActiveLocation}
                setActiveProject={setActiveProject}
                allLocations={allLocations}
                setActiveGroups={setActiveGroups}
                user={user}
                assignIncidentOwner={value =>
                  this.openAssigneeModal({ companyId: company._id }, value)
                }
                fromLoginScreen={
                  this.props?.location?.state?.fromScreen === 'login'
                }
              />
              <div className={styles.locations}>
                <h3>Long Term Establishment</h3>
                <div className={styles.establishments}>
                  {company?.groups
                    ?.filter(
                      group =>
                        !group.isHidden &&
                        group.isSeparateEstablishment &&
                        group.operationalForYearOrLonger
                    )
                    .map((location, index) => (
                      <div className={styles['location-outerDiv']} key={index}>
                        <CompanyCard
                          location={location}
                          company={company}
                          setActiveCompany={setActiveCompany}
                          setActiveLocation={setActiveLocation}
                          setActiveProject={setActiveProject}
                          allLocations={allLocations}
                          setActiveGroups={setActiveGroups}
                          openProjectModal={() =>
                            this.handleOpenModal(company, location, 5)
                          }
                          openProjectDropdown={() =>
                            this.handleUpdateState({
                              modalCompany: company,
                              modalGroup: location
                            })
                          }
                          handleProjectSelection={project =>
                            this.handleProjectSelection(project)
                          }
                          user={user}
                          assignIncidentOwner={value =>
                            user.accessLevel > 400 &&
                            this.openAssigneeModal(
                              { locationId: location._id },
                              value
                            )
                          }
                          fromLoginScreen={
                            this.props?.location?.state?.fromScreen === 'login'
                          }
                        />
                      </div>
                    ))}
                  {user.accessLevel === 900 ? (
                    <div
                      onClick={() => this.handleOpenModal(company, {}, 1)}
                      className={bStyles('location-button', 'newLocation')}
                    >
                      <div>Add Long Term Establishment</div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <h3>Short Term Establishment</h3>
                <div className={styles.establishments}>
                  {company?.groups
                    ?.filter(
                      group =>
                        !group.isHidden &&
                        (!group.isSeparateEstablishment ||
                          (!group.operationalForYearOrLonger &&
                            group.isSeparateEstablishment))
                    )
                    .map((location, index) => (
                      <div className={styles['location-outerDiv']} key={index}>
                        <CompanyCard
                          location={location}
                          company={company}
                          setActiveCompany={setActiveCompany}
                          setActiveLocation={setActiveLocation}
                          allLocations={allLocations}
                          setActiveGroups={setActiveGroups}
                          setActiveProject={setActiveProject}
                          openProjectModal={() =>
                            this.handleOpenModal(company, location, 5)
                          }
                          openProjectDropdown={() =>
                            this.handleUpdateState({
                              modalCompany: company,
                              modalGroup: location
                            })
                          }
                          handleProjectSelection={project =>
                            this.handleProjectSelection(project)
                          }
                          user={user}
                          assignIncidentOwner={value =>
                            this.openAssigneeModal(
                              { locationId: location._id },
                              value
                            )
                          }
                          fromLoginScreen={
                            this.props?.location?.state?.fromScreen === 'login'
                          }
                        />
                      </div>
                    ))}
                  {user.accessLevel === 900 ? (
                    <div
                      onClick={() => this.handleOpenModal(company, {}, 1)}
                      className={bStyles('location-button', 'newLocation')}
                    >
                      <div>
                        <p>Add a New Group</p>
                        <p>– or –</p>
                        <p>Short Term Establishment</p>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>
          ))
        )}
        <GroupModal
          isOpen={modalOpen === 1}
          onRequestClose={() => this.handleCloseModal()}
          submitActions={values =>
            firstTimeLogin
              ? this.handleNewGroup(values)
              : this.handleNewLocationSubmit(values, user, modalCompany)
          }
          isPerrp={modalCompany.reportingType === 2}
        />
        <AreaModal
          isOpen={modalOpen === 5}
          onRequestClose={() => this.handleCloseModal()}
          currentArea={{
            companyId: modalCompany._id,
            groupId: modalGroup._id
          }}
          submitActions={values => this.handleNewProjectSubmit(values)}
          isPerrp={modalCompany.reportingType === 2}
          company={modalCompany}
          fromHomePage
        />
        {securityQuestionsModalOpen && (
          <Modal
            title="Answer Security Questions"
            titleClassName="blueHeader"
            isOpen={securityQuestionsModalOpen}
            submitButtonColor="blue"
            submitButtonText="Save Changes"
            hideCancelButton
            submitActions={() => {
              if (canSubmitSecurityQuestions) {
                const questionsAnswers = {
                  questions: [
                    this.state.securityQuestion1.label,
                    this.state.securityQuestion2.label
                  ],
                  answers: [
                    {
                      question: this.state.securityQuestion1.label,
                      answer: this.state.securityAnswer1
                    },
                    {
                      question: this.state.securityQuestion2.label,
                      answer: this.state.securityAnswer2
                    }
                  ]
                };
                user.securityQuestions = questionsAnswers.questions;
                user.securityAnswers = questionsAnswers.answers;

                createUserSecurityQuestions(user).then(r =>
                  this.setState({ securityQuestionsModalOpen: false })
                );
              }
            }}
            onMouseEnter={() =>
              this.setState({ missingRequired: !canSubmitSecurityQuestions })
            }
            submitTooltip={
              this.state.missingRequired ? 'Missing or Invalid Fields' : null
            }
          >
            <p>
              These security questions are for you to be able to reset your
              password in case you forget it. The answers provided here will not
              be shared with others.
            </p>
            <Dropdown
              options={SECURITY_QUESTIONS_OPTIONS_SET_ONE}
              fieldLabel="Security Question 1"
              onChange={e => {
                if (this.state.securityQuestion2 === e.label) {
                  alert('Questions cannot be the same');
                  e.value = null;
                  e.label = null;
                  return;
                }
                this.setState({
                  securityQuestion1: e
                });
              }}
              currentValue={this.state.securityQuestion1}
              bareValues={false}
              isRequired
              touched={
                this.state.missingRequired && !this.state.securityQuestion1
              }
            />
            <Textbox
              fieldLabel="Security Question Answer 1"
              onChange={v => this.handleUpdateState({ securityAnswer1: v })}
              isRequired={true}
              placeholder="Fill in with answer to Security Question 1"
              currentValue={this.state.securityAnswer1}
              touched={
                this.state.missingRequired && !this.state.securityAnswer1
              }
            />
            <Dropdown
              options={SECURITY_QUESTIONS_OPTIONS_SET_TWO}
              fieldLabel="Security Question 2"
              onChange={e => {
                if (this.state.securityQuestion1 === e.label) {
                  alert('Questions cannot be the same');
                  e.value = null;
                  e.label = null;
                  return;
                }

                this.setState({
                  securityQuestion2: e
                });
              }}
              currentValue={this.state.securityQuestion2}
              bareValues={false}
              isRequired
              touched={
                this.state.missingRequired && !this.state.securityQuestion2
              }
            />
            <Textbox
              fieldLabel="Security Question Answer 2"
              onChange={v => this.handleUpdateState({ securityAnswer2: v })}
              isRequired={true}
              placeholder="Fill in with answer to Security Question 2"
              currentValue={this.state.securityAnswer2}
              touched={
                this.state.missingRequired && !this.state.securityAnswer2
              }
            />
          </Modal>
        )}
        {modalOpen === 2 && (
          <Modal
            title="Add New Company Division"
            titleClassName="greenHeader"
            isOpen={modalOpen === 2}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            disableSubmit={!companyValid}
            submitActions={this.props.submitCompany}
            submitButtonText="Create"
            hideCancelButton={firstTimeLogin}
          >
            <CompanyForm
              onSubmit={values =>
                firstTimeLogin
                  ? this.handleNewCompany(values)
                  : this.handleNewCompanySubmit(values, user)
              }
              redirect={false}
              addedAttachments={addedAttachments}
              addAttachment={this.handleCompanyLogoUpload}
              removeAttachment={removeAttachment}
            />
          </Modal>
        )}
        <Modal
          title="Thanks for trying iReport!"
          titleClassName="blueHeader"
          isOpen={modalOpen === 4}
          submitActions={() => this.handleOpenModal({}, {}, 2)}
          submitButtonText="Next"
          submitButtonColor="blue"
          hideCancelButton={true}
        >
          <p>
            We are going to gather some basic information so that you can try
            the app with ease.
          </p>
        </Modal>
        <ReassignUserModal
          title={
            reassignUser ? 'Reassign Incident Owner' : 'Assign Incident Owner'
          }
          onRequestClose={() =>
            this.setState({ assignIncidentOwnerModal: false })
          }
          isOpen={assignIncidentOwnerModal}
          employeeFieldLabel="Default Incident Owner"
          submitActions={this.submitDefaultIncidentOwner}
          employees={this.state.users}
          onChange={value => this.setState({ reassignUser: value })}
          message={
            <div className={styles['reportSectionContainer-emailAssigneeText']}>
              From here you can assign a {reassignUser ? 'different' : ''} user
              to be the default incident owner for anything reported within this
              Division/Establishment/Group and any children without a default
              incident owner. Incident ownership can only be assigned to
              Collaborators or Admins with access to the Establishment/Group of
              this incident.{' '}
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                If there is no default incident owner for a
                Division/Establishment/Group, ownership will go to the default
                incident owner of the parent location. If this reaches all the
                way to company level, the Account Owner will be assigned as the
                incident owner.
              </span>
            </div>
          }
          disableSubmit={!reassignUser}
          reassignUser={reassignUser}
          permissions={[500, 900]}
        />
        <Modal
          testID="changePassword"
          title="Choose a New Password"
          titleClassName="blueHeader"
          className={styles.passwordModal}
          isOpen={user?.needsToUpdatePassword}
          submitButtonColor="green"
          onRequestClose={() => {
            addMessage({
              error: true,
              message: 'You must change your password.'
            });
          }}
          onMouseEnter={() =>
            this.setState({ missingRequired: !canSubmitPassword })
          }
          submitTooltip={
            !this.state.newPassword || !this.state.confirmPassword
              ? 'Missing Field'
              : this.state.newPassword !== this.state.confirmPassword
              ? 'Passwords Do Not Match'
              : null
          }
          submitActions={() =>
            canSubmitPassword
              ? submitPassword({
                  new_password: this.state.newPassword,
                  confirm_password: this.state.confirmPassword
                })
              : null
          }
        >
          <ChangePasswordForm
            newPassword={this.state.newPassword}
            confirmPassword={this.state.confirmPassword}
            setNewPassword={v => this.setState({ newPassword: v })}
            setConfirmPassword={v => this.setState({ confirmPassword: v })}
            missingRequired={this.state.missingRequired}
          />
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  userCompanies: getUserCompaniesSelector(state),
  user: getLoggedInUser(state),
  firstTimeLogin: isFirstTimeLogin(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  companyValid: isValid('CompanyForm')(state),
  loacationList: getAllLocations(state),
  activeLocationId: getActiveLocationId(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state)
});

export const mapDispatchToProps = dispatch => ({
  setIncidentOwner: payload => dispatch(setIncidentOwner(payload)),
  setActiveCompany: company => dispatch(setActiveCompany(company)),
  setActiveLocation: locationId => dispatch(setActiveLocation(locationId)),
  setAddedAttachments: atts => dispatch(addUploadedAttachmentsResponse(atts)),
  addCompany: values => dispatch(addCompanyRequest(values)),
  addCompanyLocation: values => dispatch(addCompanyLocationRequest(values)),
  allLocations: values => dispatch(setAllLocations(values)),
  addAttachment: values => dispatch(addUploadedAttachmentsRequest(values)),
  removeAttachment: () => dispatch(clearUploadedAttachments()),
  setActiveGroups: values => dispatch(setActiveGroups(values)),
  addProject: values => dispatch(addProjectRequest(values)),
  setActiveProject: project => dispatch(setActiveProject(project)),
  submitCompany: () => dispatch(submit('CompanyForm')),
  submitPassword: values => dispatch(updatePasswordRequest(values)),
  addMessage: message => dispatch(addMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
