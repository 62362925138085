// these must be in their own file and loaded into the src/index.js entry point in contrast to having these statements directly
// invoked from the main entry point. For more information visit: https://docs.logrocket.com/v1.0/docs/troubleshooting-sessions
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import config from './config/config';

if (config.name === 'development') {
  LogRocket.init('aorusa/ireport-react-app-local');
}
if (config.name === 'test') {
  LogRocket.init('aorusa/ireport-react-app-test');
}
if (config.name === 'stage') {
  LogRocket.init('aorusa/ireport-react-app-stage');
}
if (config.name === 'uat') {
  LogRocket.init('aorusa/ireport-react-app-uat');
}
if (config.name === 'production') {
  LogRocket.init('aorusa/ireport-react-app-prod');
}

setupLogRocketReact(LogRocket);
