import React, { Component } from 'react';
import {
  getAttachmentSelection,
  isImage
} from '../../utils/attachmentSelector';
import classnames from 'classnames/bind';
import Button from '../inputs/Button';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

export default class FileDownloader extends Component {
  render() {
    const {
      attachmentIndex,
      onClick,
      attachment,
      deleteAttachment,
      disabled,
      isInList,
      fromTasks,
      ownerType
    } = this.props;

    const classes = {
      listDownload: isInList,
      downloader: true
    };

    const deleteClasses = fromTasks
      ? [styles.taskDelete]
      : [styles.deleteAttachment];

    const imageBoolean = isImage(attachment);

    const correctOwner =
      attachment?.ownerType === undefined || ownerType === attachment.ownerType;

    return (
      <div className={styles.fileDownloader}>
        <a
          key={attachmentIndex}
          href={attachment.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            onClick={onClick}
            src={
              imageBoolean
                ? attachment.source_url
                : getAttachmentSelection(attachment)
            }
            alt="images"
            className={bStyles(classes)}
          />
        </a>
        {!imageBoolean && (
          <div className={fromTasks ? styles.shortenName : styles.fileName}>
            {attachment.original_filename ?? attachment.name}
          </div>
        )}
        {!disabled && correctOwner ? (
          <Button
            type="button"
            color="red"
            className={deleteClasses}
            text="X"
            onClick={() => deleteAttachment(attachment)}
          />
        ) : null}
      </div>
    );
  }
}
