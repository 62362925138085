import React, { Component } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';

class DateBox extends Component {
  render() {
    const { date, deleteDate, onClick, index, disabled } = this.props;
    let input;
    let dateType;
    let className;
    if (date.endDate === null) {
      input =
        moment(date.startDate)
          .utc()
          .format('MM/DD/YYYY') + ' \u2013 ONGOING';
      dateType = 2;
      className = styles.dateBoxOngoing;
    } else if (moment(date.startDate).isSame(date.endDate, 'day')) {
      input = moment(date.startDate)
        .utc()
        .format('MM/DD/YYYY');
      dateType = 1;
      className = styles.dateBox;
    } else {
      input =
        moment(date.startDate)
          .utc()
          .format('MM/DD/YYYY') +
        ' \u2013 ' +
        moment(date.endDate)
          .utc()
          .format('MM/DD/YYYY');
      dateType = 0;
      className = styles.dateBox;
    }

    return (
      <div className={className}>
        <span
          key={index}
          onClick={() => (disabled ? undefined : onClick({ date, dateType }))}
        >
          {input}
        </span>
        <img
          onClick={() => (disabled ? undefined : deleteDate({ date: date }))}
          alt="delete date"
          src={require('../../../assets/images/modalX.png')}
        ></img>
      </div>
    );
  }
}

export default DateBox;
