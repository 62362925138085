import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';

import Button from '../inputs/Button';
import Modal from '.';
import Textbox from '../inputs/Textbox';
import { InputRow } from '../inputs';
import { checkIfLinkIsYoutube } from '../../utils/attachmentSelector';

import styles from './styles.module.scss';

export class AddYouTubeLinksModal extends Component {
  state = {
    youtubeLinks: (this.props.youtubeLinks ?? []).concat([
      { title: '', link: '' }
    ])
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.youtubeLinks, this.props.youtubeLinks)) {
      let links = this.props.youtubeLinks?.length
        ? this.props.youtubeLinks ?? [{ title: '', link: '' }]
        : [{ title: '', link: '' }];
      this.setState({ youtubeLinks: links });
    }
  }

  addYouTubeLink = () => {
    this.setState(state => ({
      youtubeLinks: [...state.youtubeLinks, { title: '', link: '' }]
    }));
  };

  handleSubmit = () => {
    this.props.submitActions(this.state.youtubeLinks);
  };

  render() {
    const youtubeLinks = this.state.youtubeLinks ?? [{ title: '', link: '' }];

    const canSubmit = youtubeLinks?.every(
      link => checkIfLinkIsYoutube(link.link) && link.title.trim()
    );

    return (
      <Modal
        title="Add YouTube Links"
        titleClassName="blueHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="blue"
        submitButtonText="Save Links"
        onRequestClose={() => {
          this.setState({
            youtubeLinks: this.props.youtubeLinks ?? [{ title: '', link: '' }]
          });
          this.props.onRequestClose();
        }}
        medium
        cancelButtonText="Cancel"
        submitActions={this.handleSubmit}
        disableSubmit={!canSubmit}
        testID="Add YouTube Links"
      >
        {youtubeLinks?.map((link, index) => (
          <InputRow key={index} className={styles.grow}>
            <Textbox
              onChange={v => {
                const currentOptions = youtubeLinks;
                currentOptions[index].title = v;
                this.setState(state => ({ youtubeLinks: currentOptions }));
              }}
              currentValue={link.title}
              className={styles.youtubeInput}
              testID="youtubeLinkTitle"
              fieldLabel={index === 0 ? 'YouTube Video Title' : ''}
              isRequired
              disabled={link.disabled}
              warning="Must be a valid title"
              touched={link.title && link.title.trim() === ''}
            />
            <Textbox
              onChange={v => {
                const currentOptions = youtubeLinks;
                currentOptions[index].link = v;
                this.setState({ youtubeLinks: currentOptions });
              }}
              currentValue={link.link}
              className={styles.youtubeInput}
              testID="youtubeLinkUrl"
              fieldLabel={index === 0 ? 'YouTube Video Link' : ''}
              isRequired
              warning="Must be a valid YouTube link"
              touched={link.link && !checkIfLinkIsYoutube(link.link)}
              disabled={link.disabled}
            />
            {link.disabled ? (
              <></>
            ) : (
              <img
                src={require('../../assets/images/remove.png')}
                className={styles.removeImage}
                alt="remove row"
                onClick={() =>
                  this.setState({
                    youtubeLinks: [
                      ...youtubeLinks.slice(0, index),
                      ...youtubeLinks.slice(index + 1)
                    ]
                  })
                }
              />
            )}
          </InputRow>
        ))}
        <Button
          inputClassName={styles.addButton}
          text="Add"
          color="blue"
          style={{ width: '100px' }}
          onClick={this.addYouTubeLink}
        />
      </Modal>
    );
  }
}

export default AddYouTubeLinksModal;
