import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setActiveIncidentResponse } from '../../actions/incidents';
import { clearUploadedAttachments } from '../../actions/attachments';
import CompanyDetails from '../../components/CompanyDetails';
import EmployeeList from '../../components/EmployeeList';
import LocationList from '../../components/LocationList';
import ProjectList from '../../components/ProjectList';

const Settings = () => {
  const dispatch = useDispatch();
  const { settingType } = useParams();
  useEffect(() => {
    dispatch(clearUploadedAttachments());
    dispatch(setActiveIncidentResponse());
  }, [dispatch]);

  if (settingType === 'Projects') return <ProjectList />;
  if (settingType === 'Locations') return <LocationList />;
  if (settingType === 'CompanyDetails') return <CompanyDetails />;
  if (settingType === 'Employees') return <EmployeeList />;
  return <></>;
};

export default Settings;
