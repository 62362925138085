import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import history from '../../history';

import {
  addProjectRequest,
  deleteProjectRequest,
  updateProjectRequest,
  setActiveLocation,
  setActiveProject
} from '../../actions/company';
import { getActiveCompany, getIsPerrp } from '../../selectors/company';

import AreaModal from '../Modal/AreaModal';
import Header from '../Header';
import HeaderAndFooter from '../HeaderAndFooter';
import HideLocationModal from '../Modal/hideLocationModal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import ViewEditList from '../ViewEditList';

export const ProjectList = () => {
  const dispatch = useDispatch();

  const company = useSelector(getActiveCompany);
  const isPerrp = useSelector(getIsPerrp);
  const setActiveGroup = locationId => dispatch(setActiveLocation(locationId));
  const setActiveArea = value => dispatch(setActiveProject(value));

  const [openModal, setModal] = useState();
  const [area, setArea] = useState();

  const handleCloseModal = () => {
    setArea();
    setModal();
  };

  const submitArea = values => {
    values?._id
      ? dispatch(updateProjectRequest(values))
      : dispatch(
          addProjectRequest({
            ...values,
            companyId: company._id
          })
        );

    handleCloseModal();
  };

  const hideArea = () => {
    dispatch(
      updateProjectRequest({
        ...area,
        isHidden: !area.isHidden
      })
    );
    handleCloseModal();
  };

  const deleteArea = area => {
    dispatch(deleteProjectRequest({ ...area }));
    handleCloseModal();
  };

  const header = (
    <Header
      title="Areas"
      rightButtons={{
        text: 'New Area',
        color: 'green',
        onClick: () => setModal('editModal')
      }}
    />
  );

  return (
    <>
      <HeaderAndFooter Header={header}>
        <ScrollToTopOnMount />
        <ViewEditList
          data={company?.groups}
          subCat="projects"
          onView={area => {
            const group = company.groups?.find(g => g._id === area.groupId);
            setActiveGroup(group);
            setActiveArea(area);
            history.push('/app/dashboard');
          }}
          onEdit={area => {
            setArea(area);
            setModal('editModal');
          }}
          onDelete={deleteArea}
          onHide={area => {
            setArea(area);
            setModal('hideModal');
          }}
        />
      </HeaderAndFooter>
      <HideLocationModal
        isOpen={openModal === 'hideModal'}
        onRequestClose={handleCloseModal}
        submitActions={hideArea}
        location={area}
        locationType="area"
      />
      <AreaModal
        isOpen={openModal === 'editModal'}
        onRequestClose={handleCloseModal}
        submitActions={submitArea}
        isEditing={area?._id}
        currentArea={area}
        isPerrp={isPerrp}
        company={company}
      />
    </>
  );
};

export default ProjectList;
