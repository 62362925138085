import React from 'react';
import './svg.css';

export default () => (
  <svg height="25px" width="30px" viewBox="0 0 1024 1024">
    <g>
      <g>
        <g>
          <path d="M58.9,911.4c33.2,0,66.3,0,99.5,0c22.5,0,45,0,67.5,0c36.7,0,73.4,0,110,0c27.8,0,55.5,0,83.3,0     c26.7,0,53.3,0,80,0c29.9,0,59.8,0,89.6,0c30.8,0,61.6,0,92.4,0c28.7,0,57.4,0,86,0c27.3,0,54.6,0,81.8,0c35.2,0,70.3,0,105.5,0     c3.5,0,7,0,10.5,0c12.8,0,26.3-5.6,35.4-14.6c8.7-8.7,15.2-22.9,14.6-35.4c-0.6-12.9-4.8-26.3-14.6-35.4     c-9.8-9-21.8-14.6-35.4-14.6c-33.2,0-66.4,0-99.7,0c-22.2,0-44.5,0-66.7,0c-37.2,0-74.5,0-111.7,0c-28.2,0-56.3,0-84.5,0     c-23.9,0-47.9,0-71.8,0c-35.3,0-70.6,0-105.9,0c-27.8,0-55.7,0-83.5,0c-27.9,0-55.8,0-83.7,0c-28.1,0-56.2,0-84.3,0     c-34.6,0-69.2,0-103.8,0c-3.6,0-7.1,0-10.7,0c-12.8,0-26.3,5.6-35.4,14.6c-8.7,8.7-15.2,22.9-14.6,35.4     c0.6,12.9,4.8,26.3,14.6,35.4C33.3,905.7,45.3,911.4,58.9,911.4L58.9,911.4z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M329.4,477.7c0,12.7,0,25.3,0,38c0,30.6,0,61.1,0,91.7c0,36.8,0,73.7,0,110.5c0,32,0,64,0,96     c0,15.5-0.3,31,0,46.5c0,0.2,0,0.4,0,0.7c16.7-16.7,33.3-33.3,50-50c-29.8,0-59.6,0-89.5,0c-47.5,0-95,0-142.5,0     c-10.8,0-21.7,0-32.5,0c16.7,16.7,33.3,33.3,50,50c0-12.7,0-25.3,0-38c0-30.6,0-61.1,0-91.7c0-36.8,0-73.7,0-110.5     c0-32,0-64,0-96c0-15.5,0.3-31,0-46.5c0-0.2,0-0.4,0-0.7c-16.7,16.7-33.3,33.3-50,50c29.8,0,59.6,0,89.5,0c47.5,0,95,0,142.5,0     c10.8,0,21.7,0,32.5,0c12.8,0,26.3-5.6,35.4-14.6c8.7-8.7,15.2-22.9,14.6-35.4c-0.6-12.9-4.8-26.3-14.6-35.4     c-9.8-9-21.8-14.6-35.4-14.6c-29.8,0-59.6,0-89.5,0c-47.5,0-95,0-142.5,0c-10.8,0-21.7,0-32.5,0c-27,0-50,22.9-50,50     c0,12.7,0,25.3,0,38c0,30.6,0,61.1,0,91.7c0,36.8,0,73.7,0,110.5c0,32,0,64,0,96c0,15.5-0.2,31,0,46.5c0,0.2,0,0.4,0,0.7     c0,27,22.9,50,50,50c29.8,0,59.6,0,89.5,0c47.5,0,95,0,142.5,0c10.8,0,21.7,0,32.5,0c27,0,50-22.9,50-50c0-12.7,0-25.3,0-38     c0-30.6,0-61.1,0-91.7c0-36.8,0-73.7,0-110.5c0-32,0-64,0-96c0-15.5,0.2-31,0-46.5c0-0.2,0-0.4,0-0.7c0-12.8-5.6-26.3-14.6-35.4     c-8.7-8.7-22.9-15.2-35.4-14.6c-12.9,0.6-26.3,4.8-35.4,14.6C335.1,452.1,329.4,464.2,329.4,477.7z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M594.2,162.6c0,6.2,0,12.5,0,18.7c0,16.9,0,33.8,0,50.7c0,25.1,0,50.2,0,75.3c0,30.5,0,60.9,0,91.4     c0,33.5,0,67.1,0,100.6c0,33.9,0,67.7,0,101.6c0,31.4,0,62.8,0,94.3c0,26.7,0,53.4,0,80.1c0,19.1,0,38.2,0,57.3     c0,9.1-0.1,18.2,0,27.3c0,0.4,0,0.8,0,1.2c16.7-16.7,33.3-33.3,50-50c-29.9,0-59.8,0-89.7,0c-47.4,0-94.9,0-142.3,0     c-10.9,0-21.8,0-32.8,0c16.7,16.7,33.3,33.3,50,50c0-6.2,0-12.5,0-18.7c0-16.9,0-33.8,0-50.7c0-25.1,0-50.2,0-75.3     c0-30.5,0-60.9,0-91.4c0-33.5,0-67.1,0-100.6c0-33.9,0-67.7,0-101.6c0-31.4,0-62.8,0-94.3c0-26.7,0-53.4,0-80.1     c0-19.1,0-38.2,0-57.3c0-9.1,0.1-18.2,0-27.3c0-0.4,0-0.8,0-1.2c-16.7,16.7-33.3,33.3-50,50c29.9,0,59.8,0,89.7,0     c47.4,0,94.9,0,142.3,0c10.9,0,21.8,0,32.8,0c12.8,0,26.3-5.6,35.4-14.6c8.7-8.7,15.2-22.9,14.6-35.4     c-0.6-12.9-4.8-26.3-14.6-35.4c-9.8-9-21.8-14.6-35.4-14.6c-29.9,0-59.8,0-89.7,0c-47.4,0-94.9,0-142.3,0c-10.9,0-21.8,0-32.8,0     c-27,0-50,22.9-50,50c0,6.2,0,12.5,0,18.7c0,16.9,0,33.8,0,50.7c0,25.1,0,50.2,0,75.3c0,30.5,0,60.9,0,91.4     c0,33.5,0,67.1,0,100.6c0,33.9,0,67.7,0,101.6c0,31.4,0,62.8,0,94.3c0,26.7,0,53.4,0,80.1c0,19.1,0,38.2,0,57.3     c0,9.1-0.1,18.2,0,27.3c0,0.4,0,0.8,0,1.2c0,27,22.9,50,50,50c29.9,0,59.8,0,89.7,0c47.4,0,94.9,0,142.3,0c10.9,0,21.8,0,32.8,0     c27,0,50-22.9,50-50c0-6.2,0-12.5,0-18.7c0-16.9,0-33.8,0-50.7c0-25.1,0-50.2,0-75.3c0-30.5,0-60.9,0-91.4c0-33.5,0-67.1,0-100.6     c0-33.9,0-67.7,0-101.6c0-31.4,0-62.8,0-94.3c0-26.7,0-53.4,0-80.1c0-19.1,0-38.2,0-57.3c0-9.1,0.1-18.2,0-27.3     c0-0.4,0-0.8,0-1.2c0-12.8-5.6-26.3-14.6-35.4c-8.7-8.7-22.9-15.2-35.4-14.6c-12.9,0.6-26.3,4.8-35.4,14.6     C599.9,137.1,594.2,149.1,594.2,162.6z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M858.8,277.1c0,19.4,0,38.7,0,58.1c0,46.5,0,93,0,139.5c0,56.2,0,112.5,0,168.7c0,48.6,0,97.2,0,145.9     c0,23.6-0.3,47.3,0,70.9c0,0.3,0,0.7,0,1c16.7-16.7,33.3-33.3,50-50c-29.8,0-59.7,0-89.5,0c-47.5,0-95,0-142.5,0     c-10.9,0-21.7,0-32.6,0c16.7,16.7,33.3,33.3,50,50c0-19.4,0-38.7,0-58.1c0-46.5,0-93,0-139.5c0-56.2,0-112.5,0-168.7     c0-48.6,0-97.2,0-145.9c0-23.6,0.3-47.3,0-70.9c0-0.3,0-0.7,0-1c-16.7,16.7-33.3,33.3-50,50c29.8,0,59.7,0,89.5,0     c47.5,0,95,0,142.5,0c10.9,0,21.7,0,32.6,0c12.8,0,26.3-5.6,35.4-14.6c8.7-8.7,15.2-22.9,14.6-35.4c-0.6-12.9-4.8-26.3-14.6-35.4     c-9.8-9-21.8-14.6-35.4-14.6c-29.8,0-59.7,0-89.5,0c-47.5,0-95,0-142.5,0c-10.9,0-21.7,0-32.6,0c-27,0-50,22.9-50,50     c0,19.4,0,38.7,0,58.1c0,46.5,0,93,0,139.5c0,56.2,0,112.5,0,168.7c0,48.6,0,97.2,0,145.9c0,23.6-0.3,47.3,0,70.9     c0,0.3,0,0.7,0,1c0,27,22.9,50,50,50c29.8,0,59.7,0,89.5,0c47.5,0,95,0,142.5,0c10.9,0,21.7,0,32.6,0c27,0,50-22.9,50-50     c0-19.4,0-38.7,0-58.1c0-46.5,0-93,0-139.5c0-56.2,0-112.5,0-168.7c0-48.6,0-97.2,0-145.9c0-23.6,0.3-47.3,0-70.9     c0-0.3,0-0.7,0-1c0-12.8-5.6-26.3-14.6-35.4c-8.7-8.7-22.9-15.2-35.4-14.6c-12.9,0.6-26.3,4.8-35.4,14.6     C864.5,251.5,858.8,263.5,858.8,277.1z" />
        </g>
      </g>
    </g>
  </svg>
);
