import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import { getDocumentFoldersSelector } from '../../selectors/documents';

import history from '../../history';
import svgMapper from '../../utils/svgMapper';
import Modal from '../../components/Modal';
import Textbox from '../../components/inputs/Textbox';
import Button from '../../components/inputs/Button';

import styles from './leftNavSection.module.scss';
const bStyles = classnames.bind(styles);

class LeftNavSection extends PureComponent {
  state = {
    documentType: '',
    folderName: '',
    openModal: false,
    folderExists: false
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeFolder !== this.props.activeFolder &&
      Object.keys(this.props.activeFolder).length === 0
    ) {
      this.setState({ openFolder: 'All Folders' });
    }
  }

  handleAddFolder = async () => {
    const trimmed = this.state.folderName.trim();
    if (
      this.props.documentFolders?.some(
        f => !f.name.localeCompare(trimmed, 'en', { sensitivity: 'base' })
      )
    ) {
      this.setState({ folderExists: true });
      return;
    }

    await this.props.addDocumentFolder({
      documentType: this.state.documentType,
      name: this.state.folderName
    });
    this.handleCloseModal();
  };

  openFolder = (folder, link) => {
    this.props.setActiveFolder({ ...folder, page: 1, limit: 10000 });
    history.push(link);
  };

  handleOpenModal = value => {
    this.setState({ openModal: true, documentType: value });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, folderName: '', folderExists: false });
  };

  fetchAllDocuments = (documentType, link) => {
    if (this.props.closeModalMenu) this.props.closeModalMenu();
    if (documentType && this.props.fetchDocumentFolders) {
      this.props.setActiveFolder({});
      this.props.fetchDocumentFolders({ documentType });
    }

    history.push(link);
  };

  openNewSection = () => {
    this.props.openNewSection(this.props.activeSubSection);
  };

  render() {
    const shouldDisplay = (
      navItem,
      project,
      location,
      company,
      accessLevel,
      loggedInUser
    ) => {
      const navItemAccess = navItem.accessLevel.indexOf(accessLevel) !== -1;
      const navItemAppProject =
        navItem.appLevel.indexOf('Project') !== -1 && project;
      const navItemAppLocation =
        navItem.appLevel.indexOf('Group') !== -1 && location;
      const navItemAppCompany =
        navItem.appLevel.indexOf('Division') !== -1 && company;

      if (!navItem.showFeature) {
        return false;
      }

      if (navItem.isAircraftItem && !activeCompany.showAircraft) return false;

      if (navItem.text === 'Spill' && activeCompany.showAircraft) return false;

      if (
        (navItem.text === 'Lockout/Tagout Procedures' ||
          navItem.text === 'Lockout/Tagout Templates') &&
        !activeCompany.hasLockoutTagout
      )
        return false;

      if (navItem.text === 'My Analytics' && activeCompany.hideAnalytics)
        return false;

      if (
        navItem.text === 'Covid-19 Tracking' &&
        !activeCompany.turnOnCovidTracking
      )
        return false;

      if (
        navItem.text === 'Claims' &&
        ((!activeCompany.showClaims && !loggedInUser.isAccountOwner) ||
          !activeCompany.showClaims)
      ) {
        return false;
      }

      if (project) {
        return navItemAccess && navItemAppProject;
      } else if (location) {
        return navItemAccess && navItemAppLocation;
      } else {
        return navItemAccess && navItemAppCompany;
      }
    };

    const {
      title,
      section,
      accessLevel,
      sectionOpen,
      activeCompany,
      activeLocation,
      activeProject,
      activeFolder,
      activeSection,
      testID,
      fromMobileHeader,
      loggedInUser,
      documentFolders
    } = this.props;

    const { folderName, openModal } = this.state;
    return (
      <div
        className={styles.section}
        data-cy={testID}
        onClick={this.openNewSection}
      >
        <p
          className={bStyles('label', {
            activeSection: sectionOpen
          })}
        >
          {title}
        </p>
        <ul
          className={bStyles('menu', {
            activeSection: sectionOpen
          })}
        >
          {section.map((navItem, index) => {
            let active = index === activeSection;
            let hasFolders = navItem.hasFolders;
            return shouldDisplay(
              navItem,
              activeProject,
              activeLocation,
              activeCompany,
              accessLevel,
              loggedInUser
            ) ? (
              <li
                key={index}
                data-cy={
                  fromMobileHeader ? `${navItem.text}Mobile` : navItem.text
                }
              >
                <Link
                  className={bStyles(
                    'item',
                    { active: active && (!hasFolders || !activeFolder._id) },
                    { hasFolders }
                  )}
                  to={{ pathname: navItem.link }}
                  onClick={() =>
                    this.fetchAllDocuments(navItem.folderType, navItem.link)
                  }
                >
                  {svgMapper(navItem.image, { width: 18, height: 18 })}
                  <span>
                    {activeCompany.reportingType === 2 &&
                    navItem.text === 'OSHA Forms'
                      ? 'PERRP Forms'
                      : activeCompany.isDash && navItem.text === 'Safety Walks'
                      ? 'DASH Report'
                      : activeCompany.reportingType === 2 &&
                        navItem.text === 'OSHA Information'
                      ? 'BWC Information'
                      : navItem.text}
                  </span>
                </Link>
                {navItem.hasFolders && active && (
                  <div className={styles.folderContainer}>
                    <ul className={styles.folderList}>
                      {!(accessLevel === 100 || navItem.disableAddFolders) && (
                        <li>
                          <Button
                            inputClassName={styles.addFolderButton}
                            color="grey"
                            text="New Folder"
                            onClick={() =>
                              this.handleOpenModal(navItem.folderType)
                            }
                          />
                        </li>
                      )}
                      {documentFolders.map((folder, index) => (
                        <li
                          key={index}
                          className={bStyles(
                            {
                              selected: activeFolder?._id === folder._id
                            },
                            'folderItem'
                          )}
                          onClick={() => this.openFolder(folder, navItem.link)}
                        >
                          {
                            <img
                              src={require('../../assets/images/Folder.png')}
                              className={styles.addButton}
                              alt="folder open"
                            />
                          }{' '}
                          {folder.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ) : (
              ''
            );
          })}
        </ul>
        <Modal
          title="New Folder"
          titleClassName="blueHeader"
          isOpen={openModal}
          onRequestClose={this.handleCloseModal}
          submitActions={this.handleAddFolder}
          submitButtonColor="blue"
          disableSubmit={folderName.trim().length < 3}
        >
          <Textbox
            fieldLabel="Folder Name"
            onChange={v =>
              this.setState({ folderName: v, folderExists: false })
            }
            isRequired={true}
            currentValue={folderName}
            touched={this.state.folderExists}
            errorMessage="This folder name already exists"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  documentFolders: getDocumentFoldersSelector(state)
});

export default connect(mapStateToProps)(LeftNavSection);
