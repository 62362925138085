import React, { Component } from 'react';
import DynamicNumber from 'react-dynamic-number';

import classnames from 'classnames/bind';
import { Input, nameInput } from '../';

import styles from './money.module.scss';
const bStyles = classnames.bind(styles);

class Money extends Component {
  handleChange = (e, v) => {
    this.props.onChange && this.props.onChange(parseFloat(v));
  };

  render() {
    const {
      name,
      wholeDollars,
      placeholder,
      onChange,
      onBlur,
      currentValue,
      disabled,
      onMouseDown,
      autoFocus,
      inputRef,
      onKeyDown,
      ...props
    } = this.props;

    let v = '';
    if (
      currentValue !== '' &&
      currentValue !== null &&
      currentValue !== undefined
    )
      v = parseFloat(currentValue).toFixed(2);

    return (
      <Input name={name} disabled={disabled} {...props}>
        <div className={styles.container}>
          <div
            className={bStyles({
              currencyUnit: true,
              disabled: disabled
            })}
          >
            $
          </div>
          <DynamicNumber
            value={v}
            separator="."
            thousand
            // Yes, it needs 3
            fraction={wholeDollars ? 0 : 3}
            placeholder={placeholder}
            onChange={this.handleChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            autoFocus={autoFocus}
            id={name}
            disabled={disabled}
            onMouseDown={onMouseDown}
            className={styles.textbox}
          />
        </div>
      </Input>
    );
  }
}

export default nameInput(Money);
