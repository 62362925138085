import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.76181 5.60022C2.12363 5.60022 0 7.72398 0 10.362V69.4093C0 72.0475 2.12376 74.1711 4.76181 74.1711H75.2382C77.8764 74.1711 80 72.0474 80 69.4093V23.6954C80 21.0572 77.8762 18.9336 75.2382 18.9336H35.2382L29.5237 10.3619C28.0602 8.16683 26.4475 5.6001 23.8093 5.6001L4.76181 5.60022ZM35.2382 32.2669H44.7618V41.7905H54.2854V51.3141H44.7618V60.8378H35.2382V51.3141H25.7146V41.7905H35.2382V32.2669Z" />
  </svg>
);
