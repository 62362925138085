import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131789)">
      <path d="M78.5428 30.8856L40.8281 44.3109C40.2982 44.4875 39.7683 44.4875 39.3267 44.3109L1.52371 30.8856C-0.507904 30.1789 -0.507904 27.3526 1.52371 26.6458L39.2384 13.1322C39.7683 12.9556 40.2981 12.9556 40.7398 13.1322L78.5428 26.6458C80.4858 27.3523 80.4858 30.1788 78.5428 30.8853V30.8856ZM75.8048 46.2541V36.6267L71.3003 38.2167V46.4309C70.417 47.1376 69.7989 48.2856 69.7989 49.5222C69.7989 51.7304 71.5654 53.4086 73.6853 53.4086C75.8051 53.4086 77.5716 51.6421 77.5716 49.5222C77.5713 48.1088 76.8645 46.8722 75.8048 46.2541ZM40.0332 48.9921C39.2382 48.9921 38.4432 48.9038 37.7369 48.6387L13.4476 39.9829V58.7961C29.2577 70.2783 50.7206 70.2783 66.5307 58.7961V39.8946L42.3298 48.5504C41.5348 48.8155 40.8284 48.992 40.0334 48.992L40.0332 48.9921Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131789">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
