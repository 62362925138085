import React, { useEffect, useState } from 'react';

import { validZip } from '../../utils/formValidation';
import { stateAbbr } from '../../constants/states';
import { Dropdown, InputRow, Textbox } from '../inputs';
import Modal from '.';
import RadioButtons from '../inputs/RadioButtons';

export default function AreaModal({
  isOpen,
  onRequestClose,
  submitActions,
  currentArea,
  isEditing,
  isPerrp,
  company,
  fromHomePage
}) {
  const perrpOrDash = isPerrp ? 'PERRP' : 'OSHA';
  const [area, setArea] = useState({});
  const [missingRequired, setMissing] = useState(false);

  useEffect(() => {
    const group = company?.groups?.find(g => g._id === currentArea?.groupId);

    const isGroupSeparate = currentArea?.groupId
      ? group?.isSeparateEstablishment
      : false;

    setArea({ ...currentArea, isGroupSeparate });
  }, [currentArea, company.groups]);

  const validName = (area?.name?.match(/[A-Za-z]/g) || []).length > 1;

  const canSubmit = () => {
    const validSeparateInfo =
      area.isSeparateEstablishment !== false
        ? !validZip(area.zip) &&
          area.streetAddress?.trim() &&
          area.city?.trim() &&
          area.zip &&
          area.state
        : true;

    let validBasics = validName && area?.groupId;

    return validBasics && (area?.isGroupSeparate ? validSeparateInfo : true);
  };

  return (
    <Modal
      title={`${isEditing ? 'Edit' : 'New'} Area`}
      titleClassName={isEditing ? 'blueHeader' : 'greenHeader'}
      isOpen={isOpen}
      submitButtonColor={isEditing ? 'blue' : 'green'}
      submitButtonText={isEditing ? 'Update' : 'Create'}
      onRequestClose={() => {
        setArea({});
        onRequestClose();
        setMissing(false);
      }}
      submitActions={() => {
        if (canSubmit()) {
          setArea({});
          submitActions(area);
        }
      }}
      onMouseEnter={() => setMissing(!canSubmit())}
      missingRequied={missingRequired}
      submitTooltip={!canSubmit() ? 'Missing or Invalid Fields' : null}
    >
      <div>
        {fromHomePage ? null : (
          <Dropdown
            fieldLabel="Group to Add Area"
            isRequired
            options={company?.groups
              ?.filter(g => !g.isHidden)
              ?.map(g => ({
                label: g.name,
                value: g._id,
                isSeparate: g.isSeparateEstablishment
              }))}
            placeholder="Select a group"
            currentValue={area?.groupId}
            onChange={v =>
              setArea({
                ...area,
                groupId: v.value,
                isGroupSeparate: v.isSeparate,
                isSeparateEstablishment: v.isSeparate
                  ? area.isSeparateEstablishment
                  : false
              })
            }
            bareValues={false}
            disabled={isEditing}
            touched={missingRequired && !area?.groupId}
          />
        )}
        <Textbox
          currentValue={area?.name}
          fieldLabel="Area Name"
          onChange={v => setArea({ ...area, name: v })}
          isRequired
          placeholder="e.g Hotel Area"
          touched={missingRequired && !validName}
          errorMessage="Name must be at least 2 letters long"
        />
        {area?.isGroupSeparate ? (
          <RadioButtons
            fieldLabel={`Does this require its own ${perrpOrDash} Report?`}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            isRequired
            onChange={v => setArea({ ...area, isSeparateEstablishment: v })}
            currentValue={area?.isSeparateEstablishment}
            bareValues
            disabled={!area?.isGroupSeparate}
            touched={
              missingRequired && area?.isSeparateEstablishment === undefined
            }
          />
        ) : null}
        {area?.isSeparateEstablishment ? (
          <>
            <Textbox
              currentValue={area?.streetAddress}
              fieldLabel="Street Address "
              onChange={v => setArea({ ...area, streetAddress: v })}
              isRequired
              placeholder="e.g. Mall Area"
              touched={missingRequired && !area?.streetAddress}
            />
            <InputRow>
              <Textbox
                currentValue={area?.city}
                fieldLabel="City "
                onChange={v => setArea({ ...area, city: v })}
                isRequired
                placeholder="City"
                touched={missingRequired && !area?.city}
              />
              <Dropdown
                fieldLabel="State "
                isRequired
                options={stateAbbr}
                placeholder="State"
                currentValue={area?.state}
                onChange={v => setArea({ ...area, state: v })}
                touched={missingRequired && !area?.state}
              />
              <Textbox
                currentValue={area?.zip}
                fieldLabel="Zip"
                onChange={v => setArea({ ...area, zip: v })}
                isRequired
                placeholder="Zip"
                touched={(!area.zip && missingRequired) || validZip(area?.zip)}
                errorMessage={validZip(area?.zip)}
              />
            </InputRow>
          </>
        ) : null}
      </div>
    </Modal>
  );
}
