import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../history';
import { fetchSafetyMeetingTemplates, archiveMany } from '../../api/v4';
import { getAccessLevel } from '../../selectors/users';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';
import { Dropdown } from '../../components/inputs';
import TypeSelection from '../../components/TypeSelection';

export default function SafetyMeetingTemplates() {
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [listType, setListType] = useState('Active');
  const accessLevel = useSelector(getAccessLevel);

  useEffect(() => {
    fetchSafetyMeetingTemplates().then(setList);
  }, []);
  const templateDropdown = list
    ?.filter(template => !template.isArchived)
    .map(t => {
      return { value: t._id, label: t.title };
    });

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'safetyMeetingTemplate',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() => fetchSafetyMeetingTemplates().then(setList));
  };

  const header = (
    <Header
      title="Safety Meeting Templates"
      pageActionOptions={[
        {
          label: 'Create Safety Meeting Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => history.push('/app/safetyMeeting/template')
        },
        {
          label: 'Duplicate Meeting Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: list?.length > 0
        }
      ]}
    />
  );
  const columns = [
    {
      label: 'Title',
      key: 'title'
    },
    {
      key: 'instructions'
    },
    {
      label: 'Created At',
      key: 'createdAt',
      datatype: 'date'
    },
    {
      label: 'Last Updated',
      key: 'updatedAt',
      datatype: 'date'
    }
  ];

  const listTypeFilter =
    listType === 'Archived'
      ? list.filter(template => template.isArchived)
      : list.filter(template => !template.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      {accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={setListType}
          selected={listType}
        />
      ) : null}
      <List
        settings={columns}
        data={listTypeFilter}
        dataIsHash
        saveKey="safetyMeetingTemplate"
        rowClick={row => history.push(`/app/safetyMeeting/template/${row._id}`)}
        getRowId={r => r._id}
        actions={[
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => {
          setOpenModal(false);
          setSelectedTemplate(null);
        }}
        submitActions={() =>
          history.push(`/app/safetyMeeting/template/${selectedTemplate}/true`)
        }
        disableSubmit={!selectedTemplate}
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Safety Meeting Template"
          currentValue={selectedTemplate}
          isRequired
          onChange={setSelectedTemplate}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
