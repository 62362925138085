import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import { fetchWitnessStatement, resendWitnessStatement } from '../../../api/v4';
import { DatePicker } from '../DateTimePicker';
import { isFutureDate } from '../../../utils/formValidation';
import Card from '../../../components/Card';
import { Button, Checkbox } from '../../../components/inputs';
import WitnessStatementContainer from '../../../containers/WitnessStatementContainer';

import UserBadge from '../../UserBadge';
import Modal from '../../Modal';

import styles from './statement.module.scss';
const bStyles = classnames.bind(styles);

const getWitnessStatementHeaders = statement => {
  const isPastDue = moment().isAfter(Date.parse(statement.dueDate));
  const isExpired =
    moment().isAfter(statement.dueDate) && statement.externalToken;

  if (statement.isCompleted) {
    return {
      top: 'Complete',
      style: 'greenOutline'
    };
  } else if (statement.isNa) {
    return {
      top: 'Not Applicable',
      style: 'greenOutline'
    };
  } else if (isExpired) {
    return {
      top: 'Statement Link Expired',
      style: 'redOutline'
    };
  } else if (isPastDue) {
    return {
      top: 'Statement Past Due',
      style: 'redOutline'
    };
  } else if (statement.hasStarted) {
    return {
      top: 'In-Progress',
      style: 'blueOutline'
    };
  } else {
    return {
      top: statement.externalToken ? 'Awaiting Response' : 'Assigned',
      style: 'blueOutline'
    };
  }
};

export default function WitnessStatementCard({
  statementId,
  index,
  handleWsNa,
  handleWsDueDate,
  incidentArchived
}) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [statement, setStatement] = useState({});
  const [dueDate, setDueDate] = useState(null);
  const [isNa, setIsNa] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [assignedTo, setAssignedTo] = useState(null);
  const [modalDueDate, setModalDueDate] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);
  const [disableRemind, setdisableRemind] = useState(false);

  useEffect(() => {
    fetchWitnessStatement(statementId, false).then(response => {
      setStatement({ ...response });
      setDueDate(response.dueDate);
      setIsNa(response.isNa);
      setIsCompleted(response.isCompleted);
      setAssignedTo(response.assignedTo);
      setModalDueDate(response.dueDate);
      setHasStarted(response.hasStarted);
    });
  }, [statementId]);

  const header = getWitnessStatementHeaders({
    ...statement,
    hasStarted,
    isCompleted,
    isNa
  });
  const classes = bStyles({
    button: true,
    disabled: isCompleted || isNa || incidentArchived,
    [header.style]: true
  });
  let dueDateFormatted =
    dueDate || statement.dueDate
      ? `Due Date: ${moment(statement.dueDate ?? dueDate).format(
          'MM/DD/YYYY hh:mm A'
        )}`
      : isCompleted
      ? 'No Due Date Assigned'
      : 'Assign Due Date';
  return (
    <Card
      name={`wsCard${index}`}
      title={`${statement.firstName +
        ' ' +
        statement.lastName}'s Witness Statement`}
      wide
      showHeader
      key={index}
      testID={`witnessStatementCard${index}`}
      rightButton={
        statement.externalToken &&
        moment().isAfter(Date.parse(statement.dueDate)) &&
        !statement.isCompleted &&
        !isNa ? (
          <Button
            shrink={true}
            text={disableRemind ? 'Reminder Sent' : 'Resend Witness Statement'}
            color="blue"
            disabled={disableRemind}
            onClick={() =>
              resendWitnessStatement(statement._id).then(r => {
                setdisableRemind(true);
                setStatement(r);
              })
            }
          />
        ) : (
          <></>
        )
      }
    >
      {isCompleted && !isNa ? (
        <WitnessStatementContainer statement={statement} />
      ) : (
        <>
          <Checkbox
            fieldLabel="Mark as Not Applicable"
            currentValue={isNa}
            disabled={isCompleted || incidentArchived}
            onChange={value => {
              setIsNa(value);
              handleWsNa(value);
            }}
          />
          <div
            className={classes}
            onClick={() => {
              if (
                isCompleted ||
                isNa ||
                statement.externalToken ||
                incidentArchived
              )
                return;
              setShowDatePicker(true);
              setModalDueDate(dueDate);
            }}
          >
            {statement.externalToken ? (
              <></>
            ) : (
              <UserBadge size="medium" userId={assignedTo} showImage />
            )}
            <div>
              <div className={styles.text}>{header.top}</div>
              {!isNa && (
                <div className={`${styles.text} ${styles.notBold}`}>
                  {dueDateFormatted}
                </div>
              )}
            </div>
          </div>

          <Modal
            title="Assign Due Date"
            titleClassName="blueHeader"
            isOpen={showDatePicker}
            submitButtonColor="blue"
            onRequestClose={() => {
              setShowDatePicker(false);
            }}
            disableSubmit={!modalDueDate || isFutureDate(modalDueDate)}
            submitActions={() => {
              setDueDate(modalDueDate);
              handleWsDueDate(modalDueDate);
              setShowDatePicker(false);
            }}
            submitButtonText="Assign"
            cancelActions={() => {
              setShowDatePicker(false);
            }}
            tall
          >
            <DatePicker
              pickTime
              fieldLabel="Due Date"
              isRequired
              onChange={value => setModalDueDate(value)}
              currentValue={modalDueDate}
              name="wsDueDate"
              error={isFutureDate(modalDueDate)}
              touched={modalDueDate}
              disabled={isCompleted}
            />
          </Modal>
        </>
      )}
    </Card>
  );
}
