import { createSelector } from 'reselect';

const incidentsSelector = state => state.incidents;

export const getIncidentBasicsSelector = createSelector(
  incidentsSelector,
  basics => basics && basics.incidentBasics
);

export const getActiveIncidentSelector = createSelector(
  incidentsSelector,
  activeIncident => activeIncident && activeIncident.activeIncident
);

export const getIncidentsLoadingSelector = createSelector(
  incidentsSelector,
  incidents => incidents && incidents.loading
);
