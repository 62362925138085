import React from 'react';
import List from '../../components/List';

export const ImmediateList = ({
  actions,
  saveKey,
  rowClick,
  data,
  settings,
  tabs,
  getRowId,
  hideCheckboxFor,
  loading
}) => {
  return (
    <List
      actions={actions}
      saveKey={saveKey}
      rowClick={rowClick}
      data={data}
      dataIsHash
      settings={settings}
      tabs={tabs}
      getRowId={getRowId}
      hideCheckboxFor={hideCheckboxFor}
      loading={loading}
    />
  );
};

export default ImmediateList;
