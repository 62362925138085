import React from 'react';
import Modal from '.';

import styles from './styles.module.scss';

const SaveProgressModal = ({ isOpen, onRequestClose, submitActions }) => (
  <Modal
    title="Save Progress"
    titleClassName="greenHeader"
    isOpen={isOpen}
    submitButtonColor="green"
    submitButtonText="Save Progress"
    onRequestClose={onRequestClose}
    submitActions={submitActions}
  >
    <div className={styles.text}>
      You are about to be taken to the Reassign Training page. If you want to
      continue click “Save Progress”.
    </div>
  </Modal>
);

export default SaveProgressModal;
