import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export class DeleteTrainingTemplateModal extends Component {
  render() {
    return (
      <Modal
        title="Delete Training Template"
        titleClassName="redHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
      >
        <div className={styles.text}>
          <span className={styles.warningText}>
            Deleting this training template is permanent. No one will be able to
            view or edit this specific template again.{' '}
          </span>
          A new training template that is similar or identical can still be
          created.{' '}
          <span className={styles.warningText}>
            If a training is in progress, or has been approved and not evaluated
            yet it will be deleted.
          </span>
        </div>
      </Modal>
    );
  }
}

export default DeleteTrainingTemplateModal;
