import React, { Component } from 'react';

import styles from './styles.module.scss';

export class InfoBar extends Component {
  render() {
    let props = { ...this.props };
    const color = props.color || 'blue';
    let barStyles = `${styles.infoBar} ${styles[color]}`;

    if (props.className) barStyles += ` ${props.className}`;

    delete props.className;
    delete props.color;

    return (
      <div className={barStyles} {...props}>
        {this.props.children}
      </div>
    );
  }
}

export default InfoBar;
