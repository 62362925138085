import { createSelector } from 'reselect';

const safetyWalkSelector = state => state.safetyWalks;

export const getActiveSafetyWalkSelector = createSelector(
  safetyWalkSelector,
  safetyWalk => safetyWalk && safetyWalk.activeSafetyWalk
);

export const getSafetyWalksSelector = createSelector(
  safetyWalkSelector,
  safetyWalk => safetyWalk && safetyWalk.list
);
