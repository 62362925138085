import {
  AUTH_SIGNOUT,
  AUTH_SUCCESS,
  LOGIN_FAILURE,
  REGISTRATION_FAILURE,
  NEW_USER_REGISTRATION_RESPONSE,
  SET_SECURITY_QUESTIONS,
  SUBMIT_SECURITY_QUESTIONS
} from '../constants/actionTypes';

export const initialState = {
  authenticated: false,
  firstTimeLogin: false,
  securityQuestions: [],
  loginFailure: '',
  registrationFailure: ''
};

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_SUCCESS:
    case SUBMIT_SECURITY_QUESTIONS:
      return { ...state, authenticated: true };

    case SET_SECURITY_QUESTIONS:
      return { ...state, securityQuestions: payload };

    case LOGIN_FAILURE:
      return { ...state, loginFailure: payload };

    case REGISTRATION_FAILURE:
      return { ...state, registrationFailure: payload };

    case NEW_USER_REGISTRATION_RESPONSE:
      return { ...state, authenticated: true, firstTimeLogin: payload };

    case AUTH_SIGNOUT:
      return { ...state, authenticated: false };

    default:
      return {
        ...state
      };
  }
};

export default auth;
