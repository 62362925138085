import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import Bar from '../../svgs/barChart';
import Clone from '../../svgs/clone';
import Edit from '../../svgs/edit';
import Share from '../../svgs/share';
import Favorite from '../../svgs/favorite';
import Trash from '../../svgs/trash';

import history from '../../history';

import styles from './card.module.scss';
const bStyles = classnames.bind(styles);

export default function DashboardCard({
  dashboard,
  handleShareClick,
  handleEditClick,
  handleCloneClick,
  fromLeaderboard,
  handleToggleFavorite,
  handleDeleteClick,
  handleReportClick,
  bottomStyles
}) {
  return (
    <div
      key={dashboard._id}
      className={styles.dashboard}
      onClick={() =>
        fromLeaderboard
          ? history.push(`/app/leaderboards/view/${dashboard._id}`)
          : history.push(`/app/myAnalytics/dashboard/${dashboard._id}`)
      }
    >
      <span className={styles.title} data-cy={`${dashboard.name}Card`}>
        {dashboard.name}
      </span>
      <div className={styles.options}>
        {!dashboard.readOnly && !dashboard.isDefaultDashboard ? (
          <div onClick={e => handleEditClick(e)}>
            <div>
              <Edit />
            </div>
            <span data-cy={`${dashboard.name}EditButton`}>Edit</span>
          </div>
        ) : null}
        {!dashboard.sharedBy &&
        !fromLeaderboard &&
        !dashboard.isDefaultDashboard ? (
          <div onClick={e => handleShareClick(e)}>
            {dashboard?.shares?.length > 0 ? (
              <p>{dashboard?.shares?.length}</p>
            ) : null}
            <div>
              <Share />
            </div>
            <span data-cy={`${dashboard.name}ShareButton`}>Share</span>
          </div>
        ) : null}
        {!fromLeaderboard && (
          <div onClick={e => handleCloneClick(e)}>
            <div>
              <Clone />
            </div>
            <span data-cy={`${dashboard.name}CloneButton`}>Clone</span>
          </div>
        )}
        {!fromLeaderboard && !dashboard.readOnly && (
          <div onClick={e => handleReportClick(e)}>
            {dashboard?.reports?.length > 0 ? (
              <p>{dashboard?.reports?.length}</p>
            ) : null}
            <div>
              <Bar />
            </div>
            <span data-cy={`${dashboard.name}ReportButton`}>Report</span>
          </div>
        )}
      </div>
      <div className={bStyles(bottomStyles, styles.bottom)}>
        {!fromLeaderboard ? (
          <Favorite
            className={bStyles('favorite', {
              isFavorite: dashboard.isFavorite
            })}
            onClick={e => handleToggleFavorite(e)}
          />
        ) : (
          <div className={styles.favorite}></div>
        )}
        {dashboard.sharedBy ? (
          <div className={styles.sharedInfo}>{`Accessible ${
            dashboard.endDate
              ? 'until ' + moment(dashboard.endDate).format('MM/DD/YYYY')
              : 'Indefinitely'
          }`}</div>
        ) : null}
        {!dashboard.readOnly && !dashboard.isDefaultDashboard ? (
          <Trash
            className={styles.trash}
            data-cy={`${dashboard.name}TrashButton`}
            onClick={e => handleDeleteClick(e)}
          />
        ) : (
          <div className={styles.trash}></div>
        )}
      </div>
    </div>
  );
}
