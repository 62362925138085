import React from 'react';

export default ({ width, height, x, y, ...props }) => (
  <svg
    width={width || '32px'}
    height={height || '32px'}
    x={x || '0px'}
    y={y || '0px'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.28 21.76L23.04 3.05176e-05C23.04 3.05176e-05 26.88 -8.54492e-05 29.44 2.56019C32 5.12046 32 8.96003 32 8.96003L10.24 30.72L0 32L1.28 21.76Z"
      fill="#CCE6E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29254 23.8676C4.1295 23.7046 3.89137 23.6419 3.66916 23.7034C3.44695 23.765 3.27503 23.9413 3.21911 24.165L1.93911 29.285C1.88458 29.5031 1.94848 29.7338 2.10745 29.8927C2.26641 30.0517 2.49712 30.1156 2.71522 30.0611L7.83522 28.7811C8.05891 28.7252 8.23523 28.5532 8.29677 28.331C8.35832 28.1088 8.29558 27.8707 8.13254 27.7076L4.29254 23.8676Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0526 7.22764C26.3025 7.47758 26.3025 7.8828 26.0526 8.13274L10.6926 23.4927C10.4426 23.7427 10.0374 23.7427 9.78746 23.4927C9.53752 23.2428 9.53752 22.8376 9.78746 22.5876L25.1475 7.22764C25.3974 6.97771 25.8026 6.97771 26.0526 7.22764Z"
      fill="white"
    />
  </svg>
);
