import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import history from '../../../history';
import {
  fetchSafetyAuditRepeatableById,
  fetchSafetyAuditScanOnDemandById,
  createSafetyAudit,
  createSafetyAuditRepeatable,
  updateSafetyAuditRepeatable,
  deleteSafetyAuditRepeatable,
  createSafetyAuditScanOnDemand,
  updateSafetyAuditScanOnDemand,
  deleteSafetyAuditScanOnDemand,
  fetchSafetyAuditTemplates
} from '../../../api/v4';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../../selectors/company';
import { isFutureDate, notFutureDate } from '../../../utils/formValidation';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import DayOfWeekButtons from '../../../components/inputs/DayOfWeekButtons';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import DueBy from '../../../components/inputs/DueBy';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import HierarchySelector from '../../../components/HierarchySelector';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  Textbox,
  Checkbox,
  Dropdown,
  EmployeeDropdown
} from '../../../components/inputs';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function SafetyAuditCreate() {
  const company = useSelector(getActiveCompany);
  const group = useSelector(getActiveLocationId);
  const project = useSelector(getActiveProject);
  const [audit, setAudit] = useState({});
  const [templates, setTemplates] = useState([]);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupDeleted, setGroupDeleted] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const { safetyAuditId, type } = useParams();
  const location = useLocation();
  const auditType = location.pathname.includes('safety')
    ? 'safety'
    : location.pathname.includes('quality')
    ? 'quality'
    : 'loto';
  const auditTypeName =
    auditType === 'loto'
      ? 'Lockout/Tagout Procedure'
      : `${auditType.charAt(0).toUpperCase() + auditType.slice(1)} Audit`;

  const fromEquipment = location?.state?.audit?.ownerType === 'equipment';

  const equipmentGroupDropdown = location?.state?.equipmentGroupDropdown;

  const equipmentProjectDropdown = location?.state?.equipmentProjectDropdown;

  useEffect(() => {
    const passedState = history.location.state;
    if (safetyAuditId && type) {
      type === 'repeatable'
        ? fetchSafetyAuditRepeatableById(safetyAuditId).then(response => {
            setGroupDeleted(
              !company.groups.find(g => g._id === response.groupId)
            );
            setAudit({ ...response, schedule: 'repeating' });
          })
        : fetchSafetyAuditScanOnDemandById(safetyAuditId).then(response =>
            setAudit({ ...response, schedule: 'scan' })
          );
      setIsEditing(true);
    } else {
      setAudit(
        passedState
          ? { ...passedState.audit }
          : {
              groupId: group?._id,
              projectId: project?._id
            }
      );
      setIsCreating(true);
    }

    fetchSafetyAuditTemplates(auditType).then(response => {
      response = response.filter(template => !template.isArchived);
      response = passedState
        ? response.filter(r =>
            passedState?.linkedAuditTemplates.includes(r._id)
          )
        : response;
      setTemplates(response);
      setTemplateDropdown(
        response.map(template => {
          return { label: template.title, value: template._id };
        })
      );
    });
  }, [fromEquipment, group, project, safetyAuditId, auditType, type, company]);

  const handleTemplateSelection = value => {
    const template = { ...templates.find(temp => temp._id === value) };
    delete template._id;
    delete template.auditLog;
    template.sections.forEach(s => {
      delete s._id;
      s.questions.forEach(q => {
        delete q._id;
      });
    });
    setAudit({
      ...audit,
      ...template,
      template: value,
      type: audit._id ? `${template.type}Audits` : template.type
    });
    setHasChanges(true);
  };

  const handleBack = () =>
    fromEquipment
      ? history.push(`/app/equipmentContainer/${audit.ownerId}`)
      : history.push(`/app/audits/${auditType}`);

  const submit = () => {
    if (audit.repeatingOn) {
      audit.repeatingOn = Array.isArray(audit?.repeatingOn)
        ? audit?.repeatingOn
        : [audit?.repeatingOn];
    }

    audit.companyId = company._id;
    audit.projectId = audit.projectId ? audit.projectId : null;
    if (audit?._id) {
      if (audit.schedule === 'repeating') {
        updateSafetyAuditRepeatable(audit).then(handleBack);
      } else {
        updateSafetyAuditScanOnDemand(audit).then(handleBack);
      }
    } else {
      if (audit.schedule === 'immediate') {
        createSafetyAudit(audit).then(handleBack);
      } else if (audit.schedule === 'repeating') {
        createSafetyAuditRepeatable({
          ...audit,
          type: `${auditType}Audits`
        }).then(handleBack);
      } else {
        delete audit.groupId;
        delete audit.projectId;
        createSafetyAuditScanOnDemand({
          ...audit,
          type: `${auditType}Audits`
        }).then(handleBack);
      }
    }
  };

  const canSubmit =
    (audit?.groupId || audit.schedule === 'scan') &&
    ((audit?.schedule === 'immediate' &&
      audit?.dueDate &&
      notFutureDate(audit?.dueDate) &&
      audit?.assignedTo?.length > 0 &&
      audit?.sections?.length > 0) ||
      (audit?.schedule === 'repeating' &&
        audit?.frequency &&
        audit?.dueBy &&
        audit?.dueByTime &&
        (audit?.frequency === 'quarterly' ||
          audit?.frequency === 'daily' ||
          audit?.repeatingOn?.length > 0) &&
        audit?.assignedTo?.length > 0 &&
        audit?.sections?.length > 0 &&
        audit?.template) ||
      (audit?.schedule === 'scan' &&
        (audit?.sections?.title || audit?.sections?.length > 0)));

  const handleDelete = () => {
    if (audit.schedule === 'repeating') {
      deleteSafetyAuditRepeatable(audit).then(handleBack);
    } else {
      deleteSafetyAuditScanOnDemand(audit).then(handleBack);
    }
  };

  if (isEditing) {
    const template = templates?.find(t => t.title === audit.title);
    audit.template = template?._id;
  }

  const header = (
    <Header
      title={`${auditTypeName}`}
      section={
        isCreating
          ? `Create New ${auditType === 'loto' ? 'Procedure' : 'Audit'}`
          : `Edit ${auditType === 'loto' ? 'Procedure' : 'Audit'}`
      }
      clickBack={() =>
        hasChanges ? setShowSaveChangesModal(true) : handleBack()
      }
      rightButtons={{
        text: `Delete ${auditTypeName}`,
        onClick: () => setShowDeleteModal(true),
        color: 'red',
        visible: isEditing
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={isEditing}
      cancelButtonClick={() =>
        isEditing && hasChanges
          ? setShowSaveChangesModal(true)
          : isEditing && !hasChanges
          ? history.push(`/app/audits/${auditType}`)
          : setShowSaveChangesModal(true)
      }
      saveButtonClick={() => (canSubmit ? submit() : setMissingModalOpen(true))}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const template = templates.find(r => r.title === audit.title);

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={isCreating || isEditing}
    >
      <Card>
        <RadioButtons
          fieldLabel="Schedule"
          options={[
            { value: 'immediate', label: 'Immediately' },
            { value: 'repeating', label: 'Repeating' }
          ].concat(
            !fromEquipment ? [{ value: 'scan', label: 'Scan on Demand' }] : []
          )}
          isRequired
          onChange={values =>
            setAudit({
              ...audit,
              schedule: values,
              frequency: '',
              groupId: values === 'scan' ? null : audit.groupId,
              projectId: values === 'scan' ? null : audit.projectId
            })
          }
          currentValue={audit?.schedule}
          touched={missingRequired && !audit?.schedule}
          disabled={!isCreating}
        />
        {audit.schedule !== 'scan' ? (
          <HierarchySelector
            onGroupChange={values => {
              let newProject = values === audit.groupId ? audit.projectId : '';
              setAudit({ ...audit, groupId: values, projectId: newProject });
            }}
            groupId={audit?.groupId}
            groupDisabled={
              !groupDeleted &&
              (!isCreating ||
                (fromEquipment && equipmentGroupDropdown?.length === 1))
            }
            onProjectChange={values =>
              setAudit({ ...audit, projectId: values })
            }
            projectId={audit?.projectId}
            projectDisabled={
              !groupDeleted &&
              (!audit?.groupId ||
                !isCreating ||
                (fromEquipment &&
                  equipmentProjectDropdown?.length < 2 &&
                  equipmentGroupDropdown?.length < 2))
            }
            filteredGroupDropdown={equipmentGroupDropdown}
            filteredProjectDropdown={equipmentProjectDropdown?.filter(
              p => p.groupId === audit.groupId?.toString()
            )}
            showFilteredMessage={fromEquipment}
            touched={missingRequired && !audit?.groupId}
          />
        ) : (
          <></>
        )}

        <Dropdown
          options={templateDropdown}
          fieldLabel={`${auditTypeName} Template`}
          currentValue={audit?.template || template}
          isRequired
          onChange={value => handleTemplateSelection(value)}
          placeholder={`Select a ${auditTypeName}`}
          touched={missingRequired && !audit?.template}
        />
        <Textbox
          currentValue={audit?.jobLocation}
          fieldLabel="Specific Location"
          onChange={e => setAudit({ ...audit, jobLocation: e })}
          type="textarea"
          placeholder={`Briefly describe where this ${auditTypeName} should be done.`}
        />
        {audit?.schedule === 'repeating' && (
          <Dropdown
            options={FREQUENCY_OPTIONS}
            fieldLabel="Frequency:"
            currentValue={audit?.frequency}
            onChange={value =>
              setAudit({ ...audit, frequency: value, repeatingOn: undefined })
            }
            isRequired
            touched={missingRequired && !audit?.frequency}
          />
        )}
        {audit?.frequency === 'weekly' && (
          <DayOfWeekButtons
            fieldLabel="Assign Every:"
            disabled={audit?.schedule !== 'repeating'}
            onChange={values => setAudit({ ...audit, repeatingOn: values })}
            currentValue={audit?.repeatingOn}
            touched={missingRequired && !audit?.repeatingOn}
          />
        )}
        {audit?.frequency === 'monthly' && (
          <Dropdown
            options={MONTHLY_FREQUENCY_OPTIONS}
            fieldLabel="Assign Every:"
            currentValue={audit?.repeatingOn}
            onChange={values => setAudit({ ...audit, repeatingOn: values })}
            isRequired
            touched={missingRequired && !audit?.repeatingOn}
          />
        )}
        {audit?.frequency === 'yearly' && (
          <Dropdown
            options={YEARLY_FREQUENCY_OPTIONS}
            fieldLabel="Assign Every:"
            currentValue={audit?.repeatingOn}
            onChange={values => setAudit({ ...audit, repeatingOn: values })}
            isRequired
            touched={missingRequired && !audit?.repeatingOn}
          />
        )}
        {audit?.schedule === 'repeating' ? (
          <DueBy
            fieldLabel="Due By:"
            isRequired
            dueBy={audit?.dueBy}
            dueTime={audit?.dueByTime}
            onDueByChange={v => setAudit({ ...audit, dueBy: v })}
            onDueTimeChange={v => setAudit({ ...audit, dueByTime: v })}
            touched={missingRequired}
          />
        ) : audit?.schedule === 'immediate' ? (
          <DatePicker
            fieldLabel="Due By:"
            isRequired
            onChange={value => setAudit({ ...audit, dueDate: value })}
            pickTime
            currentValue={audit?.dueDate}
            name="createAudit"
            touched={isFutureDate(audit?.dueDate) && missingRequired}
            errorMessage={audit?.dueDate ? isFutureDate(audit?.dueDate) : null}
            minDate={moment()}
          />
        ) : (
          ''
        )}
        {audit?.schedule !== 'scan' && (
          <EmployeeDropdown
            multi
            selectButtons
            permissions={[100, 400, 500, 900]}
            fieldLabel="Assignee(s):"
            currentValue={audit?.assignedTo}
            isRequired
            onChange={values => setAudit({ ...audit, assignedTo: values })}
            searchable
            name="multiEmployees"
            group={audit.groupId}
            touched={missingRequired && !audit?.assignedTo}
          />
        )}
        {audit?.schedule !== 'scan' && (
          <Checkbox
            fieldLabel="Assign Individually"
            name="isIndividual"
            onChange={value => setAudit({ ...audit, isIndividual: value })}
            currentValue={audit?.isIndividual}
          />
        )}
        {audit?.schedule === 'repeating' && !isCreating && (
          <Checkbox
            fieldLabel={`Pause ${auditTypeName}`}
            name="pauseAudit"
            onChange={value => setAudit({ ...audit, isPaused: value })}
            currentValue={audit?.isPaused}
          />
        )}
      </Card>
      <SaveChangesModal
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        savingWhat={`${auditTypeName}`}
        submitActions={() => history.goBack()}
      />
      <DeleteItemModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        deletingWhat={`${auditTypeName}`}
        submitActions={handleDelete}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject={auditTypeName}
      />
    </HeaderAndFooter>
  );
}
