import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export class MarkAsCompleteModal extends Component {
  render() {
    return (
      <Modal
        title="Mark as Complete"
        titleClassName="greenHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="green"
        submitButtonText="Complete Report"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
        cancelButtonText="Later"
        cancelActions={this.props.cancelActions}
      >
        <div className={styles.text}>
          All fields within this report have been filled out. If there is
          nothing else to be added, you may mark this report as complete.{' '}
          <span className={styles.warningText}>
            Marking a report as complete will also mark any task associated with
            it as complete, even if you are not the assignee of that task.
            Marking as complete will also lock this report, preventing any
            further editing unless unlocked by the incident owner.
          </span>
        </div>
      </Modal>
    );
  }
}

export default MarkAsCompleteModal;
