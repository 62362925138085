import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1357_142370)">
      <g clipPath="url(#clip1_1357_142370)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0174 70.6107C24.0174 69.0804 25.2583 67.8369 26.7861 67.8369C28.3177 67.8369 29.5613 69.0804 29.5613 70.6107C29.5613 72.7305 31.2844 74.4543 33.4055 74.4543C35.524 74.4543 37.2466 72.7305 37.2492 70.6107V37.019C37.2492 35.9722 38.4921 35.1253 40.021 35.1253C41.552 35.1253 42.7949 35.9722 42.7949 37.019V70.6107C42.7949 75.7942 38.5873 80 33.4055 80C28.2214 80 24.0175 75.7942 24.0175 70.6107H24.0174ZM26.2209 37.1989C23.5646 34.4226 19.5511 32.654 15.0669 32.654C10.5866 32.654 6.58142 34.4136 3.92695 37.1804C2.88717 38.2617 0.844597 37.5962 0.889397 36.1949C1.45463 18.7753 17.2953 4.64214 37.2459 3.43549L37.2484 3.25027C37.2484 1.45616 38.4913 0 40.0203 0C41.5512 0 42.7941 1.45619 42.7941 3.25027V3.43549C62.7431 4.64132 78.5866 18.7753 79.1523 36.1949C79.1983 37.5962 77.1544 38.2618 76.1173 37.1804C73.4604 34.4136 69.4552 32.654 64.9774 32.654C60.4907 32.654 56.4785 34.42 53.8221 37.1989C53.1374 37.9142 51.862 37.9155 51.1773 37.1989C48.5203 34.42 44.5075 32.654 40.0202 32.654C35.5335 32.654 31.5218 34.42 28.8662 37.1989C28.1802 37.9142 26.9048 37.9155 26.22 37.1989H26.2209Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1357_142370">
        <rect width="80" height="80" />
      </clipPath>
      <clipPath id="clip1_1357_142370">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
