import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import history from '../../history';

import {
  addCompanyLocationRequest,
  deleteCompanyLocationRequest,
  setActiveLocation,
  setActiveProject,
  updateCompanyLocationRequest
} from '../../actions/company';
import { getActiveCompany, getIsPerrp } from '../../selectors/company';

import GroupModal from '../Modal/groupModal';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HideLocationModal from '../Modal/hideLocationModal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import ViewEditList from '../ViewEditList';

export const LocationList = () => {
  const dispatch = useDispatch();

  const company = useSelector(getActiveCompany);
  const isPerrp = useSelector(getIsPerrp);
  const setActiveGroup = locationId => dispatch(setActiveLocation(locationId));
  const setActiveArea = value => dispatch(setActiveProject(value));

  const [openModal, setModal] = useState();
  const [group, setGroup] = useState();

  const handleCloseModal = () => {
    setGroup();
    setModal();
  };

  const submitGroup = values => {
    values?._id
      ? dispatch(updateCompanyLocationRequest(values))
      : dispatch(addCompanyLocationRequest(values));

    handleCloseModal();
  };

  const hideGroup = () => {
    dispatch(
      updateCompanyLocationRequest({
        ...group,
        isHidden: !group.isHidden,
        setActiveGroup: false
      })
    );
    handleCloseModal();
  };

  const deleteLocation = group => {
    dispatch(deleteCompanyLocationRequest({ id: group._id }));
    handleCloseModal();
  };

  const header = (
    <Header
      title="Groups or Establishments"
      rightButtons={{
        text: 'New Group or Establishment',
        color: 'green',
        onClick: () => setModal('editModal')
      }}
    />
  );

  const groups = company.groups;

  return (
    <>
      <HeaderAndFooter Header={header}>
        <ScrollToTopOnMount />
        <ViewEditList
          data={groups}
          onView={group => {
            setActiveGroup(group);
            setActiveArea();
            history.push('/app/dashboard');
          }}
          onEdit={group => {
            setGroup(group);
            setModal('editModal');
          }}
          onDelete={deleteLocation}
          onHide={group => {
            setGroup(group);
            setModal('hideModal');
          }}
        />
      </HeaderAndFooter>
      <HideLocationModal
        isOpen={openModal === 'hideModal'}
        onRequestClose={handleCloseModal}
        submitActions={hideGroup}
        location={group}
        locationType="group"
      />
      <GroupModal
        isOpen={openModal === 'editModal'}
        onRequestClose={handleCloseModal}
        submitActions={submitGroup}
        isEditing={group?._id}
        currentGroup={group}
        isPerrp={isPerrp}
      />
    </>
  );
};

export default LocationList;
