import axios from 'axios';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import config from '../config/config';
import history from '../history';
import store from '../store';
import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';
import { MOBILE_MIN_VERSION } from '../constants/constants';

export function setTokenHeader() {
  return sessionStorage.getItem('token');
}

export function getQueryString(options = {}) {
  return Object.keys(options)
    .reduce((acc, cur) => {
      const name = encodeURIComponent(cur);
      let val = options[cur];
      if (val !== undefined) {
        if (Array.isArray(val)) {
          val.forEach(v => acc.push(`${name}[]=${encodeURIComponent(v)}`));
        } else {
          acc.push(`${name}=${encodeURIComponent(val)}`);
        }
      }
      return acc;
    }, [])
    .join('&');
}

axios.default.withCredentials = true;

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json'
};

const v2Api = axios.create({
  baseURL: `${config.apiUrl}/v2`
});

const companyApi = axios.create({
  baseURL: `${config.apiUrl}/v2/companies`
});

const companyApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v2/companies`
});

const requestConfigNoLoading = config => {
  if (!config.headers.Authorization) {
    const token = setTokenHeader();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  const userAgent = window.navigator.userAgent;

  if ((!isDesktop && !isMobileOnly) || userAgent.includes('CrOS')) {
    config.headers['ireport-mobile-version'] = MOBILE_MIN_VERSION;
  }

  return config;
};

const requestConfig = config => {
  store.dispatch({ type: START_LOADING });

  return requestConfigNoLoading(config);
};

const responseStop = response => {
  store.dispatch({ type: STOP_LOADING });

  if (
    response.data ===
    'This app needs to be updated to a newer version in order to use'
  ) {
    // eslint-disable-next-line no-throw-literal
    throw 'iReportSource web app does not work on a mobile device. Please download our mobile app!';
  }

  return response.data;
};

const errorHandlerNoLoading = error => {
  if (!error.response) throw error;

  if (error.response.status === 500 || error.response.status === 418) {
    if (process.env.NODE_ENV === 'development') throw error.response.data;
    else history.push('/error');
  }

  if (error.response.status === 403) {
    history.push('/login');
  }

  throw error.response.data;
};

const errorHandler = error => {
  store.dispatch({ type: STOP_LOADING });

  return errorHandlerNoLoading(error);
};

v2Api.interceptors.request.use(requestConfig, error => Promise.reject(error));
v2Api.interceptors.response.use(responseStop, errorHandler);

const addCompanyId = config => {
  let state = store.getState();
  let companyId =
    state.company?.activeCompany?._id || state.company?.list?.[0]?._id;

  config.url = `/${companyId || '1234'}${config.url}`;
  return requestConfig(config);
};

companyApi.interceptors.request.use(addCompanyId, error =>
  Promise.reject(error)
);
companyApi.interceptors.response.use(responseStop, errorHandler);

companyApiNoLoading.interceptors.request.use(
  config => {
    let state = store.getState();
    let companyId =
      state.company?.activeCompany?._id || state.company?.list?.[0]?._id;

    config.url = `/${companyId}${config.url}`;
    return requestConfigNoLoading(config);
  },
  error => Promise.reject(error)
);
companyApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

const v2ApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v2`
});

v2ApiNoLoading.interceptors.request.use(requestConfigNoLoading, error =>
  Promise.reject(error)
);
v2ApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

export const createOshaLog = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/oshaQuestions/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateOshaLog = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/oshaQuestions/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });
