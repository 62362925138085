export function inflateChartSettings(
  settings,
  period,
  isDetail = false,
  chartId = null
) {
  const { datasource, category, filterArgs, subcategory, showTrend } = settings;
  let ret = {
    datasource,
    category,
    filterArgs,
    subCategory: subcategory,
    mode: period?.mode,
    period: { number: period?.number, unit: period?.unit },
    startDate: period?.startDate,
    endDate: period?.endDate,
    trend: showTrend
  };
  if (!isDetail && settings.type === 'bar') ret.series = settings.series;

  if (datasource === 'Osha Compliance') {
    if (subcategory?.includes('Company')) {
      ret.category = 'getCompanyName';
    } else if (subcategory?.includes('Group')) {
      ret.category = 'getGroupName';
    }
  }

  if ((settings.type === 'line' || settings.type === 'line-trend') && !isDetail)
    ret.frequency = period?.frequency || 'monthly';

  if (
    !settings.type.includes('bar') &&
    (category.includes('getTimeToClosure') ||
      category.includes('getReportingLagTime'))
  ) {
    ret.category = `${category}Number`;
  }
  return {
    ...ret,
    chartId
  };
}
