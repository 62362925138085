import React, { useState } from 'react';
import { Button, TwoColumn } from '../inputs';
import FileDownloader from '../FileDownloader';
import Modal from '../Modal';
import docImage from '../../assets/images/Document.png';
import { isImage, isFileType } from '../../utils/attachmentSelector';
import {
  ANNOUNCEMENT_FILE_TYPES_ACCEPTED,
  EQUIPMENT_FILE_TYPES_ACCEPTED,
  TASK_FILE_TYPES_ACCEPTED
} from '../../constants/constants';
import styles from './index.module.scss';

export const Documents = ({
  isCreating,
  isEditing,
  isDuping,
  fromEquipment,
  canEditAll,
  updateDocumentsToUpload, // docs during creation
  documentsToUpload,
  uploadedDocuments,
  ownerType,
  addAttachments,
  deleteAttachment,
  removeDocumentsToUpload,
  loggedInUser,
  formattedDocumentsToUpload,
  disabled
}) => {
  const allDocuments = [...documentsToUpload, ...uploadedDocuments];

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [formattedSelectedDocuments, setFormattedSelectedDocuments] = useState(
    []
  );
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);

  const acceptableFileTypes =
    ownerType === 'equipment'
      ? EQUIPMENT_FILE_TYPES_ACCEPTED
      : ownerType === 'announcement'
      ? ANNOUNCEMENT_FILE_TYPES_ACCEPTED
      : TASK_FILE_TYPES_ACCEPTED;

  const handleRemoveNewMaterial = doc => {
    let fileToRemove = formattedSelectedDocuments.find(
      a => a?.name === doc?.name
    );
    let formattedFileToRemove = new File([fileToRemove], fileToRemove.name);
    // we use size and name since when format it the type and last modified are not the same
    // thought using both name and size would hopefully make sure the right one is being removed
    let formattedUpdated = formattedSelectedDocuments.filter(
      f =>
        f.size !== formattedFileToRemove?.size &&
        f.name !== formattedFileToRemove.name
    );
    setFormattedSelectedDocuments(formattedUpdated);
    let updated = selectedDocuments.filter(p => p !== doc);
    setSelectedDocuments(updated);
  };

  // deleting a picture during the creation that hasnt been uploaded to db yet
  const deletetDocumentToUpload = doc => {
    let updated = documentsToUpload.filter(p => p !== doc);
    let formattedUpdated = formattedDocumentsToUpload.filter(
      f => f.name !== doc.name
    );
    if (isDuping) deleteAttachment(doc);

    removeDocumentsToUpload(updated, formattedUpdated);
  };

  const checkFormatOfDocuments = e => {
    let files = e.target.files;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      if (isImage(files[i]) || !isFileType(files[i], acceptableFileTypes)) {
        let filesAccepted = acceptableFileTypes.join(', ');
        alert(`File must be one of ${filesAccepted} types`);
        return;
      }
    }
    formatFiles(e);
  };

  const formatFiles = e => {
    const files = e.target.files;
    if (!files.length) return;
    let data = [];
    let filesToUpload = [];

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      } else {
        const formatted = {
          name: files[i].name
        };

        data.push(formatted);
        filesToUpload.push(files[i]);
      }
    }
    setFormattedSelectedDocuments(filesToUpload);
    setSelectedDocuments(data);
    setOpenAddDocumentModal(true);
  };

  const handleDocumentsToUpload = () => {
    updateDocumentsToUpload(selectedDocuments, formattedSelectedDocuments);
    setOpenAddDocumentModal(false);
  };

  const handleAddDocuments = () => {
    setOpenAddDocumentModal(false);
    addAttachments(formattedSelectedDocuments);
  };
  return (
    <div>
      <Button
        type="button"
        testID="saveDoc"
        text={fromEquipment ? 'Add Video(s)' : 'Add Document'}
        color="blue"
        image={
          <img src={docImage} className={styles.buttonImage} alt="Button" />
        }
        onClick={() => document.getElementById(`myDoc`).click()}
        disabled={(!isEditing && !isCreating) || disabled}
      />
      <input
        type="file"
        id="myDoc"
        className={styles.fileUpload}
        onChange={e => {
          checkFormatOfDocuments(e);
          e.target.value = '';
        }}
        multiple
        accept={
          fromEquipment
            ? EQUIPMENT_FILE_TYPES_ACCEPTED.join(', ')
            : TASK_FILE_TYPES_ACCEPTED.join(', ')
        }
      />
      {allDocuments?.length < 1 ? (
        <span className={styles.attachmentText}>{`No ${
          fromEquipment ? 'Videos' : 'Documents'
        } Added`}</span>
      ) : (
        <div className={styles.docList}>
          {allDocuments?.map((doc, index) => {
            return (
              <FileDownloader
                attachment={doc}
                attachmentIndex={index}
                deleteAttachment={attachment =>
                  isCreating
                    ? deletetDocumentToUpload(attachment)
                    : deleteAttachment(attachment)
                }
                disabled={
                  (!isEditing && !isCreating) ||
                  (isEditing &&
                    loggedInUser._id !== doc.createdByUserId &&
                    !canEditAll)
                }
                isInList
                key={index}
                fromTasks
                ownerType={ownerType}
              />
            );
          })}
        </div>
      )}

      <Modal
        title={fromEquipment ? 'Add Videos(s)' : 'Add Document(s)'}
        titleClassName="blueHeader"
        isOpen={openAddDocumentModal}
        submitButtonColor="blue"
        submitButtonText="Save"
        cancelButtonText="Cancel"
        onRequestClose={() => {
          setFormattedSelectedDocuments([]);
          setSelectedDocuments([]);
          setOpenAddDocumentModal(false);
        }}
        submitActions={() =>
          isCreating ? handleDocumentsToUpload() : handleAddDocuments()
        }
        disableSubmit={selectedDocuments?.length === 0}
      >
        <TwoColumn>
          {selectedDocuments?.map((doc, index) => (
            <FileDownloader
              attachment={doc}
              attachmentIndex={index}
              deleteAttachment={doc => handleRemoveNewMaterial(doc)}
              key={index}
              fromTasks
              isInList
            />
          ))}
        </TwoColumn>
        <div className={styles.text}>
          Are you sure you would like to upload these documents
        </div>
      </Modal>
    </div>
  );
};

export default Documents;
