import React from 'react';
import classnames from 'classnames';

import { Input, nameInput } from '..';

import styles from './checkbox.module.scss';

const Checkbox = ({
  name,
  disabled,
  onChange,
  className,
  children,
  currentValue,
  tooltip,
  testID,
  checkboxStyle,
  image,
  hasBox,
  containerClasses,
  touched,
  smallerMargin,
  showNotSelected,
  ...props
}) => (
  <div
    className={classnames([
      hasBox ? styles.containerBox : '',
      containerClasses
    ])}
  >
    {image ? (
      <img
        className={styles.image}
        src={image}
        alt={image}
        onClick={() => document.getElementById(name).click()}
      />
    ) : null}
    <Input
      {...props}
      tooltip={tooltip || children}
      name={name}
      className={classnames([
        className,
        styles.input,
        disabled && styles.disabled,
        touched ? styles.labelInvalid : ''
      ])}
    >
      <div
        className={classnames([
          styles.container,
          smallerMargin && styles.smallerMargin
        ])}
      >
        <input
          type="checkbox"
          id={name}
          checked={currentValue}
          onChange={e => onChange && onChange(e.target.checked)}
          disabled={disabled}
          data-cy={testID ?? props.fieldLabel}
        />
        <div
          className={
            checkboxStyle ??
            classnames([styles.checkbox, touched ? styles.invalid : ''])
          }
        >
          {currentValue ? (
            <img
              src={require('../../../assets/images/Check.png')}
              alt="checked"
            />
          ) : showNotSelected ? (
            <div className={styles.notSelected} />
          ) : (
            '\u00A0'
          )}
        </div>
      </div>
    </Input>
  </div>
);

const nCheckbox = nameInput(Checkbox);
export { nCheckbox as Checkbox };
export default nCheckbox;
