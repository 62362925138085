import React, { Component } from 'react';
import moment from 'moment';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';

import './index.css';

export default class CommentCard extends Component {
  state = {
    showForSure: false
  };

  handleRemoveClick = () => {
    const showForSure = this.state.showForSure;

    if (showForSure) {
      this.setState({ showForSure: false });
      this.props.removeComment();
    } else {
      this.setState({ showForSure: true });
    }
  };

  render() {
    const { comment, setActiveComment } = this.props;
    const { showForSure } = this.state;
    const date = moment(comment?.createdAt);

    return (
      <div className="reportComment">
        <div className="reportCommentCard">
          <div className="reportCommentCard-createdDate">
            <div>{date.format('hh:mm a')}</div>
            <div>{date.format('MM/DD/YYYY')}</div>
          </div>
          <div
            className={
              comment.isRemoved
                ? 'reportCommentCard-comment reportCommentCard-commentRemoved'
                : 'reportCommentCard-comment'
            }
          >
            {comment.isRemoved && (
              <div
                className={
                  comment.isRemovedByOwner
                    ? 'reportComment-removedByOwner'
                    : 'reportComment-removed'
                }
              >
                {comment.isRemovedByOwner ? 'Removed By Owner' : 'Removed'}
              </div>
            )}
            <div className="reportCommentCard-commentBox">{comment.value}</div>
          </div>
          <UserBadge
            showImage
            showName
            userId={comment.createdByUserId}
            className="reportCommentCard-badge"
          />
        </div>
        <div className="reportCommentCard-buttons">
          <Button
            onClick={() =>
              setActiveComment({
                value: comment.value,
                date,
                userId: comment.createdByUserId
              })
            }
            type="button"
            text="View"
            color="white"
          />
          <Button
            onClick={() => this.handleRemoveClick()}
            type="button"
            text={showForSure ? 'For Sure?' : 'Remove'}
            color="red"
          />
        </div>
      </div>
    );
  }
}
