import { createSelector } from 'reselect';

const documentsSelector = state => state.documents;
export const getDocumentLoadingSelector = createSelector(
  documentsSelector,
  document => document && document.loading
);

export const getDocumentFoldersSelector = createSelector(
  documentsSelector,
  document => document && document.documentFolders
);

export const getActiveFolderSelector = createSelector(
  documentsSelector,
  document => document && document.activeDocumentFolder
);
