import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import {
  createAnalyticsChart,
  deleteAnalyticsChart,
  getAnalyticsDashboard,
  updateAnalyticsDashboard,
  customizeHomepage,
  fetchCustomHomepageCards
} from '../../../api/v4';
import history from '../../../history';
import { useDragDrop } from '../../../utils/hooks';
import { getActiveCompany } from '../../../selectors/company';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import GearMenu from '../../../components/GearMenu';
import AnalyticsChart from '../../../components/AnalyticsChart';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import AnalyticWarning from '../../../assets/images/Analytics_Warning.png';
import Modal from '../../../components/Modal';
import CheckboxGroup from '../../../components/inputs/CheckboxGroup';

import styles from './dashboard.module.scss';
const bStyles = classnames.bind(styles);

export const MyAnalyticsDashboard = props => {
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [savedPeriod, setSavedPeriod] = useState({});
  const [dashboard, setDashboard] = useState(null);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [isDefaultDashboard, setIsDefaultDashboard] = useState(false);
  const [customHomepageCards, setCustomHomepageCards] = useState();
  const [openMaxHPCardsModal, setOpenMaxHPCardsModal] = useState(false);
  const [cardsToBeRemoved, setCardsToBeRemoved] = useState();
  const [cardToReplace, setCardToReplace] = useState();
  const { dashboardId } = useParams();
  const company = useSelector(getActiveCompany);

  const renderItem = chart => (
    <>
      <div className={styles.chartTitle}>
        {chart.title}
        <GearMenu
          gear
          options={[
            {
              label: 'Detailed View',
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/detail/${chart._id}`,
                  state: {
                    chart,
                    dashboardId: dashboardId,
                    period: currentPeriod
                  }
                }),
              hide: isDefaultDashboard || readOnly
            },
            {
              label: 'Edit',
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/card/${chart._id}`,
                  state: { chart }
                }),
              hide: isDefaultDashboard || readOnly
            },
            {
              label: 'Clone',
              onClick: () => {
                let newChart = { ...chart };
                delete newChart._id;
                newChart.title += ' - Clone';
                createAnalyticsChart(dashboardId, newChart).then(data => {
                  newChart._id = data.chartId;
                  setDashboard(current => ({
                    ...current,
                    charts: [...current.charts, newChart]
                  }));
                });
              },
              hide: isDefaultDashboard || readOnly
            },
            {
              label: `${
                customHomepageCards?.some(card => card._id === chart._id)
                  ? 'Remove from'
                  : 'Add to'
              } My Home Page`,
              onClick: () => handleCustomizeHPSelection(chart._id),
              color: 'green'
            },
            {
              label: 'Delete',
              color: 'red',
              onClick: () => setConfirmDeleteId(chart._id),
              hide: isDefaultDashboard || readOnly
            }
          ]}
        />
      </div>
      <AnalyticsChart
        fromDashboard
        settings={chart}
        period={currentPeriod}
        className={styles.chart}
        noDataClassName={styles.noData}
        onClick={() =>
          history.push({
            pathname: `/app/myAnalytics/detail/${chart._id}`,
            state: { dashboardId, period: currentPeriod, readOnly }
          })
        }
        shouldBeHigherThanStandards={chart?.shouldBeHigherThanStandards}
      />
    </>
  );

  const { render } = useDragDrop({
    renderItem,
    data: dashboard?.charts,
    disabled: readOnly,
    dropStyle: styles.dropTarget,
    dragStyle: styles.dragStyle,
    dropFunc: (srcIdx, targetIdx) => {
      let charts = dashboard.charts.map(c => c._id);
      const tmp = charts[srcIdx];
      charts[srcIdx] = charts[targetIdx];
      charts[targetIdx] = tmp;
      updateAnalyticsDashboard(dashboardId, { children: charts }).then(() => {
        setDashboard(current => {
          let newDashboard = { ...current };
          const t = newDashboard.charts[srcIdx];
          newDashboard.charts[srcIdx] = newDashboard.charts[targetIdx];
          newDashboard.charts[targetIdx] = t;
          return newDashboard;
        });
      });
    }
  });

  useEffect(() => {
    if (!dashboardId) return;

    getAnalyticsDashboard(dashboardId).then(dashboardData => {
      if (!company.hasLockoutTagout) {
        const filteredCharts = dashboardData.charts.filter(
          chart => chart.datasource !== 'Lockout Tagout Procedures'
        );
        setDashboard({ ...dashboardData, charts: filteredCharts });
      } else {
        setDashboard(dashboardData);
      }

      setCurrentPeriod({ ...dashboardData.period });
      setSavedPeriod({ ...dashboardData.period });
      setReadOnly(dashboardData.readOnly || dashboardData.isDefaultDashboard);
      setIsDefaultDashboard(dashboardData.isDefaultDashboard);
    });

    fetchCustomHomepageCards().then(cards => {
      if (cards) {
        setCustomHomepageCards(cards);
      }
    });
  }, [dashboardId, company.hasLockoutTagout]);

  const handleCustomizeHPSelection = cardId => {
    if (
      customHomepageCards.length >= 4 &&
      !customHomepageCards.some(card => card._id === cardId)
    ) {
      setCardToReplace(cardId);
      setOpenMaxHPCardsModal(true);
    } else {
      customizeHomepage(cardId).then(() =>
        fetchCustomHomepageCards().then(cards => {
          setCustomHomepageCards(cards);
        })
      );
    }
  };

  const onPeriodSave = v => {
    setDashboard(current => ({ ...current, period: v }));
    setCurrentPeriod(v);
    setSavedPeriod(v);
  };

  const renameDashboard = newName =>
    setDashboard(current => {
      let newDashboard = { ...current };
      newDashboard.name = newName;
      if (dashboardId) updateAnalyticsDashboard(dashboardId, newDashboard);
      return newDashboard;
    });

  const confirmDeleteChart = () => {
    deleteAnalyticsChart(confirmDeleteId).then(() => {
      setDashboard(current => {
        let newDashboard = { ...current };
        let idx = newDashboard.charts.findIndex(d => d._id === confirmDeleteId);
        newDashboard.charts.splice(idx, 1);
        return newDashboard;
      });
      fetchCustomHomepageCards().then(cards => {
        if (cards) setCustomHomepageCards(cards);
      });
      setConfirmDeleteId(null);
    });
  };

  const newCard = () => {
    history.push({
      pathname: '/app/myAnalytics/card',
      state: { dashboardId }
    });
  };
  const name = dashboard?.name ?? 'New Dashboard';
  const header = (
    <Header
      readOnly={readOnly || isDefaultDashboard}
      title={undefined}
      section={name}
      clickBack={history.goBack}
      editName={renameDashboard}
      rightButtons={[
        {
          text: 'Create Card',
          type: 'button',
          color: 'blue',
          onClick: newCard,
          visible: !readOnly && !isDefaultDashboard
        }
      ]}
    />
  );

  return (
    <>
      <HeaderAndFooter Header={header} className={styles.container}>
        <AnalyticsPeriodPicker
          className={styles.datePicker}
          period={currentPeriod}
          savedPeriod={savedPeriod}
          onChange={setCurrentPeriod}
          onSave={onPeriodSave}
          dashboardId={dashboardId}
          readOnly={readOnly}
          showFrequencyPicker
          showTrendPicker
        />
        {dashboard?.children?.length ? (
          <div className={bStyles('charts', { readOnly })}>{render()}</div>
        ) : (
          <div className={styles.noCardMessage}>
            <img src={AnalyticWarning} alt="analyticsWarning" />
            <div className={styles.noCard}> No Cards</div>
            <div className={styles.createMessage}>
              Create Cards to start reviewing data
            </div>
          </div>
        )}
      </HeaderAndFooter>
      <DeleteItemModal
        isOpen={confirmDeleteId !== null}
        onRequestClose={() => setConfirmDeleteId(null)}
        submitActions={confirmDeleteChart}
        deletingWhat="Chart"
      />
      <Modal
        isOpen={openMaxHPCardsModal}
        onRequestClose={() => {
          setOpenMaxHPCardsModal(false);
          setCardsToBeRemoved();
          setCardToReplace();
        }}
        submitActions={() => {
          customizeHomepage([...cardsToBeRemoved, cardToReplace]).then(() => {
            fetchCustomHomepageCards().then(setCustomHomepageCards);
            setOpenMaxHPCardsModal(false);
            setCardsToBeRemoved();
          });
        }}
        title="Home Page Card Limit Reached"
        titleClassName="blueHeader"
        submitButtonColor="blue"
        submitButtonText="Confirm"
        disableSubmit={!cardsToBeRemoved?.length}
      >
        <p>
          You are attempting to add more custom home page cards than the maximum
          number allowed (4). Would you like to remove any of your existing
          cards?
        </p>{' '}
        <p> Please check any of the following you would like to remove:</p>
        {customHomepageCards ? (
          <CheckboxGroup
            options={customHomepageCards.map(card => ({
              label: card.title,
              value: card._id
            }))}
            onChange={setCardsToBeRemoved}
            currentValue={cardsToBeRemoved}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default MyAnalyticsDashboard;
