import React from 'react';

import classnames from 'classnames/bind';
import svgMapper from '../../utils/svgMapper';

import styles from './pagination.module.scss';
const bStyles = classnames.bind(styles);

export const Pagination = ({ className, page, setPage, perPage, count }) => {
  const totalPages = Math.ceil((count ?? 0) / perPage) || 1;
  const lastPage = page >= totalPages - 1;
  return (
    <div className={classnames(className, styles.pagination)}>
      <div
        className={bStyles('leftChevron', { disabled: !page })}
        onClick={() => page && setPage(0)}
      >
        {svgMapper('doubleChevron')}
      </div>
      <div
        className={bStyles('leftChevron', { disabled: !page })}
        onClick={() => page && setPage(page - 1)}
      >
        {svgMapper('chevron')}
      </div>
      <div className={styles.pages}>
        Page {page + 1} of {totalPages}
      </div>
      <div
        className={bStyles('rightChevron', { disabled: lastPage })}
        onClick={() => !lastPage && setPage(page + 1)}
      >
        {svgMapper('chevron')}
      </div>
      <div
        className={bStyles('rightChevron', { disabled: lastPage })}
        onClick={() => !lastPage && setPage(totalPages - 1)}
      >
        {svgMapper('doubleChevron')}
      </div>
    </div>
  );
};

export default Pagination;
