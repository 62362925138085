import React from 'react';

import { InputRow, Button } from '../';

export const AuthorizeRejectButtons = ({
  answer,
  updateAnswer,
  className,
  disabled,
  afterApproving
}) =>
  disabled && !answer ? (
    <div className={className}>In Progress</div>
  ) : (
    <InputRow space className={className}>
      {answer === 'approve' ? (
        <Button text={afterApproving ? 'Approved' : 'Approve'} color="green" />
      ) : !disabled ? (
        <Button
          text={afterApproving ? 'Approved' : 'Approve'}
          onClick={() => updateAnswer('approve')}
          color="greenOutline"
        />
      ) : (
        <></>
      )}
      {answer === 'reject' ? (
        <Button text={afterApproving ? 'Rejected' : 'Reject'} color="red" />
      ) : !disabled ? (
        <Button
          text={afterApproving ? 'Rejected' : 'Reject'}
          onClick={() => updateAnswer('reject')}
          color="redOutline"
        />
      ) : (
        <></>
      )}
    </InputRow>
  );

export default AuthorizeRejectButtons;
