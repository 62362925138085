import React from 'react';
import classnames from 'classnames/bind';
import { Cell, Pie, PieChart } from 'recharts';
import DueDateText from '../DueDateText';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

export default function BigHeader(props) {
  const { title, instructions, pieData } = props.courseData;

  return (
    <header
      className={bStyles({
        header: true,
        noCenter: !props.center
      })}
    >
      {props.clickBack ? (
        <img
          src={require('../../assets/images/leftChevron.png')}
          className={classnames([
            styles.backButton,
            props.needsSaved ? styles.unsaved : ''
          ])}
          alt="back button"
          onClick={props.clickBack}
          data-cy="goBack"
        />
      ) : null}
      <div
        className={bStyles({
          headerInfo: true,
          center: true
        })}
      >
        <div className={styles.pieBox}>
          <PieChart width={160} height={160} className={styles.pie}>
            <Pie
              data={pieData}
              dataKey="number"
              outerRadius={80}
              innerRadius={38}
              fill="green"
            >
              {pieData.map((entry, index) => (
                <Cell fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className={styles.infoBox}>
          <p>Training Course</p>
          <p className={styles.title}>{title}</p>
          <DueDateText date={props.date} isComplete={props.isComplete} left />
          <p className={styles.instructions}>{instructions}</p>
        </div>
      </div>
    </header>
  );
}
