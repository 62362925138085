import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import history from '../../../history';
import {
  exportAnalytics,
  fetchAnalyticsChart,
  fetchColumnSettings
} from '../../../api/v4';
import { inflateChartSettings } from '../../../utils/analytics';
import AnalyticsChart from '../../../components/AnalyticsChart';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import List from '../../../components/List';

import styles from './styles.module.scss';

const extraData = [
  'Employee Injury',
  'Safety Audit',
  'Quality Audit',
  'Lockout Tagout Procedures',
  'Behavior Observations',
  'Vehicle Accident',
  'Near Miss',
  'Aircraft',
  'Misfuel Reports',
  'Property Damage',
  'Theft',
  'Third Party Incident',
  'Spill Report',
  'General Incidents',
  'Violence Harassment Report',
  'All Incidents'
];

const confidentialTypes = [
  'Employee Injury',
  'Behavior Observations',
  'Vehicle Accident',
  'Near Miss',
  'Aircraft',
  'Misfuel Reports',
  'Property Damage',
  'Theft',
  'Third Party Incident',
  'Spill Report',
  'Violence Harassment Report',
  'Behavior Observations',
  'Documents',
  'All Incidents'
];

export class MyAnalyticsDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined,
      currentPeriod: { ...this.props.location?.state?.period } || {},
      savedPeriod: this.props.location?.state?.period,
      openDisplayModal: false,
      types: [],
      readOnly: this.props.location?.state?.readOnly
    };
  }

  componentDidMount() {
    fetchAnalyticsChart(this.props.match?.params?.chartId).then(data => {
      fetchColumnSettings().then(r => {
        const settings = Object.values(r.user ?? {}).find(
          setting => setting.label === data.datasource
        )?.data;
        this.setState({
          chart: data,
          userSettings: !!settings?.length
        });
      });
    });
  }

  onPeriodChange = v => {
    this.setState({
      currentPeriod: v
    });
  };

  onPeriodSave = v => {
    this.setState({ savedPeriod: v, currentPeriod: v });
  };

  exportDetailsView = async (
    type = 'csv',
    extraData = false,
    custom = false
  ) => {
    const src = await exportAnalytics({
      ...inflateChartSettings(
        this.state?.chart,
        this.state.currentPeriod,
        true,
        this.props.match?.params?.chartId
      ),
      title: this.state.chart.title,
      fileType: type,
      extraData,
      custom
    });
    window.location = src;
  };

  render() {
    const chart = this.state.chart;
    let types = this.state.types;
    let data = cloneDeep(this.state.data) ?? [];
    if (confidentialTypes?.includes(chart?.datasource)) {
      let index = this.state.types?.findIndex(
        t => t.key === 'shouldSeeConfidential'
      );
      if (index > -1) {
        types = types.filter(t => t.key !== 'shouldSeeConfidential');
        data = data?.map(d => {
          let shouldSee = d[index];
          d.splice(index, 1);
          if (shouldSee === 'false') {
            return d.map(d => 'Confidential');
          } else {
            return d;
          }
        });
      }
    }

    return (
      <HeaderAndFooter
        Header={
          <Header
            title="My Analytics"
            section="Detailed View"
            clickBack={() => history.goBack()}
            rightButtons={{
              text: 'Edit Card',
              color: 'blue',
              visible: !this.state.readOnly,
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/card/${chart._id}`,
                  state: { chart }
                })
            }}
          />
        }
        className={styles.container}
        showFooter={false}
      >
        <AnalyticsPeriodPicker
          className={styles.width}
          period={this.state?.currentPeriod}
          savedPeriod={this.state.savedPeriod}
          onChange={this.onPeriodChange}
          dashboardId={this.props.location?.state?.dashboardId}
          onSave={this.onPeriodSave}
          readOnly={this.state.readOnly}
          showFrequencyPicker
        />
        <Card className={styles.width}>
          <div className={styles.preview}>
            <div className={styles.title}>{chart?.title}</div>
            <AnalyticsChart
              className={styles.previewBody}
              settings={chart}
              period={this.state.currentPeriod}
              shouldBeHigherThanStandards={chart?.shouldBeHigherThanStandards}
              updateDashboardData={data => {
                this.setState({ data: data.data, types: data.types });
              }}
              fromDetailed
            />
          </div>
        </Card>
        <List
          saveKey={
            this.state.data?.length ? `analyticsDetail-${chart?._id}` : null
          }
          className={styles.width}
          data={data}
          settings={types}
          actions={(extraData.includes(this.state.chart?.datasource)
            ? [
                {
                  label: 'Export Basic Data CSV',
                  color: 'green',
                  onClick: () => this.exportDetailsView('csv')
                },
                {
                  label: 'Export Basic Data JSON',
                  color: 'green',
                  onClick: () => this.exportDetailsView('json')
                },
                {
                  label: 'Export All Data CSV',
                  color: 'blue',
                  onClick: () => this.exportDetailsView('csv', true)
                },
                {
                  label: 'Export All Data JSON',
                  color: 'blue',
                  onClick: () => this.exportDetailsView('json', true)
                }
              ]
            : [
                {
                  label: 'Export CSV',
                  color: 'green',
                  onClick: () => this.exportDetailsView('csv')
                },
                {
                  label: 'Export JSON',
                  color: 'green',
                  onClick: () => this.exportDetailsView('json')
                }
              ]
          ).concat(
            this.state.userSettings
              ? [
                  {
                    label: 'Customized Export CSV',
                    color: 'blue',
                    onClick: () => this.exportDetailsView('csv', true, true)
                  },
                  {
                    label: 'Customized Export JSON',
                    color: 'blue',
                    onClick: () => this.exportDetailsView('json', true, true)
                  }
                ]
              : []
          )}
          hideCheckboxes
        />
      </HeaderAndFooter>
    );
  }
}

export default MyAnalyticsDetail;
