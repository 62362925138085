import React from 'react';
import classnames from 'classnames/bind';

import styles from './progress.module.scss';
const bStyles = classnames.bind(styles);

export default function RequiredTime({ attachments, minMaterialTime }) {
  const totalMaterialTime = attachments.reduce((a, c) => a + c.timeSpent, 0);
  const bars = []
    .concat(
      totalMaterialTime >= minMaterialTime
        ? {
            width: totalMaterialTime,
            color: 'green'
          }
        : totalMaterialTime
        ? {
            width: totalMaterialTime,
            color: 'blue'
          }
        : []
    )
    .concat(
      minMaterialTime - totalMaterialTime > 0
        ? {
            width: minMaterialTime - totalMaterialTime,
            color: 'grey'
          }
        : []
    );
  return (
    <div className={styles.container}>
      <p className={styles.headerText}>Required Material Time: </p>
      <div className={styles.stats}>
        <div className={styles.timeSpent}>
          {totalMaterialTime?.toFixed(2)} of {minMaterialTime?.toFixed(2)}{' '}
          minutes
        </div>
        <div className={styles.bar} data-tip="whiteTheme">
          {bars.map((bar, i) => {
            if (!bar.width) {
              return <React.Fragment key={i} />;
            }
            return (
              <div
                key={i}
                className={bStyles('barPiece', bar.color)}
                style={{
                  width: `${(bar.width / minMaterialTime) * 100}%`
                }}
              />
            );
          })}
        </div>
        {totalMaterialTime > minMaterialTime ? (
          <img
            className={styles.check}
            src={require('../../../assets/images/GreenCheck.png')}
            alt="check"
          />
        ) : null}
      </div>
    </div>
  );
}
