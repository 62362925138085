import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';

import { fetchDocumentById, fetchDocumentFoldersByType } from '../../api/v4';

import { clearUploadedAttachments } from '../../actions/attachments';

import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';

import Card from '../../components/Card';
import FileDownloader from '../../components/FileDownloader';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Notebox from '../../components/Notebox';
import { Dropdown } from '../../components/inputs';

export default function OSHAInfoDoc() {
  const { docId } = useParams();
  const dispatch = useDispatch();

  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const company = useSelector(getActiveCompany);

  const [document, setDocument] = useState();
  const [attachments, setAttachments] = useState([]);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    fetchDocumentById(docId).then(response => {
      setDocument(response);
      setAttachments(response?.attachments);
    });
    fetchDocumentFoldersByType('oshaInfoDoc').then(r =>
      setFolders(
        r.map(f => {
          return { value: f._id, label: f.name };
        })
      )
    );
  }, [docId, company]);

  useEffect(() => {
    setAttachments(addedAttachments);
  }, [addedAttachments]);

  const handleBack = () => {
    dispatch(clearUploadedAttachments());
    history.goBack();
  };

  const header = (
    <Header
      title={`${company.reportingType === 2 ? 'BWC' : 'OSHA'} Information`}
      clickBack={handleBack}
    />
  );

  return (
    <>
      <HeaderAndFooter Header={header}>
        <Card>
          <h2>{document?.name}</h2>
          {document?.documentFolderId ? (
            <Dropdown
              options={folders}
              fieldLabel="Folder"
              currentValue={document.documentFolderId}
              disabled
              searchable
            />
          ) : (
            <></>
          )}
          <Notebox note={document?.label} />
          <h4>Attachments</h4>
          {attachments?.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              ownerType="documents"
              disabled
            />
          ))}
        </Card>
      </HeaderAndFooter>
    </>
  );
}
