import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import {
  fetchEmployeeCovidInfo,
  addTestResult,
  updateEmployeeVaccinationInfo
} from '../../../api/v4';
import { uploadAttachment } from '../../../api/v4';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import { Dropdown, TwoColumn, Button } from '../../../components/inputs';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { addUploadedAttachmentsRequest } from '../../../actions/attachments';
import { notFutureDate } from '../../../utils/formValidation';
import { addMessage } from '../../../actions/messages';
import { getLoggedInUser } from '../../../selectors/users';

import styles from '../covid.module.scss';
import moment from 'moment';

export default function EmployeeCovidStatus() {
  const [employeeName, setEmployeeName] = useState('');
  const [resultList, setResultList] = useState([]);
  const [vaccinated, setVaccinated] = useState(false);
  const [boosterDate, setBoosterDate] = useState(null);
  const [vaccinationDate, setVaccinationDate] = useState(null);
  const [openTestModal, setOpenTestModal] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [testDate, setTestDate] = useState(null);
  const [vaccineCard, setVaccineCard] = useState(null);
  const [vaccineUrl, setVaccineUrl] = useState(null);
  const [testResultsUrl, setTestResultsUrl] = useState(null);
  const [testResultImage, setTestResultsImage] = useState(null);
  const [canUpdateVaccineInfo, setCanUpdateVaccineInfo] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const dispatch = useDispatch();
  const addAttachment = payload =>
    dispatch(addUploadedAttachmentsRequest(payload));
  const loggedInUser = useSelector(getLoggedInUser);

  const { id } = useParams();

  useEffect(() => {
    fetchEmployeeCovidInfo(id).then(response => {
      let sortedTestResults = response?.testResults?.sort((t1, t2) => {
        return new Date(t1.date) - new Date(t2.date);
      });
      // if they are vaccinated they can only change booster date
      setCanUpdateVaccineInfo(!response?.isVaccinated);
      setVaccinated(response?.isVaccinated);
      setResultList(sortedTestResults ?? []);
      setVaccinationDate(response?.lastVaccinationDate);
      setBoosterDate(response?.lastBoosterDate);
      setEmployeeName(response?.name);
      setVaccineCard(response?.vaccineCard);
      setEmployeeId(response.employeeId);
    });
  }, [id]);

  const addNewTest = async () => {
    try {
      await uploadAttachment(testResultImage, {
        ownerId: employeeId,
        ownerType: 'EmployeeCovidTracking',
        isSignature: false,
        isPhotoEvidence: false,
        photoType: 'testResult'
      });
      dispatch(addMessage({ error: false, message: 'Attachments Uploaded' }));

      setTimeout(() => {
        addTestResult(employeeId, {
          result: testResult,
          date: testDate
        }).then(response => {
          let sortedTestResults = response?.testResults?.sort((t1, t2) => {
            return new Date(t1.date) - new Date(t2.date);
          });
          setResultList(sortedTestResults);
          handleCloseTestModal();
        });
      }, 2000);
    } catch (e) {
      dispatch(
        addMessage({
          error: true,
          message:
            'Attachment cannot be uploaded at this time. Please try again later.'
        })
      );
    }
  };

  const submitVaccinationInfo = async () => {
    if (canUpdateVaccineInfo)
      await addAttachment({
        data: vaccineCard,
        ownerId: employeeId,
        ownerType: 'EmployeeCovidTracking',
        photoType: 'vaccineCard'
      });
    setTimeout(() => {
      updateEmployeeVaccinationInfo(employeeId, {
        isVaccinated: vaccinated,
        lastBoosterDate: boosterDate,
        lastVaccinationDate: vaccinationDate,
        uploadVaccinationCard: canUpdateVaccineInfo
      }).then(() => history.push('/app/covidTracking'));
    }, 2000);
  };

  const canSubmit = () => {
    const validBooster = boosterDate
      ? !notFutureDate(boosterDate) &&
        moment(boosterDate).isAfter(moment(vaccinationDate))
      : true;
    return (
      !notFutureDate(vaccinationDate) &&
      vaccinationDate &&
      vaccineCard &&
      vaccinated &&
      validBooster
    );
  };

  const handleCloseTestModal = () => {
    setTestDate(null);
    setTestResult(null);
    setTestResultsImage(null);
    setTestResultsUrl(null);
    setOpenTestModal(false);
  };
  const formatFiles = (e, type = 'vaccineCard') => {
    const files = e.target.files;
    if (!files.length) return;
    let urls = [];

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      } else {
        urls.push(URL.createObjectURL(files[i]));
      }
    }

    type === 'vaccineCard'
      ? setVaccineUrl(urls[0])
      : setTestResultsUrl(urls[0]);

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    if (type === 'vaccineCard') {
      setVaccineCard(data);
      setHasChanges(true);
    } else {
      setTestResultsImage(data);
    }
  };

  const header = (
    <Header
      title={employeeName}
      section="Covid-19 Test Status"
      clickBack={() =>
        hasChanges
          ? setShowSaveChangesModal(true)
          : loggedInUser.accessLevel === 900
          ? history.push('/app/covidTracking')
          : history.push('/app/dashboard')
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={!hasChanges}
      cancelButtonClick={
        hasChanges
          ? () => setShowSaveChangesModal(true)
          : () =>
              loggedInUser.accessLevel === 900
                ? history.push('/app/covidTracking')
                : history.push('/app/dashboard')
      }
      saveButtonDisabled={!canSubmit() || !hasChanges}
      saveButtonClick={() => submitVaccinationInfo()}
      cancelButtonText="cancel"
      saveButtonText="Save"
    />
  );

  const columns = [
    {
      key: 'date',
      label: 'Test Date',
      datatype: 'date'
    },
    {
      key: 'result',
      Label: 'Test Result'
    },
    {
      datatype: 'covidAttachments',
      key: 'attachment',
      label: 'Photo Evidence of Test',
      accessor: r => [r?.attachment] ?? []
    }
  ];

  return (
    <HeaderAndFooter Header={header} Footer={footer}>
      <Card title="Vaccination Information" showHeader wide>
        <TwoColumn>
          <Dropdown
            options={[
              { label: 'Vaccinated', value: true },
              { label: 'Not Vaccinated', value: false }
            ]}
            fieldLabel="Vaccination Status"
            currentValue={vaccinated}
            isRequired
            onChange={value => {
              setHasChanges(value);
              setVaccinated(value);
              if (!value) {
                setVaccinationDate(null);
                setBoosterDate(null);
                setVaccineCard(null);
              }
            }}
            disabled={!canUpdateVaccineInfo}
            error="Must Upload Vaccine Card"
            touched={vaccinated && !vaccineCard}
          />
          <DatePicker
            fieldLabel="Date of Last Vaccination"
            onChange={value => {
              setHasChanges(true);
              setVaccinationDate(value);
            }}
            currentValue={vaccinationDate}
            isRequired
            appendToBody
            name="vaccinationDate"
            error={
              notFutureDate(vaccinationDate)
                ? 'Vaccination Date Cannot be a Future Date'
                : null
            }
            touched={vaccinationDate}
            disabled={!canUpdateVaccineInfo || !vaccinated}
          />
          <DatePicker
            fieldLabel="Date of Last Booster"
            onChange={value => {
              setHasChanges(true);
              setBoosterDate(value);
            }}
            currentValue={boosterDate}
            appendToBody
            name="boostereDate"
            error={
              notFutureDate(boosterDate)
                ? 'Vaccination Date Cannot be a Future Date'
                : moment(boosterDate).isSameOrBefore(moment(vaccinationDate))
                ? 'Booster Date Must be After Vaccination Date'
                : null
            }
            touched={boosterDate}
            disabled={!vaccinated}
          />
          {vaccineCard && (
            <div className={styles.attachmentContainer}>
              <img
                className={styles.image}
                src={vaccineUrl ?? vaccineCard?.source_url}
                alt="vaccineCard"
              />
              {vaccineUrl && (
                <Button
                  type="button"
                  color="red"
                  inputClassName={styles.deleteButton}
                  text="X"
                  onClick={() => {
                    setVaccineUrl(null);
                    setVaccineCard(null);
                  }}
                />
              )}
            </div>
          )}
        </TwoColumn>
        {!vaccineCard && (
          <Button
            color="blue"
            text="Add Vaccination Card"
            onClick={() => document.getElementById(`vaccineCardImage`).click()}
            inputClassName={styles.button}
            disabled={!vaccinated}
          />
        )}
        <input
          type="file"
          id="vaccineCardImage"
          className={styles.fileUpload}
          onChange={e => {
            formatFiles(e);
            e.target.value = '';
          }}
          accept={['image/png', 'image/jpeg', 'image/jpg', '.pdf']}
        />
      </Card>
      <Card
        title="Test Results"
        showHeader
        wide
        rightButton={
          <Button
            color="blue"
            text="Add Test Result"
            onClick={() => setOpenTestModal(true)}
          />
        }
      >
        <List
          saveKey="employeeCovidResults"
          data={resultList}
          dataIsHash
          settings={columns}
        />
      </Card>

      <Modal
        title="Add Test Result"
        isOpen={openTestModal}
        submitButtonColor="blue"
        titleClassName="blueHeader"
        submitButtonText="Save"
        onRequestClose={() => handleCloseTestModal()}
        submitActions={() => addNewTest()}
        disableSubmit={
          !(
            testResult &&
            testDate &&
            testResultsUrl &&
            !notFutureDate(testDate)
          )
        }
      >
        <div>
          <RadioButtons
            fieldLabel="Result"
            options={[
              { label: 'Positive', value: 'Positive' },
              { label: 'Negative', value: 'Negative' }
            ]}
            currentValue={testResult}
            isRequired={true}
            onChange={value => setTestResult(value)}
          />
          <DatePicker
            fieldLabel="Date"
            onChange={value => setTestDate(value)}
            currentValue={testDate}
            appendToBody
            name="testDate"
            isRequired
            error={
              notFutureDate(testDate)
                ? 'Date of Test Cannot be a Future Date'
                : null
            }
            touched={testDate}
          />
          {testResultsUrl ? (
            <div className={styles.attachmentContainer}>
              <img
                className={styles.image}
                src={testResultsUrl}
                alt="testResults"
              />
              <Button
                type="button"
                color="red"
                inputClassName={styles.testResultDeleteButton}
                text="X"
                onClick={() => {
                  setTestResultsImage(null);
                  setTestResultsUrl(null);
                }}
              />
            </div>
          ) : (
            <Button
              color="blue"
              text="Upload Photo Evidence"
              onClick={() => document.getElementById(`testResultImage`).click()}
              inputClassName={styles.button}
            />
          )}
          <input
            type="file"
            id="testResultImage"
            className={styles.fileUpload}
            onChange={e => formatFiles(e, 'testResults')}
            accept={['image/png', 'image/jpeg', 'image/jpg', '.pdf']}
          />
        </div>
      </Modal>
      <SaveChangesModal
        onRequestClose={() => setShowSaveChangesModal(false)}
        isOpen={showSaveChangesModal}
        submitActions={() => history.push('/app/covidTracking')}
      />
    </HeaderAndFooter>
  );
}
