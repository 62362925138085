import React, { useState, useEffect } from 'react';
import history from '../../history';
import Modal from '../Modal';

function IdleSessionHandler() {
  const [openIdleModal, setOpenIdleModal] = useState(false);

  const logout = () => {
    sessionStorage.clear();
    removeEvents();
    history.push('/login');
    clearTimeout(promptTimer);
    setOpenIdleModal(false);
  };

  let promptTimer;
  let logoutTimer;

  let events = ['click', 'keydown'];

  const eventHandler = () => {
    clearTimeout(promptTimer);
    clearTimeout(logoutTimer);
    promptTimer = setTimeout(() => {
      setOpenIdleModal(true);
    }, 1000 * 60 * 50);
    if (openIdleModal) {
      logoutTimer = setTimeout(logout, 1000 * 60 * 10);
    }
  };

  const addEvents = () => {
    for (let e of events) {
      window.addEventListener(e, eventHandler);
    }
  };

  const removeEvents = () => {
    for (let e of events) {
      window.removeEventListener(e, eventHandler);
    }
  };

  useEffect(() => {
    eventHandler();
    addEvents();

    return () => {
      clearTimeout(promptTimer);
      clearTimeout(logoutTimer);
      removeEvents();
    };
  });

  return (
    <>
      <Modal
        isOpen={openIdleModal}
        title="Close Session?"
        onRequestClose={() => setOpenIdleModal(false)}
        submitActions={() => setOpenIdleModal(false)}
        cancelActions={logout}
        cancelButtonText="Logout"
        submitButtonText="Stay Logged In"
        submitButtonColor="blue"
        titleClassName="blueHeader"
      >
        Your session is currently idle and will expire in 10 minutes. Would you
        like to stay logged in or logout?
      </Modal>
    </>
  );
}

export default IdleSessionHandler;
