import React from 'react';

export default props => (
  <svg
    width={props.width || '40px'}
    height={props.height || '40px'}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.4737 15.7052C13.4737 16.7578 12.6105 17.5999 11.5789 17.5999H4.29474C3.24211 17.5999 2.4 16.7367 2.4 15.7052V8.42096C2.4 7.36833 3.26316 6.52622 4.29474 6.52622H8.29474L10.6947 4.12622H4.29474C1.91579 4.12622 0 6.06306 0 8.42096V15.7052C0 18.0841 1.93684 19.9999 4.29474 19.9999H11.5789C13.9579 19.9999 15.8737 18.0631 15.8737 15.7052V9.30517L13.4737 11.7052V15.7052Z" />
    <path d="M18.8 0H12.6737C12 0 11.4737 0.526316 11.4737 1.2C11.4737 1.87368 12 2.4 12.6737 2.4H15.9158L8.71579 9.6C8.25263 10.0632 8.25263 10.8211 8.71579 11.2842C8.94737 11.5158 9.26316 11.6421 9.5579 11.6421C9.85263 11.6421 10.1684 11.5158 10.4 11.2842L17.6 4.08421V7.32632C17.6 7.97895 18.1263 8.52632 18.8 8.52632C19.4526 8.52632 20 8 20 7.32632V1.2C20 0.526316 19.4737 0 18.8 0Z" />
  </svg>
);
