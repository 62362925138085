import { createSelector } from 'reselect';

const authSelector = state => state.auth;

export const isAuthenticated = createSelector(
  authSelector,
  auth => auth.authenticated
);

export const isFirstTimeLogin = createSelector(
  authSelector,
  auth => auth.firstTimeLogin
);

export const getSecurityQuestionsSelector = createSelector(
  authSelector,
  auth => auth.securityQuestions
);

export const getLoginFailure = createSelector(
  authSelector,
  auth => auth.loginFailure
);

export const getRegistrationFailure = createSelector(
  authSelector,
  auth => auth.registrationFailure
);
