import React from 'react';
import Modal from '.';

import styles from './styles.module.scss';

const RemoveExceptionItemModel = ({
  isOpen,
  onRequestClose,
  submitActions,
  itemsToRemove,
  removeWhat
}) => (
  <Modal
    title={`Remove ${removeWhat}?`}
    titleClassName="redHeader"
    isOpen={isOpen}
    submitButtonColor="red"
    submitButtonText="Remove"
    onRequestClose={onRequestClose}
    submitActions={submitActions}
  >
    <div className={styles.text}>
      Changing this date range will remove {itemsToRemove.join(', ')} from the
      filters? Are you sure you would like to continue?
    </div>
  </Modal>
);

export default RemoveExceptionItemModel;
