import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  downloadEmployeeQRcodeResponse,
  setActiveEmployeeResponse
} from '../actions/personnel';
import { addMessage } from '../actions/messages';
import { getActiveEmployeeSelector } from '../selectors/personnel';
import { clearAllUsers } from '../actions/user';

export function* updateLocationEmployee(apiV4, { payload }) {
  const activeEmployee = yield select(getActiveEmployeeSelector);
  try {
    payload = {
      ...payload,
      dateOfHire: moment(payload.dateOfHire),
      dateOfTermination: payload.dateOfTermination
        ? moment(payload.dateOfTermination)
        : '',
      dateOfBirth: payload.dateOfBirth ? moment(payload.dateOfBirth) : ''
    };

    const updateResponse = yield call(apiV4.updateEmployee, payload);

    yield put(
      setActiveEmployeeResponse({
        ...updateResponse,
        trainings: payload.trainings,
        documents: payload.documents,
        qualifications: payload.qualifications,
        incidents: payload.incidents
      })
    );
    yield put(clearAllUsers());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e?.data }));
    yield put(setActiveEmployeeResponse(activeEmployee));
  }
}

export function* setActiveEmployee(apiV4, { payload }) {
  try {
    const employee = yield call(apiV4.fetchEmployee, payload?._id ?? payload);
    const empTrainings = yield call(apiV4.fetchEmployeeTrainings, employee._id);
    const empQualifications = yield call(
      apiV4.fetchEmployeeQualifications,
      employee._id
    );
    const empIncidents = yield call(apiV4.fetchEmployeeIncidents, employee._id);
    const empBravos = yield call(apiV4.fetchEmployeeBravos, employee._id);
    const empObservations = yield call(
      apiV4.fetchEmployeeObservations,
      employee._id
    );

    let attachments = yield all(
      empQualifications.documents.map(document =>
        call(apiV4.fetchAttachmentByOwnerId, document._id, 'documents')
      )
    );

    empQualifications.documents.map(
      (res, index) => (res.attachments = attachments[index])
    );

    employee.trainings = empTrainings;
    employee.incidents = empIncidents;
    employee.qualifications = empQualifications;
    employee.bravos = empBravos;
    employee.observations = empObservations;

    if (employee.accessLevel) {
      employee.systemInteractions = yield call(
        apiV4.fetchEmployeeInteractions,
        employee._id
      );
    }

    yield put(setActiveEmployeeResponse(employee));
  } catch (e) {
    console.log(e);
  }
}

export function* downloadPersonnelQRCode(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.fetchQrCodeSheet, payload.ids);

    const link = document.createElement('a');
    link.href = `${response}`;
    link.target = '_blank';
    link.download = `Employee QR Codes`;
    link.click();

    yield put(downloadEmployeeQRcodeResponse());
  } catch (e) {
    console.log(e);
  }
}
