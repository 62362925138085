import React from 'react';
import Dashboard from '../svgs/dashboard';
import Tasks from '../svgs/tasks';
import FROI from '../svgs/froi';
import AutoIncident from '../svgs/autoIncident';
import ThirdPartyInjury from '../svgs/thirdPartyInjury';
import CorrectiveAction from '../svgs/correctiveAction';
import NearMiss from '../svgs/nearMiss';
import JSA from '../svgs/jsa';
import SafetySuggestion from '../svgs/safetySuggestion';
import CriticalBehavior from '../svgs/criticalBehavior';
import SafetyProcedures from '../svgs/safetyProcedures';
import SDS from '../svgs/sds';
import EmployeesUsers from '../svgs/employeesUsers';
import Groups from '../svgs/groups';
import DOT from '../svgs/dot';
import LockoutTagout from '../svgs/lockoutTagout';
import Incidents from '../svgs/incidents';
import DailyReports from '../svgs/dailyReports';
import Theft from '../svgs/theft';
import PropertyDamage from '../svgs/propertyDamage';
import Gear from '../svgs/gear';
import PlusSign from '../svgs/plusSign';
import Home from '../svgs/home';
import Company from '../svgs/company';
import Search from '../svgs/search';
import Reporter from '../svgs/reporter';
import Collaborator from '../svgs/collaborator';
import Admin from '../svgs/admin';
import Delete from '../svgs/delete';
import Toolbox from '../svgs/toolbox';
import Supervisor from '../svgs/supervisor';
import LRR from '../svgs/lrr';
import OshaLogo from '../svgs/oshaLogo';
import Analytics from '../svgs/analytics';
import Qualifications from '../svgs/qualifications';
import Areas from '../svgs/areas';
import S4 from '../svgs/s4';
import InfoIcon from '../svgs/infoIcon';
import CustomQuestions from '../svgs/customQuestions';
import CustomDocument from '../svgs/customDocument';
import Aircraft from '../svgs/aircraft';
import FuelReport from '../svgs/fuelReports';
import BarChart from '../svgs/barChart';
import LineChart from '../svgs/lineChart';
import PieChart from '../svgs/pieChart';
import List from '../svgs/list';
import Statistic from '../svgs/statistic';
import Quiz from '../svgs/quiz';
import Chevron from '../svgs/chevron';
import DoubleChevron from '../svgs/doubleChevron';
import Equipment from '../svgs/equipment';
import EditAlternative from '../svgs/editAlternative';
import Claims from '../svgs/claims';
import Leaderboard from '../svgs/leaderBoard';
import TrainingException from '../svgs/trainingException';
import Announcement from '../svgs/broadcast';
import SafetyAuditTemplates from '../svgs/safetyAuditTemplates';
import SafetyWalk from '../svgs/safetyWalk';
import SafetyAudit from '../svgs/safetyAudit';
import SafetyTraining from '../svgs/safetyTraining';
import CustomTemplate from '../svgs/customTemplate';
import QualityAudit from '../svgs/qualityAudit';
import Upload from '../svgs/upload';
import Email from '../svgs/email';
import WitnessStatements from '../svgs/witnessStatements';
import Courses from '../svgs/courses';
import Checkmark from '../svgs/checkmark';
import InProgress from '../svgs/inProgress';
import Violence from '../svgs/violence';
import General from '../svgs/general';
import Spill from '../svgs/spill';

const svgMapper = (imageSvg, props = {}) => {
  switch (imageSvg) {
    case 'Dashboard':
      return <Dashboard {...props} />;
    case 'Tasks':
      return <Tasks {...props} />;
    case 'first-report-of-injury':
    case 2:
    case 'froi':
    case '2':
      return <FROI {...props} />;
    case 'vehicle':
    case 'auto-accident':
    case 1:
    case '1':
      return <AutoIncident {...props} />;
    case 'thirdParty':
    case 'general-liability':
    case 5:
    case '5':
      return <ThirdPartyInjury {...props} />;
    case 'corrective-action':
      return <CorrectiveAction {...props} />;
    case 'nearMiss':
    case 'near-miss-report':
    case 7:
    case '7':
      return <NearMiss {...props} />;
    case 'riskAssessment':
    case 'jsa':
    case 8:
    case '8':
      return <JSA {...props} />;
    case 'safety-suggestion':
    case 9:
    case '9':
      return <SafetySuggestion {...props} />;
    case 'safetyWalk':
    case 'site-inspection':
    case 10:
    case '10':
      return <SafetyWalk {...props} />;
    case 'critical-behavior':
    case 'bo':
    case 12:
    case '12':
      return <CriticalBehavior {...props} />;
    case 11:
    case '11':
    case 's4':
      return <S4 {...props} />;
    case 'safety-instruction':
      return <SafetyProcedures {...props} />;
    case 'sds':
      return <SDS {...props} />;
    case 'safetyAudit':
      return <SafetyAudit {...props} />;
    case 'qualityAudit':
      return <QualityAudit {...props} />;
    case 'safety-training':
    case 'safetyTraining':
      return <SafetyTraining {...props} />;
    case 'courses':
      return <Courses {...props} />;
    case 'Employees':
    case 'Users':
    case 'Contractors':
      return <EmployeesUsers {...props} />;
    case 'Locations':
      return <Groups {...props} />;
    case 'dot':
    case 15:
    case '15':
      return <DOT {...props} />;
    case 'lockout-tagout':
    case 14:
    case '14':
      return <LockoutTagout {...props} />;
    case 'Incidents':
      return <Incidents {...props} />;
    case 'daily-reports':
      return <DailyReports {...props} />;
    case 'theft':
    case 'theft-reports':
    case 4:
    case '4':
      return <Theft {...props} />;
    case 'property-damage':
    case 3:
    case '3':
      return <PropertyDamage {...props} />;
    case 'gear':
      return <Gear {...props} />;
    case 'plusSign':
      return <PlusSign {...props} />;
    case 'home':
      return <Home {...props} />;
    case 'company':
      return <Company {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'reporter':
      return <Reporter {...props} />;
    case 'collaborator':
      return <Collaborator {...props} />;
    case 'admin':
      return <Admin {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'toolbox':
      return <Toolbox {...props} />;
    case 'supervisor':
      return <Supervisor {...props} />;
    case 'lossRun':
      return <LRR {...props} />;
    case 'oshaLogo':
      return <OshaLogo {...props} />;
    case 'Analytics':
      return <Analytics {...props} />;
    case 'qualifications':
      return <Qualifications {...props} />;
    case 'Projects':
    case 'Areas':
      return <Areas {...props} />;
    case 'InfoIcon':
      return <InfoIcon {...props} />;
    case 'SafetyAuditTemplates':
      return <SafetyAuditTemplates {...props} />;
    case 'CustomQuestions':
      return <CustomQuestions {...props} />;
    case 'CustomTemplate':
      return <CustomTemplate {...props} />;
    case 'CustomDocument':
      return <CustomDocument {...props} />;
    case 'BarChart':
      return <BarChart {...props} />;
    case 'LineChart':
      return <LineChart {...props} />;
    case 'PieChart':
      return <PieChart {...props} />;
    case 'List':
      return <List {...props} />;
    case 'Statistic':
      return <Statistic {...props} />;
    case 6:
    case '6':
    case 'aircraft':
      return <Aircraft {...props} />;
    case 16:
    case '16':
    case 'misfuel':
      return <FuelReport {...props} />;
    case 17:
    case '17':
    case 'violence':
      return <Violence {...props} />;
    case 18:
    case '18':
    case 'spill':
      return <Spill {...props} />;
    case 19:
    case '19':
    case 'general':
      return <General {...props} />;
    case 'quiz':
      return <Quiz {...props} />;
    case 'chevron':
      return <Chevron {...props} />;
    case 'doubleChevron':
      return <DoubleChevron {...props} />;
    case 'Equipment':
      return <Equipment {...props} />;
    case 'EditAlternative':
      return <EditAlternative {...props} />;
    case 'Claims':
      return <Claims {...props} />;
    case 'checkmark':
      return <Checkmark {...props} />;
    case 'Leaderboard':
      return <Leaderboard {...props} />;
    case 'Exception':
      return <TrainingException {...props} />;
    case 'Announcement':
      return <Announcement {...props} />;
    case 'upload':
      return <Upload {...props} />;
    case 'email':
      return <Email {...props} />;
    case 'witnessStatements':
      return <WitnessStatements {...props} />;
    case 'inProgress':
      return <InProgress {...props} />;
    default:
      return '';
  }
};

export default svgMapper;
