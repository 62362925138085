import React from 'react';
import './svg.css';

export default props => (
  <svg width="25px" height="30px" viewBox="0 0 90 67">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99933 6C7.99933 4.89543 7.1039 4 5.99933 4C4.89476 4 3.99933 4.89543 3.99933 6V61C3.99933 62.1046 4.89476 63 5.99933 63H83.9993C85.1039 63 85.9993 62.1046 85.9993 61C85.9993 59.8954 85.1039 59 83.9993 59H10.6338L38.9919 28.9233L51.5372 42.3646L53.0187 43.9519L54.4792 42.3453L84.4792 9.34535C85.2222 8.52803 85.162 7.26313 84.3446 6.52012C83.5273 5.77711 82.2624 5.83734 81.5194 6.65465L52.9799 38.0481L40.4614 24.6354L39.0067 23.0767L37.5441 24.628L7.99933 55.9634V6Z"
      />
    </g>
  </svg>
);
