import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="80" height="80" fill="transparent" />
    <path d="M31.4121 75.0515C33.021 77.9991 36.1476 80 39.7427 80C43.3377 80 46.4636 77.9991 48.0733 75.0515H31.4121Z" />
    <path d="M48.8144 61.8559C50.4639 61.8559 52.1134 60.2064 52.1134 58.5569C52.1134 44.5363 64.4845 42.6319 64.4845 24.7425C64.4845 11.0772 53.4069 0.000244141 39.7423 0.000244141C26.077 0.000244141 15 11.0772 15 24.7425C15 42.6319 27.3711 44.5363 27.3711 58.5569C27.3711 60.2064 29.0206 61.8559 30.6701 61.8559H48.8144Z" />
    <path d="M50.4638 68.454C50.4638 69.8149 49.3505 70.9282 47.9896 70.9282H31.4947C30.1338 70.9282 29.0205 69.8149 29.0205 68.454C29.0205 67.0931 30.1338 65.9797 31.4947 65.9797H47.9896C49.3505 65.9797 50.4638 67.0931 50.4638 68.454Z" />
  </svg>
);
