import React from 'react';
import styles from './index.module.scss';

export const BoldTextHeader = ({ label }) => {
  return (
    <div className={styles.header}>
      <div>
        <span>{label}</span>
      </div>
    </div>
  );
};
