import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.8404 8.80011H58.0004V11.6001C58.0004 14.16 55.9205 16.16 53.4405 16.16H26.6405C24.0807 16.16 22.0807 14.08 22.0807 11.6001L22.0804 8.80011H14.1604C12.1604 8.80011 10.4805 10.48 10.4805 12.48V74.32C10.4805 76.32 12.1604 78 14.1604 78H65.7604C67.7604 78 69.4403 76.32 69.4403 74.32L69.4406 12.48C69.4406 10.48 67.8404 8.80011 65.8404 8.80011Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6404 12.5601H53.3604C53.8404 12.5601 54.2403 12.1601 54.2403 11.6802V6.96011C54.2403 4.24004 52.0002 2.00024 49.2805 2.00024H30.6405C27.9204 2.00024 25.6806 4.24038 25.6806 6.96011V11.6802C25.7603 12.1601 26.1602 12.5601 26.6404 12.5601Z"
    />
  </svg>
);
