import {
  ADD_USER_TASK_REQUEST,
  ADD_USER_TASK_RESPONSE,
  DELETE_USER_TASK_REQUEST,
  DELETE_USER_TASK_RESPONSE,
  LOAD_TASK,
  SEND_REMINDER_REQUEST,
  SET_ACTIVE_TASK_REQUEST,
  SET_ACTIVE_TASK_RESPONSE,
  UPDATE_REMINDERS,
  UPDATE_USER_TASK_REQUEST,
  UPDATE_USER_TASK_RESPONSE
} from '../constants/actionTypes';

export const setActiveTaskRequest = payload => ({
  type: SET_ACTIVE_TASK_REQUEST,
  payload
});

export const setActiveTaskResponse = payload => ({
  type: SET_ACTIVE_TASK_RESPONSE,
  payload
});

export const loadTask = payload => ({
  type: LOAD_TASK,
  payload
});

export const sendReminder = payload => ({
  type: SEND_REMINDER_REQUEST,
  payload
});

export const addUserTaskRequest = payload => ({
  type: ADD_USER_TASK_REQUEST,
  payload
});

export const addUserTaskResponse = payload => ({
  type: ADD_USER_TASK_RESPONSE,
  payload
});

export const updateUserTaskRequst = payload => ({
  type: UPDATE_USER_TASK_REQUEST,
  payload
});

export const updateUserTaskResponse = payload => ({
  type: UPDATE_USER_TASK_RESPONSE,
  payload
});

export const deleteUserTaskRequest = payload => ({
  type: DELETE_USER_TASK_REQUEST,
  payload
});

export const deleteUserTaskResponse = payload => ({
  type: DELETE_USER_TASK_RESPONSE,
  payload
});

export const updateRemindedAssignments = payload => ({
  type: UPDATE_REMINDERS,
  payload
});
