import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../../actions/attachments';
import { getAddedAttachmentsSelector } from '../../../selectors/attachments';
import { getActiveSafetyWalkSelector } from '../../../selectors/safetyWalks';
import Button from '../../../components/inputs/Button';
import { DeleteItemModal } from '../../../components/Modal/deleteItemModal';
import { InputRow } from '../../../components/inputs';
import Chevron from '../../../svgs/chevron';

import styles from '../itemCard.module.scss';

class AttachmentCarousel extends Component {
  state = {
    openDeletePictureModal: false,
    selectedAttachment: 0
  };

  handlePhotoUpload = async e => {
    const files = e.target.files;
    if (files?.length === 0) return;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    await this.props.addAttachment({
      data,
      ownerId: this.props.loggedInUser._id,
      ownerType: this.props.ownerType
    });
  };

  handleDeletePicture = () => {
    this.setState({
      openDeletePictureModal: false
    });
    this.setState({ selectedAttachment: 0 });

    const allPictures = this.props?.addedAttachments.concat(
      this.props?.pictures
    );

    // checks to see whether we are going to delete from pictures or addedAttachments
    let type =
      this.props.pictures.findIndex(
        picture =>
          picture._id === allPictures[this.state.selectedAttachment]._id
      ) > -1
        ? 'pictures'
        : 'added';

    // set tmp to pictures so pictures wont be set to null when type is added
    let tmp = this.props.pictures;
    if (type === 'added') {
      this.props.deleteAttachment(
        this.props.addedAttachments[this.state.selectedAttachment]
      );
    } else {
      tmp = this.props.pictures.filter(
        picture =>
          picture._id !== allPictures[this.state.selectedAttachment]._id
      );
    }

    this.props.handleDelete({ pictures: tmp });
  };

  render() {
    const { selectedAttachment } = this.state;

    const { addedAttachments, pictures, isEditing } = this.props;

    const allPictures = addedAttachments.concat(pictures) || [];

    return (
      <>
        {allPictures.length > 0 && (
          <div
            className={
              addedAttachments.length === 1
                ? styles.onePicture
                : styles.pictureContainer
            }
          >
            {allPictures.length > 1 && (
              <Chevron
                className={styles.arrows}
                onClick={() =>
                  this.setState(state => ({
                    selectedAttachment:
                      state.selectedAttachment > 0
                        ? state.selectedAttachment - 1
                        : 0
                  }))
                }
              />
            )}

            <img
              src={allPictures[selectedAttachment].source_url}
              alt={allPictures[selectedAttachment].original_filename}
              className={styles.photoImage}
            />

            {allPictures.length > 1 && (
              <Chevron
                className={styles.arrows}
                onClick={() =>
                  this.setState({
                    selectedAttachment:
                      selectedAttachment === allPictures.length - 1
                        ? selectedAttachment
                        : selectedAttachment + 1
                  })
                }
              />
            )}
          </div>
        )}
        {isEditing && (
          <div className={styles.padding}>
            <InputRow>
              <Button
                text="Add Pictures"
                color="blue"
                onClick={() => document.getElementById('myPictureFile').click()}
              />
              {allPictures.length ? (
                <Button
                  text="Delete Picture"
                  color="red"
                  onClick={() =>
                    this.setState({ openDeletePictureModal: true })
                  }
                />
              ) : (
                <></>
              )}
            </InputRow>
            <input
              type="file"
              id="myPictureFile"
              onChange={e => {
                this.handlePhotoUpload(e);
                e.target.value = '';
              }}
              accept="image/png, image/jpeg, image/jpg"
              multiple
              data-cy="addPicture"
            />
          </div>
        )}
        <DeleteItemModal
          deletingWhat="picture"
          isOpen={this.state.openDeletePictureModal}
          onRequestClose={() =>
            this.setState({ openDeletePictureModal: false })
          }
          submitActions={this.handleDeletePicture}
          hideCustomTemplate
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  addedAttachments: getAddedAttachmentsSelector(state),
  activeSafetyWalk: getActiveSafetyWalkSelector(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload)),
  clearAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentCarousel);
