import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.3599 8.80008H59.5199V11.6001C59.5199 14.1599 57.44 16.1599 54.9601 16.1599H28.1601C25.6002 16.1599 23.6002 14.08 23.6002 11.6001L23.5999 8.80008H15.6799C13.6799 8.80008 12 10.48 12 12.48V74.32C12 76.32 13.6799 78 15.6799 78H67.2799C69.2799 78 70.9599 76.32 70.9599 74.32L70.9601 12.48C70.9601 10.48 69.3599 8.80008 67.3599 8.80008ZM56.7999 66.3201H26.2399C25.1199 66.3201 24.2399 65.4402 24.2399 64.3201C24.2399 63.2 25.1199 62.3201 26.2399 62.3201H56.7199C57.84 62.3201 58.7199 63.2 58.7199 64.3201C58.7199 65.4402 57.92 66.3201 56.7999 66.3201ZM56.7999 54.0001H26.2399C25.1199 54.0001 24.2399 53.1202 24.2399 52.0001C24.2399 50.88 25.1199 50.0001 26.2399 50.0001H56.7199C57.84 50.0001 58.7199 50.88 58.7199 52.0001C58.7199 53.1202 57.92 54.0001 56.7999 54.0001ZM56.7999 41.7601H26.2399C25.1199 41.7601 24.2399 40.8802 24.2399 39.7601C24.2399 38.64 25.1199 37.7601 26.2399 37.7601H56.7199C57.84 37.7601 58.7199 38.64 58.7199 39.7601C58.7199 40.8802 57.92 41.7601 56.7999 41.7601ZM56.7999 29.5201H26.2399C25.1199 29.5201 24.2399 28.6401 24.2399 27.5201C24.2399 26.4 25.1199 25.5201 26.2399 25.5201H56.7199C57.84 25.5201 58.7199 26.4 58.7199 27.5201C58.7199 28.6401 57.92 29.5201 56.7999 29.5201Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.16 12.5599H54.88C55.3599 12.5599 55.7599 12.1599 55.7599 11.6799V6.95987C55.7599 4.2398 53.5198 2 50.8001 2H32.1601C29.44 2 27.2002 4.24013 27.2002 6.95987V11.6799C27.2799 12.1599 27.6798 12.5599 28.16 12.5599Z"
    />
  </svg>
);
