import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import InfoBar from '../InfoBar';
import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';
import FrequencyPicker from '../inputs/FrequencyPicker';
import DateRangePicker from '../inputs/DateRangePicker';
import DateUnitPicker from '../inputs/DateUnitPicker';
import classnames from 'classnames/bind';

import { updateAnalyticsDashboard } from '../../api/v4';

import styles from './picker.module.scss';
const bStyles = classnames.bind(styles);

export default function AnalyticsPeriodPicker({
  allowAll,
  showFrequencyPicker,
  showTrendPicker,
  readOnly,
  className,
  onChange,
  period,
  onSave,
  dashboardId,
  savedPeriod,
  error,
  listPicker,
  isBulkPicker
}) {
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [periodNumber, setPeriodNumber] = useState();
  const [startDateFocused, setStartFocued] = useState();
  const [endDateFocused, setEndFocused] = useState();

  useEffect(() => {
    setCurrentPeriod({ ...(period ?? {}) });
    setPeriodNumber(period?.number);
  }, [period]);

  const save = () => {
    let period = {
      mode: currentPeriod.mode,
      frequency: currentPeriod.frequency
    };

    if (period.mode === 'absolute') {
      period.startDate = currentPeriod.startDate;
      period.endDate = currentPeriod.endDate;
    } else if (period.mode === 'relative') {
      period.unit = currentPeriod.unit;
      period.number = currentPeriod.number;
    }
    if (dashboardId) {
      updateAnalyticsDashboard(dashboardId, { period }).then(() => {
        if (onSave) onSave(currentPeriod);
      });
    } else {
      if (onSave) onSave(currentPeriod);
    }
  };

  const itemChange = (i, v) => {
    let updated = { ...currentPeriod };

    if ('number' in updated) updated.number = Number(updated.number);
    updated[i] = v;
    if (onChange) onChange({ ...updated });
  };

  const periodNumberChange = () => itemChange('number', periodNumber);
  const periodUnitChange = v => itemChange('unit', v);
  const startDateChange = d => itemChange('startDate', d?.toString());
  const endDateChange = d => itemChange('endDate', d?.toString());
  const frequencyChange = v => itemChange('frequency', v);
  const modeChange = v => itemChange('mode', v);

  const value = currentPeriod || {};

  let startDate = value.startDate;
  if (typeof startDate === 'string') startDate = moment(startDate);
  let endDate = value.endDate;
  if (typeof endDate === 'string') endDate = moment(endDate);

  const unsaveableDateRange =
    value.mode === 'absolute'
      ? !startDate || !endDate || moment(startDate).isAfter(endDate)
      : false;
  const unsaveablePeriod =
    (value.mode === 'relative' && (!value.unit || !value.number)) ?? false;
  return (
    <InfoBar
      color="white"
      className={bStyles(styles.picker, className, {
        listPicker: listPicker
      })}
    >
      <DateRangePicker
        className={styles.input}
        onChange={modeChange}
        currentValue={value.mode}
        fieldLabel="Date Range:"
        name="range"
        customRange={!isBulkPicker}
        customPeriod={showFrequencyPicker}
        trendData={showTrendPicker}
        allowAll={allowAll}
        touched={error}
        isBulkPicker={isBulkPicker}
      />
      {value.mode === 'absolute' && (
        <div styles={{ display: 'column' }} className={styles.absolute}>
          <div className={styles.period}>
            <SingleDatePicker
              className={styles.input}
              date={startDate}
              onDateChange={startDateChange}
              focused={startDateFocused}
              onFocusChange={f => setStartFocued(f.focused)}
              placeholder="Start"
              isOutsideRange={d => false}
            />
            <SingleDatePicker
              className={styles.input}
              date={endDate}
              onDateChange={endDateChange}
              focused={endDateFocused}
              onFocusChange={f => setEndFocused(f.focused)}
              placeholder="End"
              isOutsideRange={d => false}
            />
          </div>
          {startDate && endDate && moment(startDate).isAfter(endDate) ? (
            <span className={styles.warning}> Invalid Date Range</span>
          ) : null}
        </div>
      )}
      {value.mode === 'relative' && (
        <div className={styles.period}>
          <span>Past</span>
          <Textbox
            className={`${styles.input} ${styles.time}`}
            currentValue={periodNumber || ''}
            onChange={v => {
              v = v.replace(/[^0-9]/g, '');
              setPeriodNumber(Number(v));
            }}
            enter={periodNumberChange}
            onBlur={periodNumberChange}
            type="text"
          />
          <DateUnitPicker
            className={styles.input}
            onChange={periodUnitChange}
            currentValue={value.unit}
            name="unit"
          />
        </div>
      )}
      {showFrequencyPicker && currentPeriod?.mode !== 'trend' && (
        <FrequencyPicker
          className={styles.input}
          onChange={frequencyChange}
          currentValue={value.frequency}
          fieldLabel="Period:"
          displayTooltip={true}
          name="frequency"
          tooltip="This option only applies to line graphs, and controls the time period each dot represents."
        />
      )}
      {!isEqual(currentPeriod, savedPeriod) && !readOnly && !isBulkPicker && (
        <Button
          color="green"
          onClick={save}
          text="Set as Default"
          className={classnames(styles.save, styles.input)}
          disabled={unsaveableDateRange || unsaveablePeriod}
        />
      )}
    </InfoBar>
  );
}
