import React, { Component } from 'react';

import { FieldRenderer } from '../../inputs';
import Checkbox from '../../inputs/Checkbox';
import Card from '../../Card';

const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default class ReportSection extends Component {
  handleNA = (field, checked) =>
    this.handleAnswer(field, checked ? ['Not Applicable'] : '');

  handleAnswer = (field, answer, index) => {
    if (answer?.length === 0) {
      answer = '';
    }
    field.answer = answer;
    const fields = this.props.fields;

    if (field.label === 'Paid by Company') {
      let totalFieldIndex = fields?.findIndex(
        f => f.label === 'Total Paid to Date'
      );
      let insurancePaid =
        fields?.find(f => f.label === 'Paid by Insurance')?.answer ?? 0;
      if (totalFieldIndex > -1)
        fields[totalFieldIndex].answer = insurancePaid + answer;
    } else if (field.label === 'Paid by Insurance') {
      let totalFieldIndex = fields?.findIndex(
        f => f.label === 'Total Paid to Date'
      );
      let companyPaid =
        fields?.find(f => f.label === 'Paid by Company')?.answer ?? 0;
      if (totalFieldIndex > -1)
        fields[totalFieldIndex].answer = companyPaid + answer;
    }

    fields[index] = field;
    this.props.answerQuestion(fields);
  };

  render() {
    const {
      name,
      reportSectionHeader,
      canSectionNotApplicable,
      sectionNotApplicable,
      fields,
      disabled,
      handleNaSection,
      incidentId,
      groupId,
      missingRequired
    } = this.props;

    const isCustomQuestions = reportSectionHeader === 'Custom Questions';

    return (
      <Card
        name={name}
        title={reportSectionHeader}
        showHeader
        rightButton={
          canSectionNotApplicable && (
            <Checkbox
              fieldLabel="Not Applicable"
              onChange={value => handleNaSection(value)}
              currentValue={sectionNotApplicable}
              disabled={disabled}
            />
          )
        }
        wide
      >
        {fields.map((field, index) => {
          if (field.legacy)
            return <React.Fragment key={index}></React.Fragment>;
          let answer = findAnswer(fields, field._id);

          return (
            <FieldRenderer
              key={index}
              fieldLabel={field.label}
              isRequired={field.required}
              name={field.label}
              id={field._id}
              showNA={
                field.type === 'signature' ? false : field.showNotApplicable
              }
              isNA={
                (answer?.length === 1 && answer?.[0] === 'Not Applicable') ||
                answer?.label === 'Not Applicable'
              }
              currentValue={answer}
              onChange={v => this.handleAnswer(field, v, index)}
              onNAChange={v => this.handleNA(field, v, index)}
              disabled={
                disabled ||
                sectionNotApplicable ||
                field.label === 'Total Paid to Date'
              }
              options={field.options}
              type={field.type}
              bareValues={false}
              ownerType="incident"
              ownerId={incidentId}
              displayTooltip={
                field.displayTooltip ?? field.label === 'Total Paid to Date'
              }
              tooltip={
                field.tooltip ??
                'This is the sum of Paid by Company and Paid by Insurance'
              }
              groupId={groupId}
              placeholder={field.placeholder}
              alphabetize={
                isCustomQuestions ? false : field.alphabetize ?? true
              }
              missingRequired={missingRequired}
            />
          );
        })}
      </Card>
    );
  }
}
