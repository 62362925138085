import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTable } from 'react-table';
import { Textbox } from '../Textbox';
import Input from '..';

import styles from './styles.module.scss';

const CustomTable = ({
  options,
  onChange,
  currentValue,
  disabled,
  touched,
  ...props
}) => {
  const textCell = ({ row, column }) => {
    let id = column.id;
    let value = row.original[id];

    return disabled ? (
      <div className={styles.notEditingCell}>
        {value?.length ? (
          value
        ) : (
          <span className={styles.emptyCell}>{`-`}</span>
        )}
      </div>
    ) : (
      <Textbox currentValue={value} disabled={disabled} />
    );
  };
  let data;
  const headers = Object.values(options?.columnHeaders ?? {}).slice(
    0,
    options.columns
  );
  let columns = useMemo(() => {
    const col = (headers ?? [])?.map((t, i) => {
      let type = {
        Header: t,
        accessor: t,
        id: t
      };
      return type;
    });
    return col; // eslint-disable-next-line
  }, [disabled]);
  const defaultColumn = {
    Cell: textCell
  };
  const emptyCol = {};
  headers.forEach(h => (emptyCol[h] = ''));
  data = Array(parseInt(options.rows)).fill(emptyCol);
  if (currentValue) {
    data = data.map((v, i) => {
      const newRow = { ...v };
      Object.keys(v).forEach(
        (key, keyI) => (newRow[key] = currentValue[i][keyI])
      );
      return newRow;
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ defaultColumn, columns, data });
  return (
    <Input className={styles.tableContainer} {...props}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  className={classnames([
                    styles.plainHeader,
                    touched ? styles.invalidHeader : ''
                  ])}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      currentValue={currentValue?.[i]?.[cellIndex]}
                      onChange={v => {
                        if (!currentValue?.length) {
                          const xArray = new Array(parseInt(options.rows));
                          for (var y = 0; y < xArray.length; y++) {
                            xArray[y] = new Array(parseInt(options.columns));
                          }
                          xArray[i][cellIndex] = v.target.value;
                          onChange(xArray);
                        } else {
                          currentValue[i][cellIndex] = v.target.value;
                          onChange(currentValue);
                        }
                      }}
                      className={touched ? styles.invalid : ''}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Input>
  );
};

export default CustomTable;
