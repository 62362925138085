import React, { Component } from 'react';
import ghsLabelMapper from '../../../utils/ghsLabelMapper';

import './index.css';

class SdsButtons extends Component {
  state = {
    selected: []
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.currentValue &&
      prevProps.currentValue !== this.props.currentValue
    ) {
      this.setState({ selected: this.props.currentValue });
    }
  };

  handleSelectedButton = selectedButton => {
    let selected = [];

    if (this.state.selected.indexOf(selectedButton) > -1) {
      const index = this.state.selected.findIndex(
        report => report === selectedButton
      );

      selected = [
        ...this.state.selected.slice(0, index),
        ...this.state.selected.slice(index + 1)
      ];

      this.setState({
        selected: selected
      });
    } else {
      selected = [...this.state.selected, selectedButton];
      this.setState({ selected: selected });
    }

    this.props.handleButtonClick(selected);
  };

  render() {
    const { selected } = this.state;
    const { disabled } = this.props;

    const getClassNames = index => {
      let className = 'sdsButtons-button';

      if (selected.indexOf(index) > -1) {
        className = `${className} activeButton`;
      }

      if (disabled) {
        className = `${className} disabled`;
      }

      return className;
    };

    return (
      <div className="sdsButtons">
        <button
          className={getClassNames('0')}
          onClick={() => this.handleSelectedButton('0')}
          disabled={disabled}
          data-cy="sdsButton1"
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('0')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('1')}
          onClick={() => this.handleSelectedButton('1')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('1')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('2')}
          onClick={() => this.handleSelectedButton('2')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('2')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('3')}
          onClick={() => this.handleSelectedButton('3')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('3')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('4')}
          onClick={() => this.handleSelectedButton('4')}
          disabled={disabled}
          data-cy="sdsButton4"
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('4')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('5')}
          onClick={() => this.handleSelectedButton('5')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('5')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('6')}
          onClick={() => this.handleSelectedButton('6')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('6')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('7')}
          onClick={() => this.handleSelectedButton('7')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('7')} alt="severity" />
          </div>
        </button>
        <button
          className={getClassNames('8')}
          onClick={() => this.handleSelectedButton('8')}
          disabled={disabled}
        >
          <div className="sdsButtons-button--buttonStuff">
            <img src={ghsLabelMapper('8')} alt="severity" />
          </div>
        </button>
      </div>
    );
  }
}

export default SdsButtons;
