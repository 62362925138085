import React from 'react';
import ReactTooltip from 'react-tooltip';

import svgMapper from '../../utils/svgMapper';
import { ALL_REPORT_TYPES } from '../../constants/constants';
import { nameInput } from '../inputs';
import classnames from 'classnames';

import styles from './index.module.scss';

const UnnamedSvgWithTooltip = ({ type, className, name, ...props }) => {
  const getReportType = l => {
    const typeInfo = ALL_REPORT_TYPES.find(t => t.value === parseInt(l, 10));
    if (typeInfo?.label)
      return (
        <ReactTooltip className={styles.svgTooltip} id={name}>
          {typeInfo.label}
        </ReactTooltip>
      );
    else return <React.Fragment />;
  };

  return (
    <>
      <div
        className={classnames(styles.svgWrapped, className)}
        data-for={name}
        data-tip={styles.svgTooltip}
      >
        {svgMapper(type, props)}
      </div>
      {getReportType(type)}
    </>
  );
};

const SvgWithTooltip = nameInput(UnnamedSvgWithTooltip);
export { SvgWithTooltip };
export default SvgWithTooltip;
