import React, { Component } from 'react';

import Dropdown from '../Dropdown';

export default class DateUnitPicker extends Component {
  render() {
    return (
      <Dropdown
        {...this.props}
        options={[
          {
            label: 'days',
            value: 'days'
          },
          {
            label: 'weeks',
            value: 'weeks'
          },
          {
            label: 'months',
            value: 'months'
          },
          {
            label: 'years',
            value: 'years'
          }
        ]}
      />
    );
  }
}
