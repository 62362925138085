import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import ReactLoading from 'react-loading';

import './index.css';

export default class FileUploader extends Component {
  state = {
    percent: 0,
    imageFiles: []
  };

  onDropAccepted = async files => {
    let data = new FormData();
    files.forEach(async file => {
      data.append('attachments', file, file.name);
    });

    await this.props.addAttachment({
      data,
      ownerId: this.props.ownerId,
      ownerType: this.props.ownerType
    });
  };

  onDropRejected = () => {
    alert('File is too large. Please pick a file under 200MB.');
  };

  render() {
    return (
      <div>
        <div className="dropzone">
          <Dropzone
            onDropAccepted={this.onDropAccepted}
            className="dropzone-box"
            maxSize={200000000}
            onDropRejected={this.onDropRejected}
            multiple={true}
            data-cy="dropzone"
          >
            <div>Click here or drag files into this box to upload a file!</div>
          </Dropzone>
          {this.props.loading ? (
            <ReactLoading
              type="spinningBubbles"
              color="#58a15b"
              className="loading"
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
