import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  updateDocumentFolderRequest,
  deleteDocumentFolderRequest
} from '../../actions/documents';
import {
  getActiveFolderSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getAccessLevel } from '../../selectors/users';

import Button from '../inputs/Button';
import DeleteFolderModal from '../Modal/deleteFolderModal';
import Textbox from '../inputs/Textbox';

import { InputRow } from '../inputs';
import documentFolderStyles from './styles.module.scss';

class DocumentFolder extends Component {
  state = {
    editFolderName: false,
    openDeleteModal: false,
    folderName: '',
    folderExists: false,
    openFolder: 'All Folders'
  };

  handleFolderUpdate = () => {
    const trimmed = this.state.folderName.trim();
    if (
      this.props.documentFolders.some(
        f => !f.name.localeCompare(trimmed, 'en', { sensitivity: 'base' })
      )
    ) {
      this.setState({ folderExists: true });
      return;
    }

    this.props.updateDocumentFolder({
      ...this.props.activeFolder,
      name: trimmed
    });

    this.setState({ editFolderName: '', folderName: '', folderExists: false });
  };

  handleFolderDelete = deleteAll => {
    this.props.deleteDocumentFolder({
      ...this.props.activeFolder,
      deleteAll,
      documents: this.props.content
    });

    this.setState({ openDeleteModal: false });
  };

  render() {
    const { activeFolder, accessLevel, notEditable } = this.props;
    const { editFolderName, openDeleteModal, folderName } = this.state;

    if (!activeFolder?.name) return <></>;

    return (
      <div className={documentFolderStyles.folder}>
        {editFolderName ? (
          <div className={documentFolderStyles.editFolderWrapper}>
            <span>Old Folder Name: {activeFolder.name}</span>{' '}
            <InputRow className={documentFolderStyles.editFolder}>
              <Textbox
                onChange={v =>
                  this.setState({ folderName: v, folderExists: false })
                }
                fieldLabel="New Folder Name"
                currentValue={folderName}
                touched={this.state.folderExists}
                error="This folder name already exists"
              />
              <Button
                color="blue"
                text="Submit Folder Name"
                onClick={() => this.handleFolderUpdate()}
                disabled={folderName.trim().length < 3}
                inputClassName={documentFolderStyles.editFolderButton}
              />
              <Button
                color="white"
                text="Cancel"
                onClick={() => this.setState({ editFolderName: false })}
                inputClassName={documentFolderStyles.editFolderButton}
              />
            </InputRow>
          </div>
        ) : (
          <div className={documentFolderStyles.folderHeader}>
            <span>{activeFolder.name}</span>{' '}
            {notEditable ? (
              <></>
            ) : (
              <>
                {this.props.updateDocumentFolder && accessLevel !== 100 && (
                  <img
                    src={require('../../assets/images/Pen.png')}
                    alt="edit folder name"
                    onClick={() => this.setState({ editFolderName: true })}
                    className={documentFolderStyles.editIcon}
                  />
                )}
                {this.props.deleteDocumentFolder && accessLevel !== 100 && (
                  <img
                    src={require('../../assets/images/actionClose.png')}
                    alt="delete folder name"
                    onClick={() => this.setState({ openDeleteModal: true })}
                    className={documentFolderStyles.deleteIcon}
                  />
                )}
              </>
            )}
          </div>
        )}
        <DeleteFolderModal
          isOpen={openDeleteModal}
          folderName={folderName}
          onRequestClose={() => this.setState({ openDeleteModal: false })}
          submitActions={() => this.handleFolderDelete(false)}
          deleteActions={() => this.handleFolderDelete(true)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accessLevel: getAccessLevel(state),
  activeFolder: getActiveFolderSelector(state),
  documentFolders: getDocumentFoldersSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateDocumentFolder: values => dispatch(updateDocumentFolderRequest(values)),
  deleteDocumentFolder: values => dispatch(deleteDocumentFolderRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentFolder);
