import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addEmployee,
  listEmployees,
  exportEmployeesToCSV,
  importEmployees,
  doesUsernameExist,
  fetchAllUsers
} from '../../api/v4';
import { addMessage } from '../../actions/messages';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  downloadEmployeeQRcodeRequest,
  setActiveEmployeeRequest
} from '../../actions/personnel';
import { loadAllUsersResponse } from '../../actions/user';
import history from '../../history';
import { getLoggedInUser } from '../../selectors/users';
import {
  getUserCompaniesSelector,
  getActiveLocationId
} from '../../selectors/company';
import {
  getAttachmentLoadingSelector,
  getAddedAttachmentsSelector
} from '../../selectors/attachments';
import { commonName, validZip, email } from '../../utils/formValidation';

import {
  EMPLOYEE_BULK_UPLOAD_TEMPLATE,
  USER_ROLES
} from '../../constants/constants';
import Button from '../inputs/Button';
import ContractorForm from '../ContractorForm';
import CSVUploader from '../../components/CSVUploader';
import EmployeeForm from '../EmployeeForm';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import List from '../List';

export class EmployeeList extends Component {
  state = {
    modalOpen: 0,
    userExists: null,
    data: null,
    messages: null,
    openContractorModal: false,
    contractor: { accessLevel: 100, usedContractorForm: true },
    missingRequired: false,
    employee: null
  };

  componentDidMount() {
    listEmployees().then(data => this.setState({ data }));
  }

  handleOpenModal(modalId) {
    this.setState({
      modalOpen: modalId
    });
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: 0 });
    this.setState({ missingRequired: false });
    this.setState({ employee: null });
    this.setState({ alertMissingFields: false });
  };

  exportEmployeesToCSV = async () => {
    const src = await exportEmployeesToCSV();
    window.location = src;
  };

  handleImport = () => {
    try {
      if (!this.props.attachments[0])
        this.props.addMessage({
          error: true,
          message: 'File required!'
        });
      else {
        importEmployees(this.props.attachments[0]?.source_url).then(
          data => {
            this.props.addMessage({
              error: false,
              message: 'Imported successfully!'
            });
            this.props.clearAttachment();
          },
          error => {
            this.setState({ messages: error.data });
            this.handleOpenModal(3);
            this.props.clearAttachment();
          }
        );
      }
    } catch (e) {
      if (e.status !== 400) throw e;
      let messages = e.data;
      if (!Array.isArray(messages)) messages = [messages];
      this.setState({ messages });
    }
    this.handleCloseModal();
  };

  handleAddEmployeeSubmit = values => {
    addEmployee(values).then(() => {
      listEmployees().then(data => this.setState({ data }));
      fetchAllUsers().then(res => this.props.setAllEmployees(res));
    });
    this.setState({
      contractor: {
        accessLevel: 100,
        usedContractorForm: true,
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        username: '',
        contractorCompanyName: '',
        phoneNumber: ''
      },
      openContractorModal: false,
      modalOpen: 0
    });
    this.setState({ employee: null });
  };

  checkUsernameAvailability = username => {
    if (!username) {
      this.setState({ userExists: null });
      return;
    }

    doesUsernameExist(username).then(response => {
      this.setState({
        userExists: response?.userExists ?? null
      });
    });
  };

  render() {
    const {
      setActiveEmployee,
      userCompanies,
      loggedInUser,
      attachmentLoading,
      downloadQRCodes,
      activeLocation
    } = this.props;

    const {
      modalOpen,
      data,
      userExists,
      openContractorModal,
      contractor,
      employee,
      missingRequired
    } = this.state;
    const header = (
      <Header
        title="Employees"
        pageActionOptions={[
          {
            label: 'Add Employee',
            visible: true,
            color: 'greenOutline',
            onClick: () => this.handleOpenModal(1)
          },
          {
            label: 'Add Contractor',
            visible: true,
            color: 'greenOutline',
            onClick: () => this.setState({ openContractorModal: true })
          },
          {
            label: 'Upload Employee Template',
            visible: true,
            color: 'blueOutline',
            onClick: () => this.handleOpenModal(2)
          },
          {
            label: 'Export Employees To CSV',
            visible: true,
            color: 'blueOutline',
            onClick: this.exportEmployeesToCSV
          }
        ]}
      />
    );

    const columns = [
      { key: 'firstName' },
      { key: 'lastName' },
      {
        key: 'employeeNumber',
        label: 'Employee Id'
      },
      { label: 'Hire Date', key: 'dateOfHire', datatype: 'date' },
      { key: 'username' },
      { key: 'jobTitle' },
      {
        key: 'userType',
        accessor: row => (row.isDeactivated ? 0 : row.accessLevel),
        enum: USER_ROLES
      }
    ];

    if (!activeLocation) {
      columns.unshift({
        key: 'groupIds',
        label: 'Group/Est.',
        datatype: 'groups'
      });
    }

    const canSubmitContractor =
      this.state.contractor.companyIds?.length &&
      this.state.contractor.groupIds?.length &&
      this.state.contractor.contractorCompanyName &&
      this.state.contractor.firstName &&
      this.state.contractor.lastName &&
      this.state.contractor.jobTitle &&
      this.state.contractor.status &&
      this.state.contractor.username &&
      !userExists;

    const canSubmitEmployee =
      employee?.companyIds?.length &&
      employee.groupIds?.length &&
      employee.employeeNumber &&
      employee.firstName &&
      !commonName(employee.firstName) &&
      employee.lastName &&
      !commonName(employee.lastName) &&
      employee.dateOfHire &&
      employee.jobTitle &&
      employee.status &&
      employee.username &&
      !userExists &&
      employee.isMicrosoftUser !== undefined &&
      employee.isDeactivated !== undefined &&
      employee.accessLevel &&
      (employee.zip ? !validZip(employee.zip) : true) &&
      (employee.email ? !email(employee.email) : true) &&
      (employee.middleName ? !commonName(employee.middleName) : true);

    return (
      <>
        <HeaderAndFooter Header={header}>
          <ScrollToTopOnMount />
          <List
            settings={columns}
            actions={[
              {
                color: 'blue',
                label: 'Download QR Codes',
                onClick: ids => downloadQRCodes({ ids })
              }
            ]}
            dataIsHash
            getRowId={r => r._id}
            data={data}
            rowClick={row => {
              setActiveEmployee({ ...row });
              history.push('/app/viewEditPersonnel');
            }}
            saveKey="empList"
          />
        </HeaderAndFooter>
        {modalOpen === 1 && (
          <Modal
            title="Add New Employee"
            titleClassName="greenHeader"
            wide
            isOpen={modalOpen === 1}
            onRequestClose={this.handleCloseModal}
            submitButtonColor="green"
            testID="modal"
            onMouseEnter={() =>
              this.setState({ missingRequired: !canSubmitEmployee })
            }
            submitActions={() =>
              canSubmitEmployee ? this.handleAddEmployeeSubmit(employee) : null
            }
            submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
          >
            <EmployeeForm
              employee={employee}
              onRequestClose={this.handleCloseModal}
              onSubmit={values => this.handleAddEmployeeSubmit({ ...values })}
              userCompanies={userCompanies}
              accessLevel={loggedInUser.accessLevel}
              missingRequired={missingRequired}
              updateEmployee={values =>
                this.setState({ employee: { ...employee, ...values } })
              }
              checkUsernameAvailability={value => {
                this.checkUsernameAvailability(value);
                this.setState({ employee: { ...employee, username: value } });
              }}
              userExists={userExists}
            />
          </Modal>
        )}
        <Modal
          title="Import Employee"
          titleClassName="blueHeader"
          isOpen={modalOpen === 2}
          className="modalSmall"
          onRequestClose={this.handleCloseModal}
          submitButtonColor="blue"
          submitActions={this.handleImport}
          disableSubmit={attachmentLoading}
        >
          <Button
            color="blue"
            onClick={() => {
              var win = window.open(EMPLOYEE_BULK_UPLOAD_TEMPLATE, '_blank');
              win.focus();
            }}
            text="Download CSV Template"
          />
          <CSVUploader documentType="EmployeesUpload" className="dropzone" />
        </Modal>
        <Modal
          title="Issues with Employee Upload"
          titleClassName="redHeader"
          isOpen={modalOpen === 3}
          className="modalMedium"
          onRequestClose={this.handleCloseModal}
          hideCancelButton={true}
          submitActions={this.handleCloseModal}
          submitButtonText="Close"
          showXButton={true}
        >
          <ul>
            {this.state.messages?.map((issue, index) => (
              <li key={index}>{issue}</li>
            ))}
          </ul>
        </Modal>
        <Modal
          title="Add New Contractor"
          titleClassName="greenHeader"
          isOpen={openContractorModal}
          className="modalWide"
          onRequestClose={() => this.setState({ openContractorModal: false })}
          submitActions={() =>
            canSubmitContractor
              ? this.handleAddEmployeeSubmit(contractor)
              : null
          }
          submitButtonText="Submit"
          submitButtonColor="green"
          onMouseEnter={() =>
            this.setState({ missingRequired: !canSubmitContractor })
          }
          submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
        >
          <ContractorForm
            contractor={contractor}
            updateContractor={values =>
              this.setState({ contractor: { ...contractor, ...values } })
            }
            checkUsernameAvailability={value => {
              this.checkUsernameAvailability(value);
              this.setState({ contractor: { ...contractor, username: value } });
            }}
            missingRequired={missingRequired}
            userExists={userExists}
          />
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  userCompanies: getUserCompaniesSelector(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  attachments: getAddedAttachmentsSelector(state),
  activeLocation: getActiveLocationId(state)
});

export const mapDispatchToProps = dispatch => ({
  setActiveEmployee: employee => dispatch(setActiveEmployeeRequest(employee)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  addMessage: payload => dispatch(addMessage(payload)),
  downloadQRCodes: values => dispatch(downloadEmployeeQRcodeRequest(values)),
  setAllEmployees: employees => dispatch(loadAllUsersResponse(employees))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
