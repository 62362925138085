import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';
import classnames from 'classnames/bind';

import {
  createDocument,
  deleteDocument,
  fetchDocumentById,
  updateDocument,
  deleteAttachment
} from '../../api/v4';

import { addMessage } from '../../actions/messages';
import {
  clearUploadedAttachments,
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse
} from '../../actions/attachments';

import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';
import { getAccessLevel, getLoggedInUser } from '../../selectors/users';

import { isImage, isPDF } from '../../utils/attachmentSelector';

import { Button, Textbox } from '../../components/inputs';
import Carousel from '../../components/Carousel';
import Card from '../../components/Card';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../components/Footer';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

import styles from './myDocumentContainer.module.scss';
const bStyles = classnames.bind(styles);

export default function MyDocumentContainer(props) {
  const { id, isUserId } = useParams();
  const dispatch = useDispatch();
  const addAttachment = payload =>
    dispatch(addUploadedAttachmentsRequest(payload));

  const accessLevel = useSelector(getAccessLevel);
  const user = useSelector(getLoggedInUser);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const company = useSelector(getActiveCompany);
  const fromEmployeeProfile = props.location?.state?.fromEmployeeProfile;

  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const allAttachments = [...attachments, ...addedAttachments];

  useEffect(() => {
    if (id && !isUserId) {
      fetchDocumentById(id).then(response => {
        setLabel(response?.label);
        setDescription(response.documentData.description);
        setAttachments(response?.attachments);
      });
    } else {
      setCreating(true);
    }
  }, [id, isUserId]);

  const handleSubmit = () => {
    const attachmentIds = allAttachments?.map(a => a._id);
    const payload = {
      label,
      documentType: 'EmployeeDocument',
      companyId: company._id,
      employees: isUserId ? id : user._id,
      documentData: {
        description
      },
      attachments: attachmentIds
    };

    if (isEditing) {
      updateDocument({ ...payload, _id: id });
    } else {
      createDocument(payload).then(response => {
        dispatch(
          addMessage({
            error: false,
            message: 'Successfully Created'
          })
        );
      });
    }
    handleBack();
  };

  const handleDelete = () => {
    deleteDocument(id).then(() => history.goBack());
  };

  const handleDeleteAttachment = attachment => {
    let updatedAttachments = attachments?.filter(
      a => a._id !== attachment?._id
    );
    let updatedAddedAttachments = addedAttachments?.filter(
      a => a._id !== attachment?._id
    );

    dispatch(addUploadedAttachmentsResponse(updatedAddedAttachments));
    deleteAttachment(attachment?._id).then(response => {
      dispatch(
        addMessage({
          error: false,
          message: 'Successfully Deleted'
        })
      );
    });
    setAttachments(updatedAttachments);
  };

  const handleBack = () => {
    dispatch(clearUploadedAttachments());
    if (fromEmployeeProfile)
      history.push('/app/viewEditPersonnel', { populateEmployee: true });
    else history.goBack();
  };

  const handleDocumentUpload = async e => {
    const files = e.target.files;
    if (!files.length) return;

    let data = new FormData();
    Object.entries(files).forEach(async file => {
      if (isImage(file[1].name) || isPDF(file[1].name)) {
        data.append('attachments', file[1], file[1].name);
      } else {
        alert('Needs to be either an image file or a PDF file.');
      }
    });

    if (data.get('attachments') !== null) {
      await addAttachment({
        data,
        ownerId: user?._id,
        ownerType: 'EmployeeDocument'
      });
    }

    setHasUnsavedChanges(true);
  };

  const canSubmit = label && description && allAttachments?.length;

  const header = (
    <Header
      title="My Documents"
      section={
        !isEditing && !isCreating
          ? 'View Document'
          : isCreating
          ? 'Create Document'
          : 'Edit Document'
      }
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
      }
      needsSaved={hasUnsavedChanges}
      rightButtons={[
        {
          visible: !isCreating && !isEditing && accessLevel !== 100,
          text: 'Edit',
          color: 'blue',
          onClick: () => setEditing(true)
        },
        {
          visible: isEditing,
          text: 'Delete',
          color: 'red',
          onClick: () => setOpenDeleteModal(true)
        }
      ]}
    />
  );

  const footer =
    isEditing || isCreating ? (
      <SaveCancelFooter
        saveButtonClick={() =>
          canSubmit ? handleSubmit() : setMissingModalOpen(true)
        }
        cancelButtonClick={() =>
          hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
        }
        editing={isEditing}
        onMouseEnter={() => setMissingRequired(!canSubmit)}
      />
    ) : null;

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer}>
        <Card>
          {allAttachments.length ? (
            <Carousel
              items={allAttachments}
              deleteAction={handleDeleteAttachment}
              canDelete={true}
            />
          ) : null}
          {isEditing || isCreating ? (
            <div className={styles.addDocs}>
              <Button
                text="Add Attachments"
                color={
                  missingRequired && !allAttachments.length ? 'red' : 'blue'
                }
                testID="Add Attachments"
                onClick={() => document.getElementById('myDocument').click()}
              />
              {allAttachments.length === 0 && (
                <div className={styles.requiredDocs}>
                  *At least 1 attachment is required
                </div>
              )}
            </div>
          ) : null}
          <input
            type="file"
            id="myDocument"
            onChange={e => {
              handleDocumentUpload(e);
              e.target.value = '';
            }}
            multiple
            data-cy="customDocAttachment"
          />
          <Textbox
            fieldLabel="Document Title"
            placeholder="Type a clear and consise title."
            isRequired
            onChange={e => {
              setLabel(e);
              setHasUnsavedChanges(true);
            }}
            currentValue={label}
            disabled={!isEditing && !isCreating}
            className={bStyles({
              padding: isCreating || isEditing || allAttachments.length > 0
            })}
            touched={missingRequired && !label}
          />
          <Textbox
            type="textarea"
            fieldLabel="Description"
            placeholder="Describe this document. Be sure to include any important details."
            onChange={e => {
              setDescription(e);
              setHasUnsavedChanges(true);
            }}
            currentValue={description}
            disabled={!isEditing && !isCreating}
            isRequired
            touched={missingRequired && !description}
          />
        </Card>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() => setOpenUnsavedChangesModal(false)}
          submitActions={() => handleBack()}
          savingWhat="a Qualification"
        />
        <DeleteItemModal
          isOpen={openDeleteModal}
          onRequestClose={() => setOpenDeleteModal(false)}
          submitActions={() => handleDelete()}
          deletingWhat="Document"
        />
        <MissingRequiredModal
          isOpen={missingModalOpen}
          onRequestClose={() => setMissingModalOpen(false)}
          subject="Document"
          isEditing={isEditing}
        />
      </HeaderAndFooter>
    </>
  );
}
