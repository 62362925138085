import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../../selectors/auth';

export const AuthenticatedRoute = routerProps => {
  const auth = useSelector(isAuthenticated, shallowEqual);
  return auth && routerProps?.location?.key ? (
    <Route {...routerProps} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { referrer: routerProps.location.pathname }
      }}
    />
  );
};

export default AuthenticatedRoute;
