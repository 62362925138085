import {
  ADD_DOCUMENT_FOLDER_REQUEST,
  ADD_DOCUMENT_FOLDER_RESPONSE,
  ADD_DOCUMENTS_TO_FOLDER_REQUEST,
  ADD_DOCUMENTS_TO_FOLDER_RESPONSE,
  DELETE_DOCUMENT_FOLDER_REQUEST,
  DELETE_DOCUMENT_FOLDER_RESPONSE,
  FETCH_DOCUMENT_FOLDERS_REQUEST,
  FETCH_DOCUMENT_FOLDERS_RESPONSE,
  SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
  SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE,
  UPDATE_DOCUMENT_FOLDER_REQUEST,
  UPDATE_DOCUMENT_FOLDER_RESPONSE
} from '../constants/actionTypes';

export const fetchDocumentFoldersRequest = payload => ({
  type: FETCH_DOCUMENT_FOLDERS_REQUEST,
  payload
});

export const fetchDocumentFoldersResponse = payload => ({
  type: FETCH_DOCUMENT_FOLDERS_RESPONSE,
  payload
});

export const addDocumentFolderRequest = payload => ({
  type: ADD_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const addDocumentFolderResponse = payload => ({
  type: ADD_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const updateDocumentFolderRequest = payload => ({
  type: UPDATE_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const updateDocumentFolderResponse = payload => ({
  type: UPDATE_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const deleteDocumentFolderRequest = payload => ({
  type: DELETE_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const deleteDocumentFolderResponse = payload => ({
  type: DELETE_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const setActiveDocumentFolderRequest = payload => ({
  type: SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const setActiveDocumentFolderResponse = payload => ({
  type: SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const addDocumentsToFolderRequest = payload => ({
  type: ADD_DOCUMENTS_TO_FOLDER_REQUEST,
  payload
});

export const addDocumentsToFolderResponse = payload => ({
  type: ADD_DOCUMENTS_TO_FOLDER_RESPONSE,
  payload
});
