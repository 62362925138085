import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M65.4566 11.8644C59.3613 4.29379 50.0392 0 40 0C29.9608 0 20.6387 4.29379 14.5434 11.8644C5.81886 22.7119 5.81886 37.7401 14.5434 48.5876L40 80L65.4566 48.5876C74.1811 37.7401 74.1811 22.7119 65.4566 11.8644ZM40 13.6723C49.2026 13.6723 56.732 20.791 56.732 29.4915C56.732 38.1921 49.2026 45.3107 40 45.3107C30.7974 45.3107 23.268 38.1921 23.268 29.4915C23.268 20.791 30.7974 13.6723 40 13.6723Z" />
  </svg>
);
