import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M60.1193 54.8915L54.8915 60.1193C54.8915 60.1193 70.1866 86.6463 78.4164 78.4164C86.6463 70.1866 60.1193 54.8915 60.1193 54.8915Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9804 67.9609C52.7473 67.9609 67.9609 52.7473 67.9609 33.9804C67.9609 15.2136 52.7473 0 33.9804 0C15.2136 0 0 15.2136 0 33.9804C0 52.7473 15.2136 67.9609 33.9804 67.9609ZM33.9804 62.7331C49.8601 62.7331 62.7331 49.8601 62.7331 33.9804C62.7331 18.1008 49.8601 5.22776 33.9804 5.22776C18.1008 5.22776 5.22776 18.1008 5.22776 33.9804C5.22776 49.8601 18.1008 62.7331 33.9804 62.7331Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.7644 39.861C57.2481 37.9814 57.5054 36.011 57.5054 33.9804C57.5054 31.9499 57.2481 29.9795 56.7644 28.0999C51.5069 23.6489 43.2567 18.2972 33.9804 18.2972C24.7042 18.2972 16.454 23.6489 11.1965 28.0999C10.7128 29.9795 10.4555 31.9499 10.4555 33.9804C10.4555 36.011 10.7128 37.9814 11.1965 39.861C16.454 44.312 24.7042 49.6637 33.9804 49.6637C43.2567 49.6637 51.5069 44.312 56.7644 39.861ZM44.436 33.9804C44.436 39.7549 39.7549 44.436 33.9804 44.436C28.206 44.436 23.5249 39.7549 23.5249 33.9804C23.5249 28.206 28.206 23.5249 33.9804 23.5249C39.7549 23.5249 44.436 28.206 44.436 33.9804Z"
    />
  </svg>
);
