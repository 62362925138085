import React, { Component } from 'react';
import history from '../../history';
import ReactTooltip from 'react-tooltip';
import {
  PUBLIC_ENTITY_CODES,
  SCHOOL_ENTITY_CODES
} from '../../constants/constants';
import Modal from '../Modal';
import OshaEmploymentForm from '../../forms/OshaEmploymentInformation';
import PublicEmploymentForm from '../../forms/PublicEmploymentForm';
import SchoolEmploymentForm from '../../forms/SchoolEmploymentForm';

import './index.css';

export default class Osha300AForm extends Component {
  state = {
    hasEstablishmentInformation: false,
    modalOpen: 0,
    isGroupOrShortTermEst: false,
    goToLocation: false,
    hasSchoolEntity: false,
    hasPublicEntity: false,
    isSeparateProject: false
  };

  componentDidMount() {
    const est = this.props.divisonEstInfo;

    if (
      est.name &&
      est.mainAddress.streetAddress &&
      est.mainAddress.city &&
      est.mainAddress.stateName &&
      est.mainAddress.zip &&
      est.NAICS
    ) {
      this.setState({ hasEstablishmentInformation: true });
    }

    if (this.props.project?.isSeparateEstablishment) {
      this.setState({ isSeparateProject: true });
    }

    if (!est.isSeparateEstablishment) {
      this.setState({ isGroupOrShortTermEst: true });
    }

    this.setState({ goToLocation: est.goToLocation });

    if (
      PUBLIC_ENTITY_CODES.includes(est.entityCode) &&
      est.reportingType === 2
    ) {
      this.setState({ hasPublicEntity: true });
    } else if (
      SCHOOL_ENTITY_CODES.includes(est.entityCode) &&
      est.reportingType === 2
    ) {
      this.setState({ hasSchoolEntity: true });
    }

    this.setHoursWorked();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.year !== prevProps.year ||
      this.props.location?._id !== prevProps.location?._id
    ) {
      this.setHoursWorked();
    }
  }

  setHoursWorked = () => {
    this.props.setHours();
  };

  handleOpenModal(value) {
    this.setState({ modalOpen: value });
  }

  handleCloseModal() {
    this.setState({ modalOpen: 0 });
  }

  handleEstablishmentUpdate = () => {
    this.state.goToLocation
      ? history.push('/app/settings/Locations')
      : history.push('/app/settings/CompanyDetails');
  };

  render() {
    const {
      modalOpen,
      isGroupOrShortTermEst,
      hasEstablishmentInformation,
      hasPublicEntity,
      hasSchoolEntity,
      isSeparateProject
    } = this.state;

    const showEmploymentInfo = this.props?.project
      ? isSeparateProject
      : !isGroupOrShortTermEst;

    const {
      aggrates,
      divisonEstInfo,
      createOshaLog,
      year,
      isPerrp,
      totalHours
    } = this.props;

    const optionList = [
      'Under 20',
      '20 - 49',
      '50 - 99',
      '100 - 249',
      '250 - 499',
      '500 - 999',
      '1000+'
    ];

    let maximum = parseInt(aggrates.maximumNumberOfWorkersEmployed, 10);
    if (Number.isNaN(maximum)) maximum = undefined;

    let hasEmploymentInformation = false;
    if (
      (!hasPublicEntity &&
        !hasSchoolEntity &&
        aggrates.annualAverageNumberOfWorkers &&
        totalHours &&
        aggrates.maximumNumberOfWorkersEmployed) ||
      (hasPublicEntity &&
        aggrates.numberOfFullTime &&
        aggrates.numberOfPartTime &&
        aggrates.numberOfFireEmtParamedic &&
        aggrates.numberOfPoliceFireEmt) ||
      (hasSchoolEntity &&
        aggrates.numberOfTeachersInstructors &&
        aggrates.numberOfAllOtherSupportStaff)
    ) {
      hasEmploymentInformation = true;
    }

    return (
      <div className="osha300aForm">
        <div className="osha300aForm-leftSide">
          <div
            className={
              hasEstablishmentInformation
                ? 'osha300aForm-leftSide--establishmentInformation hasInfo'
                : 'osha300aForm-leftSide--establishmentInformation noInfo'
            }
          >
            {!hasEstablishmentInformation && (
              <div
                className="establishmentIcon"
                onClick={() => this.handleEstablishmentUpdate()}
              >
                <ReactTooltip className="errorTheme" id="divEstError">
                  <p>{`Missing ${
                    isPerrp ? 'PERRP' : 'OSHA'
                  } compliant information.`}</p>
                  <p>Click to Go Edit Establishment</p>
                </ReactTooltip>
                <img
                  src={require('../../assets/images/incompleteCase.png')}
                  alt="caseIncomplete"
                  data-tip="errorTheme"
                  data-for="divEstError"
                />
              </div>
            )}
            <h4 className="osha300aForm-leftSide--header">
              Establishment Information
            </h4>
            <div className="osha300aForm-leftSide--sectionField">
              <span className="osha300aForm-leftSide--sectionLabel">Name:</span>{' '}
              <div className="underline-fullWidth">{divisonEstInfo.name}</div>
            </div>
            <div className="osha300aForm-leftSide--sectionFieldNoBorder">
              <span className="osha300aForm-leftSide--sectionLabel">
                Street:
              </span>{' '}
              <div className="underline-fullWidth">
                {divisonEstInfo.mainAddress.streetAddress}
              </div>
              <div className="osha300aForm-leftSide--fieldCombo">
                <div>
                  <span className="osha300aForm-leftSide--sectionLabel">
                    City:
                  </span>{' '}
                  <div className="underline-cityWidth">
                    {divisonEstInfo.mainAddress.city}
                  </div>
                </div>
                <div>
                  <span className="osha300aForm-leftSide--sectionLabel">
                    State:
                  </span>{' '}
                  <span className="underline">
                    {divisonEstInfo.mainAddress.stateName}
                  </span>
                </div>
                <div>
                  <span className="osha300aForm-leftSide--sectionLabel">
                    ZIP:
                  </span>{' '}
                  <span className="underline">
                    {divisonEstInfo.mainAddress.zip}
                  </span>
                </div>
              </div>
            </div>
            {/* TODO add later */}
            {/* <div className="osha300aForm-leftSide--sectionField">
              <span className="osha300aForm-leftSide--sectionLabel">
                Industry Description:
              </span>
              <div className="underline-allWidth">
                ''
              </div>
            </div> */}
            <div className="osha300aForm-leftSide--orField">
              <div className="osha300aForm-leftSide--displayAllInline">
                <span className="osha300aForm-leftSide--sectionLabel">
                  Standard Industrial Classification:
                </span>
                {divisonEstInfo.SIC && (
                  <div style={{ margin: 0, padding: 0, display: 'inline' }}>
                    <span className="underline">{divisonEstInfo.SIC[0]}</span>
                    <span className="underline">{divisonEstInfo.SIC[1]}</span>
                    <span className="underline">{divisonEstInfo.SIC[2]}</span>
                    <span className="underline">{divisonEstInfo.SIC[3]}</span>
                  </div>
                )}
              </div>
              <div className="osha300aForm-leftSide--sectionLabel orField">
                OR
              </div>
              <div className="osha300aForm-leftSide--displayAllInline">
                <span className="osha300aForm-leftSide--sectionLabel">
                  North American Industrial Classification:
                </span>
                {divisonEstInfo.NAICS && (
                  <div style={{ margin: 0, padding: 0, display: 'inline' }}>
                    <span className="underline">{divisonEstInfo.NAICS[0]}</span>
                    <span className="underline">{divisonEstInfo.NAICS[1]}</span>
                    <span className="underline">{divisonEstInfo.NAICS[2]}</span>
                    <span className="underline">{divisonEstInfo.NAICS[3]}</span>
                    <span className="underline">{divisonEstInfo.NAICS[4]}</span>
                    <span className="underline">{divisonEstInfo.NAICS[5]}</span>
                  </div>
                )}
              </div>
            </div>
            {isPerrp && (
              <div
                className="osha300aForm-leftSide--orField"
                style={{ marginTop: '10px' }}
              >
                <div className="osha300aForm-leftSide--displayAllInline">
                  <span className="osha300aForm-leftSide--sectionLabel">
                    BWC Policy Number
                  </span>
                  {divisonEstInfo.bwcNumber && (
                    <div style={{ margin: 0, padding: 0, display: 'inline' }}>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[0]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[1]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[2]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[3]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[4]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[5]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[6]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[7]}
                      </span>{' '}
                      -
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[9]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[10]}
                      </span>
                      <span className="underline">
                        {divisonEstInfo.bwcNumber[11]}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {showEmploymentInfo && (
            <div
              className={
                hasEmploymentInformation
                  ? 'osha300aForm-leftSide--employmentInformation hasInfo'
                  : 'osha300aForm-leftSide--employmentInformation noInfo'
              }
              onClick={() => {
                if (hasPublicEntity) return this.handleOpenModal(2);
                if (hasSchoolEntity) return this.handleOpenModal(3);

                return this.handleOpenModal(1);
              }}
            >
              {!hasEmploymentInformation && (
                <div className="employmentIcon">
                  <ReactTooltip className="errorTheme" id="empError">
                    <p>{`Missing ${
                      isPerrp ? 'PERRP' : 'OSHA'
                    } compliant information.`}</p>
                    <p>Click to Edit Employment Information</p>
                  </ReactTooltip>
                  <img
                    src={require('../../assets/images/incompleteCase.png')}
                    alt="caseIncomplete"
                    data-tip="errorTheme"
                    data-for="empError"
                  />
                </div>
              )}
              <h4 className="osha300aForm-leftSide--header">
                Employment Information
              </h4>
              {isPerrp && hasPublicEntity ? (
                <div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Full Time:
                    </span>
                    <div className="underline-cityWidth">
                      {aggrates.numberOfFullTime}
                    </div>
                  </div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Part Time:
                    </span>
                    <div className="underline-cityWidth">
                      {aggrates.numberOfPartTime}
                    </div>
                  </div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Fire/EMT Paramedic:
                    </span>
                    <div className="underline-cityWidth">
                      {aggrates.numberOfFireEmtParamedic}
                    </div>
                  </div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Police/Fire/EMT:
                    </span>
                    <div className="underline-cityWidth">
                      {aggrates.numberOfPoliceFireEmt}
                    </div>
                  </div>
                </div>
              ) : isPerrp && hasSchoolEntity ? (
                <div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Teachers/Instructors:
                    </span>
                    <div className="underline-allWidth">
                      {aggrates.numberOfTeachersInstructors}
                    </div>
                  </div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      All others/support staff:
                    </span>
                    <div className="underline-allWidth">
                      {aggrates.numberOfAllOtherSupportStaff}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Annual average number of workers:
                    </span>
                    <div className="underline-allWidth">
                      {aggrates.annualAverageNumberOfWorkers}
                    </div>
                  </div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Total hours worked by all workers in {year}:
                    </span>
                    <div className="underline-allWidth">{totalHours}</div>
                  </div>
                  <div className="osha300aForm-leftSide--sectionField">
                    <span className="osha300aForm-leftSide--sectionLabel">
                      Maximum number of workers at any given time last year:
                    </span>
                    <div className="underline-allWidth">
                      {aggrates.maximumNumberOfWorkersEmployed
                        ? optionList[aggrates.maximumNumberOfWorkersEmployed]
                        : ''}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="osha300aForm-rightSide">
          <section className="osha300aForm-rightSide--sections">
            <h4 className="osha300aForm-rightSide--header">Number of Cases</h4>
            <div className="osha300aForm-rightSide--rows">
              <div>
                <div className="osha300aForm-rightSide--fieldLabel">
                  <div className="osha300aForm-rightSide--fieldLabelAlignBottom">
                    Total Deaths
                  </div>
                </div>
                <div className="osha300aForm-rightSide--underline">
                  {aggrates.totalCasesWithDeaths}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--fieldLabel">
                  With Days Away from Work
                </div>
                <div className="osha300aForm-rightSide--underline">
                  {aggrates.totalCasesWithDaysAwayFromWork}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--fieldLabel">
                  With Transfer or Restriction
                </div>
                <div className="osha300aForm-rightSide--underline">
                  {aggrates.totalCasesWithTransferOrRestriction}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--fieldLabel">
                  Total Other Cases
                </div>
                <div className="osha300aForm-rightSide--underline">
                  {aggrates.totalCasesOther}
                </div>
              </div>
            </div>
          </section>
          <section className="osha300aForm-rightSide--sections">
            <h4 className="osha300aForm-rightSide--header">Number of Days</h4>
            <div className="osha300aForm-rightSide--rows">
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  Total number of days away from work
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalNumberOfDaysAwayFromWork}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  Total number of days of job transfer or restriction
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalNumberOfDaysOfJobTransferOrRestriction}
                </div>
              </div>
            </div>
          </section>
          <section className="osha300aForm-rightSide--sections">
            <h4 className="osha300aForm-rightSide--header">
              Injury and Illness Type Totals
            </h4>
            <div className="osha300aForm-rightSide--rows osha300aForm-rightSide--illnessRows">
              <div>
                <div className="osha300aForm-rightSide--bigLabel">Injuries</div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalInjuries}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  Poisonings
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalPoisonings}
                </div>
              </div>
            </div>
            <div className="osha300aForm-rightSide--rows osha300aForm-rightSide--illnessRows">
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  Skin Disorders
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalSkinDisorders}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  Hearing Loss
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalHearingLoss}
                </div>
              </div>
            </div>
            <div className="osha300aForm-rightSide--rows osha300aForm-rightSide--illnessRows">
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  Respiratory Conditions
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalRespiratoryConditions}
                </div>
              </div>
              <div>
                <div className="osha300aForm-rightSide--bigLabel">
                  All Other Illnesses
                </div>
                <div className="osha300aForm-rightSide--underlineMargin">
                  {aggrates.totalOtherIllnesses}
                </div>
              </div>
            </div>
          </section>
        </div>
        <Modal
          title="Employment Information"
          titleClassName="blueHeader"
          isOpen={modalOpen === 1}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          formName="OshaEmploymentForm"
          submitButtonText="Save"
        >
          <OshaEmploymentForm
            onSubmit={values =>
              createOshaLog({ year, ...values }) && this.handleCloseModal()
            }
            initialValues={{
              annualAverageNumberOfWorkers:
                aggrates.annualAverageNumberOfWorkers,
              totalHoursWorkedByAllEmployees:
                aggrates.totalHoursWorkedByAllEmployees,
              maximumNumberOfWorkersEmployed: maximum
            }}
            totalHours={this.props.totalHours}
            year={year}
            project={this.props.project}
          />
        </Modal>
        <Modal
          title="Employment Information"
          titleClassName="blueHeader"
          isOpen={modalOpen === 2}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          formName="PublicEmploymentForm"
          submitButtonText="Save"
        >
          <PublicEmploymentForm
            onSubmit={values =>
              createOshaLog({ year: year, ...values }) &&
              this.handleCloseModal()
            }
            initialValues={{
              numberOfFullTime: aggrates.numberOfFullTime,
              numberOfPartTime: aggrates.numberOfPartTime,
              numberOfFireEmtParamedic: aggrates.numberOfFireEmtParamedic,
              numberOfPoliceFireEmt: aggrates.numberOfPoliceFireEmt
            }}
          />
        </Modal>
        <Modal
          title="Employment Information"
          titleClassName="blueHeader"
          isOpen={modalOpen === 3}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          formName="SchoolEmploymentForm"
          submitButtonText="Save"
        >
          <SchoolEmploymentForm
            onSubmit={values =>
              createOshaLog({ year: year, ...values }) &&
              this.handleCloseModal()
            }
            initialValues={{
              numberOfTeachersInstructors: aggrates.numberOfTeachersInstructors,
              numberOfAllOtherSupportStaff:
                aggrates.numberOfAllOtherSupportStaff
            }}
          />
        </Modal>
      </div>
    );
  }
}
