import React from 'react';
import Card from '../../Card';

import styles from './instructionCard.module.scss';

export default function InstructionCard({ name }) {
  return (
    <Card showHeader title="Instructions" name={name}>
      <p className={styles.instructionText}>
        Review all information below and fill out any missing fields. You can
        save this form and return to it later. Your insurance company will not
        be able to view this information until you have submitted the form.
      </p>
      <p className={styles.instructionText}>
        Once this form has been submitted any updates or edits will not be
        submitted to your insurance company, update within the incident, or be
        updated from the related incident.
      </p>
    </Card>
  );
}
