import { call, put, select } from 'redux-saga/effects';
import history from '../history';
import { setActiveCompany } from '../actions/company';
import { setActiveCustomReportQuestions } from '../actions/reports';
import { getActiveCompany } from '../selectors/company';
import { getActiveCustomReportQuestionsSelector } from '../selectors/reports';

export function* fetchCustomReportQuestions(apiV4) {
  try {
    const activeReport = yield select(getActiveCustomReportQuestionsSelector);

    const response = yield call(
      apiV4.fetchCustomReportQuestions,
      activeReport.reportTemplateNumber,
      activeReport.reportComponentName
    );

    if (response.length > 0) {
      yield put(setActiveCustomReportQuestions(response[0]));
    }
    history.push('/app/customReportQuestionContainer');
  } catch (error) {
    console.log(error);
  }
}

export function* createCustomReportQuestions(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeReport = yield select(getActiveCustomReportQuestionsSelector);

    payload.fields.forEach((field, index) => {
      field.subSectionOrder = 1000 + index;
      field.type = field.type.value;
      field.options = field.options.map(option => option.option);
    });

    payload = {
      ...payload,
      label: 'Custom Questions',
      companyId: activeCompany._id,
      reportTemplateNumber: activeReport.reportTemplateNumber,
      reportComponentName: activeReport.reportComponentName
    };
    yield call(apiV4.createCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    let showGeneral = yield call(apiV4.shouldShowGeneral);
    yield put(
      setActiveCompany({ ...activeCompany, showGeneral: showGeneral?.show })
    );
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* updateCustomReportQuestions(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach((field, index) => {
      field.subSectionOrder = 1000 + index;
      field.type = field.type.value ? field.type.value : field.type;

      if (field.options.length > 0 && field.options[0].option) {
        field.options = field.options.map(option => option.option);
      }
    });

    payload = {
      ...payload,
      label: 'Custom Questions',
      companyId: activeCompany._id
    };

    yield call(apiV4.updateCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    let showGeneral = yield call(apiV4.shouldShowGeneral);
    yield put(
      setActiveCompany({ ...activeCompany, showGeneral: showGeneral?.show })
    );
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* deleteCustomReportQuestions(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    yield call(apiV4.deleteCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    let showGeneral = yield call(apiV4.shouldShowGeneral);
    yield put(
      setActiveCompany({ ...activeCompany, showGeneral: showGeneral?.show })
    );
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}
