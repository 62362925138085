import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M68.56 27.68H78V50.24H68.56V27.68Z" />
    <path d="M76.16 17.76H70.4001C69.36 17.76 68.56 18.4801 68.56 19.36V24.7199H78V19.36C78 18.4801 77.2001 17.76 76.16 17.76Z" />
    <path d="M71.04 59.84L72.1601 61.6801C72.64 62.4801 73.9199 62.4801 74.4002 61.6801L75.5203 59.84C76.8002 57.7601 77.6002 55.5199 77.8403 53.1999H68.7203C68.9602 55.5999 69.7598 57.84 71.04 59.84Z" />
    <path d="M43.8033 16.8823C43.8033 18.5015 45.1867 19.9001 46.9539 19.9001H59.5563C59.3257 19.6056 59.1721 19.3111 58.8647 19.0905L44.6485 5.47328C44.4179 5.25236 44.1107 5.03169 43.8033 4.81076L43.8033 16.8823Z" />
    <path d="M46.954 23.8737C42.9582 23.8737 39.7307 20.7824 39.7307 16.9547V4H7.22554C4.38243 4 2 6.20819 2 9.00536V68.9946C2 71.718 4.30533 74 7.22554 74H55.1763C58.0195 74 60.4019 71.7918 60.4019 68.9946L60.4016 23.8739L46.954 23.8737Z" />
  </svg>
);
