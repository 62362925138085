import { createSelector } from 'reselect';

const userSelector = state => state.user;

export const getLoggedInUser = createSelector(
  userSelector,
  user => user && user.activeUser
);

export const getMyUserId = createSelector(
  userSelector,
  user => user?.activeUser?._id
);

export const getAccessLevel = createSelector(
  userSelector,
  user => user.activeUser?.accessLevel
);

export const getUserLoading = createSelector(
  userSelector,
  user => user && user.loading
);

export const getLoadedUsers = createSelector(userSelector, user => user.users);
export const getAllUsers = createSelector(userSelector, u => u.allUsers);
export const getAllUsersLoaded = createSelector(userSelector, u => u.allLoaded);
export const getAllCompanyUsers = createSelector(
  userSelector,
  u => u.companyUsers
);
