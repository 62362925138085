import React from 'react';

export default ({ width, height, x, y, ...props }) => (
  <svg
    width={width || '30px'}
    height={height || '25px'}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1224_131767)">
      <path d="M24.3743 33.3748L64.4997 78.1248C66.3747 80.2498 69.6247 80.2498 71.625 78.3748L78.375 71.6248C80.375 69.6248 80.25 66.3748 78.125 64.4994L33.375 24.3741C36.125 17.9991 34.875 10.3741 29.75 5.24878C25.25 0.748779 19 -0.751221 13.25 0.748779C12.5 0.873779 12.25 1.74878 12.75 2.24878L23.1247 12.6234L12.6247 23.1234L2.25 12.7488C1.75 12.2488 0.875 12.4988 0.75 13.2488C-0.75 18.9988 0.75 25.2488 5.25 29.7488C10.375 34.8738 18.1247 36.1238 24.3753 33.3738L24.3743 33.3748Z" />
      <path d="M27.8738 43.5L38.8738 55.75L17.2485 77.3753C14.7485 79.8753 11.2485 80.3753 8.24851 79.1253C7.24851 78.7503 6.37351 78.1253 5.62351 77.3753C4.87351 76.6253 4.24851 75.7503 3.87351 74.7503C2.62351 71.7503 3.12351 68.1253 5.62351 65.7503L27.8738 43.5Z" />
      <path d="M76.873 11.8748L67.123 24.2495L63.373 25.3745L62.623 24.7495L51.873 35.4995L47.248 31.3745L58.248 20.3745L57.498 19.6245L58.748 15.8745L70.998 6.12451L76.873 11.8748Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131767">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
