import React, { Component } from 'react';
import {
  FIELD_TYPES_CUSTOMIZABLE,
  FIELD_TYPES_OPTIONS
} from '../../../constants/constants';
import reportTemplateMapper from '../../../utils/reportTemplateMapper';
import Button from '../../inputs/Button';
import Card from '../../Card';
import Checkbox from '../../inputs/Checkbox';
import Dropdown from '../../inputs/Dropdown';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';
import { InputRow } from '../../inputs';
import HoverImage from '../../HoverImage';
import { cloneDeep } from 'lodash';

import styles from './customReportQuestion.module.scss';
import { Rearranger } from '../../Rearranger';

export default class CustomReportQuestionsCard extends Component {
  state = {
    modalOpen: false,
    activeOptions: [],
    rowIndex: 0
  };

  handleCheckboxChange = (index, field, answer) => {
    let fields = [...this.props.fields];
    fields[index][field] = answer;

    this.props.handleChange({ fields });
  };

  handleQuestionChange = (index, questionLabel) => {
    let fields = [...this.props.fields];
    fields[index][questionLabel.field] = questionLabel.answer;

    this.setState({
      rowIndex: null,
      modalOpen: false
    });
    this.props.handleChange({ fields });
  };

  addRowsToTemplate = numberOfRows => {
    const fields = [...this.props.fields];

    for (let i = 1; i <= numberOfRows; i++) {
      fields.push({
        type: '',
        label: '',
        options: [],
        showNotApplicable: false,
        required: false,
        hide: false,
        legacy: false,
        subSection: 'Custom Questions'
      });
    }

    this.props.handleChange({ fields });
  };

  setChangeOptions = (options, rowIndex) => {
    options = options.map(option => {
      if (option.option) return option;
      return {
        option,
        isEditable: false
      };
    });

    this.setState({ modalOpen: true, rowIndex, activeOptions: options });
  };

  renderItem = (item, index) => {
    const notEditable = '_id' in item;
    const { disabled, missingRequired } = this.props;
    const tooltip =
      'Existing questions cannot have their properties changed, due to data integrity issues.  If you need to change the properties, you must delete and re-add the question.';

    return (
      <>
        <Textbox
          currentValue={item.label}
          onChange={answer =>
            this.handleQuestionChange(index, { field: 'label', answer })
          }
          fieldLabel="Question"
          type="textarea"
          placeholder="Type your question here (e.g. Who fell?)"
          isRequired
          disabled={disabled}
          name={`question-${index}`}
          touched={missingRequired && !item.label}
        />
        <Checkbox
          onChange={v => this.handleCheckboxChange(index, 'required', v)}
          currentValue={item.required}
          disabled={disabled || notEditable}
          fieldLabel="Required"
          displayTooltip={!disabled && notEditable}
          name={`required-${index}`}
          tooltip={tooltip}
        />
        <Dropdown
          options={FIELD_TYPES_CUSTOMIZABLE}
          fieldLabel="Type of Input"
          onChange={values =>
            this.handleQuestionChange(index, {
              field: 'type',
              answer: values
            })
          }
          isRequired
          placeholder="Choose a question type (e.g. Textbox, Dropdown)"
          currentValue={item.type}
          disabled={disabled || notEditable}
          displayTooltip={!disabled && notEditable}
          name={`type-${index}`}
          tooltip={tooltip}
          testID={`card${index}`}
          bareValues={false}
          touched={missingRequired && !item.type}
        />
        {item?.type?.value !== 'signature' && item?.type !== 'signature' && (
          <Checkbox
            onChange={v =>
              this.handleCheckboxChange(index, 'showNotApplicable', v)
            }
            currentValue={item.showNotApplicable}
            disabled={disabled || notEditable}
            fieldLabel="Add Not Applicable Checkbox"
            name={`notApplicable-${index}`}
            displayTooltip={!disabled && notEditable}
          >
            {tooltip}
          </Checkbox>
        )}
        {(FIELD_TYPES_OPTIONS.includes(item.type.value) ||
          FIELD_TYPES_OPTIONS.includes(item.type)) && (
          <Button
            text="View Options"
            color="blue"
            onClick={() => this.setChangeOptions(item.options, index)}
            disabled={disabled}
          />
        )}
        {(FIELD_TYPES_OPTIONS.includes(item.type.value) ||
          FIELD_TYPES_OPTIONS.includes(item.type)) &&
          item.options.length === 0 && (
            <div style={{ color: '#c74846', fontWeight: 'bold' }}>
              * Must add at least one option
            </div>
          )}
      </>
    );
  };

  render() {
    const { modalOpen, activeOptions, rowIndex } = this.state;
    const { disabled, activeTemplate } = this.props;

    const footer = !disabled && (
      <InputRow className={styles.footer}>
        <Button
          text="Add Row"
          color="blue"
          onClick={() => this.addRowsToTemplate(1)}
        />
        <Button
          text="Add Five Rows"
          color="blue"
          onClick={() => this.addRowsToTemplate(5)}
        />
      </InputRow>
    );

    return (
      <>
        <Card
          showHeader
          title={`${
            reportTemplateMapper(activeTemplate.reportTemplateNumber).report
          } - ${activeTemplate.reportComponentName}`}
        >
          <Rearranger
            className={styles.fields}
            items={this.props.fields}
            onChange={fields => this.props.handleChange({ fields })}
            renderItem={this.renderItem}
            disabled={this.props.disabled || this.props.notEditable}
            onDuplicate={row => {
              const clone = cloneDeep(row);
              delete clone._id;
              return clone;
            }}
          />
          {footer}
        </Card>
        <Modal
          title="Change Options"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ modalOpen: false, activeOptions: [] })
          }
          submitActions={() =>
            this.handleQuestionChange(rowIndex, {
              field: 'options',
              answer: activeOptions
            })
          }
          disableSubmit={
            activeOptions?.length < 1 ||
            activeOptions.some(option => option.option?.trim().length < 1)
          }
        >
          <div>
            {activeOptions.map((option, index) => (
              <div className={styles.options} key={index}>
                <Textbox
                  currentValue={option.option}
                  onChange={v => {
                    const currentOptions = activeOptions;
                    currentOptions[index].option = v;
                    this.setState({ activeOptions: currentOptions });
                  }}
                  placeholder="Type your option here."
                  disabled={!option.isEditable}
                  className={styles.option}
                  testID={'option' + index}
                />
                {option.isEditable && (
                  <HoverImage
                    className={styles.removeImage}
                    onClick={() =>
                      this.setState({
                        activeOptions: [
                          ...activeOptions.slice(0, index),
                          ...activeOptions.slice(index + 1)
                        ]
                      })
                    }
                    src={require('../../../assets/images/removePermissions.png')}
                    srcHover={require('../../../assets/images/removePermissonsHover.png')}
                  />
                )}
              </div>
            ))}
            <Button
              text="Add Option"
              color="blue"
              onClick={() =>
                this.setState({
                  activeOptions: [
                    ...activeOptions,
                    { option: '', isEditable: true }
                  ]
                })
              }
            />
          </div>
        </Modal>
      </>
    );
  }
}
