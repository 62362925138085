import React from 'react';
import classnames from 'classnames/bind';
import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

const AddButton = ({
  text,
  name,
  onClick,
  onMouseDown,
  disabled,
  testID,
  circleSide = 'right'
}) => {
  return (
    <div>
      <button
        data-cy={testID}
        className={bStyles({
          button: true,
          leftCircle: circleSide === 'left'
        })}
        type="button"
        id={name}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        {circleSide === 'left' ? (
          <div className={styles.circle}> + </div>
        ) : null}
        <div className={styles.buttonText}>{text}</div>
        {circleSide === 'right' ? (
          <div className={styles.circle}> + </div>
        ) : null}
      </button>
    </div>
  );
};

export default AddButton;
