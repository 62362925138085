import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import history from '../../../history';
import {
  deleteAttachment,
  fetchTrainingById,
  updateInPersonTraining,
  uploadAttachment
} from '../../../api/v4';
import { isImage, isPDF } from '../../../utils/attachmentSelector';
import { addMessage } from '../../../actions/messages';
import Card from '../../../components/Card';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SubmitSaveForLaterFooter } from '../../../components/Footer';
import DueDateText from '../../../components/DueDateText';
import SignatureCard from '../../../components/SignatureCard';
import LeftNav from '../../../components/LeftNav';
import Input, { Button, EmployeeDropdown } from '../../../components/inputs';
import TrainingMaterials from '../TrainingMaterials';
import BlockText from '../../../components/inputs/BlockText';
import { QuizStatusCard } from '../../../components/training/Cards/QuizStatusCard';
import MarkItemAsCompleteModal from '../../../components/Modal/markItemAsCompleteModal';
import FileDownloader from '../../../components/FileDownloader';
import { getActiveCompany } from '../../../selectors/company';

import styles from './styles.module.scss';

export default function AdministerTraining() {
  const { trainingId } = useParams();
  const dispatch = useDispatch();
  const company = useSelector(getActiveCompany);
  const [training, setTraining] = useState({});
  const [completeModal, setCompleteModal] = useState(false);
  const groupName = company.groups.find(
    g => g._id.toString() === training?.groupIds?.[0]
  )?.name;

  useEffect(() => {
    let stored = localStorage.getItem('trainingTemplate');
    if (stored) {
      setTraining(JSON.parse(stored));
      localStorage.removeItem('trainingTemplate');
    } else if (trainingId) {
      fetchTrainingById(trainingId).then(r => {
        setTraining(r);
      });
    }
  }, [trainingId]);
  const handleBack = () => {
    localStorage.removeItem('trainingTemplate');
    history.goBack();
  };
  const handleSubmit = (complete = false) => {
    if (trainingId)
      updateInPersonTraining({
        ...training,
        isCompleted: training.releaseType === 2,
        isAdministered: complete
      }).then(handleBack);
  };

  let leftNav = [{ label: training.title, id: 'title' }];

  if (training.attachments?.length)
    leftNav.push({ label: 'Materials', id: 'materials' });
  if (training.quiz) leftNav.push({ label: 'Quiz', id: 'quiz' });
  if (training.releaseType === 2)
    leftNav.push({ label: 'Attendance', id: 'attendance' });
  leftNav.push({
    label: 'Administrator Signature',
    id: 'Administrator Signature'
  });

  const handleDocumentUpload = async e => {
    const files = e.target.files;
    if (!files.length) return;

    let data = new FormData();
    Object.entries(files).forEach(async file => {
      if (isImage(file[1].name) || isPDF(file[1].name)) {
        data.append('attachments', file[1], file[1].name);
      } else {
        dispatch(
          addMessage({
            error: true,
            message: 'Must be either an image file or  PDF file.'
          })
        );
      }
    });

    if (data.get('attachments') !== null) {
      uploadAttachment(data, {
        ownerId: training._id,
        ownerType: 'TrainingAttendanceSheet'
      }).then(r => setTraining({ ...training, attendanceDoc: r[0] }));
    }
  };

  const handleDeleteAttachment = attachment => {
    deleteAttachment(attachment?._id).then(response => {
      delete training.attendanceDoc;
      dispatch(
        addMessage({
          error: false,
          message: 'Successfully Deleted'
        })
      );
    });
  };

  const header = (
    <Header
      title={`Administer Training`}
      section={training.title?.concat(groupName ? ` - ${groupName}` : '')}
      clickBack={handleBack}
    />
  );

  const footer = (
    <SubmitSaveForLaterFooter
      submitButtonDisabled={
        (training.releaseType !== 2 && !training?.assignedTo?.length) ||
        !training.adminSignature
      }
      submitButtonClick={() => setCompleteModal(true)}
      saveButtonClick={() => handleSubmit()}
      submitButtonText="Submit"
    />
  );
  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={true}
        Left={<LeftNav items={leftNav} />}
        className={styles.trainingContainer}
      >
        <Card
          showHeader
          title={training.title?.concat(groupName ? ` - ${groupName}` : '')}
          name="title"
        >
          {training.instructions ? (
            <Input className={styles.instructions} fieldLabel="Instructions">
              {training.instructions}
            </Input>
          ) : null}
          <DueDateText date={training.dueDate} left />
          {training.releaseType !== 2 ? (
            <>
              <EmployeeDropdown
                multi
                searchable
                selectButtons
                isRequired
                group={training?.groupIds?.[0]}
                currentValue={training?.assignedTo}
                onChange={v => {
                  let newDidNotAttend = training?.empsWhoDidntAttend?.filter(
                    e => !v?.includes(e)
                  );
                  setTraining({
                    ...training,
                    assignedTo: v,
                    empsWhoDidntAttend: newDidNotAttend
                  });
                }}
                filterChoices={[
                  {
                    label: 'Inactive Employees',
                    value: 'inactive'
                  }
                ]}
                fieldLabel="Attendee(s):"
              />
              <EmployeeDropdown
                multi
                searchable
                selectButtons
                currentValue={training?.empsWhoDidntAttend}
                fieldLabel="Employee(s) Who Didn't Attend"
                tooltip="If this field is filled out on submission a new in person training will be assigned"
                displayTooltip
                hideDeactivated
                exclude={training?.assignedTo}
                onChange={v => {
                  setTraining({ ...training, empsWhoDidntAttend: v });
                }}
                group={training?.groupIds?.[0]}
              />
            </>
          ) : null}
        </Card>
        {training.attachments?.length || training.youtubeLinks?.length ? (
          <Card showHeader title="Materials" name="materials">
            <BlockText blockOfText="This is where you will see all training materials. Please go through each one by one. When you have completed all the training material, sign at the bottom and submit." />
            <TrainingMaterials
              hideLabel
              hideTime
              training={training}
              fromApproveTraining
              attachments={training?.attachments}
              youtubeLinks={training?.youtubeLinks}
            />
          </Card>
        ) : null}
        {training.quiz ? (
          <QuizStatusCard
            fromAdminister
            training={training}
            canTakeQuiz={false}
            name="quiz"
            showAnswers={false}
          />
        ) : null}
        {training.releaseType === 2 ? (
          <Card
            showHeader
            title="Attendance"
            name="attendance"
            className={styles.instructions}
          >
            <img
              src={training.qrCodeImageUrl}
              className={styles.qrCode}
              alt={'Error Displaying QR Code'}
            />
            <Button
              color="blue"
              text="Download QR Code"
              onClick={() =>
                window.open(`https://${training.qrCodeUrl}`, '_blank')
              }
              inputClassName={styles.button}
            />
            <Button
              color="blue"
              text="Upload Attendance Sheet"
              onClick={() => document.getElementById('attendanceSheet').click()}
              inputClassName={styles.button}
              disabled={training.attendanceDoc}
            />
            <input
              type="file"
              id="attendanceSheet"
              onChange={e => {
                handleDocumentUpload(e);
                e.target.value = '';
              }}
            />
            {training.attendanceDoc ? (
              <FileDownloader
                attachment={training.attendanceDoc}
                ownerType="TrainingAttendanceSheet"
                deleteAttachment={handleDeleteAttachment}
              />
            ) : null}
            {training.releaseType === 2 ? (
              <EmployeeDropdown
                multi
                currentValue={training?.assignedTo}
                disabled
                fieldLabel="Attendee(s):"
              />
            ) : null}
          </Card>
        ) : null}
        <SignatureCard
          header="Administrator Signature"
          currentValue={training.adminSignature}
          name="Administrator Signature"
          onChange={v => setTraining({ ...training, adminSignature: v })}
          ownerType="training"
          ownerId={training._id}
          disabled={training.isCompleted}
        />
        <MarkItemAsCompleteModal
          message="By Signing and Submitting your signature you are confirming that you
        have administered the In-Person Training."
          isOpen={completeModal}
          cancelButtonText="Cancel"
          cancelButtonColor="white"
          completingWhat="In-Person Training"
          onRequestClose={() => setCompleteModal(false)}
          submitActions={() => handleSubmit(true)}
          cancelActions={() => setCompleteModal(false)}
        />
      </HeaderAndFooter>
    </>
  );
}
