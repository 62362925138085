import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 90 85"
    width="30px"
    height="25px"
  >
    <g>
      <g>
        <path d="M56.5,5.3L56.5,5.3L56.5,5.3L56.5,5.3z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M56.5,5.3L56.5,5.3L56.5,5.3L56.5,5.3z" />
      </g>
    </g>
    <g>
      <path d="M73.2,23.2l-1.8-2l-2-2.1l-9.2-9.9l-1.8-2l-1.8-2l-0.1,0.1V5.3H28.2c-4.4,0-7.9,3.5-7.9,7.9v63.6c0,4.3,3.5,7.9,7.9,7.9   h37.3c4.4,0,7.9-3.6,7.9-7.9V23.2H73.2z M69.4,76.8c0,2.2-1.8,4-4,4H28.2c-2.2,0-4-1.8-4-4V13.2c0-2.2,1.8-4,4-4h24.4v12   c0,3.3,2.7,5.9,5.9,5.9h10.9V76.8z M58.5,23.2c-1.1,0-2-0.9-2-2v-12l12.9,13.9H58.5z" />
      <rect x="27.3" y="61.8" width="38.5" height="4" />
      <rect x="27.3" y="53.7" width="38.7" height="4" />
      <rect x="27.3" y="45.5" width="24.5" height="4" />
      <rect x="27.3" y="37.4" width="24.5" height="4" />
      <rect x="27.3" y="28.7" width="24.5" height="4" />
    </g>
  </svg>
);
