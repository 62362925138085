import React, { Component } from 'react';

import classnames from 'classnames/bind';

import styles from './hoverImage.module.scss';
const bStyles = classnames.bind(styles);

export default class HoverImage extends Component {
  render() {
    let {
      className,
      alt,
      src,
      srcHover,
      selected,
      srcSelected,
      srcHoverSelected,
      onClick,
      testID,
      ...props
    } = this.props;
    src = src || srcSelected;
    srcHover = srcHover || srcHoverSelected;
    srcSelected = srcSelected || src;
    srcHoverSelected = srcHoverSelected || srcHover;

    return (
      <div
        className={bStyles(className, {
          container: true,
          onClick: onClick
        })}
        onClick={onClick}
        {...props}
      >
        <img
          className={styles.hoverOff}
          src={selected ? srcSelected : src}
          alt={alt}
          data-cy={testID}
        />
        <img
          className={styles.hoverOn}
          src={selected ? srcHoverSelected : srcHover}
          alt={alt}
          data-cy={`${testID}Hover`}
        />
      </div>
    );
  }
}
