import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useState } from 'react';
import Button from '../../components/inputs/Button';
import '../../components/inputs/input.css';
import { Checkbox, Textbox } from '../../components/inputs';
import '../form.css';
import styles from './loginForm.module.scss';

export default function LoginForm(props) {
  const {
    onSubmit,
    isLoading,
    setLoginPage,
    onMicrosoftSubmit,
    rememberUser,
    handleChangeCheckbox,
    badUsername,
    badPassword
  } = props;
  const [filledUser, setFilled] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});
  const [missingRequired, setMissingRequired] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('username') && !filledUser) {
      setLoginInfo({ username: localStorage.getItem('username') });
      setFilled(true);
    }
  }, [filledUser]);

  const canSubmit =
    loginInfo?.username !== undefined && loginInfo?.password !== undefined;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(loginInfo);
      }}
    >
      <h2>Log In</h2>
      <Textbox
        fieldLabel="Username"
        currentValue={loginInfo?.username}
        onChange={v => setLoginInfo({ ...loginInfo, username: v })}
        touched={(missingRequired && !loginInfo?.username) || badUsername}
        name="username"
      />
      <Textbox
        fieldLabel="Password"
        currentValue={loginInfo?.password}
        onChange={v =>
          setLoginInfo({
            ...loginInfo,
            password: v
          })
        }
        touched={(missingRequired && !loginInfo?.password) || badPassword}
        type="password"
        name="password"
      />
      {/* eslint-disable-next-line */}
      <a href="#" onClick={() => setLoginPage('forgotPassword')}>
        Forgot password?
      </a>
      <Checkbox
        fieldLabel="Remember Me"
        onChange={handleChangeCheckbox}
        currentValue={rememberUser}
      />
      <Button
        type="submit"
        color="green"
        text={
          isLoading ? (
            <ReactLoading
              type="spinningBubbles"
              color="#FFF"
              className="loading"
              height="45px"
              width="45px"
            />
          ) : (
            'Login'
          )
        }
        onMouseEnter={() => setMissingRequired(!canSubmit)}
      />
      <Button
        color="white"
        image={require('../../assets/images/microsoft logo.png')}
        text="Sign in with Microsoft"
        className={styles.microsoftLoginButton}
        onClick={onMicrosoftSubmit}
      />
    </form>
  );
}
