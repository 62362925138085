import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 100 100"
  >
    <path
      d="M93.6,74.6L79,49.3l-3.4,3.4l15.1,24.8c0.1,0.2,0.1,0.5,0.1,0.7c0,1.3-1.1,2.3-2.3,2.3l-77.1,1
                    c-1.3,0-2.3-1-2.3-2.3c0-0.2,0.1-0.5,0.1-0.7l40-67.9c0.3-0.5,0.9-0.6,1.4-0.4c0.2,0.1,0.3,0.2,0.4,0.4l15,25l3.4-3.4L56.1,9.6
                    c-1.9-3.3-6.2-4.5-9.6-2.6c-1.1,0.6-1.9,1.5-2.6,2.6l-37.5,65c-1.7,3-0.9,10.8,1.6,15.2c2.1,3.6,4.9,4.2,6.3,4.2h71.4
                    c1.5,0,4.2-0.5,6.3-4.2C94.5,85.4,95.3,77.6,93.6,74.6z M87.8,88.8c-0.2,0.5-0.5,0.9-0.9,1.1L14,90.4c-0.4-0.1-1.5-1.3-1.7-1.9
                    c-0.8-1.6-1.6-2.6-1.8-4.5c0.6,0.3,3.5,0.4,4.2,0.4L87.4,84c0.7,0,1.3-0.1,2-0.5C89.1,85.3,88.5,87.2,87.8,88.8L87.8,88.8z"
    />
    <polygon points="52.5,60.8 35.6,44.1 31,48.7 52.4,70 90.5,32.3 85.5,27.2 " />
  </svg>
);
