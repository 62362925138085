import {
  CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  CREATE_OSHA_FORM_REQUEST,
  CREATE_OSHA_FORM_RESPONSE,
  CREATE_REPORT_RESPONSE,
  DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
  FETCH_INCIDENT_REPORT_RESPONSE,
  SET_ACTIVE_CUSTOM_REPORT_QUESTIONS,
  SET_REPORT_TEMPLATES,
  UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  UPDATE_OSHA_FORM_REQUEST,
  UPDATE_OSHA_FORM_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  reports: [],
  oshaLog: {},
  reportTemplates: [],
  activeCustomQuestions: {}
};

const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_OSHA_FORM_REQUEST:
    case UPDATE_OSHA_FORM_REQUEST:
    case FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CREATE_REPORT_RESPONSE:
      return {
        ...state,
        loading: false,
        reports: payload
      };

    case FETCH_INCIDENT_REPORT_RESPONSE:
      return {
        ...state,
        loading: false,
        reports: payload
      };

    case CREATE_OSHA_FORM_RESPONSE:
    case UPDATE_OSHA_FORM_RESPONSE:
      return {
        ...state,
        oshaLog: payload
      };

    case SET_REPORT_TEMPLATES:
      return {
        ...state,
        reportTemplates: payload
      };

    case SET_ACTIVE_CUSTOM_REPORT_QUESTIONS:
      return {
        ...state,
        loading: false,
        activeCustomQuestions: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default reports;
