import React, { Component } from 'react';

import Dropdown from '../../components/inputs/Dropdown';
import classnames from 'classnames/bind';

import styles from './stageSelector.module.scss';

export class StageSelector extends Component {
  render() {
    let {
      onChange,
      className,
      fieldLabel,
      testID,
      name,
      label,
      ...props
    } = this.props;

    return (
      <div className={classnames(className, styles.container)}>
        {label && <div className={styles.stageLabel}>{label}:</div>}
        <Dropdown
          fieldLabel={fieldLabel}
          testID={testID}
          onChange={onChange}
          className={styles.input}
          labelStyles={styles.stageLabel}
          {...props}
        />
      </div>
    );
  }
}

export default StageSelector;
