import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Input, nameInput } from '../';
import Loading from '../../Loading';
import classnames from 'classnames/bind';
import { noBubble } from '../../../utils/events';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

const Button = ({
  text,
  color,
  inputClassName,
  type,
  image,
  name,
  onClick,
  onMouseDown,
  disabled,
  loading,
  shrink,
  testID,
  imageClass,
  isDashboard,
  onMouseEnter,
  isFromHeader,
  ...props
}) => {
  type = type || 'button';
  color = color || 'white';
  let buttonClasses = {
    button: true,
    [color]: true,
    shrink: shrink
  };

  if (loading) {
    disabled = true;
    onClick = noBubble;
  }
  return (
    <Input name={name} disabled={disabled} inline={shrink} {...props}>
      <button
        data-cy={testID}
        className={bStyles(inputClassName, buttonClasses)}
        type={type}
        id={name}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        style={{
          boxShadow: isDashboard ? '0px 0px 2px 1px hsl(0, 0%, 90%)' : null
        }}
        onMouseEnter={onMouseEnter}
      >
        {loading ? (
          <Loading size={32} skipDiv />
        ) : image ? (
          typeof image !== 'string' ? (
            <div className={imageClass}>{image}</div>
          ) : (
            <img
              src={image}
              className={bStyles(styles.image, imageClass)}
              alt="Button"
            />
          )
        ) : (
          <></>
        )}
        {isFromHeader ? (
          <>
            <span data-tip data-for={text}>
              {text}
            </span>
            <ReactTooltip id={text} key={text}>
              <span>{text}</span>
            </ReactTooltip>{' '}
          </>
        ) : (
          <div>{text}</div>
        )}
      </button>
    </Input>
  );
};

const nButton = nameInput(Button);
export { nButton as Button };
export default nButton;
