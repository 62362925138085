import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../history';
import {
  createCustomReportQuestionsRequest,
  deleteCustomReportQuestionsRequest,
  setActiveCustomReportQuestions,
  updateCustomReportQuestionsRequest
} from '../../actions/reports';
import { getActiveCustomReportQuestionsSelector } from '../../selectors/reports';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import CustomReportQuestionsCard from '../../components/reportCards/CustomReportQuestionsCard';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export class CustomReportQuestionContainer extends Component {
  state = {
    openUnsavedTemplateModal: false,
    openUnsavedChangesModal: false,
    openDeleteTemplateModal: false,
    openUpdateCustomQuestionsModal: false,
    fields: [],
    isCreating: false,
    isEditing: false,
    hasUnsavedChanges: false,
    missingRequired: false,
    missingModalOpen: false
  };

  componentDidMount() {
    this.setState({ isCreating: true });

    if (this.props.activeTemplate._id) {
      this.setState({ ...this.props.activeTemplate, isCreating: false });
    } else {
      this.setState({
        isCreating: true,
        fields: [
          {
            type: '',
            label: '',
            options: [],
            showNotApplicable: false,
            required: false,
            hide: false,
            legacy: false,
            subSection: 'Custom Questions'
          }
        ]
      });
    }
  }

  handleCustomSubmit = () => {
    if (this.state._id) {
      this.props.updateCustomTemplate(this.state);
    } else {
      const stringCopy = JSON.stringify(this.state);
      const copy = JSON.parse(stringCopy);
      this.props.createCustomTemplate(copy);
    }
  };

  handleCustomDelete = () => {
    this.props.deleteCustomTemplate(this.state);
  };

  canSubmit = () => {
    const questionsHaveAllParts = this.state.fields.filter(
      question =>
        !question.type ||
        !question.label?.trim() ||
        (question.type &&
          ['dropdown', 'multiselect', 'radiobuttons', 'checkbox'].includes(
            question.type.value
          ) &&
          question.options.length === 0)
    );

    if (this.state.fields.length > 0 && questionsHaveAllParts.length === 0) {
      return true;
    }

    return false;
  };

  render() {
    const {
      openUnsavedTemplateModal,
      openUnsavedChangesModal,
      openDeleteTemplateModal,
      openUpdateCustomQuestionsModal,
      fields,
      isCreating,
      isEditing,
      hasUnsavedChanges,
      missingRequired,
      missingModalOpen
    } = this.state;

    const { setActiveCustomReportQuestions, activeTemplate } = this.props;

    const header = (
      <Header
        title="Custom Report Questions"
        section={
          !isEditing && !isCreating
            ? 'View Questions'
            : isCreating
            ? 'Create Questions'
            : 'Edit Questions'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges && isEditing
            ? this.setState({ openUnsavedChangesModal: true })
            : hasUnsavedChanges && isCreating
            ? this.setState({ openUnsavedTemplateModal: true })
            : setActiveCustomReportQuestions({}) && history.goBack()
        }
        rightButtons={{
          visible: !isCreating,
          text: isEditing ? 'Delete Report Questions' : 'Edit Report Questions',
          color: isEditing ? 'red' : 'blue',
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteTemplateModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonClick={() =>
          !this.canSubmit()
            ? this.setState({ missingModalOpen: true })
            : isEditing
            ? this.setState({ openUpdateCustomQuestionsModal: true })
            : this.handleCustomSubmit()
        }
        cancelButtonClick={() =>
          isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : isEditing && !hasUnsavedChanges
            ? this.setState({ isEditing: false })
            : this.setState({ openUnsavedChangesModal: true })
        }
        editing={isEditing}
        onMouseEnter={() =>
          this.setState({ missingRequired: !this.canSubmit() })
        }
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating}
        >
          <CustomReportQuestionsCard
            handleChange={values =>
              this.setState({ ...values, hasUnsavedChanges: true })
            }
            fields={fields}
            disabled={!isEditing && !isCreating}
            activeTemplate={activeTemplate}
            isEditing={isEditing}
            missingRequired={missingRequired}
          />
        </HeaderAndFooter>
        <Modal
          title="Unsaved Report Questions"
          titleClassName="redHeader"
          isOpen={openUnsavedTemplateModal}
          submitButtonColor="red"
          submitButtonText="Discard Report Questions"
          onRequestClose={() =>
            this.setState({ openUnsavedTemplateModal: false })
          }
          submitActions={() =>
            setActiveCustomReportQuestions({}) && history.goBack()
          }
        >
          <div className="reportSectionContainer-emailAssigneeText">
            You are navigating away from a template that you were creating.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Leaving without saving will result in this template not being
              created.{' '}
            </span>
            If you wish to keep your template, click “Cancel”, and then hit
            “Save Changes” at the bottom of the create template screen.
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() =>
            setActiveCustomReportQuestions({}) && history.goBack()
          }
          savingWhat="a template"
        />
        <Modal
          title="Delete Report Questions"
          titleClassName="redHeader"
          isOpen={openDeleteTemplateModal}
          submitButtonColor="red"
          submitButtonText="Delete Report Questions"
          onRequestClose={() =>
            this.setState({ openDeleteTemplateModal: false })
          }
          submitActions={() => this.handleCustomDelete()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting a template will permanently remove it for all users. Be
              sure that no one else needs to use this template before deleting.{' '}
            </span>
            Any templates that have already been assigned will not be affected,
            and can be completed normally.
          </div>
        </Modal>
        <Modal
          title="Updating Custom Questions"
          titleClassName="blueHeader"
          isOpen={openUpdateCustomQuestionsModal}
          submitButtonColor="blue"
          submitButtonText="Confirm Changes"
          onRequestClose={() =>
            this.setState({ openUpdateCustomQuestionsModal: false })
          }
          submitActions={() => this.handleCustomSubmit()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            You are about to save changes to the Custom Questions of a Report
            Component.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              These changes will only apply to future Reports.{' '}
            </span>
            If you wish to keep your changes, click “Confirm Changes”.
          </div>
        </Modal>
        <MissingRequiredModal
          isOpen={missingModalOpen}
          onRequestClose={() => this.setState({ missingModalOpen: false })}
          subject="Custom Report Questions"
          creatingMultiple
          isEditing={isEditing}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTemplate: getActiveCustomReportQuestionsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  createCustomTemplate: values => {
    return dispatch(createCustomReportQuestionsRequest(values));
  },
  updateCustomTemplate: values => {
    return dispatch(updateCustomReportQuestionsRequest(values));
  },
  deleteCustomTemplate: values =>
    dispatch(deleteCustomReportQuestionsRequest(values)),
  setActiveCustomReportQuestions: values =>
    dispatch(setActiveCustomReportQuestions(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReportQuestionContainer);
