import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M79.1086 33.8841L67.2949 24.2289V7.95225C67.2949 7.22301 66.7115 6.63961 65.9823 6.63961H55.4812C54.7519 6.63961 54.1685 7.22301 54.1685 7.95225V13.4945L44.5134 5.61867C41.8589 3.46012 38.1252 3.46012 35.4708 5.61867L26.0198 13.3195L25.7865 19.0659L40.2546 25.1624L31.562 44.3852L42.7049 61.2744L37.746 64.5414L24.7947 44.9394L32.3205 28.2835L19.6608 22.9455L19.8358 18.3658L17.8523 19.9993L0.875544 33.8841C0.0879626 34.5258 -0.203734 35.5759 0.146302 36.5385C0.496338 37.5011 1.37143 38.1137 2.39237 38.1137H9.36392V72.1839C9.36392 75.1592 11.785 77.5803 14.7603 77.5803H65.253C68.2283 77.5803 70.6494 75.1592 70.6494 72.1839V38.0845H77.621C78.6419 38.0845 79.517 37.472 79.867 36.5094C80.1879 35.5468 79.9254 34.5258 79.1086 33.8841Z" />
  </svg>
);
