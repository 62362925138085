import React from 'react';
import './svg.css';

export default props => (
  <svg version="1.1" x={props.x || "0px"} y={props.y || "0px"} viewBox="0 0 512 512">
    <g>
      <path d="M244.2,240.4c0.2,0,0.4,0,0.7,0c17.4-0.2,33.7-7.1,45.8-19.5c12.2-12.4,18.8-28.8,18.6-46.2l-0.6-54.3   c-0.3-27.6-22.9-49.7-50.5-49.5l-62.1,0.6c-10,0.1-18.1,8.3-17.9,18.3l0.9,86.1C179.4,211.6,208.6,240.4,244.2,240.4z M213.3,114.4   c-8.8,3.7-15.6,9.2-20.7,14.8l-0.4-37.6C196.9,97.8,204,106.3,213.3,114.4z M280.7,211.1c-9.6,9.7-22.3,15.2-36,15.3   c-0.2,0-0.4,0-0.5,0c-27.9,0-50.8-22.6-51.1-50.6l-0.2-21.5c1.7-4.2,11.4-25.1,34.2-29.5c14.7,9.5,29.9,14.3,45.3,14.3   c7.5,0,15.1-1.2,22.6-3.5l0.4,39.2C295.4,188.4,290.2,201.3,280.7,211.1z M258.4,84.9c0.1,0,0.2,0,0.4,0c19.7,0,35.8,15.9,36,35.6   l0,0.4c-22.7,8.5-45.4,4.2-67.4-13.1c-9.6-7.5-17.1-16-22.1-22.4L258.4,84.9z" />
      <path d="M475,238c0-60.6-23.6-117.6-66.5-160.5C365.6,34.6,308.6,11,248,11S130.4,34.6,87.5,77.5C44.6,120.4,21,177.4,21,238   s23.6,117.6,66.5,160.5C130.4,441.4,187.4,465,248,465c33.9,0,67.4-7.6,97.8-22.1l-2,47.7c0,0.1,0,0.2,0,0.3c0,3.9,2.3,7.6,5.8,9.2   c3.6,1.6,7.6,1,10.7-1.6l43.7-37.7l43.7,37.7c1.9,1.6,4.2,2.5,6.5,2.5c1.4,0,2.8-0.3,4.1-0.9c3.5-1.6,5.8-5.2,5.8-9.2   c0-0.1,0-0.2,0-0.3l-4.1-98.5c18.9-16,30.9-39.8,30.9-66.5c0-20.2-6.9-38.8-18.5-53.6C474.1,260.8,475,249.4,475,238z M35,238   c0-117.4,95.6-213,213-213s213,95.6,213,213c0,7-0.4,14.1-1,21.1c-15.1-12.7-34.7-20.4-56-20.4c-21.4,0-41,7.8-56.1,20.6   c-10-5.5-21.3-8.3-32.8-8.2l-14.7,0.1c-3.1,0-5.9,2.1-6.7,5.2l-18,65.9l-14.6-25.5l10.4-34.3c0.6-2.1,0.2-4.4-1.1-6.2   c-1.3-1.8-3.4-2.8-5.7-2.8l-37.1,0.4c-2.2,0-4.3,1.1-5.6,2.9c-1.3,1.8-1.7,4.1-1,6.2l11.1,34.2l-12.3,24.3l-17.5-65.3   c-0.9-3.3-4-5.5-7.4-5.2l-16.2,1.5c-18.9,0.3-36.9,8.5-49.5,22.7c-12.5,14.1-18.5,33-16.5,51.8l0.8,76C65.6,363.9,35,304.5,35,238z    M248.3,290.7l-3.6,0l-7.5-23l18-0.2L248.3,290.7z M248,451c-44.7,0-86.2-13.8-120.5-37.4l-0.9-87.1c0-0.2,0-0.5,0-0.7   c-1.7-15,3.1-30,13.1-41.3c10-11.3,24.4-17.8,39.4-18c0.2,0,0.4,0,0.6,0l10.6-1l20.7,77.3c0.7,2.7,3,4.7,5.7,5.1   c2.8,0.4,5.5-0.9,7-3.3c0.1-0.2,0.2-0.3,0.3-0.5l19.9-39.4l5.5-0.1l22.5,39.2c1.4,2.5,4.2,3.8,7,3.5c2.8-0.4,5.1-2.4,5.9-5.1l21-77   l9.4-0.1c7.8-0.1,15.3,1.5,22.3,4.6c-12.7,15.1-20.4,34.7-20.4,55.9c0,26.6,12,50.5,30.9,66.5l-1.5,34.8   C316.2,442.7,282.3,451,248,451z M449.9,481.9l-39.1-33.7c-1.9-1.7-4.2-2.7-6.8-2.7s-4.9,1-6.8,2.7l-39.1,33.7l3.4-80.3   c12.6,7.1,27.1,11.1,42.5,11.1s29.9-4,42.5-11.1L449.9,481.9z M404,398.7c-40.3,0-73-32.7-73-73c0-20.4,8.4-38.8,21.9-52.1   c0.5-0.3,0.9-0.7,1.3-1.2c13.1-12.2,30.6-19.7,49.8-19.7c40.3,0,73,32.7,73,73S444.3,398.7,404,398.7z" />
      <path d="M440.4,291.6l-48,48l-24.7-25c-2.7-2.7-7.2-2.8-9.9,0c-2.7,2.7-2.8,7.2,0,9.9l29.7,30c1.3,1.3,3.1,2.1,5,2.1c0,0,0,0,0,0   c1.9,0,3.6-0.7,5-2l52.9-52.9c2.7-2.7,2.7-7.2,0-9.9C447.5,288.9,443.1,288.9,440.4,291.6z" />
    </g>
  </svg>
);
