import React, { useState } from 'react';
import classnames from 'classnames/bind';

import { noBubble } from '../../utils/events';
import Gear from '../../svgs/gear';

import styles from './gearStyles.module.scss';

export const GearMenu = ({ className, disabled, options, gear, text }) => {
  const [open, setOpen] = useState(false);

  const toggle = e => {
    noBubble(e);
    setOpen(disabled ? false : !open);
  };

  let classes = classnames.bind(styles)({
    gearMenu: true,
    disabled,
    open
  });

  return (
    <div className={className}>
      <ul
        onClick={toggle}
        onBlur={() => setOpen(false)}
        className={classes}
        data-cy="gearMenu"
      >
        <li className={styles.menu}>
          {text && <span className={styles.text}>{text}</span>}
          {gear && <Gear />}

          <div className={styles.right}>
            <img
              src={require('../../assets/images/downChevron.png')}
              alt="Menu"
              style={{
                transform: open ? 'rotate(180deg)' : ''
              }}
            />
          </div>
        </li>
        <li className={styles.wrapper} style={{ right: gear ? '78px' : null }}>
          <ul
            style={{
              borderTopRightRadius: gear ? '0px' : null,
              borderTopLeftRadius: gear ? '5px' : null
            }}
          >
            {options.map((option, index) =>
              option.hide ? null : (
                <li
                  key={index}
                  onClick={e => {
                    noBubble(e);
                    setOpen(false);
                    option.onClick();
                  }}
                  className={styles[option.color || 'blue']}
                  data-cy={option.label}
                >
                  <div>{option.label}</div>
                </li>
              )
            )}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default GearMenu;
