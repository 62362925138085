import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Attachments from './attachments';
import Card from '../Card';
import Documents from './documents';
import { TwoColumn } from '../inputs';
import { isImage } from '../../utils/attachmentSelector';
import { getLoggedInUser } from '../../selectors/users';

export const AttachmentUploadCard = ({
  addedAttachments,
  name,
  handleAnswer,
  isCreating,
  isEditing,
  isDuping,
  deleteAttachment,
  addAttachments,
  ownerType,
  createdBy,
  addPhotosOnly,
  attachmentsToUpload = [],
  formattedAttachmentsToUpload = [],
  keepAttachmentsAnonymous,
  disabled
}) => {
  const fromEquipment = ownerType === 'equipment';
  const loggedInUser = useSelector(getLoggedInUser);

  // documets during creation  - uploaded to db on creation of owner
  let documentsToUpload = attachmentsToUpload?.filter(a => !isImage(a));
  let formattedDocumentsToUpload = formattedAttachmentsToUpload?.filter(
    a => !isImage(a)
  );
  // pictures during editing  - uploaded to db on creation of owner
  let picturesToUpload = attachmentsToUpload?.filter(a => isImage(a));
  let formattedPicturesToUpload = formattedAttachmentsToUpload?.filter(a =>
    isImage(a)
  );

  // pictures during editing  - uploaded to db right away
  const [uploadedPictures, setUploadedPictures] = useState([]);
  // documets during editing  - uploaded to db right away
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  useEffect(() => {
    setUploadedDocuments(addedAttachments?.filter(a => !isImage(a)));
    setUploadedPictures(addedAttachments?.filter(a => isImage(a)));
  }, [addedAttachments]);

  const canEditAll =
    fromEquipment ||
    ((loggedInUser.accessLevel === 900 || createdBy === loggedInUser._id) &&
      isEditing);

  return (
    <Card showHeader title="Attachments" name={name}>
      <TwoColumn>
        {addPhotosOnly ? null : (
          <Documents
            isEditing={isEditing}
            isCreating={isCreating}
            isDuping={isDuping}
            documentsToUpload={documentsToUpload}
            formattedDocumentsToUpload={formattedDocumentsToUpload}
            uploadedDocuments={uploadedDocuments}
            updateDocumentsToUpload={(selected, formatted) => {
              let together = documentsToUpload.concat(selected);
              let ft = formattedDocumentsToUpload.concat(formatted);
              handleAnswer(
                [...together, ...picturesToUpload],
                [...ft, ...formattedPicturesToUpload]
              );
            }}
            removeDocumentsToUpload={(selected, formatted) => {
              handleAnswer(
                [...selected, ...picturesToUpload],
                [...formatted, ...formattedPicturesToUpload]
              );
            }}
            deleteAttachment={attachment => deleteAttachment(attachment)}
            addAttachments={attachments => addAttachments(attachments)}
            ownerType={ownerType}
            fromEquipment={fromEquipment}
            loggedInUser={loggedInUser}
            canEditAll={canEditAll}
            disabled={disabled}
          />
        )}
        <Attachments
          isEditing={isEditing}
          isCreating={isCreating}
          isDuping={isDuping}
          picturesToUpload={picturesToUpload}
          formattedPicturesToUpload={formattedPicturesToUpload}
          uploadedPictures={uploadedPictures}
          updatePicturesToUpload={(selected, formatted) => {
            let together = picturesToUpload.concat(selected);
            let ft = formattedPicturesToUpload.concat(formatted);
            handleAnswer(
              [...together, ...documentsToUpload],
              [...ft, ...formattedDocumentsToUpload]
            );
          }}
          removePicturesToUpload={(selected, formatted) => {
            handleAnswer(
              [...selected, ...documentsToUpload],
              [...formatted, ...formattedDocumentsToUpload]
            );
          }}
          deleteAttachment={attachment => deleteAttachment(attachment)}
          addAttachments={(attachments, photoType) =>
            addAttachments(attachments, photoType)
          }
          fromEquipment={fromEquipment}
          canEditAll={canEditAll}
          ownerType={ownerType}
          keepAttachmentsAnonymous={keepAttachmentsAnonymous}
          disabled={disabled}
        />
      </TwoColumn>
    </Card>
  );
};

export default AttachmentUploadCard;
