function stop(e) {
  if (e?.stopPropagation) e.stopPropagation();
}

/*
  If you call this function with an event, it stops event bubbling.
  If you call it with a function, it returns a function that stops
  bubbling, then calls the passed function.  Use this mode like this:
  <div onClick={noBubble(handleClick)} />
*/

export function noBubble(e) {
  if (typeof e === 'function') {
    return evt => {
      stop(evt);
      return e(evt);
    };
  } else {
    stop(e);
  }
}
