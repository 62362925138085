import {
  DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
  DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE,
  SET_ACTIVE_EMPLOYEE_REQUEST,
  SET_ACTIVE_EMPLOYEE_RESPONSE,
  UPDATE_LOCATION_EMPLOYEE_REQUEST
} from '../constants/actionTypes';

export const setActiveEmployeeRequest = payload => ({
  type: SET_ACTIVE_EMPLOYEE_REQUEST,
  payload
});

export const updateLocationEmployeeRequest = payload => ({
  type: UPDATE_LOCATION_EMPLOYEE_REQUEST,
  payload
});

export const setActiveEmployeeResponse = payload => ({
  type: SET_ACTIVE_EMPLOYEE_RESPONSE,
  payload
});

export const downloadEmployeeQRcodeRequest = payload => ({
  type: DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
  payload
});

export const downloadEmployeeQRcodeResponse = payload => ({
  type: DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE,
  payload
});
