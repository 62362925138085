import React, { useState, useEffect } from 'react';
import history from '../../../history';
import { fetchCustomTrainingTemplates, archiveMany } from '../../../api/v4';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import { Dropdown } from '../../../components/inputs';
import TypeSelection from '../../../components/TypeSelection';

export default function TemplateList() {
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [listType, setListType] = useState('Active');

  useEffect(() => {
    fetchCustomTrainingTemplates().then(templates => {
      const noNSC = templates.filter(t => !t?.nscCompanyIds?.length);
      setTemplates(noNSC);
    });
  }, []);

  const templateDropdown = templates
    ?.filter(template => !template.isArchived)
    .map(t => {
      return { value: t._id, label: t.title };
    });

  const header = (
    <Header
      title="Training Templates"
      pageActionOptions={[
        {
          label: 'Create Training Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            history.push('/app/training/template/');
          }
        },
        {
          label: 'Duplicate Training Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );
  const handleViewEdit = (template, duplicate = false) => {
    history.push(
      `/app/training/template/${duplicate ? `${template}/true` : template._id}`
    );
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'Custom Training Template',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchCustomTrainingTemplates().then(templates => {
        const noNSC = templates.filter(t => !t?.nscCompanyIds?.length);
        setTemplates(noNSC);
      })
    );
  };

  const columns = [
    { key: 'title' },
    { key: 'doesExpire', datatype: 'boolean' },
    { key: 'needsApproval', datatype: 'boolean' },
    { key: 'updatedAt', label: 'Last Updated', datatype: 'date' }
  ];

  const listTypeFilter =
    listType === 'Archived'
      ? templates?.filter(template => template.isArchived)
      : templates?.filter(template => !template.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={setListType}
        selected={listType}
      />
      <List
        saveKey="trainingTemplateList"
        rowClick={handleViewEdit}
        dataIsHash
        data={listTypeFilter}
        settings={columns}
        getRowId={r => r._id}
        actions={[
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() => handleViewEdit(currentTemplate, true)}
        testID="Training Template"
        disableSubmit={!currentTemplate}
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Training Template"
          currentValue={currentTemplate}
          isRequired
          onChange={setCurrentTemplate}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
