import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { emailAssignees } from '../../api/v4';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse
} from '../../actions/attachments';
import {
  deleteIncidentAttachment,
  updateReportComponentRequest
} from '../../actions/incidents';
import { setActiveTaskRequest } from '../../actions/tasks';
import { getActiveCompany } from '../../selectors/company';
import { getActiveIncidentSelector } from '../../selectors/incidents';
import {
  getAddedAttachmentsSelector,
  getPhotoEvidenceSelector
} from '../../selectors/attachments';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getLoggedInUser } from '../../selectors/users';
import { hasAccess } from '../../utils/incidentHelper';
import Diagram from '../../components/Diagram';
import DiagramCard from '../../components/reportCards/DiagramCard';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import PhotoEvidence from '../../components/reportCards/PhotoEvidence';
import Textbox from '../../components/inputs/Textbox';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import LeftNav from '../../components/LeftNav';
import ViewOnly from '../../components/ViewOnly';
import Assignee from '../../components/Assignee';
import { isComplete } from '../../utils/reportHelper';
import { isImage } from '../../utils/attachmentSelector';

import './index.css';

export class PhotoEvidenceContainer extends Component {
  state = {
    openUnsavedChangesModal: false,
    openMarkAsCompleteModal: false,
    openDeleteIrrelevantReportModal: false,
    openUnlockAndReopenReportModal: false,
    imageUrl: '',
    diagramCategory: { name: '', url: '' },
    hasUnsavedChanges: false,
    nonRemovedPhotos: [],
    removedPhotos: [],
    disabled: false
  };

  componentDidMount() {
    if (this.props.activeReport.report) {
      this.setState({
        activeReport: [...this.props.activeReport.report],
        disabled: this.props?.activeReport?.disabled
      });
    } else {
      this.setState({
        activeReport: [...this.props.activeReport]
      });
    }
    const nonRemovedPhotos = this.props.photoEvidence.filter(
      photo => !photo.isRemoved && !photo.isRemovedByOwner
    );
    const removedPhotos = this.props.photoEvidence.filter(
      photo => photo.isRemoved || photo.isRemovedByOwner
    );
    this.setState({ nonRemovedPhotos, removedPhotos });
  }

  componentDidUpdate(prevProps) {
    if (this.RCUpdated(prevProps)) {
      this.setState({ activeReport: this.props.activeReport });
    }
    if (this.photoEvidenceUpdated(prevProps)) {
      const nonRemovedPhotos = this.props.photoEvidence.filter(
        photo => !photo.isRemoved && !photo.isRemovedByOwner
      );
      const removedPhotos = this.props.photoEvidence.filter(
        photo => photo.isRemoved || photo.isRemovedByOwner
      );
      this.setState({ nonRemovedPhotos, removedPhotos });
    }
  }

  photoEvidenceUpdated(prevProps) {
    return (
      this.props.photoEvidence &&
      JSON.stringify(this.props.photoEvidence) !==
        JSON.stringify(prevProps.photoEvidence)
    );
  }

  RCUpdated(prevProps) {
    return (
      this.props.activeReport.length > 0 &&
      JSON.stringify(prevProps.activeReport) !==
        JSON.stringify(this.props.activeReport)
    );
  }

  handlePhotoUpload = e => {
    const files = e.target.files;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      if (!isImage(files[i])) {
        alert(`File must be one of png, jpeg, or jpg type`);
        return;
      }
    }
    let data = new FormData();
    for (const file of Object.entries(files)) {
      data.append('attachments', file[1], file[1].name);
    }
    this.props.addAttachment({
      data,
      ownerId: this.props.activeIncident._id,
      ownerType: 'incident',
      isPhotoEvidence: true
    });
  };

  flattenedAttachmentList = addedAttachments =>
    addedAttachments.reduce((acc, x) => acc.concat(x), []);

  updatePhotoEvidence = async photo => {
    const { addUploadedAttachments } = this.props;
    let photoEvidence = this.props.photoEvidence;
    photoEvidence = photoEvidence.map(currentPhoto => {
      if (currentPhoto._id === photo._id) {
        return { ...photo };
      }
      return { ...currentPhoto };
    });
    await addUploadedAttachments(photoEvidence);
    this.setState({ hasUnsavedChanges: true });
  };

  handleSubmit = async markAsComplete => {
    const { addedAttachments } = this.props;
    const { activeReport } = this.state;

    const currentNumber = addedAttachments.length;

    if (
      (currentNumber > 0 || activeReport[0][1].isNotApplicable) &&
      markAsComplete === undefined
    ) {
      this.setState({ openMarkAsCompleteModal: true });
      return;
    }

    if (markAsComplete) {
      activeReport[0][4].isComplete = true;
    }

    this.props.updateReportComponent({ stage: this.props.match.params.stage });
  };

  reopenReportSection = async () => {
    const { activeReport } = this.state;
    activeReport[0][4].isComplete = false;

    this.props.updateReportComponent({ stage: this.props.match.params.stage });
  };

  handleNaSection = value => {
    this.setState(state => {
      const { activeReport } = state;

      activeReport[0][1] = {
        isNotApplicable: value
      };
      return { activeReport, hasUnsavedChanges: true };
    });
  };

  sendEmail = () => {
    const { activeIncident } = this.props;
    const { activeReport, emailMessage } = this.state;

    const reportTask =
      activeIncident &&
      activeIncident.incidentActivity &&
      activeIncident.incidentActivity.openTasks.filter(
        task =>
          activeReport.length > 0 &&
          task.reportComponentName === activeReport[0][0]
      );

    emailAssignees({
      messageContent: emailMessage,
      incidentId: activeIncident?._id,
      sentMessageToIds: reportTask?.[0]?.assignedTo
    }).then(() => this.setState({ openEmailAssigneeModal: false }));
  };

  render() {
    const {
      loggedInUser,
      activeIncident,
      match,
      photoEvidence,
      addAttachment,
      addedAttachments,
      setActiveTask,
      deleteAttachment
    } = this.props;

    const {
      openEmailAssigneeModal,
      openUnsavedChangesModal,
      openMarkAsCompleteModal,
      openUnlockAndReopenReportModal,
      emailMessage,
      imageUrl,
      diagramCategory,
      hasUnsavedChanges,
      activeReport,
      nonRemovedPhotos,
      removedPhotos,
      disabled
    } = this.state;

    const leftNav = match.params.isPhotoEvidence
      ? photoEvidence.map((photo, index) => ({
          label: photo.photoName || `Photo #${index + 1}`,
          removed: photo.isRemoved || photo.isRemovedByOwner,
          id: photo._id
        }))
      : [{ label: 'Diagrams' }];

    const canRemoveEdit =
      activeIncident &&
      activeReport &&
      (loggedInUser._id === activeIncident.incidentOwner ||
        loggedInUser._id === activeReport[0][2].assignedTo ||
        hasAccess(
          loggedInUser,
          this.props.activeReport.stage,
          activeIncident
        )) &&
      !activeReport[0][4].isComplete &&
      !disabled;

    const reportTask =
      activeIncident &&
      activeIncident.incidentActivity &&
      activeIncident.incidentActivity.openTasks.filter(
        task =>
          activeReport &&
          activeReport.length > 0 &&
          task.reportComponentName === activeReport[0][0]
      );

    if (imageUrl) {
      return (
        <div className="photoEvidenceContainer">
          <div>
            {diagramCategory.name !== '' && (
              <Diagram
                imageUrl={imageUrl}
                addAttachment={values =>
                  addAttachment(values) &&
                  this.setState({ hasUnsavedChanges: true })
                }
                resetDiagrams={() =>
                  this.setState({
                    imageUrl: '',
                    diagramCategory: { name: '', url: '' },
                    diagramSubcategory: { name: '', url: '' }
                  })
                }
                loggedInUser={loggedInUser}
                category={diagramCategory.name}
              />
            )}
          </div>
        </div>
      );
    }

    const footer = (
      <SaveCancelFooter
        editing
        saveButtonClick={() => this.handleSubmit(undefined)}
        cancelButtonClick={() => history.goBack()}
      />
    );

    const left = (
      <LeftNav
        removed={activeIncident.incidentOwner === loggedInUser._id}
        items={leftNav}
        onAddEvidence={r => {
          if (match.params.isPhotoEvidence && canRemoveEdit) {
            this.handlePhotoUpload(r);
            r.target.value = '';
          }
        }}
        showAddEvidence={match.params.isPhotoEvidence && canRemoveEdit}
      />
    );

    const assignedTo = activeReport?.[0]?.[2]?.assignedTo;

    const center = (
      <ViewOnly
        canUnlock={
          !disabled &&
          activeReport?.[0]?.[4]?.isComplete &&
          (loggedInUser?.accessLevel === 900 ||
            activeIncident.incidentOwner === loggedInUser?._id)
        }
        incident={activeIncident}
        loggedInUser={loggedInUser}
        onClick={() => this.setState({ openUnlockAndReopenReportModal: true })}
        activeReport={this.props.activeReport}
      />
    );
    const right =
      assignedTo && assignedTo !== 'unassigned' ? (
        <Assignee
          user={assignedTo}
          options={[
            {
              onClick: () => this.setState({ openEmailAssigneeModal: true }),
              label: 'Email Assignee',
              visible: true
            },
            {
              onClick: () =>
                setActiveTask({
                  ...reportTask[0],
                  stage: 'investigation'
                }),
              visible: activeIncident?.incidentOwner === loggedInUser._id,
              label: 'Edit Task'
            }
          ]}
        />
      ) : (
        <></>
      );

    const showMarkAsCompleteButton =
      !hasUnsavedChanges &&
      !isComplete(activeReport) &&
      hasAccess(loggedInUser, this.props.activeReport.stage, activeIncident);
    const header = (
      <Header
        name={activeIncident?.incidentNumber}
        section={match.params.isPhotoEvidence ? 'Photo Evidence' : 'Diagrams'}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
        needsSaved={hasUnsavedChanges}
        center={center}
        right={right}
        rightButtons={[
          {
            visible:
              !disabled &&
              assignedTo === 'unassigned' &&
              activeIncident?.incidentOwner === loggedInUser._id &&
              !isComplete(activeReport),
            text: 'Assign Report as Task',
            color: 'blue',
            onClick: () =>
              setActiveTask({
                ownerId: activeIncident._id,
                ownerType: 'incident',
                reportComponentName: activeReport[0][0],
                label: `${activeIncident.incidentNumber}, ${activeReport[0][0]}`,
                stage: 'investigation',
                schedule: 'immediate',
                groupId: activeIncident.groupId
              })
          },
          {
            visible: !disabled && showMarkAsCompleteButton,
            text: 'Mark as Complete',
            color: 'blue',
            onClick: () => this.setState({ openMarkAsCompleteModal: true })
          }
        ]}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          showFooter={!isComplete(activeReport) && !disabled}
        >
          {match.params.isPhotoEvidence && photoEvidence.length > 0 ? (
            <>
              {nonRemovedPhotos.map(photo => (
                <PhotoEvidence
                  photo={photo}
                  key={photo._id}
                  name={photo._id}
                  updatePhotoEvidence={values =>
                    this.updatePhotoEvidence({ ...photo, ...values })
                  }
                  isIncidentOwner={
                    loggedInUser._id === activeIncident.incidentOwner
                  }
                  canRemoveEdit={canRemoveEdit}
                />
              ))}
              {activeIncident.incidentOwner === loggedInUser._id &&
                removedPhotos.length > 0 &&
                removedPhotos.map(photo => (
                  <PhotoEvidence
                    photo={photo}
                    key={photo._id}
                    name={photo._id}
                    updatePhotoEvidence={values =>
                      this.updatePhotoEvidence({ ...photo, ...values })
                    }
                    isIncidentOwner={
                      loggedInUser._id === activeIncident.incidentOwner
                    }
                    canRemoveEdit={canRemoveEdit}
                  />
                ))}
            </>
          ) : match.params.isPhotoEvidence && photoEvidence.length === 0 ? (
            <div className="photoEvidenceContainer-noContent">
              <div>Nothing has been added yet.</div>
              <div style={{ fontSize: 18, fontWeight: 'normal' }}>
                Use Add Button to start populating this report.
              </div>
            </div>
          ) : (
            <DiagramCard
              reportSectionHeader="Diagrams"
              addedAttachments={addedAttachments}
              deleteAttachment={deleteAttachment}
              handleDiagramSelection={value => this.setState(value)}
              name={0}
              canRemoveEdit={canRemoveEdit}
              handleNaSection={values => this.handleNaSection(values)}
              sectionNotApplicable={
                activeReport &&
                activeReport.length > 0 &&
                activeReport[0][1].isNotApplicable
              }
              showAircraft={this.props.activeCompany.showAircraft}
            />
          )}
        </HeaderAndFooter>
        <Modal
          title="Email Assignee"
          titleClassName="blueHeader"
          isOpen={openEmailAssigneeModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailAssigneeModal: false })
          }
          submitActions={this.sendEmail}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This email will go through the iReportSource system for record
            keeping purposes.
          </div>
          <Textbox
            fieldLabel="Email Content"
            type="textarea"
            placeholder="Type email content here"
            currentValue={emailMessage}
            onChange={v => this.setState({ emailMessage: v })}
          />
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
          savingWhat="a report"
        />
        <MarkAsCompleteModal
          isOpen={openMarkAsCompleteModal}
          onRequestClose={() =>
            this.setState({ openMarkAsCompleteModal: false })
          }
          submitActions={() => this.handleSubmit(true)}
          cancelActions={() => this.handleSubmit(false)}
        />
        <Modal
          title="Unlock and Re-Open Report"
          titleClassName="blueHeader"
          isOpen={openUnlockAndReopenReportModal}
          submitButtonColor="blue"
          submitButtonText="Re-open Report"
          onRequestClose={() =>
            this.setState({ openUnlockAndReopenReportModal: false })
          }
          submitActions={() => this.reopenReportSection()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Unlocking this report will allow you –and users with edit
            permission– to make changes and additions to the report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This also means that the report will no longer be considered
              completed until it is filled out, saved, and marked as complete
              once more. Re-Opening this report will also remove the currently
              assigned user.
            </span>{' '}
            You can reassign this report to the same or a different user as a
            new task.
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeReport: getActiveReportsSelector(state),
  activeIncident: getActiveIncidentSelector(state),
  photoEvidence: getPhotoEvidenceSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  addUploadedAttachments: payload =>
    dispatch(addUploadedAttachmentsResponse(payload)),
  setActiveTask: payload => dispatch(setActiveTaskRequest(payload)),
  deleteAttachment: payload => dispatch(deleteIncidentAttachment(payload)),
  updateReportComponent: payload =>
    dispatch(updateReportComponentRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoEvidenceContainer);
