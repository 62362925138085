import React from 'react';
import Card from '../Card';
import { email } from '../../utils/formValidation';
import { Textbox, TwoColumn } from '../inputs';

import styles from './externalStatement.module.scss';

export default function ExternalWitnessCard(props) {
  const {
    statement,
    statementIndex,
    onChange,
    removeStatement,
    missingRequired
  } = props;
  return (
    <Card wide>
      <div className={styles.buttonBar}>
        <div></div>
        <img
          src={require('../../assets/images/remove.png')}
          alt="delete question"
          onClick={removeStatement}
          data-cy="removeButton"
          style={{ margin: 0, cursor: 'pointer' }}
        />
      </div>

      <TwoColumn>
        <Textbox
          fieldLabel="Witness's First Name"
          currentValue={statement?.witnessFirstName}
          onChange={value =>
            onChange(value, 'witnessFirstName', statementIndex)
          }
          isRequired
          touched={missingRequired && !statement?.witnessFirstName}
        />
        <Textbox
          fieldLabel="Witness's Last Name"
          currentValue={statement?.witnessLastName}
          onChange={value => onChange(value, 'witnessLastName', statementIndex)}
          isRequired
          touched={missingRequired && !statement?.witnessLastName}
        />
      </TwoColumn>
      <Textbox
        fieldLabel="Witness's Email"
        currentValue={statement?.witnessEmail}
        onChange={value =>
          onChange(value?.toLowerCase(), 'witnessEmail', statementIndex)
        }
        touched={
          missingRequired &&
          (email(statement?.witnessEmail) || !statement?.witnessEmail)
        }
        isRequired
        errorMessage={email(statement?.witnessEmail)}
      />
    </Card>
  );
}
