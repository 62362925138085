import React from 'react';
import styles from './quizBoxStyles.module.scss';

export default function QuizScoreBox(props) {
  const roundedScore = Number.parseFloat(props.quizScore).toFixed(2);
  return (
    <div className={styles.quizBox}>
      <img
        className={props.didPass ? styles.passedImage : styles.failedImage}
        src={
          props.didPass
            ? require('../../../assets/images/checkmark.png')
            : require('../../../assets/images/removePermissions.png')
        }
        alt={props.didPass ? 'check' : 'x'}
      />
      <div className={styles.textContainer}>
        <div className={styles.attempt}>
          {' '}
          Attempt {props.attemptNumber} of {props.attemptsAllowed}{' '}
        </div>
        <div className={styles.score}>
          {roundedScore}% {props.didPass ? '(Pass)' : '(Fail)'}
        </div>
      </div>
    </div>
  );
}
