import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { Button, Textbox } from '../inputs';
import UserBadge from '../UserBadge';

import styles from './auditNote.module.scss';
const bStyles = classnames.bind(styles);

const AuditNote = ({
  note,
  canEdit,
  updateQuestion,
  onChange,
  deleteNote,
  sectionCompleted,
  sectionNumber,
  questionNumber,
  disabled
}) => {
  const [editing, setEditing] = useState(false);
  const [warning, setWarning] = useState(null);
  const [oldNote] = useState(note.value);

  useEffect(
    () => () => {
      //for when sections collapse and this unmounts
      if (!note.value.trim()) updateQuestion(oldNote, note);
    }, // eslint-disable-next-line
    [oldNote, note]
  );
  return (
    <div
      className={bStyles({
        notesContainer: true,
        sectionCompletedNote: sectionCompleted
      })}
    >
      <UserBadge
        userId={note.createdByUserId}
        showImage
        showName
        byline="Added By"
        time={note.updatedAt ?? note.createdAt}
        size="medium"
      />
      {editing ? (
        <div
          className={styles.note}
          autoFocus
          onBlur={() => {
            setWarning(
              note.value.trim().length ? null : 'Notes cannot be empty'
            );
            setEditing(!note.value.trim().length);
          }}
        >
          <div className={styles.textbox} id="noteBox">
            <Textbox
              autoFocus
              currentValue={note.value}
              onChange={e => {
                updateQuestion(e.trim(), note);
                onChange(sectionNumber, questionNumber - 1, note, e.trim());
                setWarning(e.trim().length ? null : 'Notes cannot be empty');
              }}
              enter={() => {
                setWarning(
                  note.value.trim().length ? null : 'Notes cannot be empty'
                );
                setEditing(!note.value.trim().length);
              }}
              touched={editing}
              error={warning}
            />
          </div>
          <Button
            className="deleteButton"
            color="red"
            text="Delete"
            shrink
            onMouseDown={() => {
              document.getElementById('noteBox').focus();
              deleteNote(sectionNumber, questionNumber, note);
            }}
          />
        </div>
      ) : (
        <div className={styles.note}>
          {note.value}
          {canEdit && !disabled ? (
            <div
              className={styles.edit}
              onClick={() => {
                setEditing(true);
                setWarning(note.value.length ? null : 'Notes cannot be empty');
              }}
            >
              <img
                src={require('../../assets/images/editName.png')}
                alt="Edit Note"
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AuditNote;
