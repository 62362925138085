import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import config from '../config/config';
import {
  fetchIncidentBasicsResponse,
  setActiveIncidentRequest,
  setActiveIncidentResponse,
  updateIncidentBasicsResponse
} from '../actions/incidents';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import { addMessage } from '../actions/messages';
import { fetchOshaFormResponse } from '../actions/oshaLogs';
import { createReportResponse, setReportTemplate } from '../actions/reports';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getActiveIncidentSelector } from '../selectors/incidents';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveReportsSelector,
  getActiveReportTemplatesSelector
} from '../selectors/reports';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';
import { getLoggedInUser } from '../selectors/users';
import { isDiagram } from '../utils/attachmentSelector';
import {
  isConfidentialReport,
  userHasNoConfidentialAccess
} from '../utils/incidentHelper';
import { ACTION_COMPONENTS, CLOSURE_COMPONENTS } from '../constants/constants';

export function* fetchIncidentById(apiV4, { payload }) {
  try {
    const returnPath = payload?.returnPath;
    const selectedTab = payload?.selectedTab;
    const response = yield call(apiV4.fetchIncidentById, payload._id);

    yield put(
      setActiveIncidentResponse({ ...response, returnPath, selectedTab })
    );
    yield put(
      addUploadedAttachmentsResponse(
        response.incidentActivity.attachments.filter(a => !a.isRemoved)
      )
    );
  } catch (e) {
    console.log(e);
  }
}

export function* setActiveIncident(apiV4, { payload }) {
  const company = yield select(getActiveCompany);
  const returnPath = payload.returnPath;
  const selectedTab = payload?.selectedTab;
  const leavePage = payload.leavePage ?? true;
  const selectedSection = payload.selectedSection;
  const isSigning = payload.isSigning;
  try {
    const loggedInUser = yield select(getLoggedInUser);
    if (typeof payload === 'string') {
      payload = yield call(apiV4.fetchIncidentById, payload);
    } else {
      payload = yield call(apiV4.fetchIncidentById, payload._id);
    }
    const froi = payload.reportTemplateIds.indexOf('2') > -1;

    if (froi) {
      let oshaForm = yield call(apiV4.populateOshaForms, payload._id);

      if (oshaForm.length > 0) {
        oshaForm.map(
          form => (form.formAnswers = oshaQuestionMapper(form.questions))
        );
      }

      yield put(fetchOshaFormResponse(oshaForm));
    } else {
      yield put(fetchOshaFormResponse([]));
    }

    const response = { sections: [] };

    let incidentBasics = payload.incidentBasicsFieldGroup;
    let supIndex = incidentBasics?.fields.findIndex(
      f => f._id?.toString() === config.staticIds.incidentSupervisor
    );

    if (payload.reportTemplateIds.includes('19')) {
      incidentBasics.fields[supIndex].hide = true;
    }

    if (!payload.reportTemplateIds.includes('17')) {
      let index = incidentBasics?.fields.findIndex(
        f => f._id?.toString() === config.staticIds.confidentialReport
      );
      incidentBasics.fields[index].hide = true;

      const isConfidential = isConfidentialReport(payload);

      if (isConfidential) incidentBasics.fields[supIndex].hide = true;
    }

    let reportBasics = payload.reportBasicsFieldGroups;

    if (payload.reportTemplateIds.indexOf('17') > -1) {
      let basicsIndex = reportBasics?.findIndex(
        rb => rb.templateNumber === '17'
      );

      if (payload.answers[config.staticIds.werePoliceCalled]?.label === 'Yes') {
        let timeIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time police were called?'
        );
        let arrivedIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time police arrived?'
        );

        reportBasics[basicsIndex].fields[timeIndex].hide = false;
        reportBasics[basicsIndex].fields[arrivedIndex].hide = false;
      }
      if (
        payload.answers[config.staticIds.wereEmergencyCalled]?.label === 'Yes'
      ) {
        let timeIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time emergency personnel were called'
        );
        let arrivedIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time emergency personnel arrived?'
        );
        reportBasics[basicsIndex].fields[timeIndex].hide = false;
        reportBasics[basicsIndex].fields[arrivedIndex].hide = false;
      }
      if (
        payload.answers[config.staticIds.otherViolence]?.findIndex(
          a => a.label === 'Other'
        ) > -1
      ) {
        let index = reportBasics?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Other'
        );
        reportBasics[basicsIndex].fields[index].hide = false;
      }
    }

    if (payload.reportTemplateIds.indexOf('18') > -1) {
      let basicsIndex = reportBasics?.findIndex(
        rb => rb.templateNumber === '18'
      );

      let enviromentImpacted =
        payload.answers[config.staticIds.enviromentImpacted];
      let waterIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
        f =>
          f.label ===
          'Specify name of waterway and what is the length of area of waterway affected'
      );
      let landIndex = reportBasics?.[basicsIndex]?.fields?.findIndex(
        f =>
          f.label ===
          'Specify ground surface area in square feet or yards and depth of soil'
      );

      reportBasics[basicsIndex].fields[
        waterIndex
      ].hide = !enviromentImpacted?.includes('Water');
      reportBasics[basicsIndex].fields[
        landIndex
      ].hide = !enviromentImpacted?.includes('Land');
    }

    response.sections = [incidentBasics, ...reportBasics];

    yield put(
      setActiveIncidentResponse({
        ...payload,
        returnPath: returnPath,
        selectedTab: selectedTab
      })
    );

    yield put(
      addUploadedAttachmentsResponse(payload.incidentActivity.attachments)
    );

    yield put(fetchIncidentBasicsResponse(response));

    if (selectedSection) {
      const selectedComponent = payload.reportComponents.filter(
        report => report[0] === selectedSection
      );

      let stage = ACTION_COMPONENTS.includes(selectedSection)
        ? 'action'
        : CLOSURE_COMPONENTS.includes(selectedSection)
        ? 'closure'
        : 'investigation';

      const group = company.groups?.find(g => g._id === payload?.groupId);
      const area = group?.projects?.find(p => p._id === payload?.projectId);

      yield put(
        createReportResponse({
          report: selectedComponent,
          returnPath: returnPath,
          stage,
          disabled: group?.isHidden || area?.isHidden || payload?.isArchived
        })
      );

      if (
        selectedComponent[0][0] === 'Witness Statements' &&
        !payload.isWitnessLegacy
      )
        history.push(`/app/witnessComponentContainer/${stage}`);
      else if (
        selectedComponent[0][0] === 'Return to Work' &&
        !payload.isCcLegacy
      )
        history.push({
          pathname: `/app/returnToWorkContainer/${stage}`,
          state: {
            isSigning: true
          }
        });
      else history.push(`/app/reportSectionContainer/${stage}`);
      return;
    }
    const noConfidentialAccess = userHasNoConfidentialAccess(
      payload,
      loggedInUser
    );

    if (leavePage)
      loggedInUser.accessLevel === 100 || noConfidentialAccess || isSigning
        ? history.push({
            pathname: '/app/initalReportContainer',
            state: { isSigning }
          })
        : history.push('/app/incidentContainer');
  } catch (e) {
    console.error(e);
  }
}

export function* fetchIncidentBasics(apiV4, { payload }) {
  try {
    const response = { sections: [] };

    let incidentBasics = yield call(apiV4.fetchIncidentBasics);
    const isConfidentialReport =
      payload?.answers?.[config.staticIds.confidentialReport]?.label === 'Yes';

    if (
      payload.reportTemplateIds.includes('19') ||
      payload.reportTemplateIds.includes(19)
    ) {
      let index = incidentBasics[0]?.fields.findIndex(
        f => f._id?.toString() === config.staticIds.incidentSupervisor
      );
      incidentBasics[0].fields[index].hide = true;
    }

    if (
      !payload.reportTemplateIds.includes('17') &&
      !payload.reportTemplateIds.includes(17)
    ) {
      let index = incidentBasics[0]?.fields.findIndex(
        f => f._id?.toString() === config.staticIds.confidentialReport
      );
      incidentBasics[0].fields[index].hide = true;
    } else {
      let index = incidentBasics[0]?.fields.findIndex(
        f => f._id?.toString() === config.staticIds.incidentSupervisor
      );
      incidentBasics[0].fields[index].hide = isConfidentialReport;
    }

    let reportBasics = yield call(
      apiV4.findIncidentQuestionsByReportType,
      payload.reportTemplateIds?.map(id => id.toString())
    );

    let customQuestions = yield call(
      apiV4.fetchCustomReportQuestions,
      payload.reportTemplateIds?.map(id => id.toString()),
      'Report Basics'
    );

    customQuestions = customQuestions?.reduce((acc, x) => acc.concat(x), []);

    if (customQuestions?.length > 0) {
      let customQuestionFields = [];

      customQuestions.forEach(custom =>
        customQuestionFields.push(...custom.fields)
      );

      response.sections = [...incidentBasics];
      reportBasics.forEach(rb => {
        if (Array.isArray(rb)) {
          rb.forEach(r => response.sections.push(r));
        } else {
          response.sections.push(rb);
        }
      });

      response.sections = [
        ...response.sections,
        {
          label: 'Custom Questions',
          fields: customQuestionFields
        }
      ];
    } else {
      response.sections = [...incidentBasics];
      reportBasics.forEach(rb => {
        if (Array.isArray(rb)) {
          rb.forEach(r => response.sections.push(r));
        } else {
          response.sections.push(rb);
        }
      });
    }

    if (payload._id) {
      const initialReport = yield call(
        apiV4.fetchInitialReportById,
        payload._id
      );
      initialReport.rowId = initialReport._id;

      const response = yield call(
        apiV4.fetchAttachmentByOwnerId,
        initialReport._id,
        'incident'
      );
      delete initialReport._id;

      yield put(addUploadedAttachmentsResponse(response));

      if (initialReport.signature) {
        const signature = yield call(
          apiV4.getAttachmentById,
          initialReport.signature
        );
        initialReport.signature = signature;
      }

      yield put(setActiveIncidentResponse(initialReport));
    } else {
      yield put(setActiveIncidentResponse());
      yield put(addUploadedAttachmentsResponse([]));
    }

    yield put(fetchIncidentBasicsResponse(response));
    yield put(setReportTemplate(payload.reportTemplateIds));
  } catch (e) {
    console.log(e);
  } finally {
    history.push('/app/initalReportContainer');
  }
}

export function* saveIncidentBasics(apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const locationId = yield select(getActiveLocationId);
    let project = yield select(getActiveProject);
    let addedAttachments = yield select(getAddedAttachmentsSelector);
    const reportTemplates = yield select(getActiveReportTemplatesSelector);

    project = payload?.answers?.projectId
      ? payload?.answers?.projectId
      : project
      ? project._id
      : '';

    const id = payload?.rowId;

    payload = {
      groupId: locationId?._id ?? payload?.answers?.locationId,
      companyId: company._id,
      projectId: project,
      answers: payload.answers,
      signature: payload?.signature?._id,
      reportTemplateIds: payload?.reportTemplates ?? reportTemplates
    };

    const savedBasics = id
      ? yield call(apiV4.updateInitialReport, { ...payload, _id: id })
      : yield call(apiV4.createInitialReport, payload);

    if (addedAttachments.length) {
      yield all(
        addedAttachments.map(attachment =>
          call(apiV4.updateAttachment, {
            ...attachment,
            ownerId: savedBasics._id,
            ownerType: 'incident'
          })
        )
      );
    }

    yield put(addMessage({ message: 'Initial Report Saved' }));
    yield put(addUploadedAttachmentsResponse([]));
    history.goBack();
  } catch (err) {
    console.error(err);
    yield put(addMessage({ error: true, message: err }));
  }
}

export function* updateIncidentBasics(apiV4, { payload }) {
  try {
    const response = yield call(
      apiV4.updateIncidentBasics,
      payload.id,
      payload.answers,
      payload.signature._id,
      payload.additionalSignatures
    );

    call(apiV4.updateAttachment, {
      _id: payload.signature._id,
      ownerType: 'incident',
      ownerId: payload.id
    });

    yield put(updateIncidentBasicsResponse(response));
    yield put(addMessage({ error: false, message: 'Initial Report Updated!' }));
    if (payload.isSigning) history.push('/app/dashboard');
    else yield put(setActiveIncidentRequest(payload.id));
  } catch (e) {
    yield put(addMessage({ error: true, message: e.data }));
    return;
  }
}

export function* createIncident(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const locationId = yield select(getActiveLocationId);
    const loggedInUser = yield select(getLoggedInUser);
    let project = yield select(getActiveProject);
    const addedSignature = payload.signature;
    let addedAttachments = yield select(getAddedAttachmentsSelector);
    const reportTemplates = yield select(getActiveReportTemplatesSelector);
    const rowId = payload.rowId;

    project = payload?.answers?.projectId
      ? payload?.answers?.projectId
      : project
      ? project._id
      : '';

    let incidentPayload = yield call(apiV4.createIncident, {
      locationId: locationId ? locationId._id : payload.answers.locationId,
      companyId: company._id,
      groupId: locationId ? locationId._id : payload.answers.locationId,
      answers: payload.answers,
      projectId: project,
      reportTemplateIds: reportTemplates,
      signature: addedSignature._id
    });
    let newAttachments = [];

    if (addedAttachments.length > 0) {
      const temp = yield all(
        addedAttachments.map(attachment =>
          call(apiV4.updateAttachment, {
            ...attachment,
            ownerId: incidentPayload._id,
            ownerType: 'incident'
          })
        )
      );

      newAttachments = temp;
    }

    yield put(addUploadedAttachmentsResponse([...newAttachments]));

    incidentPayload = yield call(apiV4.fetchIncidentById, incidentPayload._id);

    let hasPhotoEvidence = false;
    let hasDiagrams = false;
    newAttachments.forEach(attachment => {
      if (attachment.isPhotoEvidence) {
        hasPhotoEvidence = true;
      } else if (isDiagram(attachment)) {
        hasDiagrams = true;
      }
    });

    incidentPayload.reportComponents.forEach(report => {
      if (report[0] === 'Photo Evidence') {
        report[5].isStarted = hasPhotoEvidence;
      } else if (report[0] === 'Diagrams') {
        report[5].isStarted = hasDiagrams;
      }
    });

    if (rowId) yield call(apiV4.deleteInitialReport, rowId);

    yield put(setActiveIncidentResponse(incidentPayload));
    const froi = incidentPayload.reportTemplateIds.indexOf('2') > -1;
    if (froi) {
      let oshaForm = yield call(apiV4.populateOshaForms, incidentPayload._id);

      if (oshaForm.length > 0) {
        oshaForm.map(
          form => (form.formAnswers = oshaQuestionMapper(form.questions))
        );
      }

      yield put(fetchOshaFormResponse(oshaForm));
    } else {
      yield put(fetchOshaFormResponse([]));
    }

    yield put(
      addUploadedAttachmentsResponse(
        incidentPayload.incidentActivity.attachments
      )
    );

    const noConfidentialAccess = userHasNoConfidentialAccess(
      incidentPayload,
      loggedInUser
    );

    loggedInUser.accessLevel === 100 || noConfidentialAccess
      ? history.push('/app/initalReportContainer')
      : history.push('/app/incidentContainer');
  } catch (error) {
    console.error(error);
  }
}

export function* updateReportComponent(apiV4, { payload }) {
  try {
    let activeReportComponent = yield select(getActiveReportsSelector);
    let activeIncident = yield select(getActiveIncidentSelector);
    let addedAttachments = yield select(getAddedAttachmentsSelector);
    const returnPath = activeIncident?.returnPath;
    const selectedTab = activeIncident?.selectedTab;
    const stage = payload.stage;

    if (activeReportComponent?.report) {
      activeReportComponent = activeReportComponent.report;
    }

    if (payload.report) activeReportComponent = payload?.report;

    const dontLeavePage = payload.dontLeavePage;

    payload = {
      activeReportComponent: activeReportComponent?.[0],
      incidentId: activeIncident._id
    };

    yield call(apiV4.updateReportComponent, payload);

    if (addedAttachments.length > 0) {
      yield all(
        addedAttachments.map(attachment =>
          call(apiV4.updateAttachment, {
            ...attachment,
            ownerId: activeIncident._id,
            ownerType: 'incident'
          })
        )
      );
    }

    const incident = yield call(apiV4.fetchIncidentById, activeIncident._id);

    const froi = incident.reportTemplateIds.indexOf('2') > -1;

    if (froi) {
      let oshaForm = yield call(apiV4.populateOshaForms, incident._id);

      if (oshaForm.length > 0) {
        oshaForm.map(
          form => (form.formAnswers = oshaQuestionMapper(form.questions))
        );
      }

      yield put(fetchOshaFormResponse(oshaForm));
    } else {
      yield put(fetchOshaFormResponse([]));
    }

    yield put(
      setActiveIncidentResponse({ ...incident, returnPath, selectedTab })
    );
    yield put(addMessage({ error: false, message: 'Report Updated!' }));
    if (!dontLeavePage) history.push(`/app/incidentContainer/${stage}`);
  } catch (error) {
    console.error(error);
  }
}

export function* deleteIncidentAttachment(apiV4, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    let activeIncidentAttachments = yield select(getAddedAttachmentsSelector);

    yield call(apiV4.deleteAttachment, payload._id);

    const incidentPayload = yield call(
      apiV4.fetchIncidentById,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));

    yield put(
      addUploadedAttachmentsResponse(
        activeIncidentAttachments.filter(a => a._id !== payload._id)
      )
    );
  } catch (e) {
    console.log(e);
  }
}
