import React, { Component } from 'react';

import history from '../../history';
import Button from '../inputs/Button';
import { InputRow } from '../inputs';

import './index.css';

export default class ErrorPage extends Component {
  render() {
    return (
      <div className="errorPage">
        <h3>Oops something happened!</h3>
        <img
          style={{ height: '500px', objectFit: 'contain' }}
          src={require('../../assets/images/test.png')}
          alt="broken app link"
        />
        <h3>
          Try again later or please contact techsupport@ireportsource.com or
          (513) 549-3459
        </h3>
        <InputRow center>
          <Button
            color="white"
            text="Back to Login"
            onClick={() => history.push('/login')}
          />
        </InputRow>
      </div>
    );
  }
}
