import React, { useState } from 'react';
import classnames from 'classnames/bind';

import { nameInput, Input } from '../';
import Button from '../Button';
import Textbox from '../Textbox';

import styles from './tag.module.scss';

const Tag = ({
  currentValue,
  onChange,
  placeholder,
  disabled,
  className,
  ...props
}) => {
  const [tagText, setTagText] = useState('');

  const removeTag = tag => {
    if (!disabled) {
      let newTags = currentValue.filter(t => t !== tag);
      onChange(newTags);
    }
  };
  const clearAll = () => {
    onChange([]);
  };

  const addTag = () => {
    let newTag = tagText;
    if (newTag.trim() === '') {
      setTagText('');
      return;
    }
    let duplicate = false;
    currentValue.forEach(e => {
      if (e === newTag.trim()) {
        duplicate = true;
      }
    });
    if (duplicate) {
      setTagText('');
      return;
    }
    const returnTags = currentValue.concat(newTag);
    setTagText('');
    onChange(returnTags);
  };

  return (
    <Input className={[styles.tagRow, className]}>
      {!disabled && (
        <ul className={styles.noBulletList}>
          <li className={styles.tagInput}>
            <Textbox
              onChange={setTagText}
              currentValue={tagText}
              enter={addTag}
              placeholder={placeholder || 'Input new tag'}
            />
          </li>
          <li>
            <Button text="Add tag" color="blue" onClick={addTag} />
          </li>
          <li>
            <Button text="Clear tags" color="red" onClick={clearAll} />
          </li>
        </ul>
      )}
      <ul className={classnames(styles.tagList, styles.noBulletList)}>
        {currentValue?.length ? (
          currentValue.map((tag, index) => (
            <span className={styles.tag}>
              <li>{tag}</li>
              {!disabled && (
                <div
                  className={styles.removeButton}
                  onClick={() => removeTag(tag)}
                >
                  X
                </div>
              )}
            </span>
          ))
        ) : (
          <></>
        )}
      </ul>
    </Input>
  );
};

const d = nameInput(Tag);
export { d as Tag };
export default d;
