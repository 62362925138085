import React from 'react';
import classnames from 'classnames/bind';

import styles from './blockText.module.scss';

export default function BlockText({ blockOfText, className }) {
  return (
    <div className={classnames(styles.blockText, className)}>{blockOfText}</div>
  );
}
