import React from 'react';
import UserBadge from '../../components/UserBadge';
import { Input, InputRow, Dropdown } from '../../components/inputs';
import {
  ANALYTICS_QUARTERLY_FREQUENCY_OPTIONS,
  DAILY_OPTIONS,
  DAY_OF_WEEK_DROPDOWN,
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  REPORT_FILE_TYPES,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import svgMapper from '../../utils/svgMapper';

import styles from './reports.module.scss';

export default function DashboardReports({
  reports,
  title,
  onChange,
  typeOptions = REPORT_FILE_TYPES
}) {
  const getOptions = frequency => {
    switch (frequency) {
      case 'daily':
        return DAILY_OPTIONS;
      case 'weekly':
        return DAY_OF_WEEK_DROPDOWN;
      case 'monthly':
        return MONTHLY_FREQUENCY_OPTIONS;
      case 'quarterly':
        return ANALYTICS_QUARTERLY_FREQUENCY_OPTIONS;
      case 'yearly':
        return YEARLY_FREQUENCY_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <Input fieldLabel={title} className={styles.report}>
      {reports?.map((s, i) => (
        <div key={i}>
          <span
            onClick={() => {
              reports.splice(i, 1);
              onChange(reports);
            }}
          >
            &times;
          </span>
          {s.externalEmail ? (
            <span className={styles.email}>
              {svgMapper('email')}
              {`Email: ${s.externalEmail} `}
            </span>
          ) : (
            <UserBadge userId={s.userId} showName showImage />
          )}
          <InputRow className={styles.reportOptions}>
            <Dropdown
              multi
              options={typeOptions}
              currentValue={s.type}
              disabled={typeOptions.length < 2}
              onChange={types => {
                reports[i].type = types;
                onChange(reports);
              }}
            />
            <Dropdown
              options={[
                { value: 'daily', label: 'Daily' },
                ...FREQUENCY_OPTIONS
              ]}
              currentValue={s.frequency}
              onChange={frequency => {
                reports[i].frequency = frequency;
                reports[i].sendOn = null;
                onChange(reports);
              }}
            />
            <Dropdown
              disabled={!s.frequency}
              options={getOptions(s.frequency)}
              currentValue={s.sendOn}
              onChange={sendOn => {
                reports[i].sendOn = sendOn;
                onChange(reports);
              }}
              valueKey={s.frequency === 'weekly' ? 'label' : 'value'}
            />
          </InputRow>
        </div>
      ))}
    </Input>
  );
}
