import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1651_286080)">
      <path d="M62.4653 25.37C61.1166 24.1055 59.0092 24.1055 57.7447 25.4542C56.4802 26.8029 56.4802 28.9103 57.829 30.1748C60.0206 32.2823 61.2009 35.0642 61.2009 38.0988C61.2009 41.1335 60.0206 43.9154 57.829 46.0228C56.4803 47.2873 56.4803 49.3947 57.7447 50.7434C58.4192 51.4179 59.2622 51.7549 60.1894 51.7549C61.0324 51.7549 61.8754 51.4176 62.4653 50.8277C66.0057 47.4557 67.8605 42.9037 67.8605 38.0987C67.8605 33.2938 65.9215 28.7416 62.4653 25.3697V25.37Z" />
      <path d="M70.1352 15.8443C68.7865 14.5798 66.6791 14.7483 65.4146 16.0973C64.1501 17.446 64.3186 19.5534 65.6676 20.8179C70.6413 25.2013 73.423 31.5237 73.423 38.1833C73.423 44.7586 70.5568 51.0808 65.6676 55.5486C64.3189 56.8131 64.1501 58.9206 65.4146 60.2692C66.0891 61.028 67.0163 61.3653 67.8593 61.3653C68.6181 61.3653 69.461 61.1123 70.051 60.5223C76.3733 54.8745 79.9981 46.6974 79.9981 38.1833C80.0824 29.6693 76.4575 21.4924 70.1353 15.8444L70.1352 15.8443Z" />
      <path d="M48.8083 11.2081C48.1338 10.8709 47.2908 10.9551 46.6166 11.3766L26.3851 26.6345H6.06931C2.69739 26.6345 0 29.3319 0 32.7038V43.494C0 46.8659 2.69739 49.5633 6.06931 49.5633H8.51402L11.5487 67.0972C11.7172 68.1087 12.5601 68.8674 13.5719 68.8674H21.6645C22.2545 68.8674 22.8447 68.6144 23.2662 68.1087C23.6877 67.6029 23.8562 67.0127 23.6877 66.4227L20.7373 49.5631H26.3851L46.6166 64.8211C46.9538 65.0741 47.4596 65.2425 47.8811 65.2425C48.2183 65.2425 48.5556 65.1583 48.8083 64.9895C49.4828 64.6523 49.9885 63.8935 49.9885 63.1351L49.9888 13.0622C49.9043 12.3034 49.4828 11.6293 48.8086 11.2078L48.8083 11.2081Z" />
    </g>
    <defs>
      <clipPath id="clip0_1651_286080">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
