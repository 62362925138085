import {
  AUTH_SIGNIN,
  AUTH_SIGNOUT,
  AUTH_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  SET_SECURITY_QUESTIONS,
  SUBMIT_SECURITY_QUESTIONS
} from '../constants/actionTypes';
import history from '../history';
import { SSOClient } from '../containers/Login';

export const authSuccess = () => {
  return { type: AUTH_SUCCESS };
};

export const loginFailure = payload => ({ type: LOGIN_FAILURE, payload });

export const signIn = payload => {
  return { type: AUTH_SIGNIN, payload };
};

export const signOut = () => {
  history.push('/home');
  if (SSOClient) {
    SSOClient.logout();
  }
  sessionStorage.clear();
  return { type: AUTH_SIGNOUT };
};

export const forgotPasswordRequest = payload => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload
});

export const submitSecurityQuestions = payload => {
  return { type: SUBMIT_SECURITY_QUESTIONS, payload };
};

export const setSecurityQuestions = payload => {
  return { type: SET_SECURITY_QUESTIONS, payload };
};
