import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getActiveCompany } from '../../selectors/company';
import { updateWitnessStatement } from '../../api/v4';
import { Textbox, TwoColumn } from '../../components/inputs';
import Signature from '../../components/inputs/Signature';
import Card from '../../components/Card';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { ConfirmFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import history from '../../history';

import styles from './styles.module.scss';

export default function ExternalStatement() {
  const { statementId } = useParams();
  const activeCompany = useSelector(getActiveCompany);
  const [info, setInfo] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [submissionModal, setSubmissionModal] = useState(false);

  useEffect(() => {
    const stored = JSON.parse(sessionStorage.getItem('statement'));
    setInfo({
      ...stored,
      contactInfo: stored.contactInfo.length
        ? stored.contactInfo
        : stored.externalEmail
    });
    return () => {
      sessionStorage.clear();
      localStorage.clear();
    };
  }, [statementId]);

  const handleSubmit = () => {
    updateWitnessStatement({
      ...info,
      id: info._id,
      isCompleted: true,
      hasStarted: true
    }).then(() => setSubmissionModal(true));
  };

  const header = (
    <Header title="Witness Statement" section="Witness Statement" />
  );

  const footer = (
    <ConfirmFooter
      saveButtonDisabled={
        !(
          info.firstName?.trim() &&
          info.lastName?.trim() &&
          info.contactInfo?.trim() &&
          info.incidentDetails?.trim() &&
          info.signature
        )
      }
      buttonText="Submit"
      saveButtonClick={() => setConfirmModal(true)}
    />
  );

  return (
    <HeaderAndFooter Header={header} Footer={footer} showFooter={true}>
      <Card className={styles.body}>
        <TwoColumn className={styles.nameFields}>
          <Textbox
            className={styles.name}
            fieldLabel="First Name"
            currentValue={info.firstName}
            placeholder="Enter your preferred name"
            onChange={v => setInfo({ ...info, firstName: v })}
            isRequired
          />
          <Textbox
            className={styles.name}
            fieldLabel="Last Name"
            currentValue={info.lastName}
            placeholder="Enter your last name"
            onChange={v => setInfo({ ...info, lastName: v })}
            isRequired
          />
        </TwoColumn>

        <Textbox
          fieldLabel="Contact Information"
          currentValue={info.contactInfo}
          onChange={v => setInfo({ ...info, contactInfo: v })}
          placeholder="Email, Phone, or Both"
          tooltip="How would you like to be contacted for any further information"
          displayTooltip
          isRequired
        />
        <Textbox
          fieldLabel="Date of Incident"
          currentValue={moment(info?.incidentDateTime).format(
            'MM/DD/YYYY hh:mm a'
          )}
          disabled
        />
        <Textbox
          fieldLabel="Describe the incident in detail"
          currentValue={info.incidentDetails}
          onChange={v => setInfo({ ...info, incidentDetails: v })}
          type="textarea"
          isRequired
        />
        <Textbox
          fieldLabel="What were you doing when incident occurred?"
          currentValue={info.actionDuringIncident}
          onChange={v => setInfo({ ...info, actionDuringIncident: v })}
          type="textarea"
        />
        <Textbox
          fieldLabel="Anything else you would like to add?"
          currentValue={info.additionalComment}
          onChange={v => setInfo({ ...info, additionalComment: v })}
          type="textarea"
        />
        <Signature
          fieldLabel="Signature"
          currentValue={info.signature}
          onChange={signature => setInfo({ ...info, signature })}
          ownerType="witnessStatement"
          ownerId={info?._id}
          isRequired
        />
      </Card>
      <Modal
        title="Submit Witness Statement?"
        titleClassName="blueHeader"
        submitButtonText="Submit"
        submitButtonColor="blue"
        onRequestClose={() => setConfirmModal(false)}
        submitActions={handleSubmit}
        isOpen={confirmModal}
      >
        {`Once submitted, your statement cannot be edited.`}
      </Modal>
      <Modal
        title="Witness Statement Submitted"
        titleClassName="greenHeader"
        submitButtonText="Close"
        hideCancelButton
        onRequestClose={() => history.push(`/externalLogin/${statementId}`)}
        submitActions={() => history.push(`/externalLogin/${statementId}`)}
        isOpen={submissionModal}
      >
        {`Your Witness Statement has been successfully submitted. If there are any
        other questions, ${info?.userContact?.name}
        from ${activeCompany?.name} will contact you
        at your contact information.`}
      </Modal>
    </HeaderAndFooter>
  );
}
