import React from 'react';
import classnames from 'classnames/bind';

import UserBadge from '../UserBadge';
import HeaderCards from '../HeaderCards';

import styles from './headerandfooter.module.scss';
const bStyles = classnames.bind(styles);

export const HeaderAndFooter = ({
  Header,
  Footer,
  Left,
  children,
  className,
  showFooter = true,
  bigHeader = false,
  cards,
  lockInfo = {}
}) => {
  const { dirty, lockedUser, myUser } = lockInfo;
  let otherLock = lockedUser && lockedUser !== myUser;

  if (!Footer) showFooter = false;
  let classes = bStyles({
    container: true,
    noFooter: !showFooter,
    bigHeader: bigHeader,
    locked: otherLock || dirty,
    withLeft: Left,
    cards
  });
  let childClasses = bStyles(className, 'children');
  let lockMessage = otherLock
    ? 'is currently editing this section'
    : dirty
    ? 'This report section has been updated. Please click cancel or the back button to get the latest data from this report section.'
    : undefined;

  return (
    <div className={classes}>
      {Header}
      {lockMessage ? (
        <header className={styles.lock}>
          {otherLock ? (
            <UserBadge
              userId={lockedUser}
              showImage
              showName
              className={styles.lockUser}
            />
          ) : (
            <></>
          )}
          <div className={styles.lockMessage}>{lockMessage}</div>
        </header>
      ) : (
        <></>
      )}
      {Left && <div className={styles.left}>{Left}</div>}
      {cards ? (
        <div className={styles.cards}>
          <HeaderCards location={cards} />
          <div className={childClasses} id="mainPage">
            {children}
          </div>
        </div>
      ) : (
        <div className={classnames(childClasses)} id="mainPage">
          {children}
        </div>
      )}
      {showFooter && Footer}
    </div>
  );
};

export default HeaderAndFooter;
