import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="15 13 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill="#FFCC00" />
    <g clipPath="url(#clip0_1446_9047)">
      <path
        d="M40 16C26.7621 16 16 26.7621 16 40C16 53.2379 26.7621 64 40 64C53.2379 64 64 53.2379 64 40C64 26.7621 53.2379 16 40 16ZM49.4484 46.619C48.9936 47.2759 48.2862 47.6295 47.5283 47.6295C47.0736 47.6295 46.5683 47.4778 46.1642 47.2252L38.6358 41.9199C38.0296 41.4652 37.6253 40.7577 37.6253 39.9998V27.3177C37.6253 26.0041 38.6863 24.9431 39.9999 24.9431C41.3136 24.9431 42.3746 26.0041 42.3746 27.3177V38.7872L48.8925 43.3851C49.9535 44.0925 50.2061 45.5578 49.4482 46.6188L49.4484 46.619Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1446_9047">
        <rect
          width="50"
          height="50"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);
