import React from 'react';

import Button from '../../inputs/Button';
import Card from '../../Card';
import AssignCorrectiveActionButton from '../../inputs/AssignCorrectiveActionButton';

import styles from './itemSummaryCard.module.scss';

const ItemSummaryCard = ({
  item,
  history,
  groupId,
  projectId,
  index,
  name,
  type,
  hazardNumber,
  disabled,
  ownerId
}) => (
  <Card
    title={`#${index + 1} ${item.title}`}
    cardTitle={type.toUpperCase()}
    showHeader
    name={name}
    wide
  >
    <div className={styles.main}>
      {item.pictures && item.pictures.length > 0 && (
        <div className={styles.pictures}>
          <img src={item.pictures[0].source_url} alt="add hazard" />
          <div className={styles.pictureInfo}>
            {item.pictures.length} Picture(s)
          </div>
        </div>
      )}
      <div className={styles.itemInfo}>
        <Button
          color="blue"
          className={styles.button}
          text={`View ${type} Info`}
          onClick={() =>
            history.push(
              `/app/safetyWalkContainer/${type.toLowerCase()}Card/${index}`
            )
          }
        />
        <div className={styles.itemDescription}>{item.description}</div>
        {type === 'Hazard' ? (
          <AssignCorrectiveActionButton
            groupId={groupId}
            task={item?.tasks?.[0]}
            ownerType="safetyWalks"
            label={item.title}
            description={item.description}
            hazardNumber={hazardNumber}
            notes={`Solution - \n ${item.solution} \n Exact Location - \n ${item.exactLocation}`}
            schedule="immediate"
            projectId={projectId}
            immediatelyFixed={item?.immediatelyFixed?.length > 0}
            ownerId={ownerId}
            pictures={item.pictures}
            type="correctiveAction"
            disabled={disabled}
          />
        ) : null}
      </div>
    </div>
  </Card>
);

export default ItemSummaryCard;
