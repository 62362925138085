import React, { useState, useEffect } from 'react';
import history from '../../history';
import {
  fetchCustomDocumentTemplates,
  createCustomDocumentQRCodeSheet,
  archiveMany
} from '../../api/v4';

import { Dropdown } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import Modal from '../../components/Modal';
import TypeSelection from '../../components/TypeSelection';

export default function CustomDocumentTemplateList() {
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [listType, setListType] = useState('Active');

  useEffect(() => {
    fetchCustomDocumentTemplates().then(setTemplates);
  }, []);

  const downloadDocumentQRCode = ids => {
    if (!ids.length) return;
    createCustomDocumentQRCodeSheet(ids).then(response =>
      window.open(response, '_blank')
    );
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'Custom Document Template',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() => fetchCustomDocumentTemplates().then(setTemplates));
  };

  const header = (
    <Header
      title="Custom Document Templates"
      pageActionOptions={[
        {
          label: 'Create Document Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            history.push('/app/customDocumentTemplateContainer');
          }
        },
        {
          label: 'Duplicate Document Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );

  const columns = [
    { key: 'label', label: 'Title' },
    { key: 'createdAt', label: 'Created Date', datatype: 'date' },
    { key: 'updatedAt', label: 'Updated Date', datatype: 'date' }
  ];

  const templateDropdown = templates
    ?.filter(template => !template.isArchived)
    .map(t => {
      return { value: t._id, label: t.label };
    });

  const listTypeFilter =
    listType === 'Archived'
      ? templates?.filter(template => template.isArchived)
      : templates?.filter(template => !template.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={setListType}
        selected={listType}
      />
      <List
        saveKey="customDocTempList"
        rowClick={row =>
          history.push(`/app/customDocumentTemplateContainer/${row._id}`)
        }
        dataIsHash
        data={listTypeFilter}
        settings={columns}
        getRowId={r => r._id}
        actions={[
          {
            color: 'green',
            label: 'Download QR Codes',
            onClick: ids => {
              downloadDocumentQRCode(ids);
            }
          },
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => {
          setOpenModal(false);
          setCurrentTemplate(null);
        }}
        submitActions={() =>
          history.push(
            `/app/customDocumentTemplateContainer/${currentTemplate}/true`
          )
        }
        disableSubmit={!currentTemplate}
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Custom Document Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
