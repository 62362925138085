import React from 'react';
import './svg.css';

export default props => (
  <svg
    width="20"
    height="32"
    viewBox="0 0 20 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_di)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4181 4.26674C16.0608 4.70857 16.191 5.5443 15.709 6.1334L7.63634 16L15.709 25.8666C16.191 26.4557 16.0608 27.2914 15.4181 27.7333C14.7754 28.1751 13.8637 28.0557 13.3818 27.4666L4 16L13.3818 4.53341C13.8637 3.94431 14.7754 3.82492 15.4181 4.26674Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_di"
        x="0"
        y="0"
        width="20"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
    </defs>
  </svg>
);
