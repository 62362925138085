import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { withSocket } from '../../utils/withSocket';
import history from '../../history';
import {
  addSafetyWalkRequest,
  deleteSafetyWalkRequest,
  setActiveSafetyWalk
} from '../../actions/safetyWalks';
import { SAFETY_WALKS_STAGES } from '../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { getActiveSafetyWalkSelector } from '../../selectors/safetyWalks';
import { getLoggedInUser } from '../../selectors/users';
import Card from '../../components/Card';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import ItemSummaryCard from '../../components/safetyWalkCards/ItemSummaryCard';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import SignatureCard from '../../components/SignatureCard';
import SummaryCard from '../../components/safetyWalkCards/SummaryCard/index';
import Textbox from '../../components/inputs/Textbox';
import LeftNav from '../../components/LeftNav';
import StageSelector from '../../components/StageSelector';
import HierarchySelector from '../../components/HierarchySelector';
import { setSafetyWalkNumber, updateSafetyWalk } from '../../api/v4';
import { deleteUserTaskRequest } from '../../actions/tasks';
import { clearUploadedAttachments } from '../../actions/attachments';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

import styles from './itemCard.module.scss';

class SafetyWalkContainer extends Component {
  state = {
    hasUnsavedChanges: false,
    isCreating: true,
    openDiscardModal: false,
    openUnsavedChangesModal: false,
    openEditInspectionLocationModal: false,
    noHazardsModal: false,
    groupId: '',
    projectId: null,
    inspectionLocation: '',
    signature: undefined,
    tempInspectionLocation: '',
    missingRequired: false,
    missingModalOpen: false
  };

  componentDidMount() {
    if (this.props.activeSafetyWalk?.groupId) {
      const id = this.props.activeSafetyWalk._id;
      this.setState({
        ...this.props.activeSafetyWalk,
        isCreating: !id
      });

      if (id) {
        this.props.socketSubscribe({ id: this.props.activeSafetyWalk._id });
      }
    } else if (this.props.activeProject) {
      this.setState({
        companyId: this.props.activeCompany._id,
        groupId: this.props.activeLocation._id,
        projectId: this.props.activeProject._id
      });
    } else if (this.props.activeLocation) {
      this.setState({
        companyId: this.props.activeCompany._id,
        groupId: this.props.activeLocation._id
      });
    } else {
      this.setState({
        companyId: this.props.activeCompany._id,
        hasUnsavedChanges: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeSafetyWalk, this.props.activeSafetyWalk)) {
      this.setState({ ...this.props.activeSafetyWalk });
    }
  }

  updateAnswer = (key, value) => {
    this.setState({
      [key]: value,
      hasUnsavedChanges: true
    });
  };

  canSubmit = () => {
    return (
      this.state.groupId &&
      this.state.inspectionLocation &&
      this.state.signature
    );
  };

  handleAddHazard = () => {
    this.props.clearAttachments();
    this.props.setSafetyWalk({
      companyId: this.state.companyId,
      groupId: this.state.groupId,
      projectId: this.state.projectId,
      inspectionLocation: this.state.inspectionLocation,
      hazards:
        this.props.activeSafetyWalk?.hazards?.length > 0
          ? this.props.activeSafetyWalk.hazards
          : [],
      bravos:
        this.props.activeSafetyWalk?.bravos?.length > 0
          ? this.props.activeSafetyWalk.bravos
          : [],
      signature: this.state.signature,
      hasUnsavedChanges: true
    });
    history.push('/app/safetyWalkContainer/hazardCard');
  };

  handleAddBravos = () => {
    this.props.clearAttachments();
    this.props.setSafetyWalk({
      companyId: this.state.companyId,
      groupId: this.state.groupId,
      projectId: this.state.projectId,
      inspectionLocation: this.state.inspectionLocation,
      bravos:
        this.props.activeSafetyWalk?.bravos?.length > 0
          ? this.props.activeSafetyWalk.bravos
          : [],
      hazards:
        this.props.activeSafetyWalk?.hazards?.length > 0
          ? this.props.activeSafetyWalk.hazards
          : [],
      signature: this.state.signature,
      hasUnsavedChanges: true
    });
    history.push('/app/safetyWalkContainer/bravoCard');
  };

  handleSWSubmit = () => {
    this.props.addSafetyWalk({
      companyId: this.state.companyId,
      groupId: this.state.groupId,
      projectId: this.state.projectId?.length ? this.state.projectId : null,
      inspectionLocation: this.state.inspectionLocation,
      hazards: this.props.activeSafetyWalk.hazards,
      bravos: this.props.activeSafetyWalk.bravos,
      signature: this.state.signature
    });
    this.setState({
      isCreating: false,
      noHazardsModal: false,
      hasUnsavedChanges: false
    });
  };

  submitSafetyWalkName = newName => {
    const { activeSafetyWalk } = this.props;
    this.props.setSafetyWalk({
      ...activeSafetyWalk,
      safetyWalkNumber: newName
    });
    setSafetyWalkNumber(activeSafetyWalk._id, newName);
  };

  updateStage = value => {
    this.props.socketLock();
    updateSafetyWalk({
      ...this.props.activeSafetyWalk,
      ...value
    }).then(() => {
      this.props.socketDirty();
      this.setState(value);
    });
  };

  handleDiscard = () => {
    const tasks =
      this.props?.activeSafetyWalk?.hazards
        ?.map(h => h.tasks ?? [])
        .reduce((acc, x) => acc.concat(x), []) ?? [];
    if (tasks.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      tasks?.forEach(t => this.props.deleteUserTask({ ...t }));
    } else {
      this.props.setSafetyWalk({ hazards: [], bravos: [] });
      this.state.backToDashboard
        ? history.push('/app/dashboard')
        : history.goBack();
    }
  };

  render() {
    const {
      hasUnsavedChanges,
      isCreating,
      openUnsavedChangesModal,
      openDiscardModal,
      noHazardsModal,
      groupId,
      projectId,
      inspectionLocation,
      backToDashboard,
      stage,
      openEditInspectionLocationModal,
      tempInspectionLocation,
      missingRequired,
      missingModalOpen
    } = this.state;

    const {
      activeCompany,
      activeSafetyWalk,
      locked,
      loggedInUser,
      setSafetyWalk
    } = this.props;

    let hazardNumber = 0;
    const hazards = activeSafetyWalk?.hazards || [];
    const bravos = activeSafetyWalk?.bravos || [];

    const group = activeCompany.groups?.find(
      g => g._id === activeSafetyWalk?.groupId
    );
    const area = group?.projects?.find(
      p => p._id === activeSafetyWalk?.projectId
    );

    const disabled =
      group?.isHidden || area?.isHidden || activeSafetyWalk?.isArchived;

    const footer = (
      <SaveCancelFooter
        saveButtonClick={() =>
          !this.canSubmit()
            ? this.setState({ missingModalOpen: true })
            : activeSafetyWalk?.hazards?.length === 0 &&
              activeSafetyWalk?.bravos?.length === 0
            ? this.setState({ noHazardsModal: true })
            : this.handleSWSubmit()
        }
        cancelButtonClick={this.handleDiscard}
        onMouseEnter={() => this.setState({ missingRequired: true })}
      />
    );

    const myName = activeCompany.isDash ? 'DASH Report' : 'Safety Walk';
    const deleteText = `Delete ${myName}`;
    const createdBy =
      activeSafetyWalk?.auditLog?.[0]?.user ?? activeSafetyWalk?.createdBy;

    const showDelete =
      loggedInUser?.accessLevel !== 100 &&
      (loggedInUser?.accessLevel === 900 || createdBy === loggedInUser?._id);

    const handleArchive = () => {
      updateSafetyWalk({
        ...this.props.activeSafetyWalk,
        isArchived: !this.props.activeSafetyWalk.isArchived
      }).then(response => {
        if (response.isArchived) history.push('/app/safetyWalkStages');
        else this.props.setSafetyWalk(response);
      });
    };

    const header = (
      <Header
        title={`${isCreating ? 'Create' : 'View'} ${myName}`}
        section={activeSafetyWalk?.safetyWalkNumber}
        editName={disabled ? null : this.submitSafetyWalkName}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : backToDashboard
            ? history.push('/app/dashboard')
            : history.goBack()
        }
        center={
          !isCreating && (
            <StageSelector
              fieldLabel={`${myName} Stage:`}
              options={SAFETY_WALKS_STAGES}
              currentValue={stage}
              onChange={value => this.updateStage({ stage: value })}
              disabled={true}
              bareValues={false}
              displayTooltip={true}
              name="safetyWalkStage"
              tooltip="Safety walk stage is system generated"
              testID="swStage"
            />
          )
        }
        // need to come back and think about how we want to handle this
        pageActionOptions={
          !isCreating && [
            {
              label: 'Delete Safety Walk',
              visible: showDelete,
              color: 'redOutline',
              onClick: () => {
                this.setState({ openDiscardModal: true });
              }
            },
            {
              label: 'Close Safety Walk',
              onClick: () => {
                this.updateStage({
                  stage: {
                    value: 'closed',
                    label: 'Closed'
                  }
                });
              },
              color: 'blueOutline',
              visible: activeSafetyWalk?.stage?.label !== 'Closed' && !disabled
            },
            {
              label: 'Edit Location',
              onClick: () => {
                this.setState({
                  openEditInspectionLocationModal: true,
                  tempInspectionLocation: inspectionLocation
                });
              },
              color: 'blueOutline',
              visible: loggedInUser?.accessLevel === 900 && !disabled
            },
            {
              label: 'Reopen Safety Walk',
              onClick: () => {
                this.updateStage({
                  stage: { value: 'severityReview', label: 'Severity Review' }
                });
              },
              color: 'blueOutline',
              visible:
                activeSafetyWalk?.stage?.label === 'Closed' &&
                activeSafetyWalk?.canBeReopened &&
                !disabled
            },
            {
              label: `${
                activeSafetyWalk?.isArchived ? 'Un-' : ''
              }Archive Safety Walk`,
              onClick: handleArchive,
              color: 'blueOutline',
              visible: loggedInUser.accessLevel > 400
            }
          ]
        }
      />
    );

    let together = bravos.concat(hazards);
    together.sort((a, b) => a.overallOrder - b.overallOrder);
    let leftNav = together.map((h, i) => {
      if (h.bravosGiven != null) {
        return {
          label: `#${i + 1} ${h.title}`,
          id: `b-${i}`
        };
      }
      return {
        label: `#${i + 1} ${h.title}`,
        id: `h-${i}`
      };
    });

    leftNav.push({ label: 'Signature', id: 'signature' });
    if (!isCreating) leftNav.unshift({ label: 'Summary', id: 'summary' });
    leftNav.unshift({ label: `${myName} Info`, id: 'info' });

    const left = (
      <LeftNav
        items={leftNav}
        onAddHazard={
          isCreating &&
          this.state.groupId.length > 0 &&
          this.state.inspectionLocation.length > 0 &&
          this.handleAddHazard
        }
        onAddBravos={
          isCreating &&
          this.state.groupId.length > 0 &&
          this.state.inspectionLocation.length > 0 &&
          this.handleAddBravos
        }
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          lockInfo={this.props.lockInfo}
          showFooter={isCreating}
        >
          <Card showHeader title={`${myName} Info`} name="info" wide>
            {this.state.groupId.length > 0 &&
            this.state.inspectionLocation.length > 0 ? null : (
              <p className={styles.infoStyle}>
                Fill out the required fields to add Hazards and Bravos.
              </p>
            )}
            <HierarchySelector
              onGroupChange={o => this.updateAnswer('groupId', o)}
              groupId={groupId}
              groupDisabled={!isCreating}
              onProjectChange={o => this.updateAnswer('projectId', o)}
              projectId={projectId}
              projectDisabled={!isCreating || !groupId}
              touched={missingRequired && !groupId}
            />
            <Textbox
              fieldLabel="Inspection Location"
              isRequired={true}
              placeholder="Where is the inspection taking place?"
              onChange={v => this.updateAnswer('inspectionLocation', v)}
              currentValue={inspectionLocation}
              disabled={!isCreating}
              touched={missingRequired && !inspectionLocation}
            />
          </Card>
          {!isCreating && (
            <SummaryCard
              safetyWalk={activeSafetyWalk}
              loggedInUser={loggedInUser}
              name="summary"
              isDash={activeCompany.isDash}
            />
          )}
          {together.map((hazard, index) => (
            <ItemSummaryCard
              item={hazard}
              key={index}
              history={history}
              index={index}
              groupId={groupId}
              projectId={projectId}
              name={hazard.bravosGiven != null ? `b-${index}` : `h-${index}`}
              type={hazard.bravosGiven != null ? 'Bravo' : 'Hazard'}
              hazardNumber={
                hazard.bravosGiven != null ? hazardNumber : hazardNumber++
              }
              disabled={locked || disabled}
              ownerId={activeSafetyWalk?._id}
            />
          ))}
          <SignatureCard
            wide
            currentValue={this.state.signature}
            name="signature"
            disabled={!isCreating}
            header="Signature"
            onChange={signature => {
              setSafetyWalk({ ...activeSafetyWalk, signature: signature });
              this.setState({ signature });
            }}
            missingRequired={missingRequired}
          />
        </HeaderAndFooter>
        <Modal
          title={deleteText}
          titleClassName="redHeader"
          isOpen={openDiscardModal}
          submitButtonColor="red"
          submitButtonText={deleteText}
          onRequestClose={() => this.setState({ openDiscardModal: false })}
          submitActions={() => {
            if (!this.state.isCreating) {
              this.props.deleteSafetyWalk(this.props.activeSafetyWalk?._id);
            }
            setSafetyWalk({ hazards: [], bravos: [] });
            backToDashboard ? history.push('/app/dashboard') : history.goBack();
          }}
        >
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Discarding the {myName} will delete all information that has been
            gathered, pictures taken, and corrective actions assigned as tasks.
          </span>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={this.handleDiscard}
        />
        <Modal
          title="Edit Inspection Location"
          titleClassName="blueHeader"
          isOpen={openEditInspectionLocationModal}
          submitButtonColor="blue"
          submitButtonText="Save"
          disableSubmit={
            !tempInspectionLocation.match(/^(?:[^a-zA-Z0-9]*[a-zA-Z0-9]){3}.*/)
          }
          onRequestClose={() => {
            this.setState({
              openEditInspectionLocationModal: false,
              hasUnsavedChanges: false
            });
          }}
          submitActions={() => {
            if (tempInspectionLocation !== '') {
              this.props.socketLock();
              updateSafetyWalk({
                ...this.props.activeSafetyWalk,
                inspectionLocation: tempInspectionLocation
              }).then(() => {
                this.setState({
                  inspectionLocation: tempInspectionLocation
                });
                this.props.socketDirty();
              });
              this.setState({
                tempInspectionLocation: '',
                openEditInspectionLocationModal: false,
                hasUnsavedChanges: false
              });
            }
          }}
        >
          <Textbox
            fieldLabel="Inspection Location"
            placeholder="Where is the inspection taking place?"
            onChange={e => this.updateAnswer('tempInspectionLocation', e)}
            currentValue={tempInspectionLocation}
          />
        </Modal>
        <Modal
          title="No Hazards"
          titleClassName="blueHeader"
          isOpen={noHazardsModal}
          submitButtonColor="blue"
          submitButtonText="Continue"
          onRequestClose={() => this.setState({ noHazardsModal: false })}
          submitActions={() => this.handleSWSubmit()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            No Hazards or Bravos have been added to this {myName}. If this is
            correct, please continue. Otherwise, cancel and add the Hazards
            before Submitting.
          </div>
        </Modal>
        <MissingRequiredModal
          isOpen={missingModalOpen}
          onRequestClose={() => this.setState({ missingModalOpen: false })}
          subject={myName}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  activeSafetyWalk: getActiveSafetyWalkSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  addSafetyWalk: payload => dispatch(addSafetyWalkRequest(payload)),
  setSafetyWalk: payload => dispatch(setActiveSafetyWalk(payload)),
  deleteSafetyWalk: payload => dispatch(deleteSafetyWalkRequest(payload)),
  deleteUserTask: task => dispatch(deleteUserTaskRequest(task)),
  clearAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSocket(SafetyWalkContainer));
