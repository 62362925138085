import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CONTRACTOR_STATUSES } from '../../constants/constants';
import { getUserCompaniesSelector } from '../../selectors/company';
import {
  getGroupDropdown,
  getProjectDropdown
} from '../../utils/employeeDGPHelper';
import { normalizePhone } from '../../utils/formValidation';
import BlockText from '../inputs/BlockText';
import HierarchySelector from '../HierarchySelector';
import {
  Dropdown,
  Textbox,
  TwoColumn,
  InputRow,
  Button,
  Checkbox
} from '../inputs';
import DeleteItemModal from '../Modal/deleteItemModal';

export default function ContractorForm({
  contractor,
  updateContractor,
  checkUsernameAvailability,
  isEditing,
  updateEmployeeApi,
  deleteEmployeeApi,
  missingRequired,
  userExists
}) {
  const userCompanies = useSelector(getUserCompaniesSelector);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      <TwoColumn>
        <BlockText blockOfText="Note: All Contractors will have Reporter level access." />
        {isEditing ? (
          <InputRow right>
            <Button color="blue" text="Update" onClick={updateEmployeeApi} />
            <Button
              color="red"
              text="Delete"
              onClick={() => setOpenDeleteModal(true)}
            />
          </InputRow>
        ) : null}
      </TwoColumn>
      <TwoColumn>
        <Dropdown
          isRequired
          multi
          searchable
          selectButtons
          fieldLabel="Company Division:"
          options={userCompanies.map(c => ({ label: c.name, value: c._id }))}
          currentValue={contractor?.companyIds}
          onChange={values => updateContractor({ companyIds: values })}
          touched={missingRequired && !contractor?.companyIds?.length}
        />
        <Textbox
          isRequired
          fieldLabel="Contractor Company Name:"
          currentValue={contractor?.contractorCompanyName}
          onChange={value => updateContractor({ contractorCompanyName: value })}
          touched={missingRequired && !contractor?.contractorCompanyName}
        />
      </TwoColumn>
      <HierarchySelector
        multi
        groupDisabled={!contractor?.companyIds?.length}
        onGroupChange={values => updateContractor({ groupIds: values })}
        onProjectChange={values => updateContractor({ projectIds: values })}
        groupId={contractor?.groupIds}
        projectId={contractor?.projectIds}
        filteredGroupDropdown={getGroupDropdown(userCompanies, contractor)}
        filteredProjectDropdown={getProjectDropdown(userCompanies, contractor)}
        alwaysShowGroupDropdown
        alwaysShowProjectDropdown
        touched={missingRequired && !contractor?.groupIds?.length}
      />
      <TwoColumn>
        <Textbox
          isRequired
          fieldLabel="First Name"
          currentValue={contractor?.firstName}
          onChange={value => updateContractor({ firstName: value })}
          touched={missingRequired && !contractor?.firstName}
        />
        <Textbox
          isRequired
          fieldLabel="Last Name"
          currentValue={contractor?.lastName}
          onChange={value => updateContractor({ lastName: value })}
          touched={missingRequired && !contractor?.lastName}
        />
        <Textbox
          isRequired
          fieldLabel="Job Title"
          currentValue={contractor?.jobTitle}
          onChange={value => updateContractor({ jobTitle: value })}
          touched={missingRequired && !contractor?.jobTitle}
        />
        <Dropdown
          isRequired
          fieldLabel="Status"
          options={CONTRACTOR_STATUSES}
          currentValue={contractor?.status}
          onChange={value => updateContractor({ status: value })}
          touched={missingRequired && !contractor?.status}
        />
        <Textbox
          fieldLabel="Email"
          currentValue={contractor?.email}
          onChange={value => updateContractor({ email: value })}
          placeholder="ex person@company.com"
        />
        <Textbox
          fieldLabel="Phone"
          currentValue={contractor?.phoneNumber}
          onChange={value =>
            updateContractor({ phoneNumber: normalizePhone(value) })
          }
          placeholder="ex 111-111-1111"
        />
        {isEditing ? (
          <Checkbox
            fieldLabel="Deactivate User (user will not be able to log in)"
            onChange={v => {
              updateContractor({ isDeactivated: v });
            }}
            currentValue={contractor?.isDeactivated}
          />
        ) : null}
      </TwoColumn>
      {isEditing ? null : (
        <Textbox
          isRequired
          fieldLabel={`Username`}
          currentValue={contractor?.username}
          onChange={checkUsernameAvailability}
          touched={(missingRequired && !contractor?.username) || userExists}
          errorMessage={userExists ? 'Username is unavailable' : ''}
        />
      )}

      <DeleteItemModal
        deletingWhat="Contractor"
        hideCustomTemplate
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={deleteEmployeeApi}
      />
    </>
  );
}
