import React from 'react';

import BasicTrainingCard from '../Cards/Basic';
import TrainingDetails from '../Cards/Details';
import ScheduleDigitalCard from '../Cards/Schedule/Digital';
import ScheduleInPersonCard from '../Cards/Schedule/In-Person';

export default function TrainingSummary({
  training,
  company,
  fromApprove,
  disabled = true,
  fromView,
  editing,
  editingAssignees,
  onAnswer,
  setCanSubmit
}) {
  return (
    <>
      <BasicTrainingCard
        training={training}
        disabled={disabled}
        fromApprove={fromApprove}
      />
      <TrainingDetails
        training={training}
        disabled={disabled}
        fromApprove={fromApprove}
        editing={editing}
        fromView={fromView}
        onAnswer={onAnswer}
        hideTime
      />
      {training.trainingType === 1 ? (
        <ScheduleDigitalCard
          training={training}
          disabled={fromView ? !editing : disabled}
          hideWarnings={fromApprove}
          fromView={fromView}
          onAnswer={onAnswer}
          company={company}
          setCanSubmit={setCanSubmit}
          editingAssignees={editingAssignees}
        />
      ) : (
        <ScheduleInPersonCard
          training={training}
          company={company}
          disabled={fromView ? !editing : disabled}
          hideWarnings={fromApprove}
          onAnswer={onAnswer}
          setCanSubmit={setCanSubmit}
          editingAssignees={editingAssignees}
        />
      )}
    </>
  );
}
