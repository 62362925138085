import React from 'react';
import config from '../../../config/config';
import moment from 'moment';
import { FieldRenderer } from '../../inputs';
import Card from '../../Card';
import HierarchySelector from '../../HierarchySelector';
import { shouldDisableQuestion } from '../../../utils/incidentHelper';

const IncidentSection = ({
  name,
  reportSectionHeader,
  fields,
  answers,
  isIncidentBasics,
  isEditing,
  isCreating,
  activeIncident,
  handleSelectedAnswer,
  disableSection,
  alreadyHaveConfidential,
  missingRequired
}) => {
  const groupId = answers?.locationId ?? activeIncident?.locationId;

  const disabled = disableSection || (!isCreating && !isEditing);
  const witnessDisabled = !isCreating;
  const isCustomQuestions = reportSectionHeader === 'Custom Questions';
  const firstQuestion = fields[0];
  const showConfidentialQuestion =
    !firstQuestion?.hide &&
    config.staticIds.confidentialReport === firstQuestion?._id;
  return (
    <Card name={name} title={reportSectionHeader} showHeader wide>
      {showConfidentialQuestion ? (
        <FieldRenderer
          key={0}
          fieldLabel={firstQuestion.label}
          isRequired={firstQuestion.required}
          name={firstQuestion.label}
          id={firstQuestion._id}
          currentValue={answers?.[firstQuestion._id] ?? ''}
          onChange={v =>
            handleSelectedAnswer(
              { [firstQuestion._id]: v },
              firstQuestion.label
            )
          }
          disabled={alreadyHaveConfidential && disabled}
          options={firstQuestion.options}
          type={firstQuestion.type}
          bareValues={false}
          groupId={groupId}
          alphabetize
          missingRequired={missingRequired}
        />
      ) : null}
      {isIncidentBasics && (
        <HierarchySelector
          onGroupChange={v => handleSelectedAnswer({ locationId: v })}
          groupId={answers?.locationId ?? activeIncident?.locationId}
          groupDisabled={disabled}
          onProjectChange={v => handleSelectedAnswer({ projectId: v })}
          projectId={answers?.projectId ?? activeIncident?.projectId}
          projectDisabled={disabled}
          alphabetize
          touched={missingRequired && !answers.locationId}
        />
      )}
      {fields.map((field, index) => {
        if (field.legacy) return <React.Fragment key={index}></React.Fragment>;
        const answer =
          answers?.[field._id] ?? (field.type === 'multiselect' ? [] : '');

        if (field.label === 'Is this a Confidential Report?') return null;
        return (
          <FieldRenderer
            key={index}
            fieldLabel={field.label}
            isRequired={field.required}
            name={field.label}
            id={field._id}
            showNA={
              field.type === 'signature' ? false : field.showNotApplicable
            }
            isNA={answer?.length === 1 && answer?.[0] === 'Not Applicable'}
            currentValue={answer}
            onChange={v =>
              handleSelectedAnswer({ [field._id]: v }, field.label)
            }
            onNAChange={v =>
              handleSelectedAnswer({
                [field._id]: v
                  ? ['Not Applicable']
                  : field.type === 'multiselect'
                  ? []
                  : ''
              })
            }
            disabled={
              field.label === 'Additional Witnesses of the Incident:'
                ? witnessDisabled
                : disabled ||
                  (isEditing && shouldDisableQuestion(activeIncident, field))
            }
            options={field.options}
            type={field.type}
            bareValues={false}
            groupId={groupId}
            // This message will proabably needed to be worded better
            displayTooltip={
              field.displayTooltip ??
              field.label === 'Additional Witnesses of the Incident:'
            }
            tooltip={
              field.tooltip ??
              'Only Active Users are generated in this list. If a user is not found they will need to be activated on the Employee Page'
            }
            alphabetize={isCustomQuestions ? false : field.alphabetize ?? true}
            firstOptions={field.firstOptions}
            hide={field.hide}
            missingRequired={missingRequired}
            maxDate={
              field.label === 'When did the incident occur?' ? moment() : null
            }
          />
        );
      })}
    </Card>
  );
};

export default IncidentSection;
