import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import history from '../../../history';
import { uploadAttachment, deleteAttachment } from '../../../api/v4';
import {
  createAnnouncement,
  fetchAnnouncementById,
  updateAnnouncementViewed,
  deleteAnnouncement,
  updateAnnouncement,
  resendAnnouncementNotification
} from '../../../api/v4';
import { addMessage } from '../../../actions/messages';
import { getActiveCompany } from '../../../selectors/company';
import { getLoggedInUser, getAccessLevel } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import { isFutureDate } from '../../../utils/formValidation';
import { TwoColumn } from '../../../components/inputs';
import {
  Dropdown,
  EmployeeDropdown,
  Textbox
} from '../../../components/inputs';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { ConfirmFooter, SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Modal from '../../../components/Modal';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function AnnouncementContainer() {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getLoggedInUser);
  const company = useSelector(getActiveCompany);
  const groupDropdown = company?.groups
    ?.filter(g => !g.isHidden)
    ?.map(g => ({
      value: g._id,
      label: g.name
    }));

  const [announcement, setAnnouncement] = useState({});
  const [needsToSubmit, setNeedsToSubmit] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [editing, setEditing] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnArchiveModal, setOpenUnArchiveModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const accessLevel = useSelector(getAccessLevel);
  const isAdmin = accessLevel === 900;

  useEffect(() => {
    if (id) {
      fetchAnnouncementById(id)
        .then(response => {
          if (
            location?.state?.fromToast &&
            !response.assignedTo?.includes(user._id)
          ) {
            history.push('/app/dashboard');
            dispatch(
              addMessage({
                error: true,
                message: 'User does not have access to announcement'
              })
            );
          } else {
            setNeedsToSubmit(
              response.assignedTo?.includes(user._id) &&
                !response.employeesWhoViewedAnnouncement?.includes(user._id)
            );
            setAnnouncement(response);
          }
        })
        .catch(e => {
          history.push('/app/dashboard');
          dispatch(
            addMessage({
              error: true,
              message: `Unable to load announcement. ${e.data}`
            })
          );
        });
    } else {
      setCreating(true);
    }
  }, [id, user, location, dispatch]);

  const handleCreate = async () => {
    if (announcement.showUntil === null) {
      delete announcement.showUntil;
      delete announcement.showUntilDate;
    }
    if (filesToUpload?.length) {
      await addAttachments(filesToUpload);
    } else {
      createAnnouncement(announcement).then(() => history.goBack());
    }
  };

  const handleEdit = keepArchived => {
    updateAnnouncement({ ...announcement, isArchived: keepArchived }).then(
      response => {
        setAnnouncement(response);
        setEditing(false);
        setUnsavedChanges(false);
        setOpenUnArchiveModal(false);
        !keepArchived && resendAnnouncementNotification(response._id);
      }
    );
  };

  const handleUpdate = async () => {
    const updateView = [
      ...announcement?.employeesWhoViewedAnnouncement,
      user._id
    ];
    updateAnnouncementViewed({
      ...announcement,
      employeesWhoViewedAnnouncement: updateView
    }).then(() => history.goBack());
  };

  const addAttachments = attachments => {
    let data = formatFiles(attachments);
    uploadAttachment(data, {
      ownerType: 'announcement',
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      if (response === 'No file object attached') {
        dispatch(
          addMessage({
            error: true,
            message: response
          })
        );
        return;
      }

      creating
        ? createAnnouncement({
            ...announcement,
            attachments: response?.map(a => a._id)
          }).then(() => history.goBack())
        : updateAnnouncement({
            ...announcement,
            attachments: response?.map(a => a._id)
          }).then(setAnnouncement);
    });
  };

  const deleteAttach = attachment => {
    deleteAttachment(attachment._id, false).then(() => {
      let attachments = announcement.attachments.filter(a => a !== attachment);

      if (editing) {
        updateAnnouncement({
          ...announcement,
          attachments: attachments.map(a => a._id)
        }).then(setAnnouncement);
      }
    });
  };

  const handleAnswer = values => {
    setAnnouncement({ ...announcement, ...values });
    setUnsavedChanges(true);
  };

  const handleAnnouncementDuration = value => {
    if (value !== 'custom' && value !== null) {
      handleAnswer({ showUntilDate: moment().add(1, value), showUntil: value });
    } else {
      handleAnswer({ showUntil: value, showUntilDate: null });
    }
  };

  const canSubmit = () => {
    const { title, sendToChoice, message, groupIds, assignedTo } = announcement;
    const hasBasics =
      title?.trim() &&
      sendToChoice &&
      message?.trim() &&
      (announcement?.showUntil !== 'custom' ||
        new Date(announcement?.showUntilDate) > new Date());
    return sendToChoice === 'company'
      ? hasBasics
      : sendToChoice === 'groups'
      ? hasBasics && groupIds?.length
      : sendToChoice === 'employees' && hasBasics && assignedTo?.length;
  };

  const renderNonAdminView = () => (
    <>
      <Card title={announcement?.title} showHeader>
        <Textbox
          currentValue={announcement?.message}
          disabled
          inputClassName="announcementMessage"
          type="textarea"
        />
      </Card>
      {announcement?.attachments?.length ? (
        <AttachmentUploadCard
          name={2}
          addAttachments={addAttachments}
          isCreating={creating}
          addedAttachments={announcement?.attachments || []}
          attachmentsToUpload={attachmentsToUpload}
          ownerType="announcement"
          handleAnswer={(currentAttachments, currentFiles) => {
            setAttachmentsToUpload(currentAttachments);
            setFilesToUpload(currentFiles);
            setUnsavedChanges(true);
          }}
          createdBy={user._id}
        />
      ) : null}
    </>
  );

  // if admin is just viewing announcement, show datepicker with date the announcement shows until and not the show announcement for dropdown until they edit

  const durationDropdown = (
    <Dropdown
      options={[
        { value: null },
        { label: 'A Day', value: 'days' },
        { label: 'A Week', value: 'weeks' },
        { label: 'A Month', value: 'months' },
        { label: 'A Year', value: 'years' },
        { label: 'Custom', value: 'custom' }
      ]}
      fieldLabel="Show Announcement for:"
      disabled={!creating && !editing}
      onChange={handleAnnouncementDuration}
      currentValue={announcement?.showUntil}
      displayTooltip
      tooltip="This is how long the announcement will be visible in a user's announcement list."
    />
  );

  const durationDatePicker = (
    <DatePicker
      disabled={!creating && !editing}
      onChange={value => {
        handleAnswer({ showUntilDate: value });
      }}
      pickDate
      fieldLabel={
        !creating && !editing ? 'Show Announcement Until' : 'Select a Date'
      }
      currentValue={announcement?.showUntilDate}
      name="customDate"
      errorMessage={
        announcement?.showUntilDate
          ? isFutureDate(announcement?.showUntilDate)
          : null
      }
      touched={isFutureDate(announcement?.showUntilDate) && missingRequired}
      isRequired
      minDate={moment().add(1, 'days')}
    />
  );

  const renderAdminView = () => (
    <>
      <Card name="announcement" title="Announcement Information" showHeader>
        <Textbox
          fieldLabel="Title"
          onChange={v => handleAnswer({ title: v })}
          currentValue={announcement?.title}
          disabled={!creating && !editing}
          isRequired
          touched={missingRequired && !announcement?.title}
        />
        <Textbox
          fieldLabel="Message"
          onChange={v => handleAnswer({ message: v })}
          currentValue={announcement?.message}
          disabled={!creating && !editing}
          isRequired
          type="textarea"
          touched={missingRequired && !announcement?.message}
        />
        <Dropdown
          options={[
            { label: 'Whole Company', value: 'company' },
            { label: 'Groups/Establishments', value: 'groups' },
            { label: 'Employee(s)', value: 'employees' }
          ]}
          fieldLabel="Who should get this announcement?"
          currentValue={announcement?.sendToChoice}
          isRequired
          onChange={value =>
            value !== announcement?.sendToChoice
              ? handleAnswer({
                  sendToChoice: value,
                  assignedTo: [],
                  groupIds: []
                })
              : handleAnswer({ sendToChoice: value })
          }
          disabled={!creating && !editing}
          displayTooltip
          tooltip="If company is selected, any active user inside of the company will be notified. Otherwise, you will be able to choose which employee(s) or group(s) receive this announcement."
          touched={missingRequired && !announcement?.sendToChoice}
        />
        {announcement?.sendToChoice === 'groups' ? (
          <Dropdown
            options={groupDropdown}
            fieldLabel="Groups/Establishments"
            currentValue={announcement?.groupIds}
            isRequired
            onChange={value => handleAnswer({ groupIds: value })}
            disabled={!creating && !editing}
            multi
            displayTooltip
            tooltip="Any active user inside of the chosen group(s) will be notified."
            searchable
            selectButtons
            touched={missingRequired && !announcement?.groupIds.length}
          />
        ) : announcement?.sendToChoice === 'employees' ? (
          <EmployeeDropdown
            fieldLabel="Employee(s)"
            currentValue={announcement?.assignedTo}
            onChange={value => handleAnswer({ assignedTo: value })}
            isRequired
            disabled={!creating && !editing}
            permissions={[100, 400, 500, 900]}
            searchable
            selectButtons
            multi
            touched={missingRequired && !announcement?.assignedTo.length}
          />
        ) : null}
        {!creating && !editing ? (
          announcement?.showUntilDate && durationDatePicker
        ) : announcement?.showUntil !== 'custom' ? (
          durationDropdown
        ) : (
          <TwoColumn>
            {durationDropdown}
            {durationDatePicker}
          </TwoColumn>
        )}
      </Card>
      <AttachmentUploadCard
        name={2}
        addAttachments={addAttachments}
        deleteAttachment={deleteAttach}
        isCreating={creating}
        isEditing={editing}
        addedAttachments={announcement?.attachments || []}
        attachmentsToUpload={attachmentsToUpload}
        formattedAttachmentsToUpload={filesToUpload}
        ownerType="announcement"
        handleAnswer={(currentAttachments, currentFiles) => {
          setAttachmentsToUpload(currentAttachments);
          setFilesToUpload(currentFiles);
          setUnsavedChanges(true);
        }}
        createdBy={user._id}
      />
      <DeleteItemModal
        deletingWhat="Announcement"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteAnnouncement(announcement?._id).then(() =>
            history.push('/app/announcementList')
          )
        }
        hideCustomTemplate
      />
      <Modal
        title="Un-Archive Announcement?"
        isOpen={openUnArchiveModal}
        titleClassName="greenHeader"
        submitButtonText="Yes"
        submitButtonColor="green"
        submitActions={() => handleEdit(false)}
        cancelButtonText="No"
        cancelActions={() => handleEdit(true)}
        onRequestClose={() => setOpenUnArchiveModal(false)}
      >
        This announcement is currently archived. To resend it, it must be
        un-archived.
        <p>Would you like to un-archive this announcement?</p>
      </Modal>
    </>
  );

  const header = (
    <Header
      title="Announcement"
      section={
        (creating ? 'Create ' : editing ? 'Edit ' : 'View ') + 'Announcement'
      }
      needsSaved={unsavedChanges}
      clickBack={() =>
        needsToSubmit
          ? handleUpdate()
          : unsavedChanges
          ? setOpenSaveChangesModal(true)
          : history.goBack()
      }
      pageActionOptions={
        isAdmin && !creating
          ? [
              {
                label: 'Edit Announcement',
                color: 'blueOutline',
                onClick: () => setEditing(true),
                visible: !editing && !announcement?.isArchived
              },
              {
                label: `${
                  announcement?.isArchived ? 'Un-' : ''
                }Archive Announcement`,
                color: 'blueOutline',
                onClick: () =>
                  updateAnnouncement({
                    ...announcement,
                    isArchived: !announcement?.isArchived
                  }).then(response =>
                    response.isArchived
                      ? history.push('/app/announcementList')
                      : setAnnouncement(response)
                  ),
                visible: !creating
              },
              {
                label: 'Delete Announcement',
                color: 'redOutline',
                onClick: () => setOpenDeleteModal(true),
                visible: !creating
              }
            ]
          : null
      }
    />
  );

  const footer =
    creating || editing ? (
      <SaveCancelFooter
        cancelButtonClick={() =>
          unsavedChanges ? setOpenSaveChangesModal(true) : history.goBack()
        }
        saveButtonClick={() =>
          canSubmit()
            ? creating
              ? handleCreate()
              : announcement?.isArchived
              ? setOpenUnArchiveModal(true)
              : handleEdit(false)
            : setMissingModalOpen(true)
        }
        editing={editing}
        onMouseEnter={() => setMissingRequired(true)}
      />
    ) : needsToSubmit ? (
      <ConfirmFooter
        editing={needsToSubmit}
        saveButtonClick={handleUpdate}
        buttonText="Close"
      />
    ) : null;

  return (
    <HeaderAndFooter Header={header} Footer={footer} showFooter={true}>
      {!isAdmin ? renderNonAdminView() : renderAdminView()}
      <SaveChangesModal
        savingWhat="Announcement"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Announcement"
        isEditing={editing}
      />
    </HeaderAndFooter>
  );
}
