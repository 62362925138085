import React from 'react';
import moment from 'moment';
import { Bar, BarChart, XAxis, YAxis, Tooltip } from 'recharts';
import classnames from 'classnames/index';

import Card from '../../../components/Card';
import UserBadge from '../../UserBadge';

import styles from './summaryCard.module.scss';

export default function SummaryCard({ audit }) {
  const createdBy = audit?.auditLog?.[0]?.user;
  const assignedTo = audit?.assignedTo?.[0];
  const completedOn = moment(audit?.completedDate).format('MM/DD/YYYY hh:mm a');
  const timeSpent = audit?.timeSpent;
  const summary = audit?.summary;
  const equipmentName = audit?.equipment?.title;
  const totalCount = summary?.safeCount + summary?.unsafeCount;
  let colors = [
    '#800000',
    '#805500',
    '#558000',
    '#008000',
    '#008055',
    '#005580',
    '#000080',
    '#550080',
    '#800055'
  ];
  const offset = audit._id
    ? audit._id
        .split('')
        .reduce((a, v) => (a + v.charCodeAt(0)) % colors.length)
    : 0;
  const darkenColor = (col, amt) => {
    col = col.slice(1);

    var num = parseInt(col, 16);
    var r = (num >> 16) - amt;
    var b = ((num >> 8) & 0xff) - amt;
    var g = (num & 0xff) - amt;

    if (r < 0) r = 0;
    if (b < 0) b = 0;
    if (g < 0) g = 0;

    return '#' + (g | (b << 8) | (r << 16)).toString(16);
  };

  return (
    <Card showHeader title="Summary">
      <div className={styles.stats}>
        <div className={styles.chartDivs}>
          <div className={styles.chartTitle}>Overall Score</div>
          <div
            className={classnames(styles.statistic, styles.overallStatistic)}
          >
            {`${
              summary?.safeCount === 0 && summary?.unsafeCount === 0
                ? 0
                : Math.round((summary?.safeCount / totalCount) * 100)
            } %`}
          </div>
        </div>
        <div className={styles.chartDivs}>
          <div className={styles.chartTitle}>Audit Responses</div>
          <div className={styles.multipleStatistics}>
            <div className={styles.statistic}>
              <div className={styles.title}>Acceptable</div>{' '}
              {summary?.safeCount}
            </div>
            <div className={styles.statistic}>
              <div className={styles.title}>Unacceptable</div>{' '}
              {summary?.unsafeCount}
            </div>
            <div className={styles.statistic}>
              <div className={styles.title}>Not Applicable</div>
              {summary?.notApplicableCount}
            </div>
          </div>
        </div>
        <div className={styles.chartDivs}>
          <div className={styles.chartTitle}>Corrective Actions</div>
          <div className={styles.multipleStatistics}>
            <div className={styles.statistic}>
              <div className={styles.title}>Completed</div>{' '}
              {summary?.completedCA}
            </div>
            <div className={styles.statistic}>
              <div className={styles.title}>Past Due</div> {summary?.pastDueCA}
            </div>
            <div className={styles.statistic}>
              <div className={styles.title}>Assigned</div>
              {summary?.assignedCA}
            </div>
          </div>
        </div>
      </div>
      {summary?.data.length && (
        <div className={styles.chartDivs}>
          <div className={styles.chartTitle}>Section Scores</div>
          <BarChart data={summary?.data} width={900} height={271}>
            <Tooltip formatter={v => `${v.toFixed()}%`} />
            <XAxis dataKey="label" />
            <YAxis dataKey="value" />
            <Bar
              dataKey="value"
              fill={colors[offset % colors.length]}
              stroke={darkenColor(colors[offset % colors.length], 48)}
            />
          </BarChart>
        </div>
      )}
      <div className={styles.stats}>
        <UserBadge byline="Created By" userId={createdBy} showImage showName />
        <UserBadge
          byline="Assigned To"
          userId={assignedTo}
          showImage
          showName
        />
        <div className={styles.timeSpent}>
          <div className={styles.title}>Completed On</div>
          <div>{completedOn}</div>
        </div>
        <div className={styles.timeSpent}>
          <div className={styles.title}>Time Spent</div>
          <div>{timeSpent} minutes</div>
        </div>
        {equipmentName ? (
          <div className={styles.timeSpent}>
            <div className={styles.title}>Equipment Name</div>
            <div>{equipmentName}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
}
