import { ADD_MESSAGE, REMOVE_MESSAGE } from '../constants/actionTypes';

export const addMessage = payload => ({
  type: ADD_MESSAGE,
  payload
});

export const removeMessage = () => ({
  type: REMOVE_MESSAGE
});
