import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { PieChart, Pie, Cell } from 'recharts';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import history from '../../../history';
import { deleteAttachment, uploadAttachment } from '../../../api/v4';
import { addNote, deleteNoteById } from '../../../api/v4';
import {
  updateSafetyAudit,
  deleteSafetyAudit,
  fetchSafetyAuditById,
  setSafetyAuditNumber
} from '../../../api/v4';
import { getActiveCompany } from '../../../selectors/company';
import { getLoggedInUser } from '../../../selectors/users';
import AdditionalCommentsCard from '../../../components/safetyAuditCards/AdditionalCommentsCard';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import Modal from '../../../components/Modal';
import QuestionCard from '../../../components/safetyAuditCards/QuestionCard';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  Textbox,
  InputRow,
  Dropdown,
  Button
} from '../../../components/inputs';
import UserBadge from '../../../components/UserBadge';
import { useSocket } from '../../../utils/withSocket';

import styles from './perform.module.scss';
const bStyles = classnames.bind(styles);

export default function Perform() {
  const { safetyAuditId } = useParams();
  const location = useLocation();
  const loggedInUser = useSelector(getLoggedInUser);
  const company = useSelector(getActiveCompany);
  const [audit, setAudit] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [showDeleteNotesModal, setShowDeleteNotesModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSumbitSafetyAudit, setOpenSumbitSafetyAudit] = useState(false);
  const [openSumbitSafetyAuditBad, setOpenSumbitSafetyAuditBad] = useState(
    false
  );
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [note, setNote] = useState();
  const [startTime, setStartTime] = useState('');
  const [startingPerformTime, setStartingPerformTime] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [updatedNotes, setUpdatedNotes] = useState([]);
  const [collapsed, setCollapsed] = useState([]);

  const auditType = location.pathname.includes('safety')
    ? 'safety'
    : location.pathname.includes('quality')
    ? 'quality'
    : 'loto';
  const auditTypeName =
    auditType === 'loto'
      ? 'Lockout/Tagout Procedure'
      : `${auditType.charAt(0).toUpperCase() + auditType.slice(1)} Audit`;
  const socket = useSocket();

  const group = company.groups?.find(g => g._id === audit?.groupId);
  const area = group?.projects?.find(p => p._id === audit?.projectId);

  const disabled = group?.isHidden || area?.isHidden || audit?.isArchived;

  const checkIfComplete = section => {
    const acceptableAnswers = section?.questions?.every(q =>
      q.type === 'multiselect' || q.type === 'multiselectPersonnel'
        ? q.answer?.length
        : q.type === 'dropdown'
        ? q.answer !== null
        : q.type === 'textbox'
        ? q?.answer && q.answer?.trim()?.length
        : q.type === 'table'
        ? q.answer && q.answer?.some(row => row?.some(text => text))
        : q.answer
    );
    const filteredUnsafeAnswers = section?.questions?.filter(
      q => (!q.type || q.type === 'yesNo') && q.answer === 2
    );

    if (!acceptableAnswers) return false;

    if (
      audit.requireCAForUnacceptableItems &&
      filteredUnsafeAnswers.some(q => !q.task)
    )
      return false;

    if (
      audit.requireNoteForUnacceptableItems &&
      filteredUnsafeAnswers.some(
        q => q.notes?.filter(n => !n.isRemoved).length === 0
      )
    )
      return false;

    if (
      audit.requirePhotoForUnacceptableItems &&
      filteredUnsafeAnswers.some(q => q.attachments?.length === 0)
    )
      return false;

    return true;
  };

  const initiateAudit = useCallback(() => {
    if (safetyAuditId) {
      const localupdatedNotes = localStorage.getItem('updatedNotes');
      if (localupdatedNotes && localupdatedNotes !== 'undefined')
        setUpdatedNotes(JSON.parse(localupdatedNotes));
      fetchSafetyAuditById(safetyAuditId).then(response => {
        const local = JSON.parse(localStorage.getItem('activeAudit'));
        const parsedLocalNotes = JSON.parse(localupdatedNotes);
        if (local && local._id === safetyAuditId) {
          response.sections = response.sections.map((s, si) => {
            const questions = s.questions.map((q, qi) => {
              q.answer = local.sections[si].questions[qi].answer ?? null;
              q.immediatelyFixed =
                local.sections[si].questions[qi].immediatelyFixed;
              if (parsedLocalNotes?.length)
                q.notes.map((note, ni) => {
                  const foundNote = parsedLocalNotes.find(
                    localNote => localNote._id === note._id
                  );
                  if (foundNote !== undefined && foundNote.value) {
                    q.notes[ni] = foundNote;
                  }
                  return foundNote;
                });

              return q;
            });
            s.questions = questions;
            return s;
          });
          response.additionalNotes = local.additionalNotes;
        } else if (response.currentWfStage === 0) {
          response.sections = response.sections.map((s, si) => {
            const questions = s.questions.map((q, qi) => {
              if (q.type === 'dropdown' || q.type === 'textbox')
                q.answer = null;
              return q;
            });
            s.questions = questions;
            return s;
          });
        }

        setAudit(response);
        setStartingPerformTime(response.timeSpent);
        localStorage.removeItem('activeAudit');
        localStorage.removeItem('updatedNotes');
      });
    }

    setStartTime(moment());
  }, [safetyAuditId]);

  useEffect(() => {
    initiateAudit();
  }, [initiateAudit]);

  const completedSection = () => {
    const completedSections = audit?.sections?.map(s => checkIfComplete(s));
    // make sure collapsed sections are set and only once on first render
    if (firstRender && completedSections?.length) {
      setCollapsed(completedSections ?? []);
      setFirstRender(false);
    }
    return completedSections;
  };

  const checkCompletedSections = completedSection();

  const updateTimeSpent = () => {
    let secondsAsMinutes = moment().diff(startTime, 'seconds') / 60;

    secondsAsMinutes += parseFloat(startingPerformTime);
    audit.timeSpent = secondsAsMinutes.toFixed(2);

    socket.emit('updateSATime', {
      saId: audit._id,
      timeSpent: audit.timeSpent
    });
    setAudit(audit);
  };

  const updateAnswer = (sectionIndex, questionIndex, value) => {
    audit.sections[sectionIndex].questions[questionIndex].answer = value;
    setAudit({ ...audit, sections: [...audit.sections] });
    setHasChanges(true);
  };
  const handleChangeNotes = (sectionNumber, questionNumber, note, value) => {
    let newUpdatedNotes = [...updatedNotes];
    const index = updatedNotes.findIndex(
      updateNote => updateNote._id === note._id
    );
    if (index === -1) {
      newUpdatedNotes.push(note);
    } else {
      newUpdatedNotes[index].value = value;
    }

    setUpdatedNotes(newUpdatedNotes);
    setHasChanges(true);
    localStorage.setItem('updatedNotes', JSON.stringify(newUpdatedNotes));
  };

  const initiateDeleteNote = (sectionNumber, questionNumber, note) => {
    setNoteToDelete({ sectionNumber, questionNumber, note });
    setShowDeleteNotesModal(true);
  };

  const deleteNote = () => {
    const { sectionNumber, questionNumber, note } = noteToDelete;

    deleteNoteById({
      note: note,
      ownerId: safetyAuditId,
      ownerType: `${audit.type}AuditQuestion`
    });

    let newSections = [...audit.sections];

    const index = newSections[sectionNumber].questions[
      questionNumber - 1
    ].notes.findIndex(object => object._id === note._id);

    newSections[sectionNumber].questions[questionNumber - 1].notes[
      index
    ].isRemoved = true;

    let newUpdatedNotes = [...updatedNotes];
    const updatedNotesIndex = updatedNotes.findIndex(
      updateNote => updateNote._id === note._id
    );

    if (updatedNotesIndex !== -1) {
      newUpdatedNotes[updatedNotesIndex].isRemoved = true;
    }

    setUpdatedNotes(newUpdatedNotes);
    setAudit({ ...audit, sections: newSections });
    setShowDeleteNotesModal(false);
  };
  const updateImmediatelyCorrected = (sectionIndex, questionIndex, value) => {
    audit.sections[sectionIndex].questions[
      questionIndex
    ].immediatelyFixed = value;
    setAudit({ ...audit, sections: [...audit.sections] });
    setHasChanges(true);
  };

  const submit = isComplete => {
    updateTimeSpent();
    audit.isCompleted = isComplete;
    localStorage.removeItem('activeAudit');
    localStorage.removeItem('editing');
    localStorage.removeItem('updatedNotes');
    updateSafetyAudit(audit).then(() => history.goBack());
  };

  const deleteSAAttachment = attachment => {
    localStorage.setItem('activeAudit', JSON.stringify(audit));
    deleteAttachment(attachment._id).then(fetchAudit);
  };

  const addQuestionNote = () => {
    localStorage.setItem('activeAudit', JSON.stringify(audit));
    addNote({
      ownerType: `${audit.type}AuditQuestion`,
      ownerId: questionId,
      value: note
    }).then(createdNote => {
      audit.sections.map(section =>
        section.questions.map(q => {
          if (q._id === questionId) {
            q.notes.push(createdNote);
          }
          return q;
        })
      );
      setNote('');
      setOpenNoteModal(false);
    });
  };

  const fetchAudit = () => {
    fetchSafetyAuditById(safetyAuditId).then(response => {
      response.sections = response.sections.map((s, si) => {
        const questions = s.questions.map((q, qi) => {
          q.answer = audit.sections[si].questions[qi].answer;
          return q;
        });
        s.questions = questions;
        return s;
      });

      const local = JSON.parse(localStorage.getItem('activeAudit'));
      if (local && local._id === safetyAuditId) {
        response.sections = response.sections.map((s, si) => {
          const questions = s.questions.map((q, qi) => {
            q.answer = local.sections[si].questions[qi].answer;
            q.immediatelyFixed =
              local.sections[si].questions[qi].immediatelyFixed;
            return q;
          });
          s.questions = questions;
          return s;
        });
        response.additionalNotes = local.additionalNotes;
        localStorage.removeItem('activeAudit');
        localStorage.removeItem('editing');
        localStorage.removeItem('updatedNotes');
      }
      setNote('');
      setAudit(response);
      setQuestionId();
      setOpenNoteModal(false);
    });
  };

  const answerAllQuestionsInSection = (answer, sectionIndex) => {
    let questions = audit.sections[sectionIndex].questions.map(question => {
      return !question.type || question.type === 'yesNo'
        ? { ...question, answer }
        : { ...question };
    });

    audit.sections[sectionIndex].questions = questions;
    setAudit({ ...audit });
    setHasChanges(true);
  };

  const submitSafetyAuditName = newName => {
    setAudit(audit => ({ ...audit, safetyAuditNumber: newName }));
    setSafetyAuditNumber(audit._id, newName);
  };

  const canSubmit = () => {
    const sectionCount = audit?.sections?.filter(s => {
      return s.questions.some(
        q =>
          (!q.type || q.type === 'yesNo') &&
          q?.answer === 2 &&
          ((audit.requireCAForUnacceptableItems && !q.task) ||
            (audit.requireNoteForUnacceptableItems &&
              !q.notes.filter(n => !n.isRemoved).length) ||
            (audit.requirePhotoForUnacceptableItems && !q.attachments.length))
      );
    });
    return sectionCount?.length === 0;
  };

  const canSave = audit?.sections?.every(s =>
    s.questions.every(q =>
      q.notes.filter(n => !n.isRemoved).every(n => n.value?.trim().length)
    )
  );

  const handleGoBack = () => {
    updateTimeSpent();
    localStorage.removeItem('activeAudit');
    localStorage.removeItem('editing');
    localStorage.removeItem('updatedNotes');
    history.goBack();
  };

  const handlePhotoUpload = async e => {
    localStorage.setItem('activeAudit', JSON.stringify(audit));
    const files = e.target.files;
    if (!files.length) return;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    uploadAttachment(data, {
      ownerId: questionId,
      ownerType: `${audit.type}AuditQuestion`
    }).then(fetchAudit);
  };

  const handleToggleCollapse = index => {
    collapsed[index] = !collapsed[index];
    setCollapsed([...collapsed]);
  };

  const handleArchive = () => {
    updateSafetyAudit({
      ...audit,
      isArchived: !audit.isArchived
    }).then(response => {
      if (response.isArchived) history.push(`/app/audits/${auditType}`);
      else fetchAudit();
    });
  };

  const header = (
    <Header
      title={`Perform ${auditTypeName}`}
      section={audit.safetyAuditNumber}
      editName={disabled ? null : submitSafetyAuditName}
      needsSaved={hasChanges}
      clickBack={() =>
        hasChanges ? setOpenUnsavedChangesModal(true) : handleGoBack()
      }
      rightButtons={{
        text: `Delete ${auditTypeName}`,
        onClick: () => setOpenDeleteModal(true),
        color: 'red',
        visible:
          loggedInUser._id === audit?.createdByUserId ||
          loggedInUser.accessLevel === 900
      }}
      pageActionOptions={
        loggedInUser.accessLevel > 400
          ? [
              {
                label: `Delete ${auditTypeName}`,
                visible:
                  loggedInUser._id === audit?.createdByUserId ||
                  loggedInUser.accessLevel === 900,
                color: 'redOutline',
                onClick: () => setOpenDeleteModal(true)
              },
              {
                label: `${
                  audit?.isArchived ? 'Un-' : ''
                }Archive ${auditTypeName}`,
                visible: !audit?.equipment?.isArchived,
                color: 'blueOutline',
                onClick: () => handleArchive()
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      editing
      saveButtonDisabled={!canSave}
      saveButtonClick={() => setOpenSumbitSafetyAudit(true)}
      cancelButtonClick={() =>
        hasChanges ? setOpenUnsavedChangesModal(true) : handleGoBack()
      }
    />
  );

  const summaryName = `${audit?.sections?.length + 2}`;
  const additionalNotesName = `${audit?.sections?.length + 1}`;
  const leftNav = audit?.sections?.length
    ? [
        { label: 'Instructions', id: 0 },
        ...audit?.sections.map((section, i) => ({
          label: section.title,
          id: i + 1
        })),
        {
          label: 'Additional Comments & Suggestions',
          id: additionalNotesName
        },
        { label: 'Summary', id: summaryName }
      ]
    : [];
  const left = <LeftNav items={leftNav} />;
  const createdBy = audit?.auditLog?.[0]?.user;

  let safeCount = 0;
  let unsafeCount = 0;
  let notApplicableCount = 0;
  let taskCount = 0;
  const scorableSections = audit?.sections?.filter(s => s.type === 'scorable');
  for (let i = 0; i < scorableSections?.length; i++) {
    const questions = scorableSections[i]?.questions;
    for (let j = 0; j < questions?.length; j++) {
      const question = questions[j];
      if (question.type === 'yesNo' || !question.type)
        if (
          question.answer === 1 ||
          (question.answer === 2 && question.immediatelyFixed)
        ) {
          safeCount++;
        } else if (question.answer === 2) {
          unsafeCount++;
        } else if (question.answer === 3) {
          notApplicableCount++;
        }

      if (question.task) taskCount++;
    }
  }

  const data = [
    {
      label: 'Acceptable',
      value: safeCount,
      color: '#2F8738'
    },
    {
      label: 'Unacceptable',
      value: unsafeCount,
      color: '#C74846'
    },
    {
      label: 'N/A',
      value: notApplicableCount,
      color: '#5F5F5F'
    }
  ];

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!disabled}
    >
      <Card showHeader title="Instructions" name={0}>
        {audit.equipment ? (
          <div style={{ fontSize: '20px', paddingBottom: '5px' }}>
            Equipment: {audit.equipment.title}
            <br />
          </div>
        ) : null}

        {audit?.instructions}
        <div className={styles.userRow}>
          <div>
            <UserBadge
              byline="Created By"
              showName
              showImage
              userId={createdBy}
            />
          </div>
          <div className={styles.assignees}>
            {audit?.assignedTo?.map((assignee, i) => (
              <UserBadge
                byline="Assigned To"
                showName
                showImage
                userId={assignee}
                key={i}
              />
            ))}
          </div>
        </div>
      </Card>
      {audit?.sections?.map((section, i) => (
        <Card
          className={checkCompletedSections[i] ? styles.completedLabel : null}
          sectionCompleted={checkCompletedSections[i]}
          collapsed={collapsed[i]}
          key={i}
          title={section.title}
          showHeader
          name={i + 1}
          rightButton={
            <InputRow right>
              {audit.allowBulkActions && section.type === 'scorable' ? (
                <Dropdown
                  className={styles.dropdown}
                  placeholder="Mark All as Selected"
                  options={[
                    {
                      value: 1,
                      label: require('../../../assets/images/checkmark.png'),
                      alt: 'make entire section checkmark',
                      className: styles.green
                    },
                    {
                      value: 2,
                      label: require('../../../assets/images/Remove Icon.png'),
                      alt: 'make entire section checkmark'
                    },
                    {
                      value: 3,
                      label: require('../../../assets/images/NA.png'),
                      alt: 'make entire section checkmark'
                    }
                  ]}
                  isImage
                  onChange={value => answerAllQuestionsInSection(value, i)}
                  disabled={disabled}
                />
              ) : null}
              <Button
                color="white"
                id="rightButton"
                imageClass={bStyles({
                  flipChevron: collapsed?.[i]
                })}
                image={require('../../../assets/images/Chevron.png')}
                onClick={() => {
                  handleToggleCollapse(i);
                }}
              />
            </InputRow>
          }
        >
          {section?.questions.map((question, index) => (
            <QuestionCard
              sectionCompleted={checkCompletedSections[i]}
              sectionNumber={i}
              question={question}
              questionNumber={`${index + 1}`}
              key={index}
              updateAnswer={value => updateAnswer(i, index, value)}
              updateImmediatelyCorrected={value =>
                updateImmediatelyCorrected(i, index, value)
              }
              addNote={() => {
                setQuestionId(question._id);
                setOpenNoteModal(true);
              }}
              addAttachment={r => {
                handlePhotoUpload(r);
                r.target.value = '';
              }}
              setQuestionId={setQuestionId}
              section={section}
              requirePhotoForUnacceptableItems={
                audit.requirePhotoForUnacceptableItems
              }
              requireNoteForUnacceptableItems={
                audit.requireNoteForUnacceptableItems
              }
              requireCAForUnacceptableItems={
                audit.requireCAForUnacceptableItems
              }
              allowBulkItems={audit.allowBulkItems}
              deleteJsaQuestionAttachment={deleteSAAttachment}
              locationId={audit.groupId}
              projectId={audit?.projectId}
              audit={audit}
              className={bStyles({
                completedLabel: checkCompletedSections[i]
              })}
              editing
              handleChangeNotes={handleChangeNotes}
              initiateDeleteNote={initiateDeleteNote}
              editingNotes
              updatedNotes={updatedNotes}
              disabled={disabled}
            />
          ))}
        </Card>
      ))}
      <AdditionalCommentsCard
        onChange={text => {
          setAudit({ ...audit, additionalNotes: text });
          setHasChanges(true);
        }}
        name={additionalNotesName}
        notes={audit?.additionalNotes}
        disabled={disabled}
      />
      <Card title="Summary" showHeader name={summaryName}>
        <InputRow>
          <PieChart width={200} height={200} key={200}>
            <Pie data={data} dataKey="value" nameKey="label">
              {data.map((k, i) => (
                <Cell key={k} fill={k.color} />
              ))}
            </Pie>
          </PieChart>
          <div className={styles.legend}>
            <div className={bStyles({ greenOutline: true })}>
              <span className={bStyles({ white: true })}>{safeCount}</span>{' '}
              Marked Acceptable
            </div>
            <div className={styles.divider} />
            <div className={bStyles({ redOutline: true })}>
              <span className={bStyles({ white: true })}>{unsafeCount}</span>{' '}
              Marked Unacceptable
            </div>
            <div className={styles.divider} />
            <div className={bStyles({ greyOutline: true })}>
              <span className={bStyles({ white: true })}>
                {notApplicableCount}
              </span>{' '}
              Marked Not Applicable
            </div>
          </div>
          <div className={styles.legend}>
            {taskCount} Corrective Action(s) Assigned
          </div>
        </InputRow>
      </Card>
      <DeleteItemModal
        isOpen={openDeleteModal}
        deletingWhat={`${auditTypeName}`}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteSafetyAudit(audit).then(() => history.goBack())
        }
      />
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => {
          handleGoBack();
        }}
        savingWhat={`${auditTypeName}`}
      />
      <Modal
        title={`Submit ${auditTypeName}`}
        titleClassName="greenHeader"
        isOpen={openSumbitSafetyAudit}
        submitButtonColor="green"
        submitButtonText="Submit Now"
        cancelButtonText="Submit Later"
        onRequestClose={() => setOpenSumbitSafetyAudit(false)}
        submitActions={() => submit(true)}
        cancelActions={() => submit(false)}
        disableSubmit={!canSubmit()}
      >
        {`Submitting this ${auditTypeName} will lock it and prevent any further
        editing. Tasks may still be added.`}
      </Modal>
      <Modal
        title={`Submit ${auditTypeName} Audit`}
        titleClassName="redHeader"
        isOpen={openSumbitSafetyAuditBad}
        hideButtons
        onRequestClose={() => setOpenSumbitSafetyAuditBad(false)}
      >
        {`You are unable to submit the ${auditTypeName} until you have added all
        required items to unacceptable responses`}
      </Modal>
      <Modal
        title="Add Note"
        titleClassName="blueHeader"
        isOpen={openNoteModal}
        submitButtonColor="blue"
        disableSubmit={!note?.trim()}
        submitButtonText="Add Note"
        onRequestClose={() => {
          setNote('');
          setOpenNoteModal(false);
        }}
        submitActions={addQuestionNote}
      >
        <Textbox
          type="textarea"
          onChange={setNote}
          placeholder="Type Here"
          fieldLabel="Add Note"
          currentValue={note}
        />
      </Modal>
      <DeleteItemModal
        isOpen={showDeleteNotesModal}
        deletingWhat="Note"
        onRequestClose={() => setShowDeleteNotesModal(false)}
        submitActions={deleteNote}
        hideCustomTemplate
      />
    </HeaderAndFooter>
  );
}
