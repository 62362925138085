import React from 'react';
import classnames from 'classnames';

import { Input, nameInput } from '../';

import '../input.css';
import styles from './styles.module.scss';

const RadioButtons = ({
  name,
  options,
  disabled,
  className,
  displayTooltip,
  columns,
  currentValue,
  tooltips,
  onChange,
  bareValues = true,
  showImages,
  needSeparateLines,
  testID,
  checkedOptions,
  wrongOptions,
  touched,
  ...props
}) => {
  let value = currentValue?.value ?? currentValue;

  return (
    <Input
      {...props}
      name={name}
      displayTooltip={displayTooltip}
      disabled={disabled}
    >
      <div
        className={
          needSeparateLines ? styles.radioButtonsCol : styles.radioButtonsRow
        }
      >
        {options.map((option, index) => (
          <div
            className={classnames([
              needSeparateLines
                ? styles.radioButtonColInput
                : styles.radioButtoninput,
              showImages ? styles.containerBox : '',
              checkedOptions?.includes(option.label) ? styles.correct : '',
              wrongOptions?.includes(option.label) ? styles.incorrect : '',
              touched ? styles.error : ''
            ])}
            key={index}
          >
            <div className={styles.radioButtonDiv}>
              {showImages && option.photo ? (
                <img
                  onClick={() =>
                    document.getElementById(`${name}[${index}]`).click()
                  }
                  className={styles.radioImage}
                  src={option?.photo}
                  alt={`${name}${option.label}`}
                />
              ) : (
                <></>
              )}
              <label
                className={classnames([
                  styles.radioButtonLabel,
                  props.labelStyles
                ])}
              >
                {option.label}
                <input
                  type="checkbox"
                  name={`${name}[${index}]`}
                  id={`${name}[${index}]`}
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => {
                    onChange(bareValues ? option.value : option);
                  }}
                  disabled={disabled}
                  data-cy={(testID ?? props.fieldLabel) + ' ' + option.label}
                />
                <span
                  className={
                    disabled
                      ? styles.radioButtonDisabled
                      : touched
                      ? styles.radioButtonErr
                      : styles.radioButton
                  }
                />
              </label>
            </div>
          </div>
        ))}
      </div>
    </Input>
  );
};

export default nameInput(RadioButtons);
