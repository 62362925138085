import React, { useState, useEffect } from 'react';

import Card from '../../../components/Card';
import {
  fetchColumnSettings,
  getAllAnalyticsDatapoints,
  updateColumnSettings
} from '../../../api/v4';
import { SaveCancelFooter } from '../../../components/Footer';
import LeftNav from '../../../components/LeftNav';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import history from '../../../history';
import { Dropdown } from '../../../components/inputs';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { useSelector } from 'react-redux';
import { getActiveCompany } from '../../../selectors/company';

export const EditAnalyticsColumns = props => {
  const company = useSelector(getActiveCompany);
  const [userSettings, setUserSettings] = useState({});
  const [baseColumns, setBaseColumns] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [discardModal, setDiscardModal] = useState(false);
  const [datasources, setDatasources] = useState([]);

  useEffect(() => {
    getAllAnalyticsDatapoints()
      .then(r => {
        setDatasources(r.datasources);
      })
      .catch(e => console.log(e));
    fetchColumnSettings().then(r => {
      setUserSettings(r.user ?? {});
      setBaseColumns(r.base);
    });
  }, []);

  const handleChange = (v, category) => {
    const newSettings = { ...userSettings };
    newSettings[category.collection] = {
      ...newSettings[category.collection],
      label: category.label,
      data: v
    };
    setUserSettings(newSettings);
    setUnsavedChanges(true);
  };

  const left = (
    <LeftNav
      items={datasources.map((category, i) => ({
        value: i,
        label: category.label
      }))}
    />
  );
  const header = (
    <Header
      title="Customize Export Columns"
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setDiscardModal(true) : history.goBack()
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() => {
        updateColumnSettings(userSettings).then(() => history.goBack());
      }}
      editing
      saveButtonDisabled={!unsavedChanges}
      cancelButtonClick={() =>
        unsavedChanges ? setDiscardModal(true) : history.goBack()
      }
    />
  );

  return (
    <HeaderAndFooter Header={header} Footer={footer} showFooter Left={left}>
      <>
        {datasources.map((datasource, i) => (
          <Card id={i} name={i} showHeader title={datasource.label}>
            <Dropdown
              selectButtons
              searchable
              multi
              currentValue={userSettings?.[datasource.collection]?.data}
              options={baseColumns?.[datasource.collection]
                ?.filter(
                  c =>
                    company.showAircraft ||
                    !(
                      c.toLowerCase().includes('aircraft') ||
                      c.toLowerCase().includes('flight') ||
                      c.toLowerCase().includes('fuel') ||
                      c.toLowerCase().includes('spill')
                    )
                )
                .map(c => ({
                  value: c,
                  label: c.charAt(0).toUpperCase() + c.slice(1)
                }))}
              alphabetize
              onChange={v => handleChange(v, datasource)}
            />
          </Card>
        ))}
      </>
      <SaveChangesModal
        isOpen={discardModal}
        onRequestClose={() => setDiscardModal(false)}
        submitActions={() => history.goBack()}
      />
    </HeaderAndFooter>
  );
};

export default EditAnalyticsColumns;
