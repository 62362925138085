import React from 'react';
import classnames from 'classnames/bind';

import { Input, nameInput } from '../';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

const dowValues = [
  { value: 'sunday', label: 'Sun' },
  { value: 'monday', label: 'Mon' },
  { value: 'tuesday', label: 'Tues' },
  { value: 'wednesday', label: 'Wed' },
  { value: 'thursday', label: 'Thurs' },
  { value: 'friday', label: 'Fri' },
  { value: 'saturday', label: 'Sat' }
];

const DayOfWeekButtons = ({
  onChange,
  disabled,
  currentValue,
  fromList,
  touched,
  ...props
}) => {
  const handleSelection = selection => {
    if (disabled) {
      return;
    }
    let found = false;
    let newValue = (currentValue ?? []).filter(v => {
      if (v === selection.value) {
        found = true;
        return false;
      }
      return true;
    });
    if (!found) {
      newValue.push(selection.value);
    }

    onChange(newValue);
  };

  return (
    <Input disabled={disabled} {...props}>
      <div
        className={bStyles({
          dayOfWeekButtons: true,
          disabled
        })}
      >
        {dowValues.map(v => (
          <div
            className={bStyles({
              selected: (currentValue ?? []).some(
                s => (s?.value ?? s) === v.value
              ),
              fromList: fromList,
              error: touched
            })}
            onClick={() => handleSelection(v)}
            key={v.value}
          >
            {v.label}
          </div>
        ))}
      </div>
    </Input>
  );
};

let nI = nameInput(DayOfWeekButtons);
export { nI as DayOfWeekButtons };
export default nI;
