import React, { useState } from 'react';

import { positiveIntsOnly } from '../../../utils/formValidation';
import { Textbox } from '../Textbox';
import { TwoColumn } from '..';

const TableQuestion = ({
  options,
  handleChange,
  missingRequired,
  ...props
}) => {
  const [columnWarning, setColumnWarning] = useState(null);
  const [lastValidValue, setLastValidValue] = useState(null);
  const [rowWarning, setRowWarning] = useState(null);
  const invalidValue = v => {
    return isNaN(v) || v > 10 || v < 1;
  };
  const error =
    options.columnHeaders &&
    new Set(Object.values(options.columnHeaders)).size !==
      Object.values(options.columnHeaders).length
      ? '* Columns cannot have the same name'
      : options.columnHeaders &&
        !Object.values(options.columnHeaders)?.every(head =>
          /[A-Za-z_@./#&+-]/g.test(head)
        )
      ? '* Column names must contain a letter or special character'
      : '';
  return (
    <>
      <TwoColumn>
        <Textbox
          isRequired
          currentValue={options?.columns}
          onChange={v => {
            if (!v) {
              setLastValidValue(options.columns);
            }
            if (!positiveIntsOnly(v))
              return handleChange({
                options: {
                  ...options,
                  columns: ''
                }
              });
            if (v && invalidValue(v)) {
              setColumnWarning('There can only be 1-10 columns');
              return;
            }
            setColumnWarning(null);
            let newHeaders = options.columnHeaders;
            if (
              v &&
              newHeaders &&
              v < (lastValidValue ?? options.columns ?? 0)
            ) {
              newHeaders = Object.values(newHeaders).slice(0, v);
            }
            return handleChange({
              options: {
                ...options,
                columns: v ?? 0,
                columnHeaders: newHeaders
              }
            });
          }}
          touched={missingRequired && !options?.columns}
          disabled={props.disabled}
          error
          fieldLabel="# of Columns"
          errorMessage={columnWarning}
          showWarning={columnWarning}
        />
        <Textbox
          isRequired
          currentValue={options?.rows}
          onChange={v => {
            if (!positiveIntsOnly(v))
              return handleChange({
                options: {
                  ...options,
                  rows: ''
                }
              });
            if (v && invalidValue(v)) {
              setRowWarning('There can only be 1-10 rows');
              return;
            }
            setRowWarning(null);
            return handleChange({
              options: {
                ...options,
                rows: v ?? 0
              }
            });
          }}
          touched={missingRequired && !options?.rows}
          disabled={props.disabled}
          error
          fieldLabel="# of Rows"
          errorMessage={rowWarning}
          showWarning={rowWarning}
        />
      </TwoColumn>
      {error?.length ? (
        <span
          style={{
            color: 'red',
            fontWeight: 'bold'
          }}
        >
          {error}
        </span>
      ) : (
        <></>
      )}
      <TwoColumn>
        {options?.columns ? (
          [...Array(parseInt(options?.columns))].map((column, cIndex) => (
            <Textbox
              isRequired
              currentValue={options?.columnHeaders?.[cIndex]}
              fieldLabel={`Column #${cIndex + 1}`}
              disabled={props.disabled}
              onChange={v =>
                handleChange({
                  options: {
                    columns: options?.columns,
                    rows: options?.rows,
                    columnHeaders: {
                      ...options?.columnHeaders,
                      [cIndex]: v
                    }
                  }
                })
              }
              error
              touched={missingRequired && !options?.columnHeaders?.[cIndex]}
            />
          ))
        ) : (
          <></>
        )}
      </TwoColumn>
    </>
  );
};

export default TableQuestion;
