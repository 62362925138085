import {
  SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE,
  SET_ACTIVE_TRAINING_REQUEST,
  SET_ACTIVE_TRAINING_RESPONSE,
  SET_ACTIVE_TRAINING_ATTACHMENT
} from '../constants/actionTypes';

export const setActiveCustomTrainingTemplate = payload => ({
  type: SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE,
  payload
});

export const setActiveTrainingRequest = payload => ({
  type: SET_ACTIVE_TRAINING_REQUEST,
  payload
});

export const setActiveTrainingResponse = payload => ({
  type: SET_ACTIVE_TRAINING_RESPONSE,
  payload
});

export const setActiveTrainingAttachment = payload => ({
  type: SET_ACTIVE_TRAINING_ATTACHMENT,
  payload
});
