import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    width="30px"
    height="25px"
    viewBox="0 0 90 90"
  >
    <g>
      <g>
        <path
          d="M66.4,51.8L52.8,28.4c-0.5-0.8-1.3-1.3-2.2-1.3c-0.9,0-1.8,0.5-2.2,1.3L34.7,51.8c-0.5,0.8-0.5,1.8,0,2.6
			c0.5,0.8,1.3,1.3,2.2,1.3h27.2c0.9,0,1.8-0.5,2.2-1.3C66.9,53.5,66.9,52.6,66.4,51.8z M64.4,53.2c0,0.1-0.1,0.1-0.2,0.1H36.9
			c-0.1,0-0.2-0.1-0.2-0.1s-0.1-0.2,0-0.3l13.6-23.4c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1l13.6,23.4
			C64.5,53,64.4,53.1,64.4,53.2z"
        />
        <path
          d="M50.6,34.9c-0.6,0-1.1,0.5-1.1,1.1v9.2c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-9.2C51.8,35.4,51.3,34.9,50.6,34.9z
			"
        />
        <path
          d="M49.8,49.1c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C51,48.6,50.3,48.6,49.8,49.1z"
        />
      </g>
      <path
        d="M84.5,36.2c0-11.4-9.3-20.7-20.7-20.7H36.2c-11.4,0-20.7,9.3-20.7,20.7v16.1c0,11,8.7,20.1,19.5,20.7v9.6
		c0,0.8,0.5,1.5,1.2,1.8c0.2,0.1,0.5,0.1,0.7,0.1c0.5,0,1-0.2,1.4-0.6l2.2-2.2c2.2-2.2,4.7-4,7.5-5.5c5-2.1,10.2-3.2,15.8-3.3
		c8.3,0,15.5-4.9,18.8-12c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.8c0.2-0.4,0.3-0.8,0.4-1.2
		c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.5,0.3-1,0.4-1.4c0-0.1,0.1-0.3,0.1-0.5c0.1-0.6,0.2-1.1,0.3-1.7c0-0.1,0-0.1,0-0.2
		c0.1-0.7,0.1-1.3,0.1-2c0,0,0,0,0,0l0,0c0,0,0,0,0,0V36.2z M63.8,70.7c-5.8,0-11.4,1.2-16.8,3.6c-3,1.5-5.7,3.5-8,5.9l-1.6,1.6v-11
		h-1.1c-10.1,0-18.4-8.3-18.4-18.4V36.2c0-10.1,8.3-18.4,18.4-18.4h27.6c10.1,0,18.4,8.3,18.4,18.4v16.1c0,0.7,0,1.3-0.1,1.9
		c0,0.1,0,0.2,0,0.3c-0.1,0.6-0.2,1.1-0.3,1.7c0,0,0,0.1,0,0.1c-0.3,1.3-0.7,2.5-1.2,3.7C77.6,66.3,71.2,70.7,63.8,70.7z"
      />
    </g>
  </svg>
);
