import React, { useEffect, useState } from 'react';

import { validNAICS, validSIC, validZip } from '../../utils/formValidation';
import { Dropdown, InputRow, Textbox, TwoColumn } from '../inputs';
import Modal from '.';
import RadioButtons from '../inputs/RadioButtons';
import { states } from '../../constants/states';

export default function GroupModal({
  isOpen,
  onRequestClose,
  submitActions,
  currentGroup,
  isEditing,
  isPerrp
}) {
  const perrpOrDash = isPerrp ? 'PERRP' : 'OSHA';

  const [group, setGroup] = useState({});
  const [missingRequired, setMissing] = useState(false);

  useEffect(() => {
    setGroup({ ...currentGroup });
  }, [currentGroup]);

  const validSeparateInfo =
    group.isSeparateEstablishment !== false
      ? !validZip(group.zip) &&
        group.streetAddress?.trim() &&
        group.city?.trim() &&
        group.zip &&
        group.state &&
        group.operationalForYearOrLonger !== undefined &&
        group?.NAICS &&
        !validNAICS(group?.NAICS)
      : true;

  const canSubmit = group?.name?.trim()?.length && validSeparateInfo;

  return (
    <Modal
      title={`${isEditing ? 'Edit' : 'New'} Group or Establishment`}
      titleClassName={isEditing ? 'blueHeader' : 'greenHeader'}
      isOpen={isOpen}
      submitButtonColor={isEditing ? 'blue' : 'green'}
      submitButtonText={isEditing ? 'Update' : 'Create'}
      onRequestClose={() => {
        setGroup({});
        setMissing(false);
        onRequestClose();
      }}
      submitActions={() => {
        if (canSubmit) {
          setGroup({});
          setMissing(false);
          submitActions(group);
        }
      }}
      onMouseEnter={() => setMissing(!canSubmit)}
      missingRequied={missingRequired}
      submitTooltip={!canSubmit ? 'Missing or Invalid Fields' : null}
    >
      <Textbox
        fieldLabel="Group/Establishment Name"
        currentValue={group?.name}
        onChange={v => setGroup({ ...group, name: v })}
        placeholder="e.g. Mark's Team/Tallahassee District Office"
        isRequired
        touched={missingRequired && !group?.name}
        error
      />
      <RadioButtons
        fieldLabel={`Does this require its own ${perrpOrDash} Report?`}
        options={[
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]}
        isRequired
        onChange={v => setGroup({ ...group, isSeparateEstablishment: v })}
        currentValue={group?.isSeparateEstablishment}
        bareValuess
        displayTooltip
        tooltip={
          <>
            <p>
              Single physical location where work is done, or that
              supervises/acts as a base for personnel that don’t work at a
              single location.
            </p>
            <p> &mdash; With &mdash; </p>
            <p>A separate address from the associated company division.</p>
          </>
        }
        touched={
          missingRequired && group?.isSeparateEstablishment === undefined
        }
      />
      {group?.isSeparateEstablishment ? (
        <>
          <Textbox
            currentValue={group?.streetAddress}
            fieldLabel="Establishment Address"
            placeholder="Street Address"
            isRequired
            onChange={v => setGroup({ ...group, streetAddress: v })}
            touched={missingRequired && !group?.streetAddress}
          />
          <InputRow>
            <Textbox
              currentValue={group?.city}
              placeholder="City"
              isRequired
              onChange={v => setGroup({ ...group, city: v })}
              touched={missingRequired && !group?.city}
            />
            <Dropdown
              placeholder="State"
              options={states}
              currentValue={group?.state}
              onChange={v => setGroup({ ...group, state: v })}
              touched={missingRequired && !group?.state}
            />
            <Textbox
              currentValue={group?.zip}
              placeholder="ZIP Code"
              isRequired
              onChange={v => setGroup({ ...group, zip: v })}
              touched={(!group.zip && missingRequired) || validZip(group?.zip)}
              errorMessage={validZip(group?.zip)}
            />
          </InputRow>
          <RadioButtons
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            fieldLabel="Has or will this establishment be operational for a year or longer?"
            isRequired
            currentValue={group?.operationalForYearOrLonger}
            onChange={v =>
              setGroup({ ...group, operationalForYearOrLonger: v })
            }
            touched={
              missingRequired && group?.operationalForYearOrLonger === undefined
            }
          />
          <TwoColumn>
            <Textbox
              currentValue={group?.NAICS}
              fieldLabel="NAICS"
              placeholder="000000"
              isRequired
              onChange={v => setGroup({ ...group, NAICS: v })}
              touched={
                (!group.NAICS && missingRequired) || validNAICS(group?.NAICS)
              }
              errorMessage={validNAICS(group?.NAICS)}
              error
            />
            <Textbox
              currentValue={group?.SIC}
              fieldLabel="SIC"
              placeholder="0000"
              onChange={v => setGroup({ ...group, SIC: v })}
              touched={(!group.SIC && missingRequired) || validSIC(group?.SIC)}
              errorMessage={validSIC(group?.SIC)}
              error
            />
          </TwoColumn>
        </>
      ) : null}
    </Modal>
  );
}
