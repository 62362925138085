const reportTemplateMapper = reportTemplateId => {
  switch (reportTemplateId) {
    case 2:
    case '2':
      return {
        basic: 'Employee Injury Basics',
        report: 'Employee Injury',
        listType: 'froi'
      };

    case 1:
    case '1':
      return {
        basic: 'Vehicle Accident Basics',
        report: 'Vehicle Accident',
        listType: 'vehicle'
      };

    case 3:
    case '3':
      return {
        basic: 'Property Damage Basics',
        report: 'Property Damage',
        listType: 'propertyDamage'
      };

    case 4:
    case '4':
      return {
        basic: 'Theft Basics',
        report: 'Theft',
        listType: 'theft'
      };

    case '5':
    case 5:
      return {
        basic: 'Third Party Injury Basics',
        report: 'Third Party Injury',
        listType: 'thirdParty'
      };

    case '6':
    case 6:
      return {
        basic: 'Aircraft Basics',
        report: 'Aircraft Report',
        listType: 'aircraft'
      };

    case '16':
    case 16:
      return {
        basic: 'Spill-Misfuel Basics',
        report: 'Spill/Misfuel Report',
        listType: 'fuelRelated'
      };

    case '7':
    case 7:
      return {
        basic: 'Near Miss Basics',
        report: 'Near Miss Report',
        listType: 'nearMiss'
      };

    case '9':
    case 9:
      return {
        basic: 'Safety Suggestion Basics',
        report: 'Safety Suggestion Report'
      };

    case '12':
    case 12:
      return {
        basic: 'Behavior Observation Basics',
        report: 'Behavior Observation Report'
      };

    case '14':
    case 14:
      return {
        basic: 'Lockout/Tagout Basics',
        report: 'Lockout/Tagout'
      };

    case '15':
    case 15:
      return {
        basic: 'DOT Basics',
        report: 'DOT'
      };

    case '11':
    case 11:
      return {
        basic: 'See Something Say Something Basics'
      };
    case 17:
    case '17':
      return {
        basics: 'Violence/Harassment Basics',
        report: 'Violence/Harassment Report',
        listType: 'violence'
      };
    case 18:
    case '18':
      return {
        basics: 'Spill Basics',
        report: 'Spill Report',
        listType: 'spill'
      };

    case 19:
    case '19':
      return {
        basics: 'General Incident Basics',
        report: 'General Incident Report',
        listType: 'general'
      };

    default:
      return {
        basic: '',
        report: ''
      };
  }
};

export default reportTemplateMapper;
