import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1723_121014)">
      <path d="M20.6125 28.3926C21.8352 28.4013 23.0089 27.9134 23.8659 27.0414L35.3146 15.5926V56.1604C35.3146 57.7929 36.1849 59.3009 37.5987 60.1174C39.0124 60.9334 40.7543 60.9334 42.1674 60.1174C43.5811 59.3009 44.4521 57.7929 44.4521 56.1604V15.5926L55.8649 27.0414C57.035 28.1003 58.6687 28.48 60.186 28.0465C61.7033 27.6125 62.8896 26.4267 63.3231 24.9094C63.7571 23.3922 63.3769 21.7584 62.3179 20.5884L43.1007 1.33561C42.2413 0.480311 41.0782 0 39.8652 0C38.6522 0 37.4891 0.480311 36.6298 1.33561L17.3948 20.5884C16.5481 21.4477 16.0748 22.6068 16.0765 23.8129C16.0782 25.0194 16.5551 26.1768 17.4046 27.0338C18.2535 27.8909 19.4057 28.3793 20.6122 28.3926H20.6125Z" />
      <path d="M73.1621 70.8625H6.56844C4.93649 70.8625 3.42794 71.7334 2.61196 73.1466C1.79601 74.5603 1.79601 76.3022 2.61196 77.7153C3.42791 79.1291 4.93649 80 6.56844 80H73.1621C74.7941 80 76.3026 79.1291 77.1186 77.7153C77.9345 76.3022 77.9345 74.5603 77.1186 73.1466C76.3026 71.7334 74.7941 70.8625 73.1621 70.8625Z" />
    </g>
    <defs>
      <clipPath id="clip0_1723_121014">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
