import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import moment from 'moment';
import { updateCompany } from '../../api/v4';
import { updateClaimPermisisons, fetchClaimPermissions } from '../../api/v4';
import history from '../../history';
import { setActiveCompany } from '../../actions/company';
import { getActiveCompany } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import Card from '../../components/Card';
import {
  CalendarDropdown,
  Dropdown,
  EmployeeDropdown,
  InputRow
} from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import LeftNav from '../../components/LeftNav';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import UserBadge from '../../components/UserBadge';

import styles from './permissions.module.scss';
const bStyles = classnames.bind(styles);

export default function Permissions() {
  const { type, typeId } = useParams();
  const dispatch = useDispatch();
  const activeUser = useSelector(getLoggedInUser);
  const activeCompany = useSelector(getActiveCompany);
  const [shares, setShares] = useState([]);
  const [sharingDirty, setSharingDirty] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  useEffect(() => {
    if (
      activeUser?.isAccountOwner &&
      !activeCompany?.claimPermissions?.length &&
      !typeId
    ) {
      setShares([{ readOnly: false, userId: activeUser._id, disabled: true }]);
    } else if (!activeCompany?.claimPermissions && typeId) {
      fetchClaimPermissions(typeId).then(setShares);
    } else if (typeId) {
      fetchClaimPermissions(typeId).then(response => {
        setShares([
          ...activeCompany?.claimPermissions.map(p => {
            return {
              ...p,
              disabled: typeId ? true : p.userId === activeCompany.accountId
            };
          }),
          ...response.filter(
            f =>
              !activeCompany.claimPermissions
                .map(p => p.userId)
                .includes(f.userId)
          )
        ]);
      });
    } else {
      setShares([
        ...activeCompany?.claimPermissions.map(p => {
          return {
            ...p,
            disabled: typeId ? true : p.userId === activeCompany.accountId
          };
        })
      ]);
    }
  }, [activeUser, activeCompany, typeId]);

  const submitPermissions = async () => {
    if (type === 'Claims') {
      updateCompany({
        ...activeCompany,
        claimPermissions: shares
      }).then(company => {
        dispatch(setActiveCompany({ ...company }));
      });
    } else {
      await updateClaimPermisisons(typeId, shares);
    }

    history.goBack();
  };

  const renderShares = (shares, expired) => (
    <div className={styles.share}>
      {shares?.map((s, i) => (
        <div key={i} className={bStyles({ expired })}>
          {!s.disabled ? (
            <span
              onClick={() => {
                setShares(current => {
                  let shares = [...current];
                  shares = shares.filter(su => su.userId !== s.userId);
                  return shares;
                });
                setSharingDirty(true);
              }}
            >
              &times;
            </span>
          ) : null}
          <UserBadge userId={s.userId} showName showImage />
          {expired && (
            <div className={styles.expireMessage}>Permission Expired</div>
          )}
          <InputRow className={styles.shareOptions}>
            <Dropdown
              options={[
                { label: 'View Only', value: true },
                { label: 'View/Edit', value: false }
              ]}
              currentValue={s.readOnly}
              onChange={readOnly => {
                setShares(current => {
                  const sharesIndex = current.findIndex(
                    su => s.userId === su.userId
                  );
                  current[sharesIndex].readOnly = readOnly;
                  return [...current];
                });
                setSharingDirty(true);
              }}
              disabled={s.disabled}
            />
            <CalendarDropdown
              startTop
              currentValue={s.endDate ? moment(s.endDate) : null}
              onChange={endDate => {
                setShares(current => {
                  const sharesIndex = current.findIndex(
                    su => s.userId === su.userId
                  );
                  current[sharesIndex].endDate = endDate;
                  return [...current];
                });
                setSharingDirty(true);
              }}
              placeholder="Indefinitely"
              disabled={s.disabled}
            />
          </InputRow>
        </div>
      ))}
    </div>
  );

  const header = (
    <Header
      title={type}
      section="Permission"
      clickBack={() =>
        sharingDirty ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={sharingDirty}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing
      saveButtonClick={submitPermissions}
      cancelButtonClick={() =>
        sharingDirty ? setSaveChangesModal(true) : history.goBack()
      }
    />
  );

  const left = (
    <LeftNav
      items={[
        { value: 0, label: 'Instructions' },
        { value: 1, label: 'Active Permissions' },
        { value: 2, label: 'Expired Permissions' }
      ]}
    />
  );

  return (
    <HeaderAndFooter Header={header} Footer={footer} Left={left} showFooter>
      <Card
        name={0}
        title="Instructions"
        showHeader
        className={styles.instructions}
      >
        <div>
          Permissions set here apply to{' '}
          <span>{`${type === 'Claims' ? 'ALL Claims' : 'THIS Claim'}`}</span>.
        </div>
        <div>
          Those added will be able to view and/or edit{' '}
          <span>{`${type === 'Claims' ? 'all Claims' : 'this Claim'}`}</span>.
        </div>
        {type === 'Claim' && (
          <div>
            <span>
              You will not be able to edit users who have permissions at the
              company level
            </span>
            .
          </div>
        )}
      </Card>
      <Card name={1} title="Active Permissions" showHeader>
        {shares?.length && sharingDirty ? (
          <div
            className={styles.sharingWith}
          >{`Sharing with ${shares?.length} user(s)`}</div>
        ) : null}
        <EmployeeDropdown
          multi
          permissions={[500, 900]}
          fieldLabel="Share With"
          placeholder="Choose one or more users..."
          onChange={userIds => {
            setShares([
              ...shares,
              ...userIds.map(userId => {
                return { userId, readOnly: true };
              })
            ]);
            setSharingDirty(true);
          }}
          name="employeeInformation"
          searchable
          exclude={shares.map(s => s.userId)}
        />
        {renderShares(
          shares?.filter(s => !s.endDate || moment().isBefore(s.endDate)),
          false
        )}
      </Card>
      <Card name={2} title="Expired Permissions" showHeader>
        {renderShares(
          shares?.filter(s => s.endDate && moment().isSameOrAfter(s.endDate)),
          true
        )}
      </Card>
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => history.goBack()}
      />
    </HeaderAndFooter>
  );
}
