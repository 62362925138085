import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M61.5891 31.4971V20.2446C61.5891 9.08199 52.5067 0 41.3408 0H38.645C27.4788 0 18.3968 9.08236 18.3968 20.2446V31.4934C14.3549 31.9492 11.2 35.3371 11.2 39.5043V71.9021C11.2 76.3719 14.8245 80 19.2979 80H60.6914C65.1649 80 68.7894 76.3719 68.7894 71.9021V39.5043C68.7861 35.3406 65.6312 31.9528 61.589 31.497L61.5891 31.4971ZM47.0492 67.1516H32.9369L36.6451 57.4992C34.4432 56.3028 32.9369 53.9997 32.9369 51.325C32.9369 47.4255 36.0954 44.2674 39.9913 44.2674C43.8871 44.2674 47.0489 47.4259 47.0489 51.325C47.0489 54.0034 45.5358 56.306 43.3374 57.4992L47.0492 67.1516ZM53.4915 31.4071H26.4923L26.4919 20.2446C26.4919 13.5449 31.9426 8.09782 38.6424 8.09782H41.3381C48.041 8.09782 53.4886 13.5453 53.4886 20.2446V31.4071H53.4915Z" />
  </svg>
);
