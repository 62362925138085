import {
  CREATE_OSHA_FORM_REQUEST,
  CREATE_OSHA_FORM_RESPONSE,
  CREATE_OSHA_LOG_REQUEST,
  CREATE_OSHA_LOG_RESPONSE,
  FETCH_OSHA_300A_RESPONSE,
  FETCH_OSHA_300LOGS_RESPONSE,
  FETCH_OSHA_FORM_RESPONSE,
  FETCH_OSHA_LOGS_REQUEST,
  PRINT_OSHA_REQUEST,
  PRINT_OSHA_RESPONSE,
  PRINT_SELECTED_OSHA_REQUEST,
  UPDATE_OSHA_FORM_REQUEST,
  UPDATE_OSHA_FORM_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  activeOshaLog: {},
  osha300: [],
  osha300A: {},
  oshaInfo: {},
  printCSV: ''
};

const oshaLogs = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_OSHA_FORM_REQUEST:
    case UPDATE_OSHA_FORM_REQUEST:
    case FETCH_OSHA_LOGS_REQUEST:
    case CREATE_OSHA_LOG_REQUEST:
    case PRINT_OSHA_REQUEST:
    case PRINT_SELECTED_OSHA_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_OSHA_FORM_RESPONSE:
    case CREATE_OSHA_FORM_RESPONSE:
    case UPDATE_OSHA_FORM_RESPONSE:
      return {
        ...state,
        activeOshaLog: payload
      };

    case FETCH_OSHA_300LOGS_RESPONSE:
      return {
        ...state,
        osha300: payload
      };

    case FETCH_OSHA_300A_RESPONSE:
      return {
        ...state,
        osha300A: { ...payload }
      };

    case CREATE_OSHA_LOG_RESPONSE:
      return {
        ...state,
        oshaInfo: payload
      };

    case PRINT_OSHA_RESPONSE:
      return {
        ...state,
        printCSV: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default oshaLogs;
