import React, { useState, useEffect } from 'react';
import history from '../../history';
import {
  fetchContinuousCareByEmployeeId,
  fetchEmployeeDocuments,
  fetchFireExposures
} from '../../api/v4';

import { Button, InputRow } from '../inputs';
import List from '../List';
import Modal from '../Modal';

import '../../containers/MyDocumentsStages/index.css';

export default function EmployeeDocumentRepo(props) {
  const [documents, setDocuments] = useState([]);
  const [openCreateModal, setCreateModal] = useState(false);

  useEffect(() => {
    let employeeId = props.employeeId;
    async function fetchDocs() {
      const docs = await fetchEmployeeDocuments(employeeId);
      const ccDocs = await fetchContinuousCareByEmployeeId(employeeId);
      const fireExposures = await fetchFireExposures(employeeId);
      setDocuments([...docs, ...ccDocs, ...fireExposures]);
    }
    fetchDocs();
  }, [props.employeeId]);

  const handleView = original => {
    if (
      original.label ===
      'Recurrence, Relapse During Recovery & Continuing Treatment'
    ) {
      history.push(`/app/recurrenceContainer/${original._id}`);
    } else if (original.label === 'Transitional Return to Work Program') {
      history.push(`/app/transitionContainer/${original._id}`);
    } else if (original.label === 'Personal Exposure Form') {
      history.push(`/app/fireexposure/${original._id}`);
    } else {
      history.push(`/app/myDocumentContainer/${original._id}`);
    }
  };

  const columns = [
    { key: 'label', label: 'Title' },
    { key: 'createdAt', label: 'Created Date', datatype: 'date' },
    {
      key: 'status',
      accessor: r => {
        if (
          r.label ===
          'Recurrence, Relapse During Recovery & Continuing Treatment'
        ) {
          if (r.isApprovedBySupervisor) {
            return 'Approved';
          } else {
            return 'Supervisor Review';
          }
        } else if (r.label === 'Transitional Return to Work Program') {
          if (!r.isApprovedBySupervisor) {
            return 'Supervisor Review';
          } else if (r.isApprovedByHr) {
            return 'Approved';
          } else {
            return 'HR Review';
          }
        } else if (r.title) {
          return 'Approved';
        } else {
          return '--';
        }
      },
      enum: ['Approved', 'Supervisor Review', 'HR Review']
    }
  ];

  return (
    <div style={{ width: '100%' }}>
      <InputRow right>
        <Button
          color="blue"
          text="Add Employee Document"
          onClick={() => setCreateModal(true)}
        />
      </InputRow>

      <List
        saveKey="docRepo"
        data={documents}
        dataIsHash
        settings={columns}
        rowClick={handleView}
      />
      <Modal
        title="Choose Document Type"
        titleClassName="blueHeader"
        isOpen={openCreateModal}
        onRequestClose={() => setCreateModal(false)}
        hideButtons
      >
        <div className="myDocumentList-reportSelection">
          <div
            className="myDocumentList-button"
            onClick={() =>
              history.push(
                `/app/myDocumentContainer/${props.employeeId}/true`,
                { fromEmployeeProfile: true }
              )
            }
          >
            <img
              src={require('../../assets/images/Upload Image.png')}
              className="myDocumentList-uploadForms"
              alt="Select For Employee Document Upload"
            />
            <span>Upload Document</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}
