import React from 'react';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../actions/messages';
import { updateManyRepeatables } from '../../api/v4';
import List from '../../components/List';

export const RepeatableList = ({
  repeatables,
  repeatingColumns,
  updateData,
  handleRowClick,
  saveKey,
  type,
  actions = [],
  showErrorFor,
  errorMessage
}) => {
  const dispatch = useDispatch();
  const handlePauseUpdate = async (isPaused, selectedIds = []) => {
    if (selectedIds?.length) {
      updateManyRepeatables({
        repeatableIds: selectedIds,
        isPaused
      }).then(async () => {
        dispatch(
          addMessage({
            error: false,
            message: 'Successfully Updated'
          })
        );
        updateData();
      });
    }
  };

  return (
    <List
      saveKey={saveKey}
      rowClick={handleRowClick}
      dataIsHash
      data={repeatables}
      settings={repeatingColumns}
      getRowId={r => r._id}
      actions={[
        ...actions,
        {
          label: `Pause ${type}`,
          color: 'blue',
          onClick: ids => handlePauseUpdate(true, ids)
        },
        {
          label: `Resume ${type}`,
          color: 'blue',
          onClick: ids => handlePauseUpdate(false, ids)
        }
      ]}
      showErrorFor={showErrorFor}
      errorMessage={errorMessage}
    />
  );
};

export default RepeatableList;
