import React from 'react';
import AnalyticsChart from '../AnalyticsChart';
import styles from './headerCards.module.scss';
import Card from '../Card';

export const CustomHomepageCard = ({ settings, period }) => {
  return (
    <Card className={styles.card} title={settings.title} showHeader wide>
      <AnalyticsChart
        settings={settings}
        period={period}
        className={styles.customCardChart}
      />
    </Card>
  );
};
