import {
  ADD_UPLOADED_ATTACHMENTS_REQUEST,
  ADD_UPLOADED_ATTACHMENTS_RESPONSE,
  CLEAR_UPLOADED_ATTACHMENTS,
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_RESPONSE,
  UPDATE_ATTACHMENT_REQUEST
} from '../constants/actionTypes';

export const addUploadedAttachmentsRequest = payload => ({
  type: ADD_UPLOADED_ATTACHMENTS_REQUEST,
  payload
});

export const addUploadedAttachmentsResponse = payload => ({
  type: ADD_UPLOADED_ATTACHMENTS_RESPONSE,
  payload
});

export const clearUploadedAttachments = () => ({
  type: CLEAR_UPLOADED_ATTACHMENTS
});

export const deleteAttachmentRequest = payload => ({
  type: DELETE_ATTACHMENT_REQUEST,
  payload
});

export const deleteAttachmentResponse = payload => ({
  type: DELETE_ATTACHMENT_RESPONSE,
  payload
});

export const updateAttachmentRequest = payload => ({
  type: UPDATE_ATTACHMENT_REQUEST,
  payload
});
