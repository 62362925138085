import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M61.1305 68.8882C57.5737 65.6801 59.2647 59.118 59.702 56.2599C60.3142 52.2643 62.2675 46.5772 63.0839 41.6775C63.6086 38.4694 64.0751 35.9029 64.0751 30.6533C64.0751 26.9202 63.4629 17.7625 60.081 11.2879C53.7545 -0.34885 39.9938 0.00112663 39.9938 0.00112663C39.9938 0.00112663 26.2039 -0.34885 19.9941 11.2879C16.5248 17.7625 16 26.9202 16 30.6533C16 35.9029 16.4373 38.5569 16.9912 41.6775C17.6909 46.5772 19.6443 52.1768 20.3731 56.2599C20.8104 59.118 22.5014 65.6801 18.9446 68.8882C18.9446 68.8882 7.13716 80 39.9938 80C72.9379 79.9708 61.1305 68.8882 61.1305 68.8882ZM21.86 31.6449C21.5976 28.3493 24.1631 26.7452 30.2272 26.7452C35.5624 26.7452 38.5653 28.2618 38.5653 30.4783C38.5653 35.0863 32.9677 37.2445 28.4488 37.2445C24.892 37.2445 22.2098 35.6404 21.86 31.6449ZM39.9938 54.3059C36.6994 54.3059 34.0464 53.4309 34.0464 50.2228C34.0464 47.0147 35.8248 43.2816 39.9938 43.2816C44.1628 43.2816 45.9412 47.1022 45.9412 50.2228C45.9412 53.4018 43.2591 54.3059 39.9938 54.3059ZM58.0985 31.6449C57.7486 35.6404 55.0665 37.2445 51.6263 37.2445C47.0199 37.2445 41.5098 35.028 41.5098 30.4783C41.5098 28.2618 44.5418 26.7452 49.8479 26.7452C55.7953 26.6577 58.4775 28.2618 58.0985 31.6449Z" />
  </svg>
);
