import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M68.3 11.7C60.9998 4.50017 51 0 40 0C29 0 19 4.5 11.7 11.7C4.50017 19.0002 0 29 0 40C0 62.1 17.9 80 40 80C51 80 61 75.5 68.3 68.3C75.4998 60.9998 80 51 80 40C80 29 75.5 19 68.3 11.7ZM44 60.4C44 62.5998 42.1998 64.4 40 64.4C37.8002 64.4 36 62.5998 36 60.4V33.2C36 31.0002 37.8002 29.2 40 29.2C42.1998 29.2 44 31.0002 44 33.2V60.4ZM44 20.4C44 22.5998 42.1998 24.4 40 24.4C37.8002 24.4 36 22.5998 36 20.4V19.5999C36 17.4 37.8002 15.5999 40 15.5999C42.1998 15.5999 44 17.4 44 19.5999V20.4Z" />
  </svg>
);
