import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import {
  fetchCovidExposureById,
  addExposure,
  updateExposure,
  sendCovidExposureNotification
} from '../../../api/v4';
import { addMessage } from '../../../actions/messages';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  TwoColumn,
  EmployeeDropdown,
  Button
} from '../../../components/inputs';
import { notFutureDate } from '../../../utils/formValidation';

import styles from '../covid.module.scss';
import moment from 'moment';

export default function Exposure() {
  const dispatch = useDispatch();
  const [positiveTestDate, setPositiveTestDate] = useState(null);
  const [negativeTestDate, setNegativeTestDate] = useState(null);
  const [positiveEmployee, setPositiveEmployee] = useState(null);
  const [exposedWorkers, setExposedWorkers] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [disableNotification, setDisableNotification] = useState(true);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchCovidExposureById(id).then(response => {
        setPositiveEmployee(response.positiveEmployee);
        setExposedWorkers(response.exposedWorkers);
        setNegativeTestDate(response.negativeTestDate);
        setPositiveTestDate(response.positiveTestDate);
        response.negativeTestDate ? setDisableAll(true) : setIsEditing(true);
        setDisableNotification(false);
      });
    } else {
      setIsCreating(true);
    }
  }, [id]);

  const canSubmit = () => {
    if (isCreating) {
      return (
        exposedWorkers?.length > 0 &&
        positiveEmployee &&
        positiveTestDate &&
        !notFutureDate(positiveTestDate)
      );
    } else {
      return (
        negativeTestDate &&
        !notFutureDate(negativeTestDate) &&
        moment(negativeTestDate).isAfter(moment(positiveTestDate))
      );
    }
  };

  const submitExposure = () => {
    if (isCreating) {
      addExposure({
        positiveTestDate,
        negativeTestDate,
        exposedWorkers,
        positiveEmployee
      }).then(() => {
        dispatch(addMessage({ error: false, message: 'Successfully Created' }));
        setDisableNotification(false);
        setDisableAll(true);
        setHasChanges(false);
      });
    } else {
      updateExposure(id, {
        positiveTestDate,
        negativeTestDate,
        exposedWorkers,
        positiveEmployee
      }).then(() => {
        dispatch(addMessage({ error: false, message: 'Successfully Updated' }));
        setDisableNotification(false);
        setDisableAll(true);
        setHasChanges(false);
      });
    }
  };

  const header = (
    <Header
      section="Covid-19 Exposure"
      clickBack={() =>
        hasChanges
          ? setShowSaveChangesModal(true)
          : history.push('/app/covidTracking')
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={isEditing}
      cancelButtonClick={
        hasChanges
          ? () => setShowSaveChangesModal(true)
          : () => history.push('/app/covidTracking')
      }
      saveButtonDisabled={!canSubmit() || !hasChanges}
      saveButtonClick={() => submitExposure()}
    />
  );

  return (
    <HeaderAndFooter Header={header} Footer={footer}>
      <Card title="Testing Information" showHeader wide>
        <TwoColumn>
          <EmployeeDropdown
            selectButtons
            testID="positiveEmployee"
            name="employees"
            fieldLabel="Employee who tested positive"
            onChange={value => {
              setHasChanges(true);
              setPositiveEmployee(value);
            }}
            isRequired
            currentValue={positiveEmployee}
            searchable
            disabled={isEditing || disableAll}
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Positive Test"
            isRequired
            onChange={value => {
              setHasChanges(true);
              setPositiveTestDate(value);
            }}
            currentValue={positiveTestDate}
            name="dateOfPositiveTest"
            error={
              notFutureDate(positiveTestDate)
                ? 'Date Cannot be a Future Date'
                : null
            }
            touched={positiveTestDate}
            disabled={isEditing || disableAll}
          />
          <EmployeeDropdown
            multi
            selectButtons
            testID="qualification"
            name=" exposedWorkers"
            fieldLabel="Workers who were exposed"
            onChange={values => {
              setHasChanges(true);
              setExposedWorkers(values);
            }}
            isRequired
            currentValue={exposedWorkers}
            searchable
            disabled={isEditing || disableAll}
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Next Negative Test"
            onChange={value => {
              setHasChanges(true);
              setNegativeTestDate(value);
            }}
            currentValue={negativeTestDate}
            name="dateOfNegativeTest"
            error={
              notFutureDate(negativeTestDate)
                ? 'Date Cannot be a Future Date'
                : moment(negativeTestDate).isSameOrBefore(
                    moment(positiveTestDate)
                  )
                ? 'Date Cannot be before Positive Test Date'
                : null
            }
            touched={negativeTestDate}
            disabled={isCreating || disableAll}
          />
        </TwoColumn>
        <Button
          color="blue"
          text="Send Exposure Notification"
          onClick={() =>
            sendCovidExposureNotification({ exposedWorkers }).then(response => {
              if (response.length > 0) {
                const failedNames = response
                  .join(`, `)
                  .replace(/, ((?:.(?!, ))+)$/, ' and $1');
                const plural =
                  response.length > 1
                    ? { verb: `weren't`, ending: `s` }
                    : { verb: `wasn't`, ending: `` };
                dispatch(
                  addMessage({
                    error: true,
                    message: `${failedNames} ${plural.verb} notified.
                    To send notification, add an email for the user${plural.ending}
                    or have the user${plural.ending} turn on push notifications.`
                  })
                );
              } else {
                dispatch(
                  addMessage({ error: false, message: 'Notification Sent' })
                );
              }
            })
          }
          inputClassName={styles.button}
          disabled={disableNotification}
        />
      </Card>

      <SaveChangesModal
        onRequestClose={() => setShowSaveChangesModal(false)}
        isOpen={showSaveChangesModal}
        submitActions={() => history.push('/app/covidTracking')}
      />
    </HeaderAndFooter>
  );
}
