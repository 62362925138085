import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchBehaviorObservationTemplates } from '../../../api/v4';
import { notFutureDate } from '../../../utils/formValidation';
import Card from '../../Card';
import { Dropdown, EmployeeDropdown, Textbox, TwoColumn } from '../../inputs';
import { DatePicker } from '../../inputs/DateTimePicker';
import HierarchySelector from '../../HierarchySelector';

export default function InfoCard({
  name,
  observation,
  onChange,
  handleGroupChange,
  startingGroupId,
  creating,
  editing,
  editGroup,
  missingRequired,
  disabled
}) {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetchBehaviorObservationTemplates().then(response => {
      response = response.filter(template => !template.isArchived);
      setTemplates(response);
    });
  }, []);

  return (
    <Card title="Behavior Observation Info" name={name} showHeader>
      <HierarchySelector
        groupRequired={true}
        groupId={observation.groupId}
        projectId={observation.projectId}
        onGroupChange={value => {
          if (editGroup) {
            handleGroupChange(value.toString() !== startingGroupId.toString());
          }
          let employeeObserved = editGroup
            ? observation.employeeObserved
            : null;
          onChange({
            ...observation,
            groupId: value,
            projectId: undefined,
            employeeObserved
          });
        }}
        onProjectChange={value =>
          onChange({ ...observation, projectId: value })
        }
        projectDisabled={!observation.groupId || (!creating && !editGroup)}
        groupDisabled={!creating && !editGroup}
        touched={missingRequired && !observation.groupId}
      />
      <Dropdown
        fieldLabel="Behavior Observation Template"
        searchable
        alphabetize
        options={templates?.map(template => {
          return {
            value: template._id,
            label: template.title,
            instructions: template.instructions,
            fields: template.fields,
            title: template.title,
            isConfidential: template.isConfidential
          };
        })}
        currentValue={observation.templateId}
        onChange={value =>
          onChange({
            ...observation,
            templateId: value.value,
            instructions: value.instructions,
            fields: value.fields,
            title: value.title,
            isConfidential: value.isConfidential
          })
        }
        placeholder="Select a behavior observation"
        isRequired
        disabled={!creating}
        bareValues={false}
        touched={missingRequired && !observation.templateId}
      />
      <Textbox
        fieldLabel="Instructions"
        onChange={v => onChange({ instructions: v })}
        currentValue={observation?.instructions}
        disabled={true}
      />
      <TwoColumn>
        <EmployeeDropdown
          searchable
          group={!creating ? null : observation?.groupId}
          currentValue={observation?.employeeObserved}
          onChange={values => onChange({ employeeObserved: values })}
          fieldLabel="Employee Being Observed"
          disabled={!creating}
        />
        <EmployeeDropdown
          searchable
          isRequired
          currentValue={observation?.supervisorObserving}
          onChange={values => onChange({ supervisorObserving: values })}
          fieldLabel="Employee Observing"
          disabled
        />
        <Textbox
          fieldLabel="Job Task"
          onChange={v => onChange({ jobTask: v })}
          currentValue={observation?.jobTask}
          disabled={!creating}
        />
        <DatePicker
          fieldLabel="Date of Observation"
          onChange={v => onChange({ dateOfObservation: v })}
          disabled={(!creating && !editing) || disabled}
          currentValue={observation?.dateOfObservation}
          name="dateOfObservation"
          touched={
            missingRequired &&
            (notFutureDate(observation?.dateOfObservation) ||
              !observation?.dateOfObservation)
          }
          isRequired
          maxDate={moment()}
          errorMessage={
            observation?.dateOfObservation
              ? notFutureDate(observation?.dateOfObservation)
              : null
          }
        />
      </TwoColumn>
    </Card>
  );
}
