export const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  }
};

export const zIndex = {
  name: 'zIndex',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    let zIndex;
    let el = state.elements.reference;
    while (el) {
      const elZIndex = parseInt(
        document.defaultView
          .getComputedStyle(el, null)
          .getPropertyValue('z-index')
      );
      if (elZIndex > (zIndex ?? 0)) zIndex = elZIndex;

      el = el.parentElement;
    }

    if (zIndex !== undefined) {
      state.elements.popper.style.zIndex = zIndex;
    }
  }
};
