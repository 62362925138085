import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import {
  createExceptionReport,
  downloadExceptionList,
  getJobList,
  getExceptionReports,
  fetchTrainingExceptionTitles,
  fetchTrainingExceptions,
  sendTrainingReminder,
  updateExceptionReport
} from '../../api/v4';
import { addMessage } from '../../actions/messages';

import { EXCEPTION_REPORTING_FREQUENCY_OPTIONS } from '../../constants/constants';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';

import Card from '../../components/Card';
import { Button, Dropdown, TwoColumn } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';
import ReportModal from '../../components/Modal/reportModal';

import styles from './styles.module.scss';

export default function TrainingReport() {
  const dispatch = useDispatch();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const [trainingTitles, setTrainingTitles] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [hireDateRange, setHireDateRange] = useState(null);
  const [exceptionData, setExceptionData] = useState([]);
  const [exceptionColumns, setExceptionColumns] = useState([]);
  const [userExceptionReport, setUserExceptionReport] = useState({});
  const [hasRanReport, setHasRanReport] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [hireStartDate, setHireStartDate] = useState(null);
  const [hireStartDateFocused, setHireStartDateFocused] = useState(false);
  const [hireEndDate, setHireEndDate] = useState(null);
  const [hireEndDateFocused, setHireEndDateFocused] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reportInfo, setReportInfo] = useState({
    trainingTitles: [],
    year: '',
    jobTitle: [],
    groupIds: activeGroup ? [activeGroup._id] : [],
    projectIds: activeProject ? [activeProject._id] : []
  });

  const [hireDateErrorMessage, setHireDateErrorMessage] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingHireDate, setMissingHireDate] = useState(false);

  useEffect(() => {
    fetchTrainingExceptionTitles().then(setTrainingTitles);
    getJobList().then(r => {
      setJobTitles(r?.map(job => ({ value: job, label: job })));
    });
    getExceptionReports('training').then(setUserExceptionReport);
  }, []);

  const handleSendReminder = empIds => {
    let trainingIds = [];
    empIds.forEach(emp => {
      trainingIds = trainingIds.concat(
        exceptionData.find(e => e.userId === emp)?.trainingIds
      );
    });
    sendTrainingReminder({ trainingIds, empIds });
  };

  const handleRunReport = () => {
    fetchTrainingExceptions({
      ...reportInfo,
      hireDateRange: hireDateRange === 'clear' ? null : hireDateRange,
      hireStartDate,
      hireEndDate
    }).then(response => {
      setExceptionData(response.exceptions);
      getColumns(response.titleList);
      setHasRanReport(true);
    });
  };

  const handleExportTable = async () => {
    const src = await downloadExceptionList({
      exceptionData
    });
    window.location = src;
  };

  const handleUpdatingExceptionReports = reports => {
    let obj = { ...userExceptionReport, exceptionType: 'training', reports };

    (obj._id ? updateExceptionReport(obj) : createExceptionReport(obj)).then(
      r => {
        setUserExceptionReport(r);
        setOpenModal(null);
        dispatch(
          addMessage({
            error: false,
            message: `${obj._id ? 'Updated' : 'Created'} Successfully`
          })
        );
      }
    );
  };

  const getColumns = titles => {
    let columns = [
      {
        key: 'userId',
        label: 'Employee Name',
        datatype: 'users'
      },
      {
        key: 'Job Title'
      },
      {
        key: 'Employee Id'
      },
      {
        key: 'Supervisor'
      }
    ];
    titles.forEach(t => {
      columns.push({
        key: t,
        accessor: r => r[t],
        enum: [
          'Unassigned',
          'Assigned',
          'In-Progress',
          'Failed',
          'Completed',
          'Not Required',
          'Future Assignment Scheduled',
          'Awaiting Approval'
        ]
      });
    });

    setExceptionColumns(columns);
  };

  const handleGroupChange = values => {
    const validProjectIds = activeCompany.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);

    const newProjectIds = reportInfo?.projectIds.filter(p =>
      validProjectIds.includes(p)
    );
    handleReportInfoChange({ groupIds: values, projectIds: newProjectIds });
  };

  const handleReportInfoChange = values => {
    setReportInfo({ ...reportInfo, ...values });
    if (hasRanReport) setHasChanges(true);
  };

  const checkForValidHireRange = (start, end) => {
    let message = moment(start).isBefore(end) ? null : 'Invalid Date Range';
    setHireDateErrorMessage(message);
    setMissingHireDate(message);
  };

  const validHireDateRange =
    (hireDateRange === 'customRange' &&
      hireStartDate &&
      hireEndDate &&
      !hireDateErrorMessage) ||
    hireDateRange !== 'customRange';

  const canRun =
    reportInfo?.year &&
    reportInfo?.trainingTitles?.length &&
    validHireDateRange;

  const hireDateOptions = [
    {
      value: 'clear',
      label: ' '
    },
    ...EXCEPTION_REPORTING_FREQUENCY_OPTIONS
  ];

  const header = (
    <Header
      title="Training Report"
      pageActionOptions={[
        {
          label: 'Send a Report',
          visible: true,
          color: 'blueOutline',
          onClick: () => setOpenModal('sendReports')
        },
        {
          label: 'Export Table',
          visible: true,
          color: 'greenOutline',
          onClick: () => handleExportTable(true)
        }
      ]}
    />
  );
  return (
    <HeaderAndFooter Header={header}>
      <Card>
        <TwoColumn>
          <Dropdown
            options={yearToDateDropdown(2020)}
            currentValue={reportInfo?.year}
            onChange={v => handleReportInfoChange({ year: v })}
            fieldLabel="Date"
            tooltip="This will look for trainings with a due date in the selected year"
            displayTooltip
            isRequired
            touched={missingRequired && !reportInfo?.year}
          />
          <Dropdown
            options={trainingTitles}
            fieldLabel="Training Title"
            currentValue={reportInfo?.trainingTitles}
            onChange={v => {
              handleReportInfoChange({ trainingTitles: v });
            }}
            isRequired
            selectButtons
            searchable
            bareValues={false}
            multi
            touched={missingRequired && !reportInfo?.trainingTitles?.length}
          />
        </TwoColumn>
        <HierarchySelector
          onGroupChange={values => handleGroupChange(values)}
          groupId={activeGroup ? [activeGroup._id] : reportInfo?.groupIds}
          groupDisabled={
            !reportInfo?.year ||
            !reportInfo?.trainingTitles?.length ||
            activeGroup
          }
          onProjectChange={v => handleReportInfoChange({ projectIds: v })}
          projectId={
            activeProject ? [activeProject._id] : reportInfo?.projectIds
          }
          projectDisabled={
            (!reportInfo?.groupIds?.length && !activeGroup) || activeProject
          }
          groupRequired={false}
          multi
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
        />
        <TwoColumn>
          {hireDateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                searchable
                options={hireDateOptions}
                fieldLabel="Hire Date"
                currentValue={hireDateRange}
                onChange={value => {
                  setHireDateRange(value);
                  if (value === 'clear') {
                    setHireEndDate(null);
                    setHireStartDate(null);
                  }
                  if (hasRanReport) setHasChanges(true);
                }}
                disabled={
                  !reportInfo?.year || !reportInfo?.trainingTitles?.length
                }
                placeholder="Select an option"
              />
              <div className={styles.hireDateContainer}>
                <div
                  className={
                    missingHireDate
                      ? styles.datePickerInvalid
                      : styles.datePicker
                  }
                >
                  <SingleDatePicker
                    date={hireStartDate}
                    onDateChange={value => {
                      setHireStartDate(value);
                      checkForValidHireRange(value, hireEndDate);
                    }}
                    focused={hireStartDateFocused}
                    onFocusChange={f => setHireStartDateFocused(f.focused)}
                    placeholder="Start"
                    isOutsideRange={d => false}
                  />
                  <SingleDatePicker
                    date={hireEndDate}
                    onDateChange={value => {
                      setHireEndDate(value);
                      checkForValidHireRange(hireStartDate, value);
                    }}
                    focused={hireEndDateFocused}
                    onFocusChange={f => setHireEndDateFocused(f.focused)}
                    placeholder="End"
                  />
                </div>
                <span className={styles.dateWarning}>
                  {hireDateErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              searchable
              options={hireDateOptions}
              fieldLabel="Hire Date"
              currentValue={hireDateRange}
              onChange={value => {
                setHireDateRange(value);
                if (hasRanReport) setHasChanges(true);
              }}
              disabled={
                !reportInfo?.year || !reportInfo?.trainingTitles?.length
              }
              placeholder="Select an option"
            />
          )}
          <Dropdown
            options={jobTitles}
            fieldLabel="Job Title(s)"
            currentValue={reportInfo?.jobTitles}
            onChange={v => handleReportInfoChange({ jobTitles: v })}
            disabled={!reportInfo?.year || !reportInfo?.trainingTitles?.length}
            selectButtons
            searchable
            multi
          />
        </TwoColumn>
        <Button
          color="blue"
          text={hasRanReport ? 'Update Report' : 'Run Report'}
          onClick={() =>
            !canRun
              ? setOpenModal('missingRequired')
              : hasChanges || !hasRanReport
              ? handleRunReport()
              : null
          }
          className={styles.button}
          onMouseEnter={() => {
            setMissingHireDate(!validHireDateRange);
            setMissingRequired(!canRun);
          }}
        />
      </Card>
      <Card>
        {canRun && hasRanReport ? (
          <List
            data={exceptionData}
            dataIsHash
            settings={exceptionColumns}
            actions={[
              {
                label: 'Send Reminder',
                color: 'blue',
                onClick: handleSendReminder
              }
            ]}
            hideCheckboxFor={['cannotSendReminder']}
            getRowId={r => r.userId}
          />
        ) : (
          <div className={styles.messageText}>
            Fill fields above to generate report
          </div>
        )}
      </Card>
      {openModal === 'sendReports' && (
        <ReportModal
          isOpen={openModal === 'sendReports'}
          onRequestClose={() => setOpenModal(false)}
          submitActions={handleUpdatingExceptionReports}
          exceptionType="training"
          reports={userExceptionReport?.reports ?? []}
          topMessage="Send training report for this year"
          titles={trainingTitles}
          bareValuesTemplateDropdown={false}
        />
      )}
      <MissingRequiredModal
        isOpen={openModal === 'missingRequired'}
        onRequestClose={() => setOpenModal()}
        subject="Training Exception"
      />
    </HeaderAndFooter>
  );
}
