import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import { deleteAttachment, uploadAttachment } from '../../../api/v4';
import {
  fetchBehaviorObservationById,
  createBehaviorObservation,
  updateBehaviorObservation,
  deleteBehaviorObservation,
  printBehaviorObservation
} from '../../../api/v4';
import { addMessage } from '../../../actions/messages';
import { getLoggedInUser } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import { notFutureDate } from '../../../utils/formValidation';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import GeneratorCard from '../../../components/behaviorObservations/GeneratorCard';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import InfoCard from '../../../components/behaviorObservations/InfoCard';
import LeftNav from '../../../components/LeftNav';
import Modal from '../../../components/Modal';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SignatureCard from '../../../components/SignatureCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function BehaviorObservationContainer() {
  const { boId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getLoggedInUser);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const [behaviorObservation, setBehaviorObservation] = useState({});
  const [id, setId] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openChangeGroupModal, setOpenChangeGroupModal] = useState(false);
  const [hasGroupChange, setHasGroupChange] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [startingGroupId, setStartingGroupId] = useState(null);
  const [markAsComplete, setMarkAsComplete] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  // these will be used when creating an observation
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const group = activeCompany.groups?.find(
    g => g._id === behaviorObservation?.groupId
  );
  const area = group?.projects?.find(
    p => p._id === behaviorObservation?.projectId
  );

  const hiddenOrArchived =
    group?.isHidden || area?.isHidden || behaviorObservation?.isArchived;

  useEffect(() => {
    if (boId) {
      fetchBehaviorObservationById(boId)
        .then(response => {
          const local = localStorage.getItem('activeObservation');
          if (local) {
            response.fields = response.fields.map((f, fi) => {
              let answer = JSON.parse(local).fields[fi].answer;
              return { ...f, answer };
            });
            response.dateOfObservation = JSON.parse(local).dateOfObservation;
            setUnsavedChanges(true);
          }
          setBehaviorObservation(response);
          setId(boId);
          setEditing(
            !response.isCompleted && user._id === response.supervisorObserving
          );
          setStartingGroupId(response.groupId);
          localStorage.removeItem('activeObservation');
        })
        .catch(e => console.error(e));
    } else {
      setCreating(true);
      setBehaviorObservation({
        groupId: activeGroup?._id,
        projectId: activeProject?._id,
        supervisorObserving: user._id,
        dateOfObservation: new Date(),
        attachments: [],
        companyId: activeCompany?._id,
        dueDate: moment(new Date()).add(5, 'hours')
      });
    }
  }, [boId, activeCompany._id, activeGroup._id, activeProject, user]);

  const canSubmit =
    behaviorObservation?.groupId &&
    behaviorObservation?.dateOfObservation &&
    !notFutureDate(behaviorObservation?.dateOfObservation, true) &&
    behaviorObservation?.supervisorSignature &&
    behaviorObservation?.supervisorObserving &&
    (!behaviorObservation?.employeeObserved ||
      behaviorObservation.employeeSignature) &&
    !behaviorObservation?.fields?.some(
      field =>
        field.required &&
        !(field.type === 'table'
          ? field.answer?.some(row => row?.some(text => text))
          : field.answer)
    );

  const handleSubmit = async isCompleted => {
    localStorage.removeItem('activeObservation');
    let completionDate = {};
    if (isCompleted)
      completionDate = {
        completionDate: new Date(),
        isCompleted
      };
    if (id) {
      updateBehaviorObservation({
        ...behaviorObservation,
        ...completionDate
      })
        .then(response => {
          addMessage({
            error: false,
            message: response
          });
          history.goBack();
        })
        .catch(e => {
          dispatch(
            addMessage({
              error: true,
              message: e?.data
            })
          );
        });
    } else {
      // need to upload photos first
      if (filesToUpload.length > 0) {
        await addAttachments(filesToUpload, completionDate);
      } else {
        createObservation(completionDate, behaviorObservation);
      }
    }
  };
  const createObservation = (completionDate, obs) => {
    createBehaviorObservation({
      ...obs,
      ...completionDate
    })
      .then(response => {
        addMessage({
          error: false,
          message: response
        });
        history.goBack();
      })
      .catch(e => {
        dispatch(
          addMessage({
            error: true,
            message: e?.data
          })
        );
      });
  };

  const deleteObservation = () => {
    deleteBehaviorObservation(id).then(() => {
      localStorage.removeItem('activeObservation');
      history.goBack();
    });
  };

  const addAttachments = (attachments, completionDate) => {
    if (!attachments) return;
    let data = formatFiles(attachments);
    uploadAttachment(data, {
      ownerType: 'behaviorObservation',
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      if (response === 'No file object attached') {
        dispatch(
          addMessage({
            error: true,
            message: response
          })
        );
        return;
      }

      const attachmentIds = [
        ...behaviorObservation.attachments?.map(a => a._id),
        ...response?.map(a => a._id)
      ];
      if (creating) {
        createObservation(completionDate, {
          ...behaviorObservation,
          attachments: attachmentIds
        });
      }
      if (editing) {
        dispatch(
          addMessage({
            error: false,
            message: 'Photo(s) Successfully Saved'
          })
        );
        updateBehaviorObservation({
          ...behaviorObservation,
          attachments: attachmentIds
        }).then(setBehaviorObservation);
      }
    });
  };

  const deleteAtt = attachment => {
    deleteAttachment(attachment._id, false).then(() => {
      let attachments = behaviorObservation.attachments.filter(
        a => a !== attachment
      );

      if (creating)
        setBehaviorObservation({
          ...behaviorObservation,
          attachmentIds: attachments.map(a => a._id),
          attachments: attachments
        });
      if (editing)
        updateBehaviorObservation({
          ...behaviorObservation,
          attachments: attachments.map(a => a._id)
        }).then(setBehaviorObservation);
    });
  };

  const handlePrint = () => {
    printBehaviorObservation(boId).then(response => {
      window.open(response, '_blank');
    });
  };

  const handleGroupChange = isCompleted => {
    setMarkAsComplete(isCompleted);
    setOpenChangeGroupModal(true);
  };

  const handleGoBack = () => {
    localStorage.removeItem('activeObservation');
    history.goBack();
  };

  const handleArchive = () => {
    updateBehaviorObservation({
      ...behaviorObservation,
      isArchived: !behaviorObservation.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/behaviorObservations');
      else setBehaviorObservation(response);
    });
  };

  const fullyDisabled =
    (!editing && !creating) ||
    behaviorObservation.isCompleted ||
    hiddenOrArchived;

  const header = (
    <Header
      title="Behavior Observation"
      section={
        !editing && !creating
          ? 'View Observation'
          : creating
          ? 'Creating Observation'
          : 'Edit Observation'
      }
      needsSaved={unsavedChanges && !fullyDisabled}
      clickBack={() =>
        unsavedChanges && !fullyDisabled
          ? setOpenSaveChangesModal(true)
          : handleGoBack()
      }
      pageActionOptions={
        !creating
          ? [
              {
                label: 'Edit Behavior Observation',
                color: 'blueOutline',
                onClick: () => setEditing(true),
                visible:
                  user.accessLevel >= 500 &&
                  !editing &&
                  !hiddenOrArchived &&
                  !behaviorObservation.taskId
              },
              {
                label: 'Change Group/Establishment',
                color: 'blueOutline',
                onClick: () => setEditGroup(true),
                visible: user.accessLevel >= 500 && !hiddenOrArchived
              },
              {
                label: 'Print Behavior Observation',
                color: 'greenOutline',
                onClick: () => handlePrint(),
                visible: behaviorObservation.isCompleted
              },
              {
                label: 'Delete Behavior Observation',
                color: 'redOutline',
                onClick: () => setOpenDeleteModal(true),
                visible:
                  user.accessLevel >= 500 ||
                  user._id.toString() ===
                    behaviorObservation.supervisorObserving
              },
              {
                label: `${
                  behaviorObservation?.isArchived ? 'Un-' : ''
                }Archive Behavior Observation`,
                color: 'blueOutline',
                onClick: () => handleArchive(),
                visible: user.accessLevel > 400
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() =>
        !canSubmit
          ? setMissingModalOpen(true)
          : hasGroupChange
          ? handleGroupChange(true)
          : handleSubmit(true)
      }
      saveButtonDisabled={
        !unsavedChanges ||
        notFutureDate(behaviorObservation?.dateOfObservation, true) ===
          'Cannot be a Future Date' ||
        !behaviorObservation.groupId ||
        !behaviorObservation.templateId
      }
      saveButtonClick={() =>
        hasGroupChange ? handleGroupChange(false) : handleSubmit(false)
      }
      cancelButtonClick={() =>
        editing && unsavedChanges
          ? setOpenSaveChangesModal(true)
          : editing & !unsavedChanges
          ? setEditing(false)
          : setOpenSaveChangesModal(true)
      }
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const left = (
    <LeftNav
      items={
        behaviorObservation?.title
          ? [
              { value: 0, label: 'Behavior Observation Info' },
              { value: 1, label: behaviorObservation?.title },
              { value: 2, label: 'Attachments' },
              behaviorObservation?.employeeObserved
                ? { value: 3, label: 'Employee Signature' }
                : null,
              { value: 4, label: 'Observer Signature' }
            ].filter(x => x !== null)
          : [{ value: 0, label: 'Behavior Observation Info' }]
      }
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={
        fullyDisabled && !editGroup ? null : editing || creating || editGroup
      }
      Left={left}
    >
      <InfoCard
        name={0}
        observation={behaviorObservation}
        onChange={values => {
          setBehaviorObservation({ ...behaviorObservation, ...values });
          setUnsavedChanges(true);
        }}
        handleGroupChange={hasGroupChange =>
          hasGroupChange ? setHasGroupChange(true) : setHasGroupChange(false)
        }
        startingGroupId={startingGroupId}
        creating={creating}
        editing={editing}
        editGroup={editGroup}
        handleCloseModal={() => setOpenChangeGroupModal(false)}
        missingRequired={missingRequired}
        disabled={fullyDisabled}
      />
      {behaviorObservation.templateId ? (
        <>
          <GeneratorCard
            name={1}
            observation={behaviorObservation}
            onChange={values => {
              setBehaviorObservation({ ...behaviorObservation, ...values });
              setUnsavedChanges(true);
            }}
            disabled={fullyDisabled}
            creating={creating}
            editing={editing}
            isObservation
            missingRequired={missingRequired}
            hiddenOrArchived={hiddenOrArchived}
          />
          <AttachmentUploadCard
            name={2}
            addAttachments={addAttachments}
            deleteAttachment={deleteAtt}
            isEditing={editing}
            isCreating={creating}
            addedAttachments={behaviorObservation?.attachments || []}
            attachmentsToUpload={attachmentsToUpload}
            formattedAttachmentsToUpload={filesToUpload}
            taskOwnedBy="observation"
            ownerType="behaviorObservation"
            handleAnswer={(currentAttachments, currentFiles) => {
              setAttachmentsToUpload(currentAttachments);
              setFilesToUpload(currentFiles);
              setUnsavedChanges(true);
            }}
            fromObservation
            createdBy={behaviorObservation.supervisorObserving}
            disabled={fullyDisabled}
          />
          {behaviorObservation?.employeeObserved ? (
            <SignatureCard
              name={3}
              header="Employee Signature"
              currentValue={behaviorObservation?.employeeSignature}
              disabled={fullyDisabled || behaviorObservation?.employeeSignature}
              onChange={signature => {
                setBehaviorObservation({
                  ...behaviorObservation,
                  employeeSignature: signature.source_url
                });
                setUnsavedChanges(true);
              }}
              missingRequired={missingRequired}
            />
          ) : null}
          <SignatureCard
            name={4}
            header="Observer Signature"
            currentValue={behaviorObservation?.supervisorSignature}
            onChange={signature => {
              setBehaviorObservation({
                ...behaviorObservation,
                supervisorSignature: signature.source_url
              });
              setUnsavedChanges(true);
            }}
            disabled={fullyDisabled || behaviorObservation?.supervisorSignature}
            missingRequired={missingRequired}
          />
        </>
      ) : null}
      <DeleteItemModal
        deletingWhat="Behavior Observation"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={deleteObservation}
      />
      <SaveChangesModal
        savingWhat="Behavior Observation"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => handleGoBack()}
      />
      <Modal
        title="Change Group/Establishment"
        titleClassName="blueHeader"
        isOpen={openChangeGroupModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setOpenChangeGroupModal(false)}
        submitActions={() => {
          setOpenChangeGroupModal(false);
          handleSubmit(markAsComplete);
        }}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Changing the Group/Establishment of this observation will
          <p style={{ fontWeight: 'bold' }}>
            Update the Group/Establishment of the associated Corrective Action.
          </p>
          Are you sure you want to continue?
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Behavior Observation"
      />
    </HeaderAndFooter>
  );
}
