import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { DOCUMENT_TYPES_CUSTOMIZABLE } from '../../../constants/constants';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import Dropdown from '../../inputs/Dropdown';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';
import { InputRow } from '../../inputs';
import HoverImage from '../../HoverImage';
import Card from '../../Card';
import { Rearranger } from '../../Rearranger';
import TableQuestion from '../../inputs/TableQuestion';

import styles from './customDocCard.module.scss';

export default class CustomDocumentCard extends Component {
  state = {
    modalOpen: false,
    activeOptions: [],
    rowIndex: 0,
    selectedFieldType: '',
    dragIdx: null,
    dragTargetIdx: null
  };

  handleQuestionChange = (index, questionLabel) => {
    let fields = [...this.props.fields];
    let answers = questionLabel.answer;

    fields[index][questionLabel.field] = answers;

    this.setState({
      activeOptions: [],
      rowIndex: '',
      modalOpen: false,
      selectedFieldType: ''
    });

    this.props.onChange(fields);
  };

  addRowsToTemplate = numberOfRows => {
    let fields = [...this.props.fields];

    for (let i = 1; i <= numberOfRows; i++) {
      fields.push({
        type: '',
        label: '',
        options: [],
        required: false
      });
    }

    this.props.onChange(fields);
  };

  removeRowFromTemplate = index => {
    let fields = [...this.props.fields];
    if (
      this.props.isEditing &&
      fields[index].label !== '' &&
      fields[index].options.length !== 0
    ) {
      fields[index].legacy = true;
    } else {
      fields.splice(index, 1);
    }
    this.props.onChange(fields);
  };

  setChangeOptions = (options, rowIndex, fieldType) => {
    options = options.map(option => {
      if (option.option) return option;
      if (option && this.props.duplicating)
        return {
          option,
          isEditable: true
        };
      return {
        option,
        isEditable: false
      };
    });

    this.setState({
      modalOpen: true,
      rowIndex,
      activeOptions: options,
      selectedFieldType: fieldType.value
    });
  };

  renderItem = (item, index) => {
    const notEditable = '_id' in item;
    const { disabled } = this.props;
    const buttonOptions = [
      'dropdown',
      'multiselect',
      'radiobuttons',
      'checkbox'
    ];
    const optionsIncludeItem =
      buttonOptions.includes(item.type.value) ||
      buttonOptions.includes(item.type);
    const missingRequired = this.props.missingRequired;

    return (
      <>
        <Textbox
          currentValue={item.label}
          onChange={answer =>
            this.handleQuestionChange(index, { field: 'label', answer })
          }
          fieldLabel="Question"
          type="textarea"
          placeholder="Type your question here."
          disabled={disabled}
          testID={`question${index}`}
          isRequired
          touched={missingRequired && !item.label}
        />
        <Dropdown
          options={DOCUMENT_TYPES_CUSTOMIZABLE}
          fieldLabel="Type of Input"
          onChange={values =>
            this.handleQuestionChange(index, {
              field: 'type',
              answer: values
            })
          }
          isRequired
          bareValues={false}
          placeholder="Choose a question type."
          currentValue={item.type}
          disabled={disabled || notEditable}
          testID={`dropdown${index}`}
          touched={missingRequired && !item.type}
        />
        {item.type.value !== 'blockOfText' && (
          <Checkbox
            fieldLabel="Is Required"
            onChange={v =>
              this.handleQuestionChange(index, {
                field: 'required',
                answer: v
              })
            }
            currentValue={item.required}
            disabled={disabled || notEditable}
          />
        )}
        {optionsIncludeItem && (
          <Button
            text="View Options"
            color="blue"
            onClick={() =>
              this.setChangeOptions(item.options, index, item.type)
            }
            disabled={disabled}
          />
        )}
        {item.type.value === 'table' && (
          <TableQuestion
            options={item.options}
            handleChange={v =>
              this.handleQuestionChange(index, {
                field: 'options',
                answer: v.options
              })
            }
            disabled={disabled}
          />
        )}
        {optionsIncludeItem && item.options.length === 0 && (
          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
            * Must add at least one option
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      modalOpen,
      activeOptions,
      rowIndex,
      selectedFieldType
    } = this.state;
    const {
      fields,
      disabled,
      label,
      onChange,
      shouldHaveExpiredDate,
      notEditable,
      handleTitleChange,
      handleExpiredChange,
      isConfidential,
      handleConfidentialChange
    } = this.props;
    return (
      <Card title="Document Template" showHeader>
        <Textbox
          currentValue={label}
          onChange={(v, e) => handleTitleChange(v, e)}
          fieldLabel="Title"
          placeholder="Title of Document"
          disabled={disabled}
          isRequired
          touched={this.props.missingRequired && !label}
          error
        />
        <Checkbox
          fieldLabel="Should be able to be expired"
          onChange={handleExpiredChange}
          currentValue={shouldHaveExpiredDate}
          disabled={disabled}
        />
        <Checkbox
          fieldLabel="Is this a Confidential Document?"
          onChange={handleConfidentialChange}
          currentValue={isConfidential}
          disabled={disabled}
        />
        <Rearranger
          className={styles.container}
          items={fields}
          onChange={onChange}
          renderItem={this.renderItem}
          disabled={disabled || notEditable}
          onDuplicate={row => {
            const clone = cloneDeep(row);
            delete clone._id;
            return clone;
          }}
        />
        <InputRow className={styles.footer}>
          <Button
            text="Add Row"
            color="blue"
            onClick={() => this.addRowsToTemplate(1)}
            disabled={disabled}
          />
          <Button
            text="Add Five Rows"
            color="blue"
            onClick={() => this.addRowsToTemplate(5)}
            disabled={disabled}
          />
        </InputRow>
        <Modal
          title="Change Options"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ modalOpen: false, activeOptions: [] })
          }
          submitActions={() =>
            this.handleQuestionChange(rowIndex, {
              field: 'options',
              answer: activeOptions
            })
          }
          disableSubmit={
            !activeOptions?.length ||
            activeOptions?.filter(o => !o.option?.trim()?.length)?.length
          }
        >
          <div className={styles.modal}>
            {activeOptions.map((option, index) => (
              <div className={styles.options} key={index}>
                <Textbox
                  testID={`addOption${index}`}
                  currentValue={option.option}
                  onChange={v => {
                    const currentOptions = activeOptions;
                    currentOptions[index].option = v;
                    this.setState({ activeOptions: currentOptions });
                  }}
                  placeholder="Type your option here."
                  disabled={!option.isEditable && fields[rowIndex]._id}
                  className={styles.option}
                />
                {option.isEditable && (
                  <HoverImage
                    src={require('../../../assets/images/removePermissions.png')}
                    srcHover={require('../../../assets/images/removePermissonsHover.png')}
                    className={styles.removeImage}
                    alt="remove row"
                    onClick={() =>
                      this.setState({
                        activeOptions: [
                          ...activeOptions.slice(0, index),
                          ...activeOptions.slice(index + 1)
                        ]
                      })
                    }
                  />
                )}
              </div>
            ))}
            <Button
              text="Add Option"
              color="blue"
              onClick={() =>
                this.setState({
                  activeOptions: [
                    ...activeOptions,
                    { option: '', isEditable: true }
                  ]
                })
              }
              disabled={
                activeOptions.length === 2 &&
                selectedFieldType === 'radiobuttons'
              }
            />
            {activeOptions.length === 2 &&
              selectedFieldType === 'radiobuttons' && (
                <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                  * Only two options are allowed for Radio Buttons
                </div>
              )}
          </div>
        </Modal>
      </Card>
    );
  }
}
