import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M46.4186 33.5946C46.4658 33.0826 46.7286 32.6097 47.1386 32.3003C51.9473 28.6627 55.0551 21.8571 55.0551 14.9639C55.0551 6.71308 48.3422 0 40.0911 0C31.8383 0 25.1246 6.71282 25.1246 14.9639C25.1246 21.8595 28.2324 28.6636 33.0431 32.3003C33.5079 32.6535 33.7676 33.1888 33.7758 33.8078C34.0281 36.8674 33.2669 38.9868 28.6917 39.8835L14.2929 42.698C9.46605 43.6443 5 45.9403 5 56.2619V79.9982H16.7074V63.8419C16.7074 62.8276 17.5329 62.0021 18.5473 62.0021C19.5616 62.0021 20.3871 62.8276 20.3871 63.8419V80H31.7011C30.22 78.2022 29.4103 75.9651 29.4103 73.6403C29.4103 68.1031 33.914 63.5993 39.4505 63.5993H46.5962L49.8238 39.2838C46.9134 37.7657 46.1482 36.4649 46.4186 33.5946ZM33.0912 73.6397C33.0912 77.1469 35.9444 79.9994 39.452 79.9994H44.4204L46.109 67.2783H39.452C35.9445 67.278 33.0912 70.1315 33.0912 73.6397ZM53.4073 40.26L48.1318 79.9994H67.372L61.8946 41.9202L53.4073 40.26ZM65.8857 42.6981L65.7197 42.6666L71.0322 79.6006C73.525 78.6756 75.1776 76.3173 75.1776 73.6377L75.1772 56.2636C75.1772 45.9404 70.7117 43.646 65.8857 42.6981Z" />
  </svg>
);
