import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { deleteTask, emailAssignees } from '../../api/v4';
import history from '../../history';
import {
  fetchIncidentByIdRequest,
  updateReportComponentRequest
} from '../../actions/incidents';
import { setActiveTaskRequest } from '../../actions/tasks';

import {
  getActiveIncidentSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getLoggedInUser } from '../../selectors/users';
import {
  canMarkAsComplete,
  hasAccess,
  isSectionComplete
} from '../../utils/incidentHelper';
import { isComplete } from '../../utils/reportHelper';
import { useSocket } from '../../utils/withSocket';
import Assignee from '../../components/Assignee';
import Header from '../../components/Header';
import { HeaderAndFooter } from '../../components/HeaderAndFooter';
import LeftNav from '../../components/LeftNav';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import Modal from '../../components/Modal';
import ReportSection from '../../components/reportCards/ReportSection';
import RootCauseCard from '../RootCauseCard';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import Textbox from '../../components/inputs/Textbox';
import ViewOnly from '../../components/ViewOnly';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function RootCauseContainer(props) {
  const dispatch = useDispatch();
  const { stage } = useParams();
  const updateReportComponent = payload =>
    dispatch(updateReportComponentRequest(payload));
  const setTask = payload => dispatch(setActiveTaskRequest(payload));

  const activeIncident = useSelector(getActiveIncidentSelector);
  const activeReport = useSelector(getActiveReportsSelector);
  const incidentLoading = useSelector(getIncidentsLoadingSelector);
  const loggedInUser = useSelector(getLoggedInUser);

  const [openModalType, setOpenModalType] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [report, setReport] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [rootCauses, setRootCauses] = useState([]);
  const [rcIndexToRemove, setRcToRemove] = useState();
  const [missingRequired, setMissingRequired] = useState();
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const { socketDirty, socketLock, lockInfo, locked } = useSocket(
    activeIncident._id,
    activeReport?.report?.[0]?.[0]
  );

  useEffect(() => {
    if (activeReport?.report) {
      setReport([...activeReport.report]);
      setRootCauses(activeReport.report[0]?.[7]);
    } else {
      setReport([...activeReport]);
    }
  }, [activeReport]);

  const rc = report?.[0];
  const rcName = rc?.[0];
  const rcComplete = isComplete(report);
  const rootCausesComplete = rootCauses?.every(
    rc => rc?.title?.trim() && rc?.category && rc?.description?.trim()
  );

  const reportTask = activeIncident?.incidentActivity?.openTasks?.filter(
    task => report.length > 0 && task.reportComponentName === rcName
  );

  const sendEmail = () => {
    emailAssignees({
      messageContent: emailMessage,
      incidentId: activeIncident?._id,
      sentMessageToIds: reportTask?.[0]?.assignedTo
    }).then(() => {
      setOpenModalType(null);
      setEmailMessage('');
    });
  };

  const answerQuestion = async (fields, subSectionIndex) => {
    socketLock();
    report[0][1].subSections[subSectionIndex][1] = fields;
    report[0][5].isStarted = true;
    setReport({ ...report });
    setUnsavedChanges(true);
  };

  const reopenReportSection = async () => {
    report[0][4].isComplete = false;
    await updateReportComponent({
      stage
    });
  };

  const handleSectionSubmit = async (markAsComplete, dontLeave = false) => {
    setUnsavedChanges(false);

    if (
      canMarkAsComplete(report[0][1].subSections) &&
      rootCausesComplete &&
      markAsComplete === undefined
    ) {
      setOpenModalType('markComplete');
      return;
    }

    if (markAsComplete) {
      report[0][4].isComplete = true;
    }

    report[0][7] = rootCauses;
    await updateReportComponent({
      stage,
      dontLeavePage: dontLeave
    });

    socketDirty();
  };

  const handleDiscard = async () => {
    if (activeReport.returnPath) {
      history.push(activeReport.returnPath);
    } else {
      dispatch(fetchIncidentByIdRequest(activeIncident));
      history.push(`/app/incidentContainer/${stage}`);
    }
  };

  const updateRootCauses = (values, index) => {
    setUnsavedChanges(true);
    let rc = { ...rootCauses[index], ...values };
    let updatedRcs = [...rootCauses];
    updatedRcs.splice(index, 1, rc);
    setRootCauses(updatedRcs);
  };

  const getCA = rcIndex => {
    let rc = rootCauses[rcIndex];
    let cas = activeIncident.reportComponents.find(
      rc => rc[0] === 'Corrective Actions'
    )?.[1]?.correctiveActions;
    return cas.find(
      ca =>
        ca.label === rc.title && ca.description === rc.description && ca.task
    );
  };

  const removeRc = (i, needToUpdate = false) => {
    let updated = [...rootCauses];
    updated.splice(i, 1);
    setRootCauses(updated);
    setRcToRemove(null);
    if (needToUpdate) {
      let ca = getCA(i);
      let updatedRc = [...activeReport.report];
      updatedRc[0][7] = updated;

      deleteTask(ca?.task);
      updateReportComponent({
        stage: 'action',
        report: updatedRc,
        dontLeavePage: true
      });
    }
    setRcToRemove(null);
    setOpenModalType(null);
  };

  const headerCenter = (
    <ViewOnly
      canUnlock={
        !activeReport?.disabled &&
        rcComplete &&
        (loggedInUser?.accessLevel === 900 ||
          activeIncident.incidentOwner === loggedInUser?._id)
      }
      incident={activeIncident}
      loggedInUser={loggedInUser}
      onClick={() => setOpenModalType('unlockReport')}
      activeReport={activeReport}
    />
  );

  const right =
    rc?.[2]?.assignedTo !== 'unassigned' ? (
      <Assignee
        user={rc?.[2]?.assignedTo}
        options={[
          {
            label: 'Email Assignee',
            onClick: () => setOpenModalType('emailAssignee'),
            visible: true
          },
          {
            label: 'Edit Task',
            visible: activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              setTask({
                ...reportTask[0],
                stage
              })
          }
        ]}
      />
    ) : null;

  const header = (
    <Header
      title={activeIncident && activeIncident.incidentNumber}
      section={rcName}
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      center={headerCenter}
      right={right}
    />
  );

  const cannotSubmit =
    (!canMarkAsComplete(rc?.[1].subSections) || !rootCausesComplete) ??
    !unsavedChanges;

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        cannotSubmit
          ? setMissingModalOpen(true)
          : handleSectionSubmit(undefined)
      }
      cancelButtonClick={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      editing={true}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const leftNavItems = rc?.[1]?.subSections
    ?.map(subsection => ({
      label: subsection[0],
      complete: isSectionComplete(subsection)
    }))
    .concat(
      rootCauses?.map((rc, index) => {
        let num = index + 1;
        return {
          label: `Root Cause ${num}`,
          complete:
            rc?.title?.trim() && rc?.description?.trim() && rc?.category,
          id: `rcCard${index}`
        };
      })
    );

  const left = (
    <LeftNav
      items={leftNavItems}
      showAddRootCause={!rcComplete && !activeReport?.disabled}
      onAddRootCause={() => {
        setUnsavedChanges(true);
        setRootCauses([
          ...rootCauses,
          {
            title: '',
            category: '',
            description: '',
            createCa: false
          }
        ]);
      }}
    />
  );

  return (
    <>
      {incidentLoading && (
        <ReactLoading
          type="spinningBubbles"
          color="#2f8738"
          className="loading"
        />
      )}
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={left}
        lockInfo={lockInfo}
        showFooter={!rcComplete && !activeReport?.disabled}
      >
        {rc?.[1]?.subSections?.map((subsection, index) => (
          <>
            <ReportSection
              name={index}
              reportSectionHeader={subsection[0]}
              fields={subsection[1]}
              key={index}
              answerQuestion={fields => answerQuestion(fields, index)}
              disabled={
                activeReport?.disabled ||
                rcComplete ||
                !hasAccess(loggedInUser, stage, activeIncident) ||
                locked
              }
              canSectionNotApplicable={
                subsection[2] && subsection[2].showNotApplicable
              }
              sectionNotApplicable={
                subsection[3] && subsection[3].notApplicable
              }
              incidentId={activeIncident._id}
              groupId={activeIncident.groupId}
              missingRequired={missingRequired}
            />
            {rootCauses?.map((c, i) => (
              <RootCauseCard
                index={i}
                rc={c}
                disabled={rcComplete}
                updateAnswer={v => updateRootCauses(v, i)}
                removeRc={() => {
                  setRcToRemove(i);
                  let ca = getCA(i);
                  ca ? setOpenModalType('removeRc') : removeRc(i);
                }}
                missingRequired={missingRequired}
              />
            ))}
          </>
        ))}
      </HeaderAndFooter>
      <Modal
        title="Email Assignee"
        titleClassName="blueHeader"
        isOpen={openModalType === 'emailAssignee'}
        submitButtonColor="blue"
        submitButtonText="Send Email"
        onRequestClose={() => {
          setOpenModalType(null);
          setEmailMessage('');
        }}
        submitActions={sendEmail}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          This email will go through the iReportSource system for record keeping
          purposes.
        </div>
        <Textbox
          fieldLabel="Email Content"
          type="textarea"
          placeholder="Type email content here"
          currentValue={emailMessage}
          onChange={setEmailMessage}
        />
      </Modal>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={handleDiscard}
        savingWhat="a report"
      />
      <MarkAsCompleteModal
        isOpen={openModalType === 'markComplete'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => handleSectionSubmit(true)}
        cancelActions={() => handleSectionSubmit(false)}
      />
      <Modal
        title="Unlock and Re-Open Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'unlockReport'}
        submitButtonColor="blue"
        submitButtonText="Re-open Report"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => reopenReportSection()}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Unlocking this report will allow you –and users with edit permission–
          to make changes and additions to the report.{' '}
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            This also means that the report will no longer be considered
            completed until it is filled out, saved, and marked as complete once
            more. Re-Opening this report will also remove the currently assigned
            user.
          </span>{' '}
          You can reassign this report to the same or a different user as a new
          task.
        </div>
      </Modal>
      <Modal
        isOpen={openModalType === 'removeRc'}
        title="Remove Root Cause"
        onRequestClose={() => setOpenModalType(null)}
        titleClassName="redHeader"
        submitButtonText="Remove"
        submitActions={() => removeRc(rcIndexToRemove, true)}
        submitButtonColor="red"
      >
        <div>
          The root cause you are removing has a corrective action associated
          with it. Removing this root cause will delete the associated
          corrective action. Are you sure you want to delete it?
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Root Cause"
      />
    </>
  );
}
