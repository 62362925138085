import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isValidObjectId } from '../../utils/formValidation';

import { getLoadedUsers, getAllCompanyUsers } from '../../selectors/users';

export const UserCell = ({ value }) => {
  const users = useSelector(getLoadedUsers, shallowEqual);
  const companyUsers = useSelector(getAllCompanyUsers, shallowEqual);

  const getUserName = useCallback(
    (accessorFunc, row) => {
      let userId =
        typeof accessorFunc === 'function' ? accessorFunc(row) : accessorFunc;

      // this is for the incident columns where the answers might not be employeeIds
      if (userId === 'Not Applicable') return 'Not Applicable';
      if (userId === '(blank)') return '';
      // some old incident questions were saving it as an array
      if (Array.isArray(userId)) userId = userId[0];
      let user;
      if (isValidObjectId(userId)) {
        user = users[userId];

        if (!user) {
          if (companyUsers) {
            user = companyUsers.find(u => u._id === userId.toString());
          } else {
            return 'Unknown user';
          }
        }
      } else {
        return userId;
      }

      if (!user?._id) return '--';
      if (user.loading) return 'Loading user';
      if (user.error) return 'Unknown user';
      return `${user.firstName} ${user.lastName}`;
    },
    [users, companyUsers]
  );

  let v = value;
  if (!Array.isArray(v)) v = [v];
  return <>{v.map(getUserName).join(', ')}</>;
};

export function filterUsers(users, rows, columnIds, filter) {
  if (!filter.trim()) return rows;
  const texts = filter
    .trim()
    .split(' ')
    .map(t => t.toLowerCase().trim());
  const candidates = Object.keys(users).filter(u => {
    let firstName = (users[u].firstName ?? '').toLowerCase();
    let lastName = (users[u].lastName ?? '').toLowerCase();
    return texts.every(t => firstName.includes(t) || lastName.includes(t));
  });
  return rows.filter(r => {
    const value = r.values[columnIds[0]];
    if (Array.isArray(value)) {
      if (Array.isArray(value[0])) {
        return candidates?.some(c =>
          value.map(v => v[0]).includes(users[c]?._id)
        );
      } else {
        return candidates?.some(c => value.includes(users[c]?._id));
      }
    } else {
      return candidates?.some(c => value === users[c]?._id);
    }
  });
}

export function sortUsers(users, rowA, rowB, columnId) {
  let aId = rowA.values[columnId];
  let bId = rowB.values[columnId];
  if (Array.isArray(aId)) aId = aId[0];
  if (Array.isArray(bId)) bId = bId[0];

  const aUser = users.find(u => u?._id === aId);
  const bUser = users.find(u => u?._id === bId);

  if (!aUser && !bUser) return 0;
  if (!aUser) return -1;
  if (!bUser) return 1;

  const aName = `${aUser.firstName} ${aUser.lastName}`;
  const bName = `${bUser.firstName} ${bUser.lastName}`;

  return aName.localeCompare(bName, 'en', {
    sensitivity: 'base',
    numeric: true
  });
}
