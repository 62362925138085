import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generateExceptionList } from '../../../api/v4';
import {
  getActiveLocationId,
  getActiveProject
} from '../../../selectors/company';
import Card from '../../../components/Card';
import HierarchySelector from '../../../components/HierarchySelector';
import List from '../../../components/List';
import { Dropdown, Button } from '../../../components/inputs';

import styles from '../covid.module.scss';

export default function Exception() {
  const group = useSelector(getActiveLocationId);
  const project = useSelector(getActiveProject);
  const [dateRange, setDateRange] = useState(null);
  const [groupId, setGroupId] = useState(group !== 0 ? group._id : null);
  const [projectId, setProjectId] = useState(project?._id ?? null);
  const [data, setData] = useState([]);
  const [ranReport, setRanReport] = useState(false);

  const getDateOptions = () => {
    const months = getMonths();
    let options = [
      {
        label: 'Last week',
        value: 'lastWeek'
      },
      {
        label: 'This week',
        value: 'currentWeek'
      },
      {
        label: 'This month',
        value: 'thisMonth'
      }
    ];

    // eslint-disable-next-line no-unused-expressions
    months?.forEach(m => options.push({ label: m, value: m }));

    options.push({
      label: 'This year',
      value: 'year'
    });

    return options;
  };

  const getMonths = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    var currentMonth = new Date().getMonth();
    const validMonths = months.filter((m, i) => i < currentMonth);
    return validMonths;
  };

  const columns = [
    { key: 'employeeId', datatype: 'users', label: 'EmployeeName' },
    {
      key: 'numberOfTestsMissed'
    }
  ];

  const fetchExceptionReport = () => {
    generateExceptionList({ dateRange, projectId, groupId }).then(response => {
      setRanReport(true);
      setData(response);
    });
  };

  return (
    <>
      <Card wide>
        <Dropdown
          options={getDateOptions()}
          fieldLabel="Date"
          currentValue={dateRange}
          isRequired
          onChange={value => {
            setDateRange(value);
          }}
        />
        <HierarchySelector
          onGroupChange={value => setGroupId(value)}
          groupId={groupId}
          groupDisabled={group !== 0}
          onProjectChange={value => setProjectId(value)}
          projectId={projectId}
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
          projectDisabled={project}
          groupRequired={false}
        />
        <Button
          color="blue"
          text="Update Report"
          onClick={() => fetchExceptionReport()}
          inputClassName={styles.button}
          disabled={!dateRange}
        />
      </Card>
      <Card wide>
        {ranReport ? (
          <List
            saveKey="covidExceptionResults"
            data={data}
            dataIsHash
            settings={columns}
          />
        ) : (
          <p> Fill Fields above to Generate Report</p>
        )}
      </Card>
    </>
  );
}
