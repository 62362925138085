import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getActiveCompany } from '../../../selectors/company';
import history from '../../../history';
import { fetchQuizzes, archiveMany } from '../../../api/v4';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import { Dropdown } from '../../../components/inputs';
import TypeSelection from '../../../components/TypeSelection';

export default function QuizList() {
  const [quizzes, setQuizzes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [quizDropdown, setQuizDropdown] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [listType, setListType] = useState('Active');

  const company = useSelector(getActiveCompany);

  useEffect(() => {
    fetchQuizzes(company._id).then(response => {
      let quizzes = response?.filter(r => !r.isNsc);
      setQuizzes(quizzes);
      setQuizDropdown(
        quizzes
          ?.filter(quiz => !quiz.isArchived)
          .map(r => {
            return { value: r._id, label: r.title };
          })
      );
    });
  }, [company]);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'quizTemplate',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchQuizzes().then(response => {
        let quizzes = response?.filter(r => !r.isNsc);
        setQuizzes(quizzes);
        setQuizDropdown(
          quizzes
            ?.filter(quiz => !quiz.isArchived)
            .map(r => {
              return { value: r._id, label: r.title };
            })
        );
      })
    );
  };

  const header = (
    <Header
      section="Quiz Templates"
      pageActionOptions={[
        {
          label: 'Create Quiz Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            history.push('/app/quiz/quizTemplate');
          }
        },
        {
          label: 'Duplicate Quiz',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: quizDropdown?.length > 0
        }
      ]}
    />
  );

  const columns = [
    {
      key: 'title',
      label: 'Quiz Title'
    },
    {
      key: 'updatedAt',
      label: 'Last Updated At',
      datatype: 'date'
    }
  ];

  const listTypeFilter =
    listType === 'Archived'
      ? quizzes.filter(quiz => quiz.isArchived)
      : quizzes.filter(quiz => !quiz.isArchived);

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={setListType}
        selected={listType}
      />
      <List
        saveKey="quizList"
        dataIsHash
        data={listTypeFilter}
        settings={columns}
        rowClick={row => history.push(`/app/quiz/quizTemplate/${row._id}`)}
        getRowId={r => r._id}
        actions={[
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Templates`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() =>
          history.push(`/app/quiz/quizTemplate/${currentTemplate}/true`)
        }
        disableSubmit={!currentTemplate}
      >
        <Dropdown
          options={quizDropdown}
          fieldLabel="Quiz Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a quiz"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
