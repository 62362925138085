import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export class SaveChangesModal extends Component {
  render() {
    return (
      <Modal
        title="Unsaved Changes"
        titleClassName="redHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="red"
        submitButtonText="Discard Changes"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
      >
        <div className={styles.text}>
          You are navigating away from {this.props.savingWhat || 'a page'} that
          you have made changes to.{' '}
          <span className={styles.warningText}>
            Leaving without saving will result in these changes being discarded.{' '}
          </span>
          If you wish to keep your changes, click “Cancel”, and then hit “Save”
          at the bottom of the screen.
        </div>
      </Modal>
    );
  }
}

export default SaveChangesModal;
