import React from 'react';
import { useState } from 'react';
import classnames from 'classnames/bind';
import { cloneDeep } from 'lodash';

import styles from './rearranger.module.scss';

export const Rearranger = ({
  className,
  items,
  onChange,
  renderItem,
  disabled,
  disabledItems,
  errorItems,
  onDuplicate,
  inline,
  itemStyles,
  allowEdit,
  removeRow,
  hideDuplicate,
  handleEditRow,
  nonDeletableItems
}) => {
  const [dragIdx, setdragIdx] = useState(null);
  const [dragTargetIdx, setdragTargetIdx] = useState(null);

  const onDragStart = (e, index) => {
    setdragIdx(index);
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', index);
  };

  const onDragOver = (e, index) => {
    if (e.preventDefault) e.preventDefault();
    setdragTargetIdx(index);
  };

  const onDragLeave = (e, index) => {
    setdragTargetIdx(null);
  };

  const onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (dragIdx === dragTargetIdx || dragTargetIdx === null) {
      setdragIdx(null);
      setdragTargetIdx(null);
      return;
    }

    let fields = [...items];
    const tmp = fields.splice(dragIdx, 1)[0];
    const itemAtTarget = fields[dragTargetIdx];
    if (itemAtTarget) {
      const targetId = itemAtTarget?._id ?? itemAtTarget?.masterTemplateId;
      const itemDisabled =
        disabled || disabledItems?.includes(targetId?.toString());
      const nonDeletableItem = nonDeletableItems?.includes(
        targetId?.toString()
      );
      const draggable = !itemDisabled && !nonDeletableItem;
      if (nonDeletableItem || !draggable) {
        setdragIdx(null);
        setdragTargetIdx(null);
        return;
      }
    }
    let target = dragTargetIdx;
    if (target > dragIdx) target--;
    fields.splice(dragTargetIdx, 0, tmp);

    onChange(fields);

    setdragIdx(null);
    setdragTargetIdx(null);
  };

  const duplicateRow = rowIndex => {
    let fields = [...items];
    let newRowIndex = rowIndex + 1;
    var rowObject = (onDuplicate || cloneDeep)(items[rowIndex]);

    fields.splice(newRowIndex, 0, rowObject);

    onChange(fields);
  };

  const removeRowFromTemplate = index => {
    if (removeRow) {
      removeRow(index);
    } else {
      let fields = [...items];
      fields.splice(index, 1);
      onChange(fields);
    }
  };

  return (
    <ul className={classnames(styles.container, className ? className : '')}>
      {items?.map((item, index) => {
        const itemDisabled = disabled || disabledItems?.includes(item._id);

        const itemError = errorItems?.includes(item._id);
        // trainings that we want to allow edits to in courses but cannot deleted or rearrange
        const nonDeletableItem = nonDeletableItems?.includes(item._id);
        const nonDraggableItem = !itemDisabled && !nonDeletableItem;
        return (
          <li
            className={classnames(
              styles.listItem,
              itemStyles,
              dragTargetIdx === index && dragIdx !== index ? styles.target : '',
              itemError ? styles.errorBox : ''
            )}
            key={index}
            onDragOver={event => onDragOver(event, index)}
            onDragLeave={event => onDragLeave(event, index)}
            onDrop={event => onDrop(event, index)}
            data-cy={`card${index}`}
            draggable={nonDraggableItem}
            onDragStart={event => onDragStart(event, index)}
          >
            <div
              className={classnames(
                styles.item,
                itemDisabled && styles.disabled
              )}
            >
              <div className={styles.buttonBar}>
                {nonDeletableItem ? null : (
                  <img
                    src={require('../../assets/images/drag.png')}
                    alt="drag to new position"
                    className={styles.dragImage}
                  />
                )}
                {inline && !itemDisabled ? (
                  <div className={styles.field}>{renderItem(item, index)}</div>
                ) : null}
                <div className={styles.dupAndRemoveImages}>
                  {allowEdit ? (
                    <img
                      src={require('../../assets/images/Pen.png')}
                      alt="delete question"
                      onClick={() => handleEditRow(item)}
                      className={styles.editImage}
                      data-cy="editButton"
                    />
                  ) : null}
                  {hideDuplicate ? null : (
                    <img
                      src={require('../../assets/images/duplicate.png')}
                      alt="duplicate question"
                      onClick={() => duplicateRow(index)}
                      className={styles.duplicateImage}
                    />
                  )}
                  {nonDeletableItem ? null : (
                    <img
                      src={require('../../assets/images/remove.png')}
                      alt="delete question"
                      onClick={() => removeRowFromTemplate(index)}
                      className={styles.removeImage}
                      data-cy="removeButton"
                    />
                  )}
                </div>
              </div>
            </div>
            {!inline || itemDisabled ? (
              <div className={styles.field}>{renderItem(item, index)}</div>
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};
