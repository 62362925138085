import React from 'react';
import ReactLoading from 'react-loading';
import classnames from 'classnames/bind';

import styles from './loading.module.scss';

export const Loading = ({
  size = 128,
  className,
  mode,
  text,
  width,
  height,
  skipDiv
}) => {
  let style = {};
  let classes = classnames(styles.loading, className);

  if (mode === 'fixedSize') {
    style.width = width;
    style.height = height;
  } else if (mode === 'auto') {
    size = '100%';
  }

  const icon = (
    <ReactLoading
      width={size}
      height={size}
      type="bubbles"
      className={styles.blue}
    />
  );
  if (skipDiv) return icon;

  return (
    <div data-testid="loadingSign" className={classes} style={style}>
      {text && 'Loading'}
      {icon}
    </div>
  );
};

export default Loading;
