import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131777)">
      <path d="M10.9227 1.94376C4.90653 1.94376 0 6.85523 0 12.8711V50.6838C0 56.6999 4.9068 61.6264 10.9227 61.6264H13.95V75.5764C13.9552 76.2363 14.2214 76.867 14.6901 77.3316C15.1586 77.7962 15.7919 78.0566 16.4519 78.0561C17.1118 78.0558 17.7446 77.7944 18.2125 77.3293L33.9352 61.626H69.0572C75.0733 61.626 79.9999 56.6996 79.9999 50.6833V12.8706C79.9999 6.8545 75.0735 1.94263 69.0572 1.94263L10.9227 1.94376ZM40.4047 13.9118C43.3484 14.0274 46.206 15.2664 48.3147 17.481C51.5081 20.8347 52.5975 25.9357 50.4339 30.235C48.5879 33.9032 45.7527 35.5663 43.9397 36.49C43.0331 36.952 42.4167 37.2962 42.26 37.4324C42.1035 37.5686 42.2209 37.2447 42.2209 37.5447V41.0848C42.2358 41.7575 41.979 42.4077 41.5087 42.8887C41.0381 43.3697 40.3938 43.6408 39.7209 43.6408C39.0481 43.6408 38.4037 43.3697 37.9332 42.8887C37.4629 42.4078 37.2061 41.7575 37.2209 41.0848V37.5447C37.2209 36.0171 38.0241 34.4941 38.9738 33.6676C39.9238 32.8413 40.8418 32.4635 41.6691 32.0418C43.3243 31.1986 44.7142 30.4814 45.9709 27.9843C47.0941 25.7525 46.5254 22.8746 44.6769 20.9336C42.8488 19.0133 40.0652 18.3909 37.5923 19.3466C35.1191 20.3021 33.4847 22.6383 33.4225 25.2888C33.4058 25.9529 33.1261 26.5828 32.6449 27.0403C32.1636 27.4979 31.5204 27.7455 30.8566 27.7286C30.1928 27.7117 29.5629 27.4317 29.1053 26.9505C28.648 26.4692 28.4006 25.8257 28.4175 25.1619C28.5272 20.5031 31.4535 16.3579 35.8002 14.6786C36.8869 14.2588 38.0143 14.0109 39.1401 13.9315C39.5622 13.9013 39.9841 13.8955 40.4047 13.9117L40.4047 13.9118ZM39.7211 45.3764C40.4427 45.3757 41.1349 45.6621 41.645 46.1723C42.1552 46.6825 42.4416 47.3746 42.4409 48.0962C42.4403 48.8168 42.1534 49.5077 41.6432 50.017C41.1333 50.5262 40.4419 50.8118 39.7211 50.811C39.0013 50.8105 38.3112 50.5241 37.8021 50.0152C37.293 49.5061 37.0067 48.816 37.0062 48.0962C37.0054 47.3754 37.2911 46.6839 37.8002 46.1738C38.3096 45.6639 39.0005 45.3769 39.721 45.3764L39.7211 45.3764Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131777">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);
