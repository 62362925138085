import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Card from '../../components/Card';
import { getLoadedUsers } from '../../selectors/users';
import { Textbox, Button } from '../../components/inputs';
import UserBadge from '../../components/UserBadge';
import { getMyUserId } from '../../selectors/users';
import ReplyArrow from '../../svgs/replyArrow';
import ChevronFilled from '../../svgs/chevronFilled.js';

import styles from './comments.module.scss';
const bStyles = classnames.bind(styles);

let allComments = [];
let absoluteLeft = 0;

const getReplies = thisComment => {
  let replies = [];
  for (let comment of allComments) {
    if (comment.parentId === thisComment._id) {
      replies = replies.concat(comment);
    }
  }
  return replies;
};

const Comment = ({
  comment,
  commentGroupRef,
  hiddenThreads = true,
  onReply,
  replies,
  loggedInUserId,
  parentAuthor = null,
  parentTextRef = null,
  type = 'parent'
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const [threadHeight, setThreadHeight] = useState(null);

  const textRef = useRef(null);
  const threadRefTop = useRef(null);
  const threadRefBottom = useRef(null);

  const numReplies = replies?.length && isHidden ? ` (${replies.length})` : '';
  const myComment = loggedInUserId === comment.createdByUserId;

  const threadEvent = new Event('resizeThread');

  useLayoutEffect(() => {
    const { current: threadDivTop } = threadRefTop;
    const { current: threadDivBottom } = threadRefBottom;

    const getLeftValue = element => {
      const textBounds = element.getBoundingClientRect();
      return { x: textBounds.left, y: textBounds.top + textBounds.height / 2 };
    };

    const threadLineSpecs = (child, parent) => {
      const childVals = getLeftValue(child);
      const parentVals = getLeftValue(parent);
      const threadHeight = childVals.y - parentVals.y;

      const leftXVal = parentVals.x - absoluteLeft;

      const groupTop = commentGroupRef.current.getBoundingClientRect().top;
      const threadTop = commentGroupRef.current && parentVals.y - groupTop;

      return {
        height: threadHeight - 2,
        left: leftXVal - 12,
        top: threadTop + 2
      };
    };

    if (type === 'parent') {
      const commentElement = textRef.current.parentElement.parentElement;
      absoluteLeft = getLeftValue(commentElement).x;
    }

    if (type === 'child' && commentGroupRef.current) {
      const parentRef = parentTextRef.current;

      const specs = threadLineSpecs(textRef.current, parentRef);

      setThreadHeight(specs.height);

      threadDivTop.style.top = `${specs.top}px`;
      threadDivBottom.style.top = `${specs.top}px`;
      threadDivTop.style.left = `${specs.left}px`;
      threadDivBottom.style.left = `${specs.left}px`;

      threadDivTop.addEventListener('resizeThread', setThreadHeight);
    }

    return () =>
      threadDivTop.removeEventListener('resizeThread', setThreadHeight);
  }, [type, commentGroupRef, parentTextRef, threadEvent]);

  const handleOpenCloseThread = () => {
    const allThreadLines = document.getElementsByClassName(styles.threadLine);
    for (const line of allThreadLines) {
      line.dispatchEvent(threadEvent);
    }
    setIsHidden(!isHidden);
  };

  return (
    <>
      <div
        className={bStyles(
          styles.comment,
          { myComment: myComment },
          comment._id
        )}
        id={comment._id}
      >
        <div className={styles.content} id={`${comment._id}-content`}>
          <div className={styles.authorRow}>
            {myComment ? (
              'You'
            ) : (
              <UserBadge showImage showName userId={comment.createdByUserId} />
            )}
          </div>
          <p className={styles.text} id={`${comment._id}-text`} ref={textRef}>
            {parentAuthor && (
              <span className={styles.replyName}>{parentAuthor} </span>
            )}
            {comment.value}
          </p>
          <div className={styles.actionTimeRow}>
            <button
              className={styles.replyBlock}
              onClick={() => onReply(comment)}
            >
              <ReplyArrow className={styles.reply} />
              Reply{numReplies}
            </button>
            <div className={styles.timeBlock}>
              {moment(comment.createdAt).format('L LT')}
            </div>
          </div>
        </div>
        <div className={styles.collapser}>
          {replies.length > 0 && (
            <ChevronFilled
              className={bStyles(styles.chevron, { closed: isHidden })}
              onClick={handleOpenCloseThread}
              height="15px"
              width="15px"
            />
          )}
        </div>
      </div>
      <div
        className={bStyles(
          { hidden: hiddenThreads },
          styles.threadLine,
          styles.top
        )}
        ref={threadRefTop}
        style={{ height: threadHeight ? `${threadHeight}px` : 'auto' }}
      />
      <div
        className={bStyles(
          { hidden: hiddenThreads },
          styles.threadLine,
          styles.bottom
        )}
        ref={threadRefBottom}
        style={{ height: threadHeight ? `${threadHeight}px` : 'auto' }}
      />
      {replies?.length > 0 && (
        <div
          className={bStyles(styles.nestedComments, { hidden: isHidden })}
          id={`replies-to-${comment._id}`}
        >
          {replies.map(reply => (
            <Comment
              comment={reply}
              commentGroupRef={commentGroupRef}
              hiddenThreads={isHidden}
              key={reply._id}
              loggedInUserId={loggedInUserId}
              onReply={onReply}
              parentAuthor={
                <UserBadge nameOnly userId={comment.createdByUserId} />
              }
              parentTextRef={textRef}
              replies={getReplies(reply)}
              type="child"
            />
          ))}
        </div>
      )}
    </>
  );
};

const CommentCard = ({ comments, addComment, name, disabled, ...props }) => {
  const [commentText, setCommentText] = useState('');
  const [replyName, setReplyName] = useState('');
  const [replyToComment, setReplyToComment] = useState(null);
  const loggedInUserId = useSelector(getMyUserId);

  const commentGroupRef = useRef(null);
  const inputRef = useRef(null);
  const replyNameRef = useRef(null);

  const users = useSelector(getLoadedUsers);
  allComments = comments;

  useEffect(() => {
    inputRef.current.style.textIndent = '0';
    if (replyName) {
      const rnWidth = replyNameRef.current.getBoundingClientRect().width;
      inputRef.current.style.textIndent = `${rnWidth + 4}px`;
    }
  }, [replyName]);

  const handleReply = parentComment => {
    setReplyName(
      `@${users[parentComment.createdByUserId].firstName} ${
        users[parentComment.createdByUserId].lastName
      }`
    );
    setReplyToComment(parentComment);
  };

  const handleScroll = () => {
    const position = inputRef.current.scrollTop;
    replyNameRef.current.style.top = `-${position}px`;
  };

  return (
    <Card showHeader title="Comments" name={name} {...props}>
      {allComments?.length > 0 ? (
        <div className={styles.commentGroup} ref={commentGroupRef}>
          {allComments.map(
            comment =>
              !comment.parentId && (
                <Comment
                  comment={comment}
                  commentGroupRef={commentGroupRef}
                  key={comment._id}
                  replies={getReplies(comment)}
                  loggedInUserId={loggedInUserId}
                  onReply={handleReply}
                  type="parent"
                />
              )
          )}
        </div>
      ) : (
        <p>No comments yet.</p>
      )}
      <div className={styles.textBoxContainer}>
        <Textbox
          className={styles.textBox}
          currentValue={commentText}
          inputRef={inputRef}
          name="newComment"
          onChange={value => setCommentText(value)}
          onScroll={handleScroll}
          placeholder={replyName ? 'Type reply here' : 'Type comment here'}
          rows={3}
          type="textarea"
          testID="newCommentTextArea"
          disabled={disabled}
        />
        {replyName && (
          <div id={styles.replyName} ref={replyNameRef}>
            <p>{replyName}</p>
            <p
              className={styles.deleteReply}
              onClick={() => {
                setReplyName(``);
                setReplyToComment(null);
                setCommentText('');
              }}
            >
              &times;
            </p>
          </div>
        )}
      </div>
      <Button
        className={styles.submitButton}
        color="blue"
        disabled={!commentText}
        onClick={() => {
          addComment(commentText, replyToComment?._id);
          setCommentText('');
          setReplyName('');
          setReplyToComment(null);
        }}
        text="Submit"
        testID="submitCommentButton"
      />
    </Card>
  );
};

export default CommentCard;
