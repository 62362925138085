import React from 'react';

import classnames from 'classnames/bind';

import styles from './donut.module.scss';

const size = 34;
const renderProgress = (progress, text) => (
  <strong>
    {progress}%
    {text && (
      <>
        <br />
        <span>{text}</span>
      </>
    )}
  </strong>
);

export const Donut = ({
  progress = 0,
  onRender = renderProgress,
  color = 'black',
  text,
  className
}) => (
  <div className={classnames(className, styles.donut, styles[color])}>
    <svg
      className={styles.canvas}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={styles.frame}
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - 1}
      />
      <circle
        className={styles.circle}
        strokeDasharray={`${progress} 100`}
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - 1}
      />
    </svg>
    <div className={styles.text}>{onRender(progress, text)}</div>
  </div>
);
