import React from 'react';
import ReactSwitch from 'react-switch';
import { Input } from '..';

import styles from './switch.module.scss';

export default function Switch(props) {
  const { name, disabled, currentValue, onChange, label, testID } = props;

  return (
    <Input
      name={name}
      disabled={disabled}
      fieldLabel={label}
      labelStyles={styles.label}
      className={styles.container}
      {...props}
    >
      <ReactSwitch
        onChange={onChange}
        checked={currentValue}
        disabled={disabled}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#D9D9D9"
        onHandleColor="#008284"
        offColor="#5F5F5F"
        offHandleColor="#FFF"
        className={styles.switch}
        height={36}
        width={72}
        data-cy={testID}
      />
    </Input>
  );
}
