import {
  CREATE_INCIDENT_REQUEST,
  DELETE_INCIDENT_ATTACHMENT,
  FETCH_INCIDENT_BASICS_REQUEST,
  FETCH_INCIDENT_BASICS_RESPONSE,
  FETCH_INCIDENT_BY_ID_REQUEST,
  SAVE_INCIDENT_BASICS_REQUEST,
  SAVE_INCIDENT_BASICS_RESPONSE,
  SET_ACTIVE_INCIDENT_REQUEST,
  SET_ACTIVE_INCIDENT_RESPONSE,
  UPDATE_INCIDENT_BASICS_REQUEST,
  UPDATE_INCIDENT_BASICS_RESPONSE,
  UPDATE_REPORT_COMPONENT_REQUEST
} from '../constants/actionTypes';

export const fetchIncidentBasicsRequest = payload => ({
  type: FETCH_INCIDENT_BASICS_REQUEST,
  payload
});

export const fetchIncidentBasicsResponse = payload => ({
  type: FETCH_INCIDENT_BASICS_RESPONSE,
  payload
});

export const updateIncidentBasicsRequest = payload => ({
  type: UPDATE_INCIDENT_BASICS_REQUEST,
  payload
});

export const updateIncidentBasicsResponse = payload => ({
  type: UPDATE_INCIDENT_BASICS_RESPONSE,
  payload
});

export const setActiveIncidentRequest = payload => ({
  type: SET_ACTIVE_INCIDENT_REQUEST,
  payload
});

export const setActiveIncidentResponse = payload => ({
  type: SET_ACTIVE_INCIDENT_RESPONSE,
  payload
});

export const deleteIncidentAttachment = payload => ({
  type: DELETE_INCIDENT_ATTACHMENT,
  payload
});

export const fetchIncidentByIdRequest = payload => ({
  type: FETCH_INCIDENT_BY_ID_REQUEST,
  payload
});

export const saveIncidentBasicsRequest = payload => ({
  type: SAVE_INCIDENT_BASICS_REQUEST,
  payload
});

export const saveIncidentBasicsResponse = payload => ({
  type: SAVE_INCIDENT_BASICS_RESPONSE,
  payload
});

export const createIncidentRequest = payload => ({
  type: CREATE_INCIDENT_REQUEST,
  payload
});

export const updateReportComponentRequest = payload => ({
  type: UPDATE_REPORT_COMPONENT_REQUEST,
  payload
});
