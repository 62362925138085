import {
  FETCH_SAFETY_WALKS_RESPONSE,
  SET_ACTIVE_SAFETY_WALK
} from '../constants/actionTypes';

export const initialState = {
  list: [],
  loading: false,
  activeSafetyWalk: {}
};

const safetyWalks = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SAFETY_WALKS_RESPONSE:
      return {
        ...state,
        list: payload
      };

    case SET_ACTIVE_SAFETY_WALK:
      return {
        ...state,
        activeSafetyWalk: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default safetyWalks;
