import { put, call } from 'redux-saga/effects';
import history from '../history';
import { setActiveTrainingResponse } from '../actions/training';

export function* setActiveTraining(apiV4, { payload }) {
  try {
    const childTrainings = yield call(
      apiV4.fetchTrainingsByMasterId,
      payload._id
    );
    const isFromSummary = payload.isFromSummary;

    if (payload.stay) {
      payload = yield call(apiV4.fetchTrainingById, payload._id);
      payload.stay = true;
      payload.isFromSummary = isFromSummary;
    }

    const childTrainingAttachments = [];
    const childQuizzes = [];
    let childCompletionDates = [];

    childTrainings.forEach(child => {
      child.attachments.forEach(attachment => {
        childTrainingAttachments.push({
          ...attachment,
          assignedTo: child.assignedTo[0],
          approveReject: child.approveReject
        });
      });
      childQuizzes.push({
        ...child,
        assignee: child.assignedTo[0],
        completionDate: child.completionDate
      });
      childCompletionDates.push({
        assignedTo: child.assignedTo[0],
        completionDate: child.completionDate
      });
    });

    yield put(
      setActiveTrainingResponse({
        ...payload,
        childAttachments: childTrainingAttachments,
        childQuizzes,
        childCompletionDates
      })
    );

    if (payload.isApprove) {
      history.push('/app/training/approve');
    } else if (!payload.stay) {
      history.push('/app/training/trainingSummary');
    }
  } catch (error) {
    console.error(error);
  }
}
