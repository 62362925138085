import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M48.6872 26.1253C48.6872 13.0627 38.1245 2.43799 24.9998 2.43799C11.8752 2.43799 1.3125 13.0633 1.3125 26.1253C1.3125 39.1873 11.8752 49.8127 24.9998 49.8127C38.1245 49.8127 48.6872 39.1873 48.6872 26.1253ZM38.1245 20.3753L25.8118 32.5627L22.4368 35.9377C21.3118 37.0002 19.4994 37.0002 18.4368 35.8752L14.9994 32.5626L11.8744 29.3752C11.3744 28.8127 11.0618 28.1252 11.0618 27.3752C11.0618 26.6252 11.3743 25.9377 11.9368 25.3752C13.0618 24.3126 14.8118 24.3126 15.9368 25.3752L20.4994 29.9377L34.2494 16.375C34.8119 15.8125 35.4994 15.5625 36.2494 15.5625C36.9994 15.5625 37.7494 15.875 38.2494 16.375C38.7494 16.9375 39.0619 17.625 39.0619 18.375C38.9369 19.125 38.687 19.8753 38.1245 20.3753Z" />
    <path d="M54.9998 30.1873C54.3748 30.1873 53.7498 30.1873 53.1873 30.2498C52.9998 31.6873 52.6873 33.0624 52.2498 34.4373C53.1248 34.3123 54.0624 34.2498 54.9998 34.2498C65.8125 34.2498 74.6872 43.0625 74.6872 53.9371C74.6872 64.8118 65.8745 73.6245 54.9998 73.6245C44.1252 73.6245 35.3125 64.8118 35.3125 53.9371C35.3125 53.5621 35.3125 53.1246 35.375 52.7497C34.0625 53.2497 32.6875 53.6871 31.3125 53.9997C31.3125 67.0623 41.9378 77.687 55.0625 77.687C68.1871 77.687 78.7498 67.0617 78.7498 53.9997C78.6873 40.8123 68.0625 30.1873 54.9998 30.1873Z" />
    <path d="M41.8745 53.0627C41.312 53.6252 40.9995 54.3127 40.9995 55.0627C40.9995 55.8127 41.312 56.5627 41.812 57.0627L44.937 60.2501L48.3745 63.5627C49.4995 64.6877 51.312 64.6877 52.3745 63.6252L55.7495 60.2502L68.1242 48.1248C68.6867 47.6248 68.9367 46.8748 68.9367 46.1248C68.9367 45.3748 68.6242 44.6248 68.1242 44.1248C67.5617 43.5623 66.8742 43.3123 66.1242 43.3123C65.3742 43.3123 64.6242 43.6248 64.1242 44.1248L50.3742 57.6875L45.8742 53.125C44.7492 52 42.9995 52.0002 41.8745 53.0627Z" />
  </svg>
);
