const ghsLabelMapper = ghsLabel => {
  switch (ghsLabel) {
    case '0':
    case 0:
      return require(`../assets/images/Flame over circle.png`);
    case '1':
    case 1:
      return require(`../assets/images/skullAndCrossBones.png`);
    case '2':
    case 2:
      return require(`../assets/images/Corrosion.png`);
    case '3':
    case 3:
      return require(`../assets/images/Flame.png`);
    case '4':
    case 4:
      return require(`../assets/images/Exlamation Mark.png`);
    case '5':
    case 5:
      return require(`../assets/images/Health Hazard.png`);
    case '6':
    case 6:
      return require(`../assets/images/Exploding Bomb.png`);
    case '7':
    case 7:
      return require(`../assets/images/Environment.png`);
    case '8':
    case 8:
      return require(`../assets/images/Gas cylinder.png`);
    default:
      return '';
  }
};

export default ghsLabelMapper;
