import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';
import { checkForValidLink, externalStatementLogin } from '../../api/v4';
import { authSuccess } from '../../actions/auth';
import { setActiveCompany } from '../../actions/company';
import { Textbox, Button } from '../../components/inputs';
import Modal from '../../components/Modal';

import styles from './styles.module.scss';

export default function TemporaryLogin() {
  const { statementId } = useParams();
  const dispatch = useDispatch();
  const [login, setLogin] = useState({ email: null, password: null });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    checkForValidLink(statementId).then(setModal, e => {
      history.push(`/`);
    });
  }, [statementId, dispatch]);

  const handleTempLogin = () => {
    if (!(login.email?.trim() && login.password?.trim())) return;
    externalStatementLogin(login).then(r => {
      sessionStorage.setItem('token', r.token);
      sessionStorage.setItem('statement', JSON.stringify(r.data));
      dispatch(setActiveCompany(r.data.company));
      dispatch(authSuccess());
      history.push(`/app/externalWitness/${r.data?.externalToken}`);
    });
  };

  return (
    <div>
      <div className={styles.loginWholePage}>
        <div className={styles.loginFlexbox}>
          <div className={styles.login}>
            <h2>Log In</h2>
            <Textbox
              fieldLabel="Email:"
              currentValue={login.email}
              onChange={v => setLogin({ ...login, email: v })}
            />
            <Textbox
              fieldLabel="Password:"
              currentValue={login.password}
              onChange={v => setLogin({ ...login, password: v })}
              enter={handleTempLogin}
            />
            <Button text="Log in" onClick={handleTempLogin} color="green" />
          </div>
        </div>
      </div>
      <Modal
        title="Invalid or Expired Link"
        titleClassName="redHeader"
        hideCancelButton
        onRequestClose={window.close}
        isOpen={modal}
        hideButtons
      >
        {`Your Witness Statement link has either expired or been marked Not Applicable
         to the incident it pertains to.`}
        <br /> <br />
        {`If another link is required or you think it
         may have been marked in error, please contact 
        ${modal.name} at ${modal?.company} for a new one.`}
      </Modal>
    </div>
  );
}
