import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../history';
import { fetchDocumentsByType } from '../../api/v4';
import { getActiveFolderSelector } from '../../selectors/documents';
import DocumentFolder from '../../components/DocumentFolder';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { getActiveCompany } from '../../selectors/company';

export default function OSHAInfoList() {
  const [documents, setDocuments] = useState([]);

  const activeFolder = useSelector(getActiveFolderSelector);
  const activeCompany = useSelector(getActiveCompany);

  useEffect(() => {
    fetchDocumentsByType('oshaInfoDoc', activeFolder?._id).then(setDocuments);
  }, [activeFolder]);

  const header = (
    <Header
      title={`${
        activeCompany.reportingType === 2 ? 'BWC' : 'OSHA'
      } Information Documents`}
    />
  );

  const columns = [
    { key: 'name', label: 'Title' },
    { key: 'label', label: 'Information' },
    { key: 'attachments', datatype: 'attachments' }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <ScrollToTopOnMount />
      <DocumentFolder content={documents} docType="oshaInfoDoc" notEditable />
      <List
        data={documents}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/oshaInfo/${row._id}`)}
        saveKey="oshaInfoList"
      />
    </HeaderAndFooter>
  );
}
