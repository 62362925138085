import { ADD_MESSAGE, REMOVE_MESSAGE } from '../constants/actionTypes';

export const initialState = [];

const messages = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_MESSAGE:
      return [...state, payload];
    case REMOVE_MESSAGE:
      return state?.slice(1) || [];
    default:
      return state;
  }
};

export default messages;
