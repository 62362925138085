import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import BlockText from '../../inputs/BlockText';
import Card from '../../Card';
import HoverImage from '../../HoverImage';
import Modal from '../../Modal';
import { Rearranger } from '../../Rearranger';
import { Textbox, Dropdown, Button, Checkbox, InputRow } from '../../inputs';
import TableQuestion from '../../inputs/TableQuestion';
import { RichTextTextbox } from '../../inputs/RichTextEditor';
import {
  DOCUMENT_TYPES_CUSTOMIZABLE,
  DOCUMENT_TYPES_CUSTOMIZABLE_PLUS
} from '../../../constants/constants';

import styles from './boTemplate.module.scss';

export default function TemplateCard({
  document,
  disabled,
  onChange,
  duplicating,
  editing,
  type = 'behavior',
  templateType = 'Behavior Observation',
  blockTextNote = 'Each Observation will automatically have fields for Employee Observed, Supervisor Observing, Date Observed and Job task',
  missingRequired,
  invalidTitle
}) {
  const [rowIndex, setRowIndex] = useState(0);
  const [selectedFieldType, setSelectedFieldType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [activeOptions, setActiveOptions] = useState([]);

  const handleQuestionChange = (index, questionLabel, isTable = false) => {
    let fields = document?.fields ? [...document.fields] : [];
    let answers = questionLabel.answer;

    if (questionLabel.field === 'options' && !isTable) {
      answers = answers
        .filter(answer => answer.option)
        .map(answer => answer.option);
    }

    fields[index][questionLabel.field] = answers;

    setActiveOptions([]);
    setRowIndex('');
    setModalOpen(false);
    setSelectedFieldType('');

    onChange({ fields });
  };

  const addRowsToTemplate = numberOfRows => {
    let fields = document?.fields ? [...document.fields] : [];

    for (let i = 1; i <= numberOfRows; i++) {
      fields.push({
        type: '',
        label: '',
        options: [],
        required: false
      });

      onChange({ fields });
    }
  };

  const setChangeOptions = (options, rowIndex, fieldType) => {
    options = options.map(option => {
      if (option.option) return option;
      if (option && duplicating) {
        return {
          option,
          editable: true
        };
      }
      return {
        option,
        editable: false
      };
    });

    setModalOpen(true);
    setRowIndex(rowIndex);
    setActiveOptions(options);
    setSelectedFieldType(fieldType.value);
  };

  const renderItem = (item, index) => {
    const notEditable = '_id' in item;
    const buttonOptions = [
      'dropdown',
      'multiselect',
      'radiobuttons',
      'checkbox'
    ];
    const optionsIncludeItem = buttonOptions.includes(item.type);

    return (
      <>
        <RichTextTextbox
          currentValue={item.label}
          onChange={answer =>
            handleQuestionChange(index, { field: 'label', answer })
          }
          fieldLabel="Question"
          placeholder="Type your question here."
          disabled={disabled}
          isRequired
          touched={
            missingRequired && item.label.replace(/\u200B/g, '')?.trim() === ''
          }
          error
        />
        <Dropdown
          options={
            type === 'behavior'
              ? DOCUMENT_TYPES_CUSTOMIZABLE
              : DOCUMENT_TYPES_CUSTOMIZABLE_PLUS
          }
          fieldLabel="Type of Input"
          onChange={values =>
            handleQuestionChange(index, { field: 'type', answer: values })
          }
          isRequired
          placeholder="Choose a question type."
          currentValue={item.type}
          disabled={disabled || notEditable}
          touched={missingRequired && !item.type}
        />
        {item.type !== 'blockOfText' && type === 'behavior' && (
          <Checkbox
            fieldLabel="Is Required"
            onChange={v =>
              handleQuestionChange(index, {
                field: 'required',
                answer: v
              })
            }
            currentValue={item.required}
            disabled={disabled || notEditable}
          />
        )}
        {item.type === 'table' && (
          <TableQuestion
            options={item.options}
            handleChange={v =>
              handleQuestionChange(
                index,
                {
                  field: 'options',
                  answer: v.options
                },
                true
              )
            }
            disabled={disabled || notEditable}
            missingRequired={missingRequired}
          />
        )}
        {item.type !== 'blockOfText' && type === 'behavior' && (
          <Checkbox
            fieldLabel="Allow Additional Comments"
            onChange={v =>
              handleQuestionChange(index, {
                field: 'allowAdditionalComments',
                answer: v
              })
            }
            currentValue={item.allowAdditionalComments}
            disabled={disabled || notEditable}
          />
        )}
        {optionsIncludeItem && (
          <Button
            text="View Options"
            color="blue"
            onClick={() => setChangeOptions(item.options, index, item.type)}
            disabled={disabled}
          />
        )}
        {optionsIncludeItem && item.options.length === 0 && (
          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
            * Must add at least one option
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Card title="Behavior Observation Template">
        <Textbox
          currentValue={document?.title}
          placeholder={`Title of ${templateType}`}
          disabled={disabled}
          isRequired
          onChange={(answer, e) => onChange({ title: answer }, e)}
          fieldLabel={`Title of ${templateType}`}
          touched={(missingRequired && !document?.title) || invalidTitle}
          errorMessage={invalidTitle ? 'Invalid Title' : null}
        />
        <Textbox
          fieldLabel="Instructions"
          onChange={v => onChange({ instructions: v })}
          currentValue={document?.instructions}
          disabled={disabled}
        />
        {type === 'behavior' ? (
          <Checkbox
            fieldLabel="Is this a Confidential Document?"
            onChange={v => onChange({ isConfidential: v })}
            currentValue={document.isConfidential}
            disabled={disabled}
          />
        ) : null}
        <BlockText blockOfText={blockTextNote} />
        <Rearranger
          className={styles.container}
          items={document?.fields ?? []}
          onChange={fields => onChange({ fields })}
          renderItem={renderItem}
          disabled={disabled}
          onDuplicate={row => {
            const clone = cloneDeep(row);
            delete clone._id;
            return clone;
          }}
        />
        <InputRow className={styles.footer}>
          <Button
            text="Add Row"
            color="blue"
            onClick={() => addRowsToTemplate(1)}
            disabled={disabled}
          />
          <Button
            text="Add Five Rows"
            color="blue"
            onClick={() => addRowsToTemplate(5)}
            disabled={disabled}
          />
        </InputRow>
      </Card>
      <Modal
        title="Change Options"
        titleClassName="blueHeader"
        isOpen={modalOpen}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() => {
          setModalOpen(false);
          setActiveOptions([]);
        }}
        submitActions={() =>
          handleQuestionChange(rowIndex, {
            field: 'options',
            answer: activeOptions
          })
        }
        disableSubmit={
          !activeOptions?.length ||
          activeOptions?.filter(o => !o.option?.trim()?.length)?.length
        }
      >
        <div className={styles.modal}>
          {activeOptions.map((option, index) => (
            <div className={styles.options} key={index}>
              <Textbox
                testID={`addOption${index}`}
                currentValue={option.option}
                onChange={v => {
                  const currentOptions = activeOptions;
                  currentOptions[index].option = v;
                  setActiveOptions([...currentOptions]);
                }}
                placeholder="Type your option here."
                disabled={!option.isEditable && document.fields[rowIndex]._id}
                className={styles.option}
              />
              {option.isEditable && (
                <HoverImage
                  src={require('../../../assets/images/removePermissions.png')}
                  srcHover={require('../../../assets/images/removePermissonsHover.png')}
                  className={styles.removeImage}
                  alt="remove row"
                  onClick={() =>
                    setActiveOptions([
                      ...activeOptions.slice(0, index),
                      ...activeOptions.slice(index + 1)
                    ])
                  }
                />
              )}
            </div>
          ))}
          <Button
            text="Add Option"
            color="blue"
            onClick={() =>
              setActiveOptions([
                ...activeOptions,
                { option: '', isEditable: true }
              ])
            }
            disabled={
              activeOptions.length === 2 && selectedFieldType === 'radiobuttons'
            }
          />
          {activeOptions.length === 2 &&
            selectedFieldType === 'radiobuttons' && (
              <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                * Only two options are allowed for Radio Buttons
              </div>
            )}
        </div>
      </Modal>
    </>
  );
}
