import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import history from '../../../history';
import {
  isMovie,
  isMicrosoft,
  isYoutube
} from '../../../utils/attachmentSelector';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';

import styles from './styles.module.scss';
import { useSocket } from '../../../utils/withSocket';

export default function TrainingAttachmentViewer(props) {
  const { activeAttachment, activeTraining } = history?.location?.state;
  const socket = useSocket();
  const [startTime, setStartTime] = useState('');
  const [startingAttachmentTime, setAttachmentTime] = useState(0);

  const updateAttachment = useCallback(() => {
    if (activeTraining?.isCompleted) return;
    let secondsAsMinutes = moment().diff(startTime, 'seconds') / 60;
    secondsAsMinutes += parseFloat(startingAttachmentTime);
    activeAttachment.timeSpent = secondsAsMinutes.toFixed(2);

    if (!socket) return;
    setStartTime(null);
    socket.emit('updateTrainingTime', {
      trainingId: activeTraining._id,
      attachmentId: activeAttachment._id,
      timeSpent: activeAttachment.timeSpent,
      isYoutube: isYoutube(activeAttachment)
    });
  }, [
    activeAttachment,
    socket,
    startTime,
    activeTraining.isCompleted,
    activeTraining._id,
    startingAttachmentTime
  ]);

  useEffect(() => {
    if (!startTime) setStartTime(moment());
    let interval = setInterval(updateAttachment, 5000);

    return () => clearInterval(interval);
  }, [updateAttachment, startTime]);

  useEffect(() => {
    setAttachmentTime(activeAttachment.timeSpent);
  }, [activeAttachment.timeSpent]);

  const handleBack = () => {
    updateAttachment();
    history.goBack();
  };

  const header = (
    <Header
      title="Training"
      section={activeAttachment.original_filename}
      clickBack={handleBack}
    />
  );
  const source = activeAttachment.source_url?.length
    ? activeAttachment.source_url
    : activeAttachment.link ?? '';

  return (
    <HeaderAndFooter
      Header={header}
      className={styles.trainingAttachmentViewer}
    >
      <div className={styles.main}>
        {isYoutube(activeAttachment) ? (
          <iframe
            title="attachment"
            src={source}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={styles.docViewer}
          />
        ) : isMovie(activeAttachment) ? (
          <video className={styles.vidViewer} controls>
            <source src={source} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        ) : isMicrosoft(activeAttachment) ? (
          <iframe
            title="attachment"
            src={`https://docs.google.com/gview?url=${source}&embedded=true`}
            frameBorder="0"
            className={styles.docViewer}
          />
        ) : (
          <iframe
            title="attachment"
            src={source}
            frameBorder="0"
            className={styles.docViewer}
          />
        )}
      </div>
    </HeaderAndFooter>
  );
}
