import React from 'react';
import './svg.css';

export default props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.1209 29.9178L67.3174 20.2666V3.98419C67.3174 3.25505 66.72 2.65772 65.9927 2.65772L55.4931 2.658C54.7631 2.658 54.1675 3.25533 54.1675 3.98448V9.51587L44.5193 1.62865C41.8629 -0.542883 38.137 -0.542883 35.481 1.62865L17.8577 16.0371L0.878956 29.9176C0.0849182 30.5663 -0.200312 31.5948 0.143478 32.5599C0.487276 33.5246 1.36008 34.1396 2.38427 34.1396H9.35547V68.2063C9.35547 71.1736 11.7823 73.6004 14.7487 73.6004H65.2492C68.2157 73.6004 70.6424 71.1736 70.6424 68.2063L70.6427 34.1411H77.6151C78.6393 34.1411 79.5121 33.5262 79.8559 32.5614C80.2014 31.5954 79.9136 30.5666 79.1204 29.9182L79.1209 29.9178Z"
    />
  </svg>
);
