import React from 'react';
import classnames from 'classnames/bind';

import styles from './inlineTabs.module.scss';
const bStyles = classnames.bind(styles);

export const InlineTabs = props => {
  return (
    <div className={styles.tabs}>
      {props.tabs?.list?.map((t, i) => (
        <div
          key={i}
          onClick={() => props.tabs.onClick?.(t.value)}
          className={bStyles({
            blue: true,
            selected: props.tabs.selected === t.value
          })}
        >
          <span className={styles.title}>{t.label}</span>
        </div>
      ))}
    </div>
  );
};
