import React from 'react';
import { Input, nameInput } from '../';

import styles from './stepper.module.scss';
import classnames from 'classnames/bind';
import { positiveIntsOnly } from '../../../utils/formValidation';

const bStyles = classnames.bind(styles);

const NumberStepper = ({
  name,
  placeholder,
  onChange,
  onBlur,
  currentValue,
  disabled,
  type,
  inputClassName,
  rows,
  onMouseDown,
  autoFocus,
  inputRef,
  testID,
  enter,
  onKeyDown,
  touched,
  error,
  max,
  min,
  ...props
}) => {
  const maxValue = max ?? 999;
  const minValue = min ?? 1;
  const keyDown = e => {
    if (e.keyCode === 27 && escape) {
      escape();
    } else if ((e.keyCode === 13 || e.keyCode === 10) && enter) {
      enter(currentValue);
    } else if (onKeyDown) {
      return onKeyDown(e);
    }
  };
  const change = e =>
    onChange?.(
      positiveIntsOnly(e.target.value) > maxValue
        ? maxValue
        : e.target.value && positiveIntsOnly(e.target.value) < minValue
        ? minValue
        : positiveIntsOnly(e.target.value),
      e
    );

  return (
    <Input
      {...props}
      name={name}
      disabled={disabled}
      touched={touched}
      error={error}
    >
      <div className={styles.stepperContainer}>
        <p
          className={classnames(
            [styles.minus].concat(
              currentValue <= minValue || disabled ? styles.disabled : null
            )
          )}
          onClick={() =>
            currentValue <= minValue || disabled
              ? null
              : onChange(parseInt(currentValue ?? minValue) - (props.step ?? 1))
          }
        >
          -
        </p>
        <input
          value={currentValue}
          placeholder={placeholder}
          className={
            inputClassName ?? bStyles(styles.textbox, { error: touched })
          }
          onChange={change}
          onKeyDown={keyDown}
          onBlur={() =>
            onBlur ? onBlur() : onChange(currentValue ?? minValue)
          }
          onMouseDown={onMouseDown}
          disabled={disabled}
          id={name}
          autoFocus={autoFocus}
          ref={inputRef}
          data-cy={testID ?? props.fieldLabel}
        />
        <p
          className={classnames(
            [styles.plus].concat(
              currentValue >= maxValue || disabled ? styles.disabled : null
            )
          )}
          onClick={() =>
            currentValue >= maxValue || disabled
              ? null
              : onChange(parseInt(currentValue ?? minValue) + (props.step ?? 1))
          }
        >
          +
        </p>
      </div>
    </Input>
  );
};

const Stepper = nameInput(NumberStepper);
export { Stepper as NumberStepper };
export default Stepper;
