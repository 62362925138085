import { createSelector } from 'reselect';

const reportSelector = state => state.reports;

export const getReportsSelector = createSelector(
  reportSelector,
  reports => reports && reports.reports
);

export const getReportsLoadingSelector = createSelector(
  reportSelector,
  reports => reports && reports.loading
);

export const getActiveReportsSelector = createSelector(
  reportSelector,
  reports => reports && reports.reports
);

export const getActiveReportTemplatesSelector = createSelector(
  reportSelector,
  reports => reports && reports.reportTemplates
);

export const getActiveCustomReportQuestionsSelector = createSelector(
  reportSelector,
  reports => reports && reports.activeCustomQuestions
);
