import React from 'react';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';
import classnames from 'classnames/bind';

import styles from './styles.module.scss';

export const GenericFooter = ({ className, children, ...props }) => {
  return (
    <footer className={classnames(className, styles.footer)} {...props}>
      {children}
    </footer>
  );
};

export const SaveCancelFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  saveButtonText,
  cancelButtonDisabled,
  cancelButtonClick,
  editing,
  onMouseEnter,
  cancelButtonText,
  reverseOrder
}) => (
  <GenericFooter className={styles.buttonFooter}>
    <InputRow>
      {reverseOrder ? (
        <Button
          onClick={cancelButtonClick}
          type="button"
          testID="cancelButton"
          text={
            cancelButtonText ? cancelButtonText : editing ? 'Cancel' : 'Discard'
          }
          color={editing ? 'white' : 'red'}
          disabled={cancelButtonDisabled}
        />
      ) : null}
      <Button
        type="button"
        testID="saveSubmitButton"
        text={saveButtonText || (editing ? 'Save' : 'Create')}
        color={editing ? 'blue' : 'green'}
        onClick={saveButtonClick}
        disabled={saveButtonDisabled}
        onMouseEnter={onMouseEnter}
      />
      {!reverseOrder ? (
        <Button
          onClick={cancelButtonClick}
          type="button"
          testID="cancelButton"
          text={editing ? 'Cancel' : 'Discard'}
          color={editing ? 'white' : 'red'}
          disabled={cancelButtonDisabled}
        />
      ) : null}
    </InputRow>
  </GenericFooter>
);

export const SubmitSaveForLaterFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  submitButtonDisabled,
  submitButtonClick,
  submitButtonText
}) => {
  return (
    <GenericFooter className={styles.buttonFooter}>
      <InputRow>
        <Button
          type="button"
          text="Save for Later"
          color="blue"
          onClick={saveButtonClick}
          disabled={saveButtonDisabled}
        />
        <Button
          testID="saveSubmitButton"
          onClick={submitButtonClick}
          type="button"
          text={submitButtonText ?? 'Submit'}
          color="green"
          disabled={submitButtonDisabled}
        />
      </InputRow>
    </GenericFooter>
  );
};

export const SubmitSaveForLaterCancelFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  saveButtonText,
  submitButtonDisabled,
  submitButtonClick,
  submitButtonText,
  cancelButtonClick,
  cancelButtonDisabled,
  showDeleteInsteadOfCancel,
  deleteButtonClick,
  deleteButtonDisabled,
  hideCompleteButton = false,
  onMouseEnter
}) => {
  return (
    <GenericFooter className={styles.buttonFooter}>
      <InputRow>
        <Button
          type="button"
          text={saveButtonText || 'Save for Later'}
          color="blue"
          onClick={saveButtonClick}
          disabled={saveButtonDisabled}
        />
        {!hideCompleteButton ? (
          <Button
            onClick={submitButtonClick}
            type="button"
            text={submitButtonText ?? 'Submit'}
            color="green"
            disabled={submitButtonDisabled}
            testID="saveSubmitButton"
            onMouseEnter={onMouseEnter}
          />
        ) : null}
        {showDeleteInsteadOfCancel ? (
          <Button
            onClick={deleteButtonClick}
            type="button"
            text="Delete"
            color="red"
            disabled={deleteButtonDisabled}
          />
        ) : (
          <Button
            onClick={cancelButtonClick}
            type="button"
            text="Cancel"
            color="white"
            disabled={cancelButtonDisabled}
          />
        )}
      </InputRow>
    </GenericFooter>
  );
};

export const ConfirmFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  buttonText
}) => (
  <GenericFooter className={styles.buttonFooter}>
    <Button
      type="button"
      testID="confirmButton"
      text={buttonText ?? 'Confirm'}
      color="blue"
      onClick={saveButtonClick}
      disabled={saveButtonDisabled}
    />
  </GenericFooter>
);
