import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import history from '../../../history';
import {
  createQuiz,
  updateQuiz,
  deleteQuiz,
  fetchQuizById,
  fetchQuizzes,
  uploadAttachment,
  deleteAttachment
} from '../../../api/v4';
import { ATTACHMENT_TYPES_ACCEPTED } from '../../../constants/constants';
import { positiveIntsOnly } from '../../../utils/formValidation';
import { formatFiles, isImage } from '../../../utils/attachmentSelector';
import { addMessage } from '../../../actions/messages';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Textbox from '../../../components/inputs/Textbox';
import { Button, InputRow } from '../../../components/inputs';
import { Rearranger } from '../../../components/Rearranger';
import styles from './styles.module.scss';
import NumberStepper from '../../../components/inputs/NumberStepper';
import Modal from '../../../components/Modal';
import Carousel from '../../../components/Carousel';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function QuizTemplate(props) {
  const dispatch = useDispatch();
  const { quizTemplateId, duplicate } = useParams();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [quizTitles, setQuizTitles] = useState([]);
  const [titleWarning, setTitleWarning] = useState(false);
  const [openDeleteAnswerModal, setDeleteAnswerModal] = useState(false);
  const [photoModal, setPhotoModal] = useState(null);
  const [answerToDelete, setAnswerToDelete] = useState(null);
  const [quiz, setQuiz] = useState({
    title: '',
    percentToPass: '',
    numberOfRetriesAllowed: 1
  });
  const [questions, setQuestions] = useState([
    {
      label: '',
      answerOptions: [{ answer: '', attachments: [], isCorrectAnswer: false }],
      assigneeSelectedAnswers: []
    }
  ]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const disabled = !creating && !editing;

  useEffect(() => {
    const storedQuiz = localStorage.getItem('storedTemplate');
    if (storedQuiz) {
      const jsonQuiz = JSON.parse(storedQuiz);
      setCreating(jsonQuiz.creating);
      setEditing(jsonQuiz.editing);
      setQuiz(jsonQuiz);
      setQuestions(jsonQuiz.questions);
      localStorage.removeItem('storedTemplate');
    } else if (quizTemplateId) {
      fetchQuizById(quizTemplateId).then(response => {
        setQuiz({
          ...response,
          title: duplicate ? `${response.title} - Copy` : response.title,
          numberOfRetriesAllowed: response.numberOfRetriesAllowed ?? 1
        });
        setQuestions(response.questions);
        fetchQuizzes().then(r =>
          setQuizTitles(
            r
              .filter(quiz => quiz.title !== response.title)
              .map(quiz => quiz.title)
          )
        );
      });
    } else {
      fetchQuizzes().then(r =>
        setQuizTitles(r?.filter(r => !r.isNsc).map(quiz => quiz.title))
      );
      setCreating(true);
    }
    if (duplicate) {
      setCreating(true);
    }
  }, [quizTemplateId, duplicate]);

  useEffect(() => {
    if (quiz.title?.trim() && quizTitles.find(q => q === quiz.title)) {
      setTitleWarning(true);
    } else {
      setTitleWarning(false);
    }
  }, [quizTitles, quiz.title]);

  const handleBack = () => {
    props?.location?.state?.fromScreen
      ? history.goBack()
      : history.push({
          pathname: '/app/quiz/quizList',
          state: { fromScreen: 'quizTemplate' }
        });
  };

  const canSubmit = () => {
    const questionsMissingInfo = questions?.filter(question => {
      const hasCorrectAnswers = question.answerOptions.some(
        answer => answer.isCorrectAnswer
      );
      const hasAnswerLabels = question.answerOptions.every(answer =>
        answer.answer.trim()
      );
      const hasUniqueAnswers =
        new Set(question.answerOptions.map(option => option.answer)).size ===
        question.answerOptions.map(option => option.answer)?.length;

      return (
        !question.label?.trim() ||
        !hasCorrectAnswers ||
        !hasAnswerLabels ||
        !hasUniqueAnswers
      );
    });

    return (
      quiz.percentToPass !== undefined &&
      quiz.percentToPass !== '' &&
      questions?.length &&
      !questionsMissingInfo.length &&
      quiz.numberOfRetriesAllowed > 0 &&
      !titleWarning
    );
  };

  const handleSubmit = () => {
    const newQuiz = {
      ...quiz,
      questions
    };
    if (newQuiz._id && !duplicate) {
      updateQuiz(newQuiz).then(handleBack);
    } else {
      delete newQuiz._id;
      createQuiz({ ...newQuiz, duplicate: duplicate }).then(handleBack);
    }
  };

  const handleDelete = () => {
    deleteQuiz(quiz._id).then(() => history.goBack());
  };

  const handleUpdateQuestions = newQuestions => {
    setQuestions(newQuestions);
    setHasUnsavedChanges(true);
  };

  const handleChangeQuiz = value => {
    setQuiz({ ...quiz, ...value });
    setHasUnsavedChanges(true);
  };

  const checkFormatOfPhotos = async e => {
    let files = e.target.files;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      if (!isImage(files[i])) {
        let filesAccepted = ATTACHMENT_TYPES_ACCEPTED.join(', ');
        dispatch(
          addMessage({
            error: true,
            message: `File must be one of ${filesAccepted} types`
          })
        );
        return;
      }
    }
    return formatFiles(files);
  };

  const addAttachment = (attachment, questionIndex, answerIndex) => {
    const isAnswer = answerIndex !== null;
    const numBeingUploaded = Array.from(attachment.keys())?.length;
    if (
      !isAnswer &&
      (questions[questionIndex].attachments?.length ?? 0) + numBeingUploaded > 3
    ) {
      dispatch(
        addMessage({
          error: true,
          message: `Only 3 Photos Per Question`
        })
      );
      return;
    }

    uploadAttachment(attachment, {
      ownerType: `quizTemplate${!isAnswer ? '' : 'AnswerOption'}`,
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      if (isAnswer) {
        const newQuestions = [...questions];
        const newOptions = (newQuestions[questionIndex].answerOptions = [
          ...newQuestions[questionIndex].answerOptions
        ]);
        newOptions[answerIndex] = {
          ...newOptions[answerIndex],
          photo: response[0]
        };
        newQuestions[questionIndex].answerOptions = newOptions;
        handleUpdateQuestions(newQuestions);
      } else {
        const newQuestions = [...questions];
        newQuestions[questionIndex].attachments = (
          newQuestions[questionIndex].attachments ?? []
        ).concat(response);
        handleUpdateQuestions(newQuestions);
      }
    });
  };

  const removeAttachment = (attachment, questionIndex, answerIndex) => {
    const isAnswer = answerIndex !== null;
    if (!duplicate) deleteAttachment(attachment._id);
    if (isAnswer) {
      const newQuestions = [...questions];
      const newOptions = (newQuestions[questionIndex].answerOptions = [
        ...newQuestions[questionIndex].answerOptions
      ]);
      newOptions[answerIndex] = {
        ...newOptions[answerIndex],
        photo: null
      };
      newQuestions[questionIndex].answerOptions = newOptions;
      handleUpdateQuestions(newQuestions);
      setPhotoModal(null);
    } else {
      const newQuestions = [...questions];
      newQuestions[questionIndex].attachments = newQuestions[
        questionIndex
      ].attachments.filter(att => att._id !== attachment._id);
      handleUpdateQuestions(newQuestions);
    }
  };

  const updateQuestionAnswer = (questionIndex, answerIndex, value) => {
    const newQuestions = [...questions];
    const newOptions = (newQuestions[questionIndex].answerOptions = [
      ...newQuestions[questionIndex].answerOptions
    ]);
    newOptions[answerIndex] = { ...newOptions[answerIndex], ...value };
    newQuestions[questionIndex].answerOptions = newOptions;
    handleUpdateQuestions(newQuestions);
  };

  const removeQuestionAnswer = () => {
    if (openDeleteAnswerModal === 'question') {
      let fields = [...questions];
      fields.splice(answerToDelete, 1);
      handleUpdateQuestions(fields);
    } else {
      const newQuestions = [...questions];
      const newOptions = [...newQuestions[answerToDelete[0]].answerOptions];
      newOptions.splice(answerToDelete[1], 1);
      newQuestions[answerToDelete[0]].answerOptions = newOptions;
      handleUpdateQuestions(newQuestions);
    }
    setDeleteAnswerModal(false);
  };

  const addQuestionAnswer = questionIndex => {
    const newQuestions = [...questions];
    const newOptions = (newQuestions[questionIndex].answerOptions = [
      ...newQuestions[questionIndex].answerOptions,
      { answer: '', attachments: [], isCorrectAnswer: false }
    ]);
    newQuestions[questionIndex].answerOptions = newOptions;
    handleUpdateQuestions(newQuestions);
  };

  const addRowsToQuestions = number => {
    const newQuestions = [...questions];
    for (let i = 1; i <= number; i++) {
      newQuestions.push({
        label: '',
        answerOptions: [{ answer: '', attachments: [], isCorrectAnswer: false }]
      });
    }
    handleUpdateQuestions(newQuestions);
  };

  const handleArchive = () => {
    updateQuiz({ ...quiz, isArchived: !quiz.isArchived }).then(() => {
      if (!quiz.isArchived) handleBack();
      fetchQuizById(quizTemplateId).then(r => {
        setQuiz({
          ...r,
          numberOfRetriesAllowed: r.numberOfRetriesAllowed ?? 1
        });
      });
    });
  };

  //This is the quiz question card
  const renderQuestion = (item, index) => {
    const hasUniqueAnswers =
      !item.answerOptions.every(a => a.answer) ||
      new Set(item.answerOptions.map(option => option.answer)).size ===
        item.answerOptions.map(option => option.answer).length;
    const hasCorrectAnswers = item.answerOptions.some(
      answer => answer.isCorrectAnswer
    );
    return (
      <>
        <InputRow>
          <Textbox
            className={disabled ? styles.grow : styles.answerLabel}
            currentValue={item.label}
            onChange={v => {
              const newQuestions = [...questions];
              newQuestions[index].label = v;
              handleUpdateQuestions(newQuestions);
            }}
            fieldLabel="Question"
            placeholder="Type your question here (e.g. What is 2+2?)"
            isRequired
            disabled={disabled}
            name={`question-${index}`}
            touched={!hasUniqueAnswers || (missingRequired && !item.label)}
            error
            errorMessage={
              !hasUniqueAnswers ? 'Each question answer must be unique' : ''
            }
          />
          {!disabled ? (
            <>
              <Button
                imageClass={styles.photoImage}
                color="blue"
                text="Photo"
                shrink
                image={
                  item?.attachments?.length
                    ? require('../../../assets/images/cameraWithAttachments.png')
                    : require('../../../assets/images/CameraIcon.png')
                }
                className={styles.questionButton}
                onClick={() =>
                  document.getElementById(`questionAttachments${index}`).click()
                }
                disabled={
                  item.attachments?.length > 2 || (!editing && !creating)
                }
              />
              <input
                tabIndex="-1"
                type="file"
                id={`questionAttachments${index}`}
                className={styles.fileUpload}
                onChange={e => {
                  checkFormatOfPhotos(e).then(att =>
                    addAttachment(att, index, null)
                  );
                  e.target.value = '';
                }}
                multiple
                accept={ATTACHMENT_TYPES_ACCEPTED.join(', ')}
              />
            </>
          ) : null}
        </InputRow>
        {item?.attachments?.length ? (
          <Carousel
            items={item?.attachments ?? []}
            deleteAction={attachment => {
              removeAttachment(attachment, index, null);
            }}
            canDelete={editing || creating}
            canDownload
            showPostedBy={!creating}
            ownerType={'quizTemplate'}
          />
        ) : null}
        {item.answerOptions.map((answer, i) => (
          <>
            <div
              className={classnames([
                styles.answerRow,
                disabled ? null : styles.hoverTarget
              ])}
            >
              <Textbox
                className={styles.answerLabel}
                currentValue={answer.answer}
                onChange={v =>
                  updateQuestionAnswer(index, i, {
                    answer: v
                  })
                }
                fieldLabel={i === 0 ? `Answers` : null}
                placeholder="Type your answer here"
                isRequired
                disabled={disabled}
                name={`question-answer-${i}`}
                error
                touched={missingRequired && !answer.answer}
              />
              <div className={styles.answerButtons}>
                {!disabled || answer.isCorrectAnswer ? (
                  <Button
                    className={
                      answer.isCorrectAnswer
                        ? styles.selectedAnswer
                        : styles.correctButton
                    }
                    text={answer.isCorrectAnswer ? 'Correct' : 'Mark Correct'}
                    color={
                      answer.isCorrectAnswer
                        ? 'green'
                        : missingRequired && !hasCorrectAnswers
                        ? 'redOutline'
                        : 'greenOutline'
                    }
                    onClick={() =>
                      updateQuestionAnswer(index, i, {
                        isCorrectAnswer: !answer.isCorrectAnswer
                      })
                    }
                    shrink
                    disabled={disabled}
                  />
                ) : null}

                {answer.photo && !disabled ? (
                  <Button
                    shrink
                    image={require(`../../../assets/images/cameraWithAttachments${
                      disabled ? 'Disabled' : ''
                    }.png`)}
                    color={'blue'}
                    onClick={() =>
                      setPhotoModal({
                        question: item,
                        qIndex: index,
                        aIndex: i,
                        ...answer
                      })
                    }
                    disabled={disabled}
                    className={styles.photoAttachedButton}
                    imageClass={styles.cameraWithAttach}
                  />
                ) : !disabled ? (
                  <>
                    <Button
                      shrink
                      image={require('../../../assets/images/CameraIconBlue.png')}
                      color={'white'}
                      onClick={() =>
                        document
                          .getElementById(`questionAnswerPhoto${index}-${i}`)
                          .click()
                      }
                      className={styles.answerPhotoButton}
                      disabled={disabled}
                    />
                    <input
                      tabIndex="-1"
                      type="file"
                      id={`questionAnswerPhoto${index}-${i}`}
                      className={styles.fileUpload}
                      onChange={e => {
                        checkFormatOfPhotos(e).then(att =>
                          addAttachment(att, index, i)
                        );
                      }}
                      accept={ATTACHMENT_TYPES_ACCEPTED.join(', ')}
                    />
                  </>
                ) : null}
                {!disabled ? (
                  <img
                    src={require('../../../assets/images/remove.png')}
                    alt="delete question"
                    onClick={() => {
                      setAnswerToDelete([index, i]);
                      setDeleteAnswerModal('answer');
                    }}
                    className={styles.answerDeleteButton}
                    style={{
                      padding: '6px 5px'
                    }}
                    id="deleteButton"
                    data-cy="removeButton"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            {answer.photo ? (
              <>
                <div className={styles.imageCard}>
                  <img
                    src={answer.photo.source_url}
                    alt={answer.photo.original_filename}
                  />
                  {!disabled ? (
                    <div className={styles.attachmentButton}>
                      <img
                        src={require('../../../assets/images/remove.png')}
                        alt="delete question"
                        onClick={() =>
                          setPhotoModal({
                            question: item,
                            qIndex: index,
                            aIndex: i,
                            ...answer
                          })
                        }
                        className={styles.attachmentImageRemove}
                        data-cy="removeButton"
                      />{' '}
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
        {!disabled ? (
          <Button
            className={styles.addButton}
            text="Add Answer"
            color="blue"
            shrink
            onClick={() => addQuestionAnswer(index)}
          />
        ) : null}
      </>
    );
  };

  const header = (
    <Header
      title="Quiz Template"
      section={
        creating
          ? 'Create Template'
          : editing
          ? 'Edit Template'
          : 'View Template'
      }
      pageActionOptions={
        !creating
          ? [
              {
                label:
                  editing || quiz?.isArchived ? 'Delete Quiz' : 'Edit Quiz',
                onClick: () =>
                  editing || quiz?.isArchived
                    ? setOpenDeleteModal(true)
                    : setEditing(true),
                color:
                  editing || quiz.isArchived ? 'redOutline' : 'blueOutline',
                visible: true
              },
              {
                label: `${quiz.isArchived ? 'Un-' : ''}Archive Quiz`,
                onClick: () => handleArchive(),
                color: 'blueOutline',
                visible: true
              }
            ]
          : null
      }
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
      }
      needsSaved={hasUnsavedChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit() ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(!canSubmit())}
    />
  );

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer} showFooter={!disabled}>
        <Card showHeader title="Quiz Template">
          <Textbox
            currentValue={quiz.title}
            onChange={title => {
              handleChangeQuiz({ title });
            }}
            fieldLabel="Quiz Title"
            placeholder="Title of Quiz (e.g. Forklift Quiz)"
            isRequired
            disabled={disabled}
            name="quizTitle"
            error
            errorMessage={titleWarning ? 'That title is already in use' : ''}
            touched={titleWarning || (missingRequired && !quiz.title)}
          />
          <Textbox
            inputClassName={classnames([styles.percentBox, 'textbox'])}
            currentValue={quiz.percentToPass}
            onChange={v => {
              handleChangeQuiz({
                percentToPass: v ? Math.min(positiveIntsOnly(v), 100) : ''
              });
            }}
            fieldLabel="What percentage of questions correct to pass?"
            placeholder="0"
            isRequired
            disabled={disabled}
            name="percentToPass"
            error
            touched={missingRequired && !quiz.percentToPass}
          />
          <NumberStepper
            type="number"
            currentValue={quiz.numberOfRetriesAllowed}
            onChange={v => {
              handleChangeQuiz({ numberOfRetriesAllowed: v });
            }}
            fieldLabel="How many attempts will be allowed?"
            placeholder="1"
            isRequired
            max={99}
            disabled={disabled}
            touched={missingRequired && !quiz.numberOfRetriesAllowed}
          />
          <Button
            text="Preview Quiz"
            color="blue"
            disabled={!canSubmit()}
            onClick={() => {
              localStorage.setItem(
                'storedTemplate',
                JSON.stringify({
                  ...quiz,
                  questions: questions,
                  creating: creating,
                  editing: editing
                })
              );
              history.push({
                pathname: '/app/training/quiz',
                state: {
                  passedQuiz: { ...quiz, questions },
                  isPreview: true,
                  showAnswers: true,
                  hideCheckmark: true
                }
              });
            }}
            inputClassName={styles.previewButton}
          />
          <Rearranger
            className={styles.questionContainer}
            items={questions}
            onChange={handleUpdateQuestions}
            renderItem={renderQuestion}
            removeRow={questionIndex => {
              setAnswerToDelete(questionIndex);
              setDeleteAnswerModal('question');
            }}
            onDuplicate={question => {
              const dupedQ = cloneDeep(question);
              delete dupedQ._id;
              dupedQ.answerOptions.forEach(a => delete a._id);
              return dupedQ;
            }}
            disabled={disabled}
          />
          {!disabled ? (
            <InputRow className={styles.footer}>
              <Button
                text="Add Question"
                color="blue"
                onClick={() => addRowsToQuestions(1)}
              />
              <Button
                text="Add Five Questions"
                color="blue"
                onClick={() => addRowsToQuestions(5)}
              />
            </InputRow>
          ) : (
            <></>
          )}
          {missingRequired && !questions.length ? (
            <h2 className={styles.warningText}>
              At least one question is required for a quiz to be created.
            </h2>
          ) : null}
        </Card>
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        savingWhat="Quiz Template"
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={handleBack}
      />
      <DeleteItemModal
        isOpen={openDeleteModal}
        deletingWhat="Training Quiz"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={handleDelete}
      />
      <Modal
        title="Delete Question Answer?"
        isOpen={openDeleteAnswerModal}
        titleClassName="redHeader"
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={() => setDeleteAnswerModal(false)}
        submitActions={removeQuestionAnswer}
      >
        Any attachments on the {openDeleteAnswerModal} will be lost.
      </Modal>
      <Modal
        title="Response Photo"
        titleClassName="blueHeader"
        isOpen={photoModal}
        submitButtonColor="blue"
        submitButtonText="Discard"
        cancelButtonText="Close"
        submitActions={() => {
          removeAttachment(
            photoModal.photo,
            photoModal.qIndex,
            photoModal.aIndex
          );
        }}
        onRequestClose={() => setPhotoModal(null)}
      >
        <b>Response Text: </b>
        {photoModal?.answer}
        <Carousel
          items={[photoModal?.photo]}
          canDelete={false}
          canDownload={false}
        />
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        subject="Template"
        onRequestClose={() => setMissingModalOpen(false)}
        isEditing={editing}
      />
    </>
  );
}
