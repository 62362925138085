import {
  DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
  DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE,
  SET_ACTIVE_EMPLOYEE_REQUEST,
  SET_ACTIVE_EMPLOYEE_RESPONSE,
  UPDATE_LOCATION_EMPLOYEE_REQUEST
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  activeEmployee: {}
};

const company = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOCATION_EMPLOYEE_REQUEST:
    case SET_ACTIVE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case DOWNLOAD_EMPLOYEE_QRCODE_REQUEST:
      return {
        ...state
      };

    case DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE:
      return {
        ...state
      };

    case SET_ACTIVE_EMPLOYEE_RESPONSE:
      return {
        ...state,
        loading: false,
        activeEmployee: payload
      };

    default:
      return {
        ...state,
        loading: false
      };
  }
};

export default company;
