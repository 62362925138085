import React, { useState, useEffect } from 'react';
import {
  fetchCovidEmployees,
  fetchCovidExposureList,
  downloadVaccinatedEmployees,
  downloadNonVaccinatedEmployees,
  downloadCovidExposures
} from '../../api/v4';
import history from '../../history';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import TypeSelection from '../../components/TypeSelection';
import CovidException from '../Covid-19/ExceptionPage';

export default function CovidEmployeeList() {
  const [employees, setEmployees] = useState([]);
  const [exposures, setExposures] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Employees');

  useEffect(() => {
    fetchCovidEmployees().then(setEmployees);
    fetchCovidExposureList().then(setExposures);
  }, []);

  const header = (
    <Header
      title="Covid-19 Tracking"
      rightButtons={
        selectedTab === 'Exposure'
          ? {
              color: 'blue',
              text: 'Add Covid-19 Exposure',
              onClick: () => history.push(`/app/covidTracking/exposure`)
            }
          : null
      }
    />
  );

  const employeeColumns = [
    { key: 'employeeId', datatype: 'users', label: 'Employee Name' },
    {
      key: 'isVaccinated',
      label: 'Vaccinated',
      datatype: 'boolean'
    },
    {
      key: 'lastBoosterDate',
      datatype: 'date',
      accessor: r => r.lastBoosterDate ?? ''
    },
    {
      key: 'testResultsDate',
      label: 'Date of Last Test',
      datatype: 'date',
      accessor: r => {
        return r.latestTestDate ?? r.testResults?.[0]?.date;
      }
    },
    {
      key: 'testResults',
      label: 'Result of Last Test',
      accessor: r => r.resultOfTest ?? r.testResults?.[0]?.result ?? '- -'
    }
  ];

  const exposureColumns = [
    { key: 'positiveEmployee', datatype: 'users', label: 'Employee Name' },
    {
      key: 'positiveTestDate',
      datatype: 'date',
      label: 'Date of Positive Test'
    },
    {
      key: 'negativeTestDate',
      datatype: 'date',
      label: 'Date of Negative Test'
    }
  ];

  const handleDownloadVaccinatedEmployees = async () => {
    const src = await downloadVaccinatedEmployees();
    window.location = src;
  };

  const handleDownloadNonVaccinatedEmployees = async () => {
    const src = await downloadNonVaccinatedEmployees();
    window.location = src;
  };

  const handleDownloadCovidExposure = async (rowIds = []) => {
    const src = await downloadCovidExposures({ rowIds });
    window.location = src;
  };

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selected={selectedTab}
        selectedArray={['Employees', 'Exception', 'Exposure']}
        onClick={value => setSelectedTab(value)}
      />
      {selectedTab === 'Exception' ? (
        <CovidException />
      ) : selectedTab === 'Exposure' ? (
        <List
          saveKey="covidExposure"
          rowClick={row =>
            history.push(`/app/covidTracking/exposure/${row._id}`)
          }
          data={exposures}
          dataIsHash
          settings={exposureColumns}
          getRowId={r => r._id}
          actions={[
            {
              color: 'blue',
              label: 'Download CSV',
              onClick: rowIds => handleDownloadCovidExposure(rowIds)
            }
          ]}
        />
      ) : (
        <List
          saveKey="covidEmployees"
          rowClick={row =>
            history.push(`/app/covidTracking/employeeStatus/${row._id}`)
          }
          data={employees}
          dataIsHash
          settings={employeeColumns}
          getRowId={r => r._id}
          actions={[
            {
              color: 'blue',
              label: 'Download Vaccinated Employees',
              onClick: () => handleDownloadVaccinatedEmployees()
            },
            {
              color: 'blue',
              label: 'Download Non Vaccinated Employees',
              onClick: () => handleDownloadNonVaccinatedEmployees()
            }
          ]}
        />
      )}
    </HeaderAndFooter>
  );
}
