import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || '0px'}
    y={props.y || '0px'}
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
  >
    <path
      d="M26.6667 73.3333L0 46.6667L10 36.6667L26.6667 53.3333L70 10L80 20L26.6667 73.3333Z"
      fill="white"
    />
  </svg>
);
