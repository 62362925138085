import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';
import classnames from 'classnames/bind';

import { sameWidth, zIndex } from '../../../utils/popperModifiers';
import styles from './dropdown.module.scss';
const bStyles = classnames.bind(styles);

function getPicture(value) {
  switch (value) {
    case 'Edit':
      return require('../../../assets/images/Pen Icon.png');
    case 'View':
      return require('../../../assets/images/Eye Icon.png');
    case 'None':
    default:
      return require('../../../assets/images/Remove Icon.png');
  }
}

export const PermissionsDropdown = ({
  className,
  placeholder,
  disabled,
  accessLevel,
  stage,
  currentValue,
  onChange
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const [ddRef, setDdRef] = useState(null);
  const popper = usePopper(menuRef, ddRef, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: ['top-start'] }
      },
      sameWidth,
      zIndex
    ]
  });

  const _onBlur = e => {
    let node = e?.relatedTarget;
    while (node) {
      if (node === e?.currentTarget || node === ddRef) {
        return;
      }
      node = node.parentNode;
    }
    setShowOptions(false);
  };

  const currentPicture = getPicture(currentValue);

  const classes = bStyles(className, {
    permDropdown: true,
    multiSelect: true,
    disabled,
    showOptions
  });

  let ddStyles = bStyles({
    dropdown: true,
    showOptions,
    openUp: popper.attributes?.popper?.['data-popper-placement'] === 'top-start'
  });

  return (
    <>
      <div
        className={classes}
        onClick={() => setShowOptions(disabled ? false : !showOptions)}
        onBlur={_onBlur}
        tabIndex={0}
        ref={setMenuRef}
      >
        <span className={currentPicture ? undefined : styles.placeholder}>
          {currentPicture ? (
            <img
              src={currentPicture}
              alt={currentValue}
              className={styles.picture}
            />
          ) : (
            placeholder || 'Select'
          )}
        </span>
        <div className={styles.right}>
          <img
            src={
              showOptions
                ? require('../../../assets/images/Chevron.png')
                : require('../../../assets/images/downChevron.png')
            }
            alt={showOptions ? 'dropdown open' : 'dropdown closed'}
          />
        </div>
      </div>
      {showOptions && (
        <Portal>
          <ul
            className={ddStyles}
            ref={setDdRef}
            tabIndex={0}
            style={popper.styles.popper}
            {...popper.attributes.popper}
          >
            <li
              onClick={() => {
                setShowOptions(false);
                onChange('View');
              }}
              className={styles.option}
            >
              <img
                src={require('../../../assets/images/Eye Icon.png')}
                alt="View stage"
                className={styles.picture}
              />
              View
            </li>
            <li
              onClick={() => {
                setShowOptions(false);
                onChange('Edit');
              }}
              className={styles.option}
            >
              <img
                src={require('../../../assets/images/Pen Icon.png')}
                alt="Edit stage"
                className={styles.picture}
              />
              Edit
            </li>
            {(accessLevel !== 900 || (accessLevel !== 900 && stage !== 0)) && (
              <li
                onClick={() => {
                  setShowOptions(false);
                  onChange('None');
                }}
                className={styles.option}
              >
                <img
                  src={require('../../../assets/images/Remove Icon.png')}
                  alt="Remove from stage"
                  className={styles.picture}
                />
                None
              </li>
            )}
          </ul>
        </Portal>
      )}
    </>
  );
};

export default PermissionsDropdown;
