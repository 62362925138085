import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || '0px'}
    y={props.y || '0px'}
    width="30px"
    height="25px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <g display="none">
      <path
        display="inline"
        d="M75.186,68.916c0,0-6.082,0.422-12.528,0.781c-2.398-2.686-2.07-9.439-1.767-12.604   c2.509-2.766,4.729-6.279,6.044-10.264c0.136,0.223,0.29,0.414,0.494,0.523c2.248,1.238,1.689-3.824,1.797-5.959   c0.111-2.137,1.688-4.947,0-4.947c-0.456,0-0.79,0.068-1.047,0.164c-0.311-12.205-3.23-21.717-17.954-21.717   c-15.678,0-17.782,9.639-17.973,21.963c-0.247-0.213-0.685-0.41-1.479-0.41c-1.688,0-0.114,2.811,0,4.947   c0.112,2.135-0.448,7.197,1.799,5.959c0.33-0.184,0.557-0.543,0.711-1.004c1.202,4.068,3.329,7.666,5.796,10.512   c0.312,3.045,0.721,10.061-1.726,12.82c-6.478-0.354-12.541-0.766-12.541-0.766c-5.961,0-10.795,4.832-10.795,10.793v5.396h71.965   v-5.396C85.982,73.748,81.146,68.916,75.186,68.916z"
      />
    </g>
    <g display="none">
      <polygon
        display="inline"
        points="52.996,40.028 52.996,24.036 47.006,24.036 47.006,40.028 14.064,40.028 26.045,75.964    73.957,75.964 85.936,40.028  "
      />
    </g>
    <g display="none">
      <path
        display="inline"
        d="M20.015,14.018v71.965h59.971V14.018H20.015z M35.008,75.988h-5.995v-6h5.995V75.988z M35.008,63.994   h-5.995v-5.998h5.995V63.994z M35.008,51.998h-5.995v-5.996h5.995V51.998z M47.001,75.988h-5.994v-6h5.994V75.988z M47.001,63.994   h-5.994v-5.998h5.994V63.994z M47.001,51.998h-5.994v-5.996h5.994V51.998z M58.997,75.988h-5.995v-6h5.995V75.988z M58.997,63.994   h-5.995v-5.998h5.995V63.994z M58.997,51.998h-5.995v-5.996h5.995V51.998z M70.991,75.988h-5.999v-6h5.999V75.988z M70.991,63.994   h-5.999v-5.998h5.999V63.994z M70.991,51.998h-5.999v-5.996h5.999V51.998z M73.99,36.008H26.016V24.014H73.99V36.008z"
      />
    </g>
    <g>
      <polygon points="50,17.02 14.018,47.074 20.1,47.074 20.1,82.984 44.002,82.984 44.002,64.994 55.996,64.994 55.996,82.984    80.07,82.984 80.07,47.074 85.982,47.074  " />
      <polygon points="77.986,17.016 65.992,17.016 65.992,25.037 77.986,35.008  " />
    </g>
  </svg>
);
