import React from 'react';
import classnames from 'classnames';

import { Input, Checkbox } from '../';

import styles from './checkboxGroup.module.scss';

const CheckboxGroup = ({
  name,
  options,
  disabled,
  className,
  displayTooltip,
  columns,
  currentValue,
  tooltips,
  onChange,
  bareValues = true,
  labelStyles,
  alphabetize = false,
  testID,
  hasBoxes,
  checkedOptions,
  wrongOptions,
  touched,
  smallerMargin,
  ...props
}) => {
  const classes = [className, columns && styles.checkboxColumns];

  let values = currentValue;

  if (!values) {
    values = [];
  } else if (!Array.isArray(values)) {
    values = [values];
  }
  values = values.map(v => v?.value ?? v?.label ?? v);

  if (alphabetize) {
    options.sort((a, b) =>
      a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
    );
  }

  return (
    <Input className={classes} disabled={disabled} {...props}>
      {options.map((option, index) => (
        <Checkbox
          key={index}
          fieldLabel={option.label}
          currentValue={
            values.indexOf(option.value) !== -1 ||
            values.indexOf(option.label) !== -1
          }
          className={classnames([styles.checkboxInput])}
          containerClasses={classnames([
            checkedOptions?.includes(option.label) ? styles.correct : '',
            wrongOptions?.includes(option.label) ? styles.incorrect : ''
          ])}
          name={`${name}${option.label}[${index}]`}
          onChange={checked => {
            if (checked) {
              values.push(option.value);
            } else {
              values = values.filter(v => v !== option.value);
            }

            const newSelected = options.filter(
              option => values.indexOf(option.value) > -1
            );
            onChange &&
              onChange(
                bareValues ? newSelected.map(o => o.value) : newSelected
              );
          }}
          labelStyles={labelStyles}
          displayTooltip={displayTooltip && tooltips?.[index]}
          disabled={disabled || option.disabled}
          tooltip={tooltips?.[index]}
          testID={testID + ' ' + option.label}
          image={option.photo}
          hasBox={hasBoxes}
          touched={touched}
          smallerMargin={smallerMargin}
        />
      ))}
    </Input>
  );
};

export default CheckboxGroup;
