export const getSelectedCompanies = (userCompanies, selectedCompanies) => {
  let companyArray = [];

  if (!selectedCompanies) {
    return [];
  } else if (!Array.isArray(selectedCompanies)) {
    companyArray = [selectedCompanies];
  } else {
    companyArray = [...selectedCompanies];
  }

  return userCompanies.filter(o =>
    companyArray.find(o2 => o._id === (o2?.value ?? o2))
  );
};

export const getSelectedGroups = (selectedCompanies, selectedGroups) => {
  let groupArray = [];
  if (!Array.isArray(selectedGroups)) {
    groupArray = [selectedGroups];
  } else {
    groupArray = [...selectedGroups];
  }

  groupArray = groupArray.map(group => group?.value ?? group);

  const groups = [];

  if (selectedCompanies.length > 0) {
    selectedCompanies.forEach(company =>
      company.groups.forEach(group => {
        if (groupArray.indexOf(group._id) > -1 && !group?.isHidden) {
          groups.push(group);
        }
      })
    );
  }
  return groups;
};

export const getSelectedProjects = (selectedGroups, selectedProjects) => {
  if (!selectedProjects) {
    return null;
  }

  const projects = [];

  if (selectedGroups.length > 0) {
    selectedGroups.forEach(group =>
      group.projects.forEach(project => {
        if (selectedProjects.indexOf(project._id) > -1 && !project?.isHidden) {
          projects.push(project);
        }
      })
    );
  }

  return projects;
};

export const getGroupDropdown = (userCompanies, user) => {
  const selectedCompanies = getSelectedCompanies(
    userCompanies,
    user?.companyIds
  );

  let groupDropdown = [];

  if (selectedCompanies.length > 0) {
    selectedCompanies.forEach(company =>
      company.groups
        ?.filter(g => !g.isHidden)
        .forEach(group =>
          groupDropdown.push({
            value: group._id,
            label: `${company.name} - ${group.name}`
          })
        )
    );
  }
  return groupDropdown;
};

export const getProjectDropdown = (userCompanies, user) => {
  if (!user?.groupIds) {
    return [];
  }
  const selectedCompanies = getSelectedCompanies(
    userCompanies,
    user?.companyIds
  );
  const selectedGroup = getSelectedGroups(selectedCompanies, user?.groupIds);

  let projectsDropdown = [];
  selectedGroup.forEach(group => {
    group.projects.forEach(project => {
      if (!project.isHidden)
        projectsDropdown.push({
          value: project._id,
          label: `${group.name} - ${project.name}`
        });
    });
  });

  return projectsDropdown;
};
