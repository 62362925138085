import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export class DeleteItemModal extends Component {
  render() {
    const what = this.props.deletingWhat || 'page';
    return (
      <Modal
        title={`Delete ${what}`}
        titleClassName="redHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
      >
        <div className={styles.text}>
          <span className={styles.warningText}>
            Deleting a {what} is permanent. No one will be able to view or edit
            this specific {what} again.{' '}
          </span>
          A new {what} that is similar or identical can still be created.{' '}
          {!this.props.hideCustomTemplate && (
            <span className={styles.warningText}>
              If this is a custom {what}, then it may only be recreated if the
              Custom {what} Template still exists.
            </span>
          )}
        </div>
      </Modal>
    );
  }
}

export default DeleteItemModal;
