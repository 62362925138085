import React from 'react';
import isEqual from 'lodash/isEqual';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import RadioButtons from '../../inputs/RadioButtons';
import Card from '../../../components/Card';

import quizCardStyles from './styles.module.scss';
import Carousel from '../../Carousel';

export default function QuizCard(props) {
  const {
    questionNumber,
    question,
    handleQuizAnswers,
    isPreview,
    isRetaking,
    name,
    hideCheckmark,
    showAnswers,
    missingRequired
  } = props;

  const handleQuestionAnswer = answer => {
    handleQuizAnswers({
      ...question,
      assigneeSelectedAnswers: answer
    });
  };

  const answerOptions = question?.answerOptions;
  const assigneeSelectedAnswers = question?.assigneeSelectedAnswers;
  const answers = answerOptions?.map((answerOption, i) => {
    return {
      value: answerOption._id,
      label: answerOption.answer,
      photo: answerOption.photo?.source_url
    };
  });
  const correctAnswers = answerOptions
    ?.filter(answer => answer.isCorrectAnswer)
    .map(answerOption => answerOption.answer);
  const wrongAnswers = answerOptions
    ?.filter(
      answer =>
        !answer.isCorrectAnswer &&
        assigneeSelectedAnswers?.map(a => a.label).includes(answer.answer)
    )
    .map(answerOption => answerOption.answer);
  const hasCorrectAnswers = isEqual(
    correctAnswers,
    assigneeSelectedAnswers?.map(r => r.label)
  );

  const getImage = hasCorrectAnswers
    ? require('../../../assets/images/Check.png')
    : require('../../../assets/images/Remove Icon.png');

  const isReviewing =
    (assigneeSelectedAnswers?.length || !isRetaking || showAnswers) &&
    isPreview;
  const subHeaderText =
    correctAnswers?.length === 1
      ? `${questionNumber + 1}. ${question?.label}`
      : `${questionNumber + 1}. ${
          question?.label
        } (Mark All Potential Answers)`;
  const subHeader = (
    <div className={quizCardStyles.subHeader}>
      {isReviewing && !hideCheckmark ? (
        <div className={quizCardStyles.imageDiv}>
          <img
            src={getImage}
            alt={hasCorrectAnswers ? 'you got it right' : 'you got it wrong'}
          />
        </div>
      ) : null}
      <h3 className={quizCardStyles.subHeaderText}> {subHeaderText} </h3>
    </div>
  );
  return (
    <>
      <Card border name={name}>
        {question?.attachments?.length ? (
          <>
            <Carousel items={question?.attachments ?? []} />
            <br />
          </>
        ) : null}
        {subHeader}
        {correctAnswers?.length === 1 ? (
          <RadioButtons
            options={answers}
            onChange={values => handleQuestionAnswer([values])}
            currentValue={question?.assigneeSelectedAnswers?.[0]}
            disabled={isReviewing || isPreview}
            labelStyles={quizCardStyles.label}
            bareValues={false}
            needSeparateLines={!question?.answerOptions?.some(a => a.photo)}
            showImages
            checkedOptions={showAnswers ? correctAnswers : null}
            wrongOptions={showAnswers ? wrongAnswers : null}
            touched={missingRequired && !question?.assigneeSelectedAnswers?.[0]}
          />
        ) : (
          <CheckboxGroup
            name={question?._id}
            options={answers}
            onChange={values => handleQuestionAnswer(values)}
            bareValues={false}
            currentValue={question?.assigneeSelectedAnswers}
            disabled={isReviewing || isPreview}
            labelStyles={quizCardStyles.label}
            columns={question?.answerOptions?.some(a => a.photo)}
            hasBoxes
            checkedOptions={showAnswers ? correctAnswers : null}
            wrongOptions={showAnswers ? wrongAnswers : null}
            touched={
              missingRequired && !question?.assigneeSelectedAnswers?.length
            }
          />
        )}
      </Card>
    </>
  );
}
