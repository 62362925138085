import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';

const loading = (state = 0, { type, payload }) => {
  switch (type) {
    case START_LOADING:
      return state + 1;
    case STOP_LOADING:
      if (state < 1) state = 1;
      return state - 1;
    default:
      return state;
  }
};

export default loading;
