import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { getHoursWorked } from '../../api/v4';
import {
  OSHA_PERRP_DROPDOWN,
  PERRP_ENTITY_CODES
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  normalizeBwcPolicy,
  normalizePhone,
  normalizeWebsite,
  renderDropdownField,
  renderEmployeeDropdown,
  renderField,
  renderNumberField,
  renderCheckbox,
  required,
  validNAICS,
  validSIC,
  validZip
  // renderRadioButtons
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import FileCropper from '../../components/FileCropper';
import HoursTable from '../../components/HoursTable/HoursTable';

import '../form.css';

// this needs to be brought out of redux-form

const thisYear = new Date().getFullYear();

let CompanyForm = props => {
  const {
    handleSubmit,
    activeCompany,
    isUpdate,
    addAttachment,
    addedAttachments,
    removeAttachment,
    selectedState,
    founded,
    isPerrp,
    change
  } = props;

  const [year, setYear] = useState(thisYear);
  const [areaHours, setAreaHours] = useState();
  const [groupHours, setGroupHours] = useState(null);
  const [areaGroupsDontMatch, setAreaGroupsDontMatch] = useState([]);
  useEffect(() => {
    if (activeCompany) {
      getHoursWorked(year).then(data => {
        setGroupHours(data.groups);
        setAreaHours(data.areas);
        checkHours(data.groups, data.areas);
      });
    }
  }, [activeCompany, year, isUpdate]);
  const checkHours = (groupParam, areaParam) => {
    setAreaGroupsDontMatch(
      groupParam?.filter(group => {
        const allAreas = areaParam?.filter(a => a.groupId === group._id);
        if (!allAreas?.length) return false;
        const areas = allAreas.reduce(
          (a, area) => a + area?.hoursWorked.reduce((c, d) => c + (d ?? 0), 0),
          0
        );
        const groups = group?.hoursWorked?.reduce((a, b) => a + (b ?? 0), 0);
        return groups - areas < 0;
      })
    );
  };

  return (
    <form
      className="companyForm"
      onSubmit={v =>
        handleSubmit({ ...v, hours: { groups: groupHours, areas: areaHours } })
      }
    >
      {isUpdate ? (
        <div className="form-fields--logo">
          <img
            src={
              (activeCompany && activeCompany.logoUrl) ||
              require('../../assets/images/no-image-placeholder.jpg')
            }
            alt={`${activeCompany.name}'s Logo`}
          />
        </div>
      ) : (
        ''
      )}
      <div className="form-fields--split">
        <div className="form-fields--attachmentsLeftSide">
          {addedAttachments && addedAttachments.length > 0 ? (
            <div className="form-fields--uploaded">
              <img
                src={addedAttachments[0].source_url}
                alt="Uploaded Logo"
                className="form-fields--attachmentsLeftSideImage"
              />
              <Button
                text="Remove Logo"
                color="red"
                onClick={() => removeAttachment()}
                className="form-fields--hoverButton"
              />
            </div>
          ) : (
            <Field
              name="attachments"
              type="text"
              component={FileCropper}
              placeholder={''}
              activeCompany={activeCompany}
              addAttachment={addAttachment}
            />
          )}
        </div>
        <div className="form-fields--attachmentsRightSide">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Division Name"
            validate={[required]}
            placeholder="e.g. Aperture Science Human Resources"
            requiredLabel={true}
          />
          <Field
            name="phoneNumber.fullPhoneNumber"
            type="text"
            component={renderField}
            label="Phone"
            placeholder="(000) 000-0000"
            normalize={normalizePhone}
            validate={[required]}
            requiredLabel={true}
          />
        </div>
      </div>
      <Field
        label="Division Address"
        name="mainAddress.streetAddress"
        type="text"
        component={renderField}
        requiredLabel={true}
        placeholder="Street Address"
        validate={[required]}
      />
      <InputRow>
        <Field
          name="mainAddress.city"
          type="text"
          component={renderField}
          placeholder="City"
          validate={[required]}
        />
        <Field
          id="state"
          name="mainAddress.stateName"
          type="text"
          component={renderDropdownField}
          placeholder="State"
          options={states}
          validate={[required]}
          searchable
        />
        <Field
          name="mainAddress.zip"
          type="text"
          component={renderNumberField}
          placeholder="ZIP Code"
          validate={[required, validZip]}
        />
      </InputRow>
      <Field
        name="website"
        type="text"
        component={renderField}
        label="Website"
        placeholder="e.g. www.ireportsource.com"
        normalize={normalizeWebsite}
      />
      {selectedState === 'OH' && (
        <Field
          name="reportingType"
          component={renderDropdownField}
          options={OSHA_PERRP_DROPDOWN}
          label="OSHA or PERRP"
          requiredLabel={true}
        />
      )}
      {selectedState === 'OH' && isPerrp === 2 && (
        <Field
          name="entityCode"
          component={renderDropdownField}
          options={PERRP_ENTITY_CODES}
          label="Entity Code"
        />
      )}
      {selectedState === 'OH' && isPerrp === 2 && (
        <Field
          name="bwcNumber"
          component={renderField}
          type="text"
          placeholder="e.g. 12345678-000"
          label="BWC Policy Number"
          normalize={normalizeBwcPolicy}
          requiredLabel={true}
          validate={required}
        />
      )}
      <InputRow>
        <Field
          name="NAICS"
          type="text"
          component={renderNumberField}
          label="NAICS"
          placeholder="000000"
          requiredLabel={true}
          validate={[required, validNAICS]}
          displayToolTip={true}
        >
          North American Industrial Classification
        </Field>
        <Field
          name="SIC"
          type="text"
          component={renderNumberField}
          label="SIC"
          placeholder="0000"
          warn={validSIC}
          displayToolTip={true}
        >
          Standard Industrial Classification
        </Field>
      </InputRow>
      <InputRow>
        <Field
          name="yearCompanyWasFounded"
          type="text"
          component={renderField}
          label="What year was the company founded?"
          placeholder="yyyy"
        />
      </InputRow>

      {
        //I assume this was going to be used, but since its not, comment out right now
        /* {activeCompany.showClaims ? (
        <TwoColumn>
          <Field
            name="policyProvider"
            component={renderDropdownField}
            label="Insurance Provider"
            options={POLICY_PROVIDERS_OPTIONS}
          />
          <Field
            component={renderField}
            type="text"
            label="Policy Number"
            name="policyNumber"
          />
        </TwoColumn>
      ) : (
        <></>
      )} */
      }
      {isUpdate && (
        <>
          <Field
            name="supervisorsWithAllIncidentAccess"
            component={renderEmployeeDropdown}
            label="Supervisors with access to all Incidents in their Group/Establishments"
            displayToolTip
            permissions={[400]}
          >
            Supervisors selected will get access/notifications for all incidents
            in the group/establishments they have access to. In order to
            restrict supervisors again, remove them from this list.
          </Field>
          <Field
            name="adminsWithConfidentialAccess"
            component={renderEmployeeDropdown}
            label="Can see confidential reports"
            displayToolTip
            permissions={[900]}
          >
            Admins selected will get access/notifications for all confidential
            incidents.
          </Field>
          <Field
            name="usersWithCustomDocConfidentialAccess"
            component={renderEmployeeDropdown}
            label="Can see confidential Custom Docs?"
            displayToolTip
            permissions={[900, 500]}
          >
            Users selected will get access/notifications for all confidential
            custom documents.
          </Field>
          <Field
            name="usersWithBoConfidentialAccess"
            component={renderEmployeeDropdown}
            label="Can see confidential Behavior Observations?"
            displayToolTip
            permissions={[900, 500]}
          >
            Users selected will get access/notifications for all confidential
            behavior observations.
          </Field>
        </>
      )}
      <InputRow>
        <Field
          name="hasLockoutTagout"
          type="checkbox"
          label="Enable Lockout/Tagout Procedures?"
          component={renderCheckbox}
        />
      </InputRow>
      {/* <InputRow>
        <Field
          name="turnOnCovidTracking"
          component={renderRadioButtons}
          label="Opt-in to Covid-19 Tracking"
          displayToolTip={true}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          value={activeCompany?.turnOnCovidTracking}
        >
          Opt in to track Covid-19 Vaccination, Testing and Exposure. Easily
          upload Test Results or Proof of Vaccination. Admins will be notified
          every Monday of employees who missed testing last week and employees
          who are not vaccinated will a weekly reminder and To-Do to get tested
        </Field>
      </InputRow> */}
      {isUpdate ? (
        <>
          {groupHours && areaHours ? (
            <HoursTable
              companyHours={groupHours}
              year={year}
              setYear={setYear}
              change={(fieldName, value) => {
                change(fieldName, value);
                checkHours(value.hours, areaHours);
              }}
              founded={founded}
              groupOrArea="Group"
            />
          ) : (
            <></>
          )}
          {activeCompany.groups.some(g =>
            g.projects.some(a => a.isSeparateEstablishment)
          ) && areaHours ? (
            <>
              {areaGroupsDontMatch?.length ? (
                <span className="form-error">
                  The Areas in the following Groups add to be greater than the
                  hours set for the Group:{' '}
                  {areaGroupsDontMatch?.map(g => g.name).join(', ')}
                </span>
              ) : (
                <></>
              )}
              <HoursTable
                companyHours={areaHours}
                year={year}
                setYear={setYear}
                change={(fieldName, value) => {
                  change(fieldName, value);
                  checkHours(groupHours, value.hours);
                }}
                founded={founded}
                groupOrArea="Area"
              />{' '}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </form>
  );
};

CompanyForm = reduxForm({
  form: 'CompanyForm',
  enableReinitialize: true
})(CompanyForm);

const selector = formValueSelector('CompanyForm');

const mapStateToProps = state => ({
  selectedState: selector(state, 'mainAddress.stateName'),
  founded: parseInt(selector(state, 'yearCompanyWasFounded'), 10),
  isPerrp: selector(state, 'reportingType')
});

export default connect(mapStateToProps)(CompanyForm);
