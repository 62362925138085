import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, required } from '../../utils/formValidation';
import Button from '../../components/inputs/Button';

import '../../components/inputs/input.css';
import '../form.css';

const ResetPasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <h2>Recover Your Password</h2>
      <Field
        className="RecoverField"
        name="username"
        type="text"
        component={renderField}
        label="Enter your username"
        validate={[required]}
      />
      <p>
        Your password will be reset and you will receive a notification to
        either your phone or email with a new password to use to log in.
      </p>
      <Button
        className="RecoverButton"
        type="submit"
        color="green"
        text="Reset"
      />
    </form>
  );
};

export default reduxForm({
  form: 'ResetPasswordForm'
})(ResetPasswordForm);
