import React from 'react';
import Modal from '.';

export default function MissingRequiredModal({
  isOpen,
  onRequestClose,
  subject,
  isEditing,
  isCompleting,
  isRunning,
  creatingMultiple,
  isSubmitting
}) {
  return (
    <Modal
      title="Missing Required Field(s)"
      titleClassName="redHeader"
      isOpen={isOpen}
      hideButtons
      onRequestClose={onRequestClose}
    >
      <div>
        <p>
          You must complete all required fields{' '}
          <span style={{ color: 'red' }}>(*)</span> to{' '}
          {isCompleting
            ? 'complete'
            : isEditing
            ? 'edit'
            : isRunning
            ? 'run'
            : isSubmitting
            ? 'submit'
            : 'create'}{' '}
          {creatingMultiple ? 'these' : 'this'} {subject}.
        </p>
      </div>
    </Modal>
  );
}
