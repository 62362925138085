import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export default class MarkItemAsCompleteModal extends Component {
  render() {
    return (
      <Modal
        title={`Mark ${this.props.completingWhat} Complete`}
        titleClassName={this.props.headerColor ?? 'greenHeader'}
        isOpen={this.props.isOpen}
        submitButtonColor={this.props.submitButtonColor ?? 'green'}
        submitButtonText="Mark Complete"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
        cancelButtonText={this.props.cancelButtonText ?? 'Later'}
        cancelButtonColor={this.props.cancelButtonColor ?? 'blue'}
        cancelActions={this.props.cancelActions ?? this.props.onRequestClose}
      >
        {this.props.message ?? (
          <div className={styles.text}>
            By Signing and Submitting your signature you are confirming that you
            have fully completed {this.props.completingWhat} to your utmost
            ability.
          </div>
        )}
      </Modal>
    );
  }
}
