import React, { useEffect, useMemo, useReducer } from 'react';
import { useTable } from 'react-table';

import Input, { Dropdown, Textbox } from '../inputs';
import { MONTHS } from '../../constants/constants';

export default function HoursTable(props) {
  const { companyHours, year, setYear, change, founded, groupOrArea } = props;
  const [hours, dispatchHours] = useReducer((state, action) => {
    switch (action.type) {
      case 'set':
      default:
        return action.hours;
      case 'update':
        let editedHours = [...state];
        let value = parseInt(action.value, 10);
        let idx = editedHours.findIndex(c => c._id === action.id);
        if (idx === -1) return state;
        if (Number.isNaN(value)) value = undefined;
        if (!editedHours[idx].hoursWorked) editedHours[idx].hoursWorked = [];
        editedHours[idx].hoursWorked[action.month] = value;

        change(groupOrArea.toLowerCase() + 'Hours', {
          hours: editedHours,
          year
        });
        return editedHours;
    }
  }, undefined);
  const thisYear = new Date().getFullYear();

  useEffect(() => {
    dispatchHours({ type: 'set', hours: companyHours });
  }, [companyHours]);

  let columns = useMemo(() => {
    let options = [];
    for (let i = founded || 2010; i <= thisYear; i++)
      options.push({ label: i, value: i });

    let columns = MONTHS.map((m, i) => ({
      Header: m,
      id: m,
      sortable: false,
      accessor: d => d?.hoursWorked?.[i],
      Footer: d =>
        d?.data?.reduce((acc, cur) => acc + (cur?.hoursWorked?.[i] ?? 0), 0),
      Cell: ({ row, value }) => (
        <Textbox
          type="number"
          currentValue={value ?? ''}
          name={`hoursWorked-${row.original._id}-${m}`}
          onChange={value => {
            dispatchHours({
              type: 'update',
              id: row.original._id,
              month: i,
              value
            });
          }}
          className="form-small-input"
        />
      )
    }));
    columns.unshift({
      Header: groupOrArea,
      accessor: 'name',
      Footer: 'Total'
    });
    columns.push({
      id: 'groupTotal',
      Header: (
        <Dropdown
          options={options}
          currentValue={year}
          onChange={setYear}
          className="form-small-dropdown"
        />
      ),
      accessor: d =>
        d?.hoursWorked?.reduce?.((acc, cur) => acc + (cur ?? 0), 0),
      Footer: d =>
        d?.data?.reduce(
          (acc, cur) =>
            acc +
            cur?.hoursWorked?.reduce?.(
              (hwAcc, hwCur) => hwAcc + (hwCur ?? 0),
              0
            ),
          0
        ),
      sortable: false
    });
    return columns; // eslint-disable-next-line
  }, [founded, year, setYear, thisYear]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: hours ?? [] });
  return (
    <Input
      fieldLabel={`Hours Worked Per ${groupOrArea}`}
      tooltip="We collect this data to generate your OSHA Compliance Forms and to compile your DART, TCIR, and LTIR metrics."
      displayTooltip
      name="hoursTable"
      className="hours-worked-table-parent"
    >
      <table {...getTableProps({ className: 'hours-worked-table' })}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </Input>
  );
}
