import { createSelector } from 'reselect';
import { isDiagram } from '../utils/attachmentSelector';

const attachmentSelector = state => state.attachments;

export const getAddedAttachmentsSelector = createSelector(
  attachmentSelector,
  attachments => attachments && attachments.list
);

export const getAttachmentLoadingSelector = createSelector(
  attachmentSelector,
  attachments => attachments && attachments.loading
);

export const getPhotoEvidenceSelector = createSelector(
  getAddedAttachmentsSelector,
  attachments => attachments?.filter(a => a.isPhotoEvidence)
);

export const getNonPhotoEvidenceDiagramSelector = createSelector(
  getAddedAttachmentsSelector,
  attachments => attachments?.filter(a => !a.isPhotoEvidence && !isDiagram(a))
);

export const getDiagramSelector = createSelector(
  getAddedAttachmentsSelector,
  attachments => attachments?.filter(a => !a.isPhotoEvidence && isDiagram(a))
);
