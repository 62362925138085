import React from 'react';
import moment from 'moment';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';

import './index.css';

const isPastDue = task => {
  return moment().diff(task.dueDate, 'days') > 0;
};

const isCompleted = task => {
  return task.isCompleted;
};

export default ({ task, handleReportSelection, canEditTask, loadTask }) => {
  return (
    <div className="reportTask">
      <div className="reportTaskCard">
        <div className="reportTaskCard-createdDate">
          <div>{moment(task.createdAt).format('hh:mm a')}</div>
          <div>{moment(task.createdAt).format('MM/DD/YYYY')}</div>
        </div>
        <h2
          className={
            isCompleted(task)
              ? 'reportTaskCard-header greenHeading'
              : isPastDue(task)
              ? 'reportTaskCard-header redHeading'
              : 'reportTaskCard-header blueHeading'
          }
        >
          <div className="reportTaskCard-headerText">
            {isCompleted(task)
              ? 'Task Completed'
              : isPastDue(task)
              ? ' Task Past Due'
              : 'Assigned To Task'}
          </div>
        </h2>
        <div className="reportTaskCard-content">
          {task.reportComponentName ? (
            <div className="reportTaskCard-contentSummary">
              <div
                className={
                  isCompleted(task)
                    ? 'reportTaskCard-reportContainerGreen'
                    : isPastDue(task)
                    ? 'reportTaskCard-reportContainerRed'
                    : 'reportTaskCard-reportContainerBlue'
                }
              >
                <div className="reportTaskCard-reportContainerText">
                  {task.reportComponentName}
                </div>
              </div>
              <div className="reportTaskCard-dueDate">
                <span>Due: </span>{' '}
                {moment(task.dueDate).format('MM/DD/YYYY hh:mm a')}
              </div>
            </div>
          ) : (
            <div className="reportTaskCard-contentSummary">
              <div className="reportTaskCard-customContainer">
                <div className="reportTaskCard-customTaskText">Custom Task</div>
                <div className="reportTaskCard-customTaskTextLabel">
                  {task.label}
                </div>
              </div>
              <div className="reportTaskCard-dueDate">
                <span>Due: </span>{' '}
                {moment(task.dueDate).format('MM/DD/YYYY hh:mm a')}
              </div>
            </div>
          )}
          <div
            className={
              isCompleted(task)
                ? 'reportTaskCard-assigneeGreen'
                : isPastDue(task)
                ? 'reportTaskCard-assigneeRed'
                : 'reportTaskCard-assigneeBlue'
            }
          >
            Assignee
          </div>
          <UserBadge
            userId={task?.assignedTo}
            showImage
            showName
            className="reportTaskCard-assignee"
            hideMultipleUsers={
              Array.isArray(task?.assignedTo) && task?.assignedTo?.length > 1
            }
          />
        </div>
      </div>
      <div className="reportTaskCard-buttons">
        {task.reportComponentName ? (
          <Button
            onClick={() => handleReportSelection(task)}
            type="button"
            text={canEditTask ? 'Edit' : 'View'}
            color="white"
          />
        ) : (
          <Button
            onClick={() => loadTask()}
            type="button"
            text={canEditTask ? 'Edit Task' : 'View Task'}
            color="white"
          />
        )}
      </div>
    </div>
  );
};
